import Strings from "../../constants/localization/localization";
import {Dimensions, Keyboard, Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import SelectableDayOfWeek from "../AvailabilityCalendar/SelectableDayOfWeek";
import Dialog from "../dialog/Dialog";
import React, { useState } from "react";
import { useMakeBayAvailableContext } from "./context";
import { ParkAvailability } from "../../constants/getParkAvailability";
import {Colours, Input, Select, Text} from "react/parkable-components";

export enum RecurrentSharingActions {
    Share = "Share",
    Unshare = "Unshare",
}

export enum RecurrentSharingDurations {
    Week = "Week",
    Month = "Month"
}

const RecurrentSharingDurationOptions = [
    {
        label: Strings.month,
        value: RecurrentSharingDurations.Month,
    },
    {
        label: Strings.week,
        value: RecurrentSharingDurations.Week
    }
]

type RecurrentSharingDialogProps = {
    parkAvailability: ParkAvailability,
    onSubmit: () => void;
}

export const RecurrentSharingDialog = (props: RecurrentSharingDialogProps) => {

    const { parkAvailability, onSubmit } = props;
    const [sharingDurationValueError, setSharingDurationValueError] = useState<string | null>(null);

    const context = useMakeBayAvailableContext();

    const onChangingSharingDurationValue = (value: string) => {

        if (isNaN(+value)) {
            setSharingDurationValueError(Strings.must_be_a_number);
            return;
        }

        const isMonthlyDurationError = context?.sharingDurationType === RecurrentSharingDurations.Month && +value > 12;
        const isWeeklyDurationError = context?.sharingDurationType === RecurrentSharingDurations.Week && +value > 52;

        if (isMonthlyDurationError) {
            setSharingDurationValueError(Strings.max_number(12));
            context?.setSharingDurationValue("12");
            return;
        }

        if (isWeeklyDurationError) {
            setSharingDurationValueError(Strings.max_number(52));
            context?.setSharingDurationValue("52");
            return;
        }

        context?.setSharingDurationValue(value);
        setSharingDurationValueError(null);

    }

    const onChangingSharingDurationType = (value: RecurrentSharingDurations) => {
        const isMonthlyDurationError = value === RecurrentSharingDurations.Month && (context?.sharingDurationValue ?? 0) > 12;
        const isWeeklyDurationError = value === RecurrentSharingDurations.Week && (context?.sharingDurationValue ?? 0) > 52;

        if (isMonthlyDurationError) {
            context?.setSharingDurationValue("12");
        }

        if (isWeeklyDurationError) {
            context?.setSharingDurationValue("52");
        }

        context?.setSharingDurationType(value)
        setSharingDurationValueError(null);
    }

    const onConfirmPress = () => {

        if (!context?.sharingDurationValue || +context?.sharingDurationValue === 0) {
            setSharingDurationValueError(Strings.required);
            return;
        }

        onSubmit();
    }

    const pickerStyle = {
        inputIOS: styles.pickerText,
        inputAndroid: styles.pickerText,
        inputWeb: styles.pickerText,
        viewContainer: styles.pickerViewContainer,
    }

    return (
        <Dialog
            isVisible={context?.showSharingRecurrenceDialog}
            label={Strings.sharing_recurrence}
            labelProps={{ style: {color: Colours.NEUTRALS_BLACK, textAlign: 'left'} }}
            positiveText={Strings.confirm}
            negativeText={Strings.cancel}
            positiveProps={{ disabled: !context?.sharingDurationType }}
            onPositivePress={() => onConfirmPress()}
            onNegativePress={() => context?.setShowSharingRecurrenceDialog(false)}
        >
            <View style={{ width: Platform.OS === "web" ? 325 : "100%" }}>
                <View style={styles.recurrenceActionContainer}>
                    <TouchableOpacity onPress={() => context?.setSharingAction(RecurrentSharingActions.Share)} style={styles.toggleButton}>
                        <Text
                            bold={context?.sharingAction === RecurrentSharingActions.Share}
                            style={[styles.toggleText, {backgroundColor: context?.sharingAction === RecurrentSharingActions.Share ? Colours.NEUTRALS_WHITE : Colours.TRANSPARENT}]}>
                            {Strings.share_bay}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => context?.setSharingAction(RecurrentSharingActions.Unshare)} style={styles.toggleButton}>
                        <Text
                            bold={context?.sharingAction === RecurrentSharingActions.Unshare}
                            style={[styles.toggleText, {backgroundColor: context?.sharingAction === RecurrentSharingActions.Unshare ? Colours.NEUTRALS_WHITE : Colours.TRANSPARENT}]}>
                            {Strings.unshare_bay}
                        </Text>
                    </TouchableOpacity>
                </View>

                {context?.sharingAction === RecurrentSharingActions.Share &&
                    <View style={styles.recurrenceDurationContainer}>
                        <Text grey>{Strings.choose_sharing_duration}</Text>
                        <View style={styles.singleLineContainer}>
                            <Input
                                keyboardType={'numeric'}
                                number
                                returnKeyType={'done'}
                                error={sharingDurationValueError}
                                style={{width: 146.5}}
                                value={context?.sharingDurationValue}
                                onChangeText={onChangingSharingDurationValue}
                                onSubmitEditing={Keyboard.dismiss}
                            />
                            <Select
                                items={RecurrentSharingDurationOptions}
                                value={context?.sharingDurationType}
                                onValueChange={onChangingSharingDurationType}
                                pickerStyle={pickerStyle}
                                style={{height: 45, width: 165}}
                            />
                        </View>
                    </View>}

                <View style={styles.daysContainer}>
                    <Text grey>{Strings.choose_days}</Text>
                    <SelectableDayOfWeek
                        parkAvailability={parkAvailability.availability}/>
                </View>
            </View>
        </Dialog>
    )
}

const styles = StyleSheet.create({
    recurrenceActionContainer: {
        display: "flex",
        flexDirection: "row",
        padding: 5,
        backgroundColor: "#EDF1F3"
    },
    toggleButton: {
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    toggleText: {
        width: "100%",
        paddingTop: 10,
        textAlign: "center",
        paddingBottom: 10,
    },
    recurrenceDurationContainer: {
        marginTop: 25,
    },
    singleLineContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch',
    },
    pickerText: {
        backgroundColor: Colours.TRANSPARENT,
        borderColor: Colours.TRANSPARENT,
    },
    pickerViewContainer: {
        flex: 1,
        justifyContent: "center",
    },
    daysContainer: {
        marginBottom: 25,
        marginTop: 25,
    },
    dayOfWeekHeaderContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    }
});