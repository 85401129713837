import {FlatList, ScrollView, StyleSheet, View} from "react-native";
import React, { useEffect, useState } from "react";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Button from "react/parkable-components/button/Button";
import CheckBox from "react/parkable-components/checkBox/CheckBox";
import Spinner from "react/parkable-components/spinner/Spinner";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import { useBaysUserIsMember, useUserParkingPreference, createParkingPreferences, updateParkingPreferences } from "../../api/bay/bay.api";
import Strings from "../../constants/localization/localization";
import { ParkingPreferenceStatus } from "../../model/ParkingPreferenceDTO";
import { sortBaysBySignage } from "../../constants/sortBaysBySignage";
import { Bay } from "../../model/Bay";
import {createRoute, NavigationProps} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import {useAlertSnackbar} from "../../root/alert-snackbar/alert-snackbar";
import ParkableBaseView from "../common/ParkableBaseView";

class PreferredBaysSelectionViewParams {
    parkId: number;
    bayGroupId?: number;
    bayGroupName: string;
    hasNoSignageBays?: boolean;
}

type Props = NavigationProps<Routes.PreferredBaysSelectionView>;

const PreferredBaysSelectionView = (props: Props) => {
    const { parkId, bayGroupId, bayGroupName, hasNoSignageBays} = props.route.params;

    const {showSnackBar} = useAlertSnackbar();

    const {bays: _bays} = useBaysUserIsMember(parkId);
    const {
        preferences: parkingPreferences,
        mutate: preferencesRevalidate
    } = useUserParkingPreference();

    const [selectedBays, setSelectedBays] = useState<number[]>([]);
    const [unSelectedBays, setUnSelectedBays] = useState<number[]>([]);
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [flatlistData, setFlatlistData] = useState<Bay[]>([]);
    const pageLimit = 15;

    useEffect(() => {
        if (!!_bays) {
            setFlatlistData(loadMore(page));
        }
    }, [_bays]);

    if (!_bays || !parkingPreferences) {
        return <Spinner />;
    }

    // Tandem MVP: Don't show bays in a tandem pod - Faiz.
    const bays = _bays.filter((bay) => !bay.tandemPod);
    const _baysInGroup = !bayGroupId
        ? bays
        : bays.filter(bay => bay.group === bayGroupId);

    const baysInGroup = sortBaysBySignage(_baysInGroup);

    const parkingPreferencesInBayGroup = parkingPreferences.filter(pref =>
        !!baysInGroup.find(bay => bay.id === pref.bay && pref.park === bay.park)
    );

    const totalSelectedBays = parkingPreferencesInBayGroup.length + selectedBays.length - unSelectedBays.length;
    const dirtyForm = unSelectedBays.length > 0 || selectedBays.length > 0;

    const handleSelectAll = () => {
        if (totalSelectedBays !== baysInGroup.length) {
            setUnSelectedBays([]);
            // Collect all bays that are not yet a parking preference.
            const baysToBeSelected = baysInGroup
                .filter(bay => !parkingPreferencesInBayGroup.find(pref => pref.bay === bay.id))
                .map(bay => bay.id);
            setSelectedBays([...baysToBeSelected]);
        }
        else {
            setSelectedBays([]);
            // Collect all bays that are a parking preference.
            const baysToBeDeselected = baysInGroup
                .filter(bay => !!parkingPreferencesInBayGroup.find(pref => pref.bay === bay.id))
                .map(bay => bay.id);
            setUnSelectedBays([...baysToBeDeselected]);
        }
    };

    const currentlySelected = (bayId: number) => {
        // Bay is currently selected if it's found in parking preferences or selected bays AND not in unselected bays.
        return (!!parkingPreferencesInBayGroup.find(pref => pref.bay === bayId)
            || !!selectedBays.find(sb => sb === bayId))
            && !unSelectedBays.find(bay => bay === bayId);
    };

    const handleCheck = (bayId: number) => {
        if (currentlySelected(bayId)) {
            // If currently selected and is a current preference then add it to the list to be unselected.
            if (!!parkingPreferences.find(pref => pref.bay === bayId)) {
                setUnSelectedBays(unSelectedBays => [...unSelectedBays, bayId]);
            }
            setSelectedBays(selectedBays => selectedBays.filter(bay => bay !== bayId));
            return;
        }
        // If not a current parking preference then add it to the list to be selected.
        if (!parkingPreferences.find(pref => pref.bay === bayId)) {
            setSelectedBays(selectedBays => [...selectedBays, bayId]);
        }
        setUnSelectedBays(unSelectedBays => unSelectedBays.filter(bay => bay !== bayId));
    };

    const handleSave = async () => {
        setLoading(true);

        // Collect the preference repo IDs.
        const unSelectedPreferenceIds = parkingPreferencesInBayGroup
            .filter(pref => !!unSelectedBays.find(bay => pref.bay === bay))
            .map(pref => pref.id);

        try {
            await createParkingPreferences(parkId, selectedBays);
            await updateParkingPreferences(
                unSelectedPreferenceIds,
                ParkingPreferenceStatus.DELETED
            );
            showSnackBar({text: Strings.preferred_bays.success, hideDismiss: true})
        }
        catch(error) {
            showSnackBar({
                text: Strings.preferred_bays.error,
                hideDismiss: true,
                style: {
                    backgroundColor: Colours.RED
                }
            });
        }
        setLoading(false);
        await preferencesRevalidate();
        setSelectedBays([]);
        setUnSelectedBays([]);
    };

    const loadMore = (page: number) => {
        return baysInGroup.slice((page - 1) * pageLimit, page * pageLimit);
    }

    const handleLoadMore = () => {
        setFlatlistData(data => data.concat(loadMore(page + 1)));
        setPage(page => page + 1);
    };

    const renderFlatlistItem = ({item: bay}: {item: Bay}) => {
        return (
            <TableRow
                key={bay.id}
                contentRight={
                    <CheckBox
                        checkStyle={{ borderColor: Colours.BLUE_300 }}
                        checked={currentlySelected(bay.id)}
                        onChange={() => handleCheck(bay.id)}
                    />
                }
                onPress={() => handleCheck(bay.id)}
            >
                {bay.signage}
            </TableRow>
        )
    };

    const renderFlatlistFooter = () => {
        if (flatlistData.length !== baysInGroup.length) {
            return <Spinner />
        }
        return <></>;
    }

    return (
    <ParkableBaseView scrollable={false} loading={loading}>
        <View style={{flex: 1}}>
            <View style={styles.container}>
                <Text h1 bold>{bayGroupName}</Text>
                <ScrollView style={styles.baysSelectionContainer}>

                    {hasNoSignageBays && (<>
                        <Text style={{ textTransform: "uppercase" }} bold>{Strings.bays}</Text>
                        <TableRow
                            hideBorder
                            contentRight={
                                <CheckBox
                                    checkStyle={{borderColor: Colours.BLUE_300}}
                                    onChange={() => handleSelectAll()}
                                    checked={totalSelectedBays === baysInGroup.length}
                                />}>
                            {Strings.preferred_bays.all_bays_in_group}
                        </TableRow>
                    </>)}

                    {!hasNoSignageBays && (<>
                        <View style={styles.topRowContainer}>
                            <Text style={{ textTransform: "uppercase" }} bold>{Strings.bays}</Text>
                            <Text style={{ color: Colours.BLUE_300 }} onPress={() => handleSelectAll()}>
                                {totalSelectedBays === baysInGroup.length
                                    ? Strings.preferred_bays.deselect_all
                                    : Strings.preferred_bays.select_all
                                }
                            </Text>
                        </View>
                        <FlatList
                            data={flatlistData}
                            renderItem={renderFlatlistItem}
                            initialNumToRender={pageLimit-1}
                            showsVerticalScrollIndicator={false}
                            bounces={false}
                            onEndReached={handleLoadMore}
                            onEndReachedThreshold={0.5}
                            ListFooterComponent={renderFlatlistFooter}
                        />
                    </>)}

                </ScrollView>
            </View>
        </View>
        <View style={styles.buttonContainer}>
            <Button
                center
                onPress={handleSave}
                disabled={!dirtyForm}>
                {Strings.preferred_bays.save}
            </Button>
        </View>
    </ParkableBaseView>);
};

export default PreferredBaysSelectionView;

export const PreferredBaysSelectionViewRoute = createRoute({
    path: Routes.PreferredBaysSelectionView,
    params: {type: PreferredBaysSelectionViewParams},
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    baysSelectionContainer: {
        paddingTop: 7,
    },
    topRowContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 18,
    },
    buttonContainer: {
        padding: 17,
    },
});
