import React, { useCallback, useEffect, useRef, useState } from "react";
import { AppState, AppStateStatus, Image, ScrollView, View } from "react-native";
import { contentStyles, headerRowStyles, headingStyles, legendStyles, styles } from "./styles";
import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "../../constants/colors";
import Strings from "../../constants/localization/localization";
import { DayTile } from "./day-tile/day-tile";
import moment, { Moment } from "moment-timezone";
import {
    computeFutureBookingStatus,
    filterAvailableDays,
    getDayTileProps,
    legendItems,
    NextRequestState
} from "./constants";
import { LegendItem } from "./legend-item/legend-item";
import { TouchableOpacity } from "react-native-gesture-handler";
import { IRootReducer } from "../../redux/reducers/main";
import { connect } from "react-redux";
import { OwnProps } from "./types";
import {
    createFutureBookingParkingRequest,
    updateParkingRequest,
    useParkingRequestsForUser
} from "../../api/parking-request/parking-request.api";
import { ParkingRequest, ParkingRequestPriority, ParkingRequestStatus } from "../../model/ParkingRequest";
import {
    convertAvailabilityToWeekArray,
    convertMultipleParksAvailabilityToWeekArray,
    generateDefaultWeekArray
} from "../../model/Availability";
import { showAlert } from "../../alerts";
import { useCampus, useCampusesInOrganisation } from "../../api/campus/campus.api";
import { useAllocation } from "../../api/allocation/allocation.api";
import { useActiveParkSession } from "../../api/parkSession/parkSession.api";
import { getCurrentParkingSession } from "../../redux/actions/parking";
import { DispatchFunc } from "../../model/Types";
import { useParkingRequestOptions } from "../../api/parkingRequestOptions/parkingRequestOptions.api";
import { Nully } from "../../constants/nully";
import { useWaitlistForAllocationAndLocation } from "../../api/waitlist/waitlist.api";
import { DayRowHeader } from "./day-row-header/day-row-header";
import { usePark } from "../../api/park";
import * as Push from "../../navigation/pushNotifications/constants";
import { addNotificationListener } from "../../navigation/pushNotifications/notificationListener";
import { Waitlist } from "../../api/waitlist/dto/Waitlist";
import { ParkSessionDTO } from "../../model/ParkSessionDTO";
import { useParks } from "../../api/park/park.api";
import { userIsOrgMember } from "../../constants/getUserMember";
import PreferredBaysRow from "../preferredBays/PreferredBaysRow";
import { useUserRoles } from "../../api/user/user.api";
import { Routes } from "../../navigation/root/root.paths";
import { createRoute, NavigationProps, useNavigation } from "../../navigation/constants";
import { useAlertSnackbar } from "../../root/alert-snackbar/alert-snackbar";
import ParkableBaseView from "../common/ParkableBaseView";
import { PADDING } from "../../root/root.constants";
import ConfirmationDialog from "../widgets/confirmation-dialog/ConfirmationDialog";
import getParkAvailability from "react/constants/getParkAvailability";

const helpIcon = require("../../../react/resources/helpDarkBlue.png");

const EXISTING_BOOKING_FOR_DAY = 601;
const PRIORITY_REQUEST_LIMIT_REACHED = 602;

const getReduxProps = (state: IRootReducer) => {
    return { currentUser: state.user.user, };
};

const momentJodaFormat = 'YYYY-MM-DD';

type ParkingRequestEx = Omit<ParkingRequest, 'createdAt'> & {
    createdAt: moment.Moment
}

export type DialogResponse = 'Prioritise' | 'Deprioritise' | 'CancelRequest' | 'DismissDialog';

class FutureBookingParams {
    parkId?: number | string;
    campusId?: number | string;
    organisationId?: number | string;
}

const parseIntParam = (param?: number | string) => typeof param === "string" ? parseInt(param) : param

const _FutureBookingView = (
    props: OwnProps & ReturnType<typeof getReduxProps> & { dispatch: DispatchFunc } & NavigationProps<Routes.FutureBookingView>
) => {
    const navigation = useNavigation();
    const {showSnackBar} = useAlertSnackbar();

    const { currentUser } = props;
    const navigationParams = props.route?.params;
    const parkId = parseIntParam(navigationParams.parkId);
    const campusId = parseIntParam(navigationParams.campusId);
    const locationId = campusId ?? parkId;
    const isLocationPark = !campusId;
    const { park } = usePark(parkId)
    const {userRoles} = useUserRoles();
    const organisationId = parseIntParam(navigationParams.organisationId) ?? park?.organisation;
    const { campus } = useCampus(park?.ownerOrganisation, campusId);
    const { campuses } = useCampusesInOrganisation(park?.ownerOrganisation);
    const { parks: campusParks } = useParks(campus?.parks);

    const { parkSession } = useActiveParkSession();

    const { parkingRequests: _parkingRequests, mutate } = useParkingRequestsForUser(currentUser?.id);

    const { options } = useParkingRequestOptions(organisationId, parkId, campusId, campus?.organisation);
    const { priorityRequestsPerUser } = options ?? {};

    const isUserPrivateOrganisation = userIsOrgMember(userRoles, organisationId);

    const onAppStateChange = (nextAppState: AppStateStatus) => {
        if (nextAppState === 'active') {
            void mutate();
        }
    }

    useEffect(() => {
        const subscription = AppState.addEventListener('change', onAppStateChange);
        return () => subscription.remove();
    }, []);

    const parkingRequests: ParkingRequestEx[] | undefined = _parkingRequests?.
        filter(r => (parkId && r.park === parkId) || (campusId && r.campus === campusId))
        .filter(r => r.status !== ParkingRequestStatus.CancelledUser)
        .filter(r => r.status !== ParkingRequestStatus.CancelledAdmin)
        .filter(r => r.status !== ParkingRequestStatus.Spent || !!r.session)
        .map((p) => ({
            ...p,
            createdAt: moment.tz(p.createdAt, "utc"),
        }));

    const [weeksToShow, setWeeksToShow] = useState(1);

    const today = moment();
    const weekStart = today.clone()
        .startOf("week") //moment uses Sunday as start of week
        .add({ days: 1 })
        .startOf("day");
    const nextWeekStart = weekStart.clone().add({ days: 7 });
    const weeks = [...new Array(
        2 // For this week + next week
        + weeksToShow // Variable amount for upcoming weeks
    )].map((_, week) =>
        [...new Array(7)].map((x, days) => weekStart.clone().add({ days, week })));

    const { allocation: thisWeekAllocation } = useAllocation(organisationId, weekStart.format(momentJodaFormat));
    const { allocation: nextWeekAllocation } = useAllocation(organisationId, nextWeekStart.format(momentJodaFormat));

    const { waitlist: thisWeekWaitlist, mutate: revalidateThisWeek } = useWaitlistForAllocationAndLocation(organisationId, thisWeekAllocation?.id, locationId, isLocationPark);
    const { waitlist: nextWeekWaitlist, mutate: revalidateNextWeek } = useWaitlistForAllocationAndLocation(organisationId, nextWeekAllocation?.id, locationId, isLocationPark);

    const [loadingDays, setLoadingDays] = useState<Set<string /*YYYY-MM-DD*/>>(new Set());

    const [showConfirmation, setShowConfirmation] = useState({
        currentPriority: ParkingRequestPriority.Normal,
        hasAllocation: false,
        hasReachedPriorityLimit: false,
        show: false
    });
    const updateConfirmation = useRef<((confirmed: DialogResponse) => void) | undefined>(undefined);

    const closeConfirmation = (confirmed: DialogResponse) => {
        updateConfirmation.current?.(confirmed);
        setShowConfirmation(current => ({ ...current, show: false }));
    };

    const onNotificationReceived = useCallback(async (code: string) => {
        if (code === Push.ParkingRequestUpdated || code === Push.OpenBookingsView) {
            await mutate();
            await revalidateThisWeek();
            await revalidateNextWeek();
            return true;
        }
        return false;
    }, []);

    useEffect( () => {
        const notifListener = addNotificationListener(onNotificationReceived, '_FutureBookingView');
        return () => { notifListener.remove()};
    }, ["once"]);

    useEffect(() => {
        if ((!parkId || isNaN(parkId)) && (!campusId || isNaN(campusId))) {
            navigation.pop();
        }
    }, [parkId, campusId]);

    const weekAvailability = (() => {
        if (!!park) {
            return convertAvailabilityToWeekArray(park.availability);
        } else if (!!campus && campusParks && campusParks.length > 0) {
            return convertMultipleParksAvailabilityToWeekArray(campusParks.map(p => p.availability));
        } else {
            return generateDefaultWeekArray();
        }
    })();

    const filteredWeeks = filterAvailableDays(weeks, weekAvailability);

    // Display campus name (if available) first.
    const locationName = campus?.name ?? park?.displayName ?? park?.address ?? "";

    const handleTodayTap = (request?: ParkingRequestEx) => {

        if (!!parkSession) {
            props.dispatch(getCurrentParkingSession()); //so it is in redux
            props.navigation.navigate(Routes.ActiveSession, {
                sessionId: parkSession.id
            })
        } else if (park) {
            const parkAvailability = getParkAvailability(park);
            // if park has not yet opened today, go to confirmed booking view
            if (!parkAvailability?.isOpenNow && request) {
                navigation.navigate(Routes.ConfirmedBookingView, { parkingRequestId: request.id })
            } else {
                props.navigation.navigate(Routes.ParkDetailView, { parkId })
            }
        } else if (!!campusId) {
            const campusPark = campusParks?.find((cp) => cp.id === request?.park);
            const campusParkAvailability = campusPark && getParkAvailability(campusPark);
            if (!campusParkAvailability?.isOpenNow && request) {
                navigation.navigate(Routes.ConfirmedBookingView, { parkingRequestId: request.id })
            } else {
                props.navigation.navigate(Routes.CampusScreenView, {
                    campusId,
                    organisationId: campus?.organisation
                });
            }
        }
    }

    const handleCreateParkingRequest = async (date: Moment) => {
        if ((!!park || !!campus) && !!currentUser) {
            try {
                setLoadingDays(current => new Set([...current, date.format(momentJodaFormat)]));

                let campusId = campus?.id;
                let parkId = campusId ? undefined : park?.id;
                if (!campus && !!park) {
                    const campusForPark = campuses?.find(c => c.parks.includes(park.id));
                    if (!!campusForPark) {
                        campusId = campusForPark.id;
                        parkId = undefined;
                    }
                }

                if (campusId && !organisationId) {
                    showAlert(Strings.organisation_id_required, Strings.booking_failed);
                    return;
                }
                const orgId = campus ? campus.organisation : organisationId!;
                const newRequest = await createFutureBookingParkingRequest({
                    date: date.format(momentJodaFormat),
                    parkId,
                    campusId,
                    organisationId: orgId,
                    vehicleId: currentUser.vehicleId ?? undefined,
                });

                await mutate();
                revalidateForRequest(newRequest?.parkingRequest);

                switch (newRequest?.parkingRequest?.status) {
                    case ParkingRequestStatus.Accepted: {
                        showSnackBar({text: Strings.request_successful, hideDismiss: true});
                        break;
                    }
                    case ParkingRequestStatus.Confirmed: {
                        showSnackBar({text: Strings.booking_confirmed, hideDismiss: true});
                        break;
                    }
                    case ParkingRequestStatus.WaitList: {
                        showSnackBar({text: Strings.added_to_waitlist, hideDismiss: true});
                        break;
                    }
                }

            } catch (_e) {
                const e = _e as any;
                console.log("error on create request", JSON.stringify(e?.response?.data));
                if (typeof e?.response?.data?.message === "string" && e.response.data.message.length > 0) {

                    if (e.response.data.code === EXISTING_BOOKING_FOR_DAY ||
                        e.response.data.message.includes("outstanding request") /*can remove once api is always returning error code*/) {
                        showSnackBar({
                            text: Strings.youve_already_got_a_request_on_this_day,
                            isStatic: true,
                            style: {backgroundColor: Colours.Red},
                            onPress: () => {
                                props.navigation.navigate(Routes.UserBookingsAndRequestsView);
                            }
                        })
                    } else if (e.response.data.errorCode === "PARK_OPEN") {
                        showSnackBar({
                            text: Strings.booking_unavailable_park_is_open,
                            isStatic: true,
                            style: {backgroundColor: Colours.Red},
                            onPress: () => {
                                props.navigation.navigate(Routes.UserBookingsAndRequestsView);
                            }
                        })
                    } else {
                        showAlert(e.response.data.message, Strings.booking_failed);
                    }
                }
            } finally {
                setLoadingDays(current => new Set([...current].filter(d => d !== date.format(momentJodaFormat))));
            }
        }
    };

    const revalidateForRequest = (request: Nully<ParkingRequest>) => {
        if (!request?.allocation) {
            return
        }
        if (request.allocation === thisWeekAllocation?.id) {
            revalidateThisWeek().then(() => { });
        } else if (request.allocation === nextWeekAllocation?.id) {
            revalidateNextWeek().then(() => { });
        }
    }

    const getUpdateType = async (existingRequest: ParkingRequestEx, priorityRequestsPerUser: number): Promise<NextRequestState | null> => {

        const hasReachedPriorityLimit = existingRequest.priority !== ParkingRequestPriority.High && ((parkingRequests
            ?.filter(pr => pr.id !== existingRequest.id)
            ?.filter(pr => pr.priority === ParkingRequestPriority.High)
            ?.filter(pr => pr.weekStarting === existingRequest.weekStarting)
            ?.length ?? 0) >= priorityRequestsPerUser)

        setShowConfirmation({
            currentPriority: existingRequest.priority ?? ParkingRequestPriority.Normal,
            hasAllocation: !!existingRequest.allocation,
            show: true,
            hasReachedPriorityLimit
        });

        const confirmed = await new Promise<DialogResponse>((resolve) => {
            updateConfirmation.current = resolve;
        });
        switch (confirmed) {
            case "CancelRequest": {
                return {
                    nextStatus: ParkingRequestStatus.CancelledUser,
                    nextPriority: undefined,
                }
            }
            case "Deprioritise": {
                return {
                    nextStatus: undefined,
                    nextPriority: ParkingRequestPriority.Normal,
                }
            }
            case "Prioritise": {
                return {
                    nextStatus: undefined,
                    nextPriority: ParkingRequestPriority.High,
                }
            }
            case "DismissDialog": {
                return null;
            }
        }
    }

    const handleUpdateParkingRequest = async (
        position: {
            left: number;
            top: number;
        },
        existingRequest: ParkingRequestEx) => {

        if (existingRequest.status === ParkingRequestStatus.Confirmed) {
            navigation.navigate(Routes.ConfirmedBookingView, {
                parkingRequestId: existingRequest.id,
                revalidate: () => mutate()
            })
            return;
        }

        if (priorityRequestsPerUser === undefined) {
            return;
        }

        const updates = await getUpdateType(existingRequest, priorityRequestsPerUser);

        setShowConfirmation(current => ({ ...current, show: false }));

        if (!updates) {
            return;
        }

        try {
            setLoadingDays(current => new Set([...current, existingRequest.date]));

            const updated = await updateParkingRequest(existingRequest.id, {
                status: updates.nextStatus,
                priority: updates.nextPriority,
            });

            await mutate();

            revalidateForRequest(updated?.parkingRequest);
        } catch (_e) {
            const e = _e as any;
            console.log("error on update request", e, e?.response);
            if (e?.response?.data?.message) {
                if (e.response.data.code === PRIORITY_REQUEST_LIMIT_REACHED ||
                    e.response.data.message.includes("limit") /*can remove once api is always returning error code*/) {
                    showAlert(e.response.data.message, Strings.common.limit_reached);
                } else {
                    showAlert(e.response.data.message, Strings.error);
                }
            }
        } finally {
            setLoadingDays(current => new Set([...current].filter(d => d !== existingRequest.date)));
        }
    };

    const renderDay = (day: Moment, i: number, waitlist: Nully<Waitlist>, parkSession: Nully<ParkSessionDTO>) => {
        const request = parkingRequests?.find(
            (p) => p.date === day.format(momentJodaFormat)
        );
        const status = computeFutureBookingStatus(request, waitlist);
        const tileProps = getDayTileProps(day, today, parkSession);
        return (
            <DayTile
                key={i}
                status={status}
                loading={loadingDays.has(day.format(momentJodaFormat))}
                onClick={(position) => {

                    if (tileProps.isToday) {
                        handleTodayTap(request)
                    } else {
                        !!request
                            ? handleUpdateParkingRequest(position, request)
                            : handleCreateParkingRequest(day)
                    }
                }}
                {...tileProps}
            />
        );
    }

    return (
        <ParkableBaseView scrollable={false} removeStandardMargins toolbarStyle={{marginLeft: PADDING}}>

            {showConfirmation.show && (
                <ConfirmationDialog
                    onCancel={() => closeConfirmation('DismissDialog')}
                    confirmProps={[
                        ...(showConfirmation.hasAllocation ? [] : [{
                            onConfirm: () => closeConfirmation(showConfirmation.currentPriority === ParkingRequestPriority.High ? 'Deprioritise' : 'Prioritise'),
                            text: showConfirmation.currentPriority === ParkingRequestPriority.High
                                ? Strings.future_booking.deprioritise_this_request
                                : showConfirmation.hasReachedPriorityLimit
                                    ?  Strings.future_booking.priority_requests_remaining(0)
                                    : Strings.future_booking.prioritise_this_request,
                            disabled: showConfirmation.hasReachedPriorityLimit,
                        }]),
                        {
                            onConfirm: () => closeConfirmation('CancelRequest'),
                            text: Strings.future_booking.remove_request,
                        },
                        {
                            onConfirm: () => closeConfirmation('DismissDialog'),
                            text: Strings.cancel,
                        },
                    ]}
                    plainButtons={true}
                />
            )}

            <ScrollView style={contentStyles.container} scrollEnabled={!showConfirmation.show}>
                <Text bold h1 style={headingStyles.text}>
                    {Strings.future_booking.future_booking}
                </Text>
                <TableRow
                    iconLeft={"pinlocation2filled"}
                    iconLeftProps={{ color: Colours.ParkableGreen }}
                    label={Strings.location}
                    textProps={{ bold: true, numberOfLines: 2 }}
                >
                    {locationName}
                </TableRow>

                {isUserPrivateOrganisation && organisationId &&
                    <PreferredBaysRow
                        orgId={organisationId}
                        parkId={parkId}
                        campusParksIds={campus?.parks}/>}

                <View style={contentStyles.section}>
                    <View style={headerRowStyles.container}>
                        <Text bold h2>
                            {Strings.common.this_week}
                        </Text>
                        <TouchableOpacity
                            activeOpacity={0.8}
                            onPress={() => navigation.navigate(Routes.HowItWorks_ThisWeekView)}
                        >
                            <Image source={helpIcon} style={headerRowStyles.helpIcon} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.dayRow}>
                        {filteredWeeks[0].map((day, i) => renderDay(day, i, thisWeekWaitlist, parkSession))}
                    </View>
                </View>

                {!!nextWeekAllocation && <View style={contentStyles.section}>
                    <View style={headerRowStyles.container}>
                        <Text bold h2>
                            {Strings.common.next_week}
                        </Text>
                    </View>
                    <Text grey style={[styles.nextWeekText]}>
                        {Strings.future_booking.allocated_days_for_next_week}
                    </Text>
                    <View style={styles.dayRow}>
                        {filteredWeeks[1].map((day, i) => renderDay(day, i, nextWeekWaitlist, null))}
                    </View>
                </View>}

                <View style={contentStyles.section}>
                    <View style={headerRowStyles.container}>
                        <Text bold h2>
                            {Strings.common.upcoming_weeks}
                        </Text>
                        <TouchableOpacity
                            activeOpacity={0.8}
                            onPress={() => navigation.push(Routes.HowItWorks_UpcomingWeeksView, {
                                organisationId: park?.organisation ?? organisationId,
                                parkId: park?.id,
                                campusId: campus?.id,
                            })}
                        >
                            <Image source={helpIcon} style={headerRowStyles.helpIcon} />
                        </TouchableOpacity>
                    </View>
                    {!!options && <Text small style={[styles.nextWeekText]}>
                        {Strings.future_booking.info_text(Strings.dayOfWeekForIndex(options.allocationDayOfWeek), Strings.formatAllocationHourOfDay(options.allocationHourOfDay))}
                    </Text>}
                    {filteredWeeks.slice(!!nextWeekAllocation ? 2 : 1).map((week, key) => (
                        <View key={key}>
                            <DayRowHeader week={week} />

                            <View style={styles.dayRow}>
                                {week.map((day, i) => renderDay(day, i, null, null))}
                            </View>
                        </View>
                    ))}

                    <Button plain border center textProps={{h4: true, bold: false}} onPress={() => setWeeksToShow(weeksToShow + 2)}>
                            {Strings.future_booking.more_weeks}
                    </Button>
                </View>

                <View style={contentStyles.section}>
                    <View style={legendStyles.container}>
                        <View style={legendStyles.column}>
                            {legendItems.slice(0, 3).map((item, i) => (
                                <LegendItem
                                    key={i}
                                    color={item.color}
                                    text={item.text}
                                    badge={item.badge}
                                    border={item.border}
                                />
                            ))}
                        </View>
                        <View style={legendStyles.column}>
                            {legendItems.slice(3).map((item, i) => (
                                <LegendItem
                                    key={i}
                                    color={item.color}
                                    text={item.text}
                                    badge={item.badge}
                                    border={item.border}
                                />
                            ))}
                        </View>
                    </View>
                </View>
            </ScrollView>
        </ParkableBaseView>
    );
};

export const FutureBookingView = connect(getReduxProps)(_FutureBookingView);

export const FutureBookingRoute = createRoute({
    path: Routes.FutureBookingView,
    params: {type: FutureBookingParams}
})
