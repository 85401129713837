import React, { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import Strings from "../../constants/localization/localization";
import Spinner from "react/parkable-components/spinner/Spinner";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import { Bay, BayWithTandemPod } from "../../model/Bay";
import { sortBaysBySignage } from "react/constants/sortBaysBySignage";
import BayOption from "./BayOption";
import getParkAvailability from "../../constants/getParkAvailability";
import { usePark } from "../../api/park";
import { ParkingType } from "../../model/Types";
import TandemPodOption from "../tandemParking/TandemPodOption";
import { createRoute, NavigationProps } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import ParkableBaseView from "./ParkableBaseView";
import { BayDTO } from "react/model/BayDTO";
import { SharingPoolRow } from "react/components/parkDetails/sharing-pool/SharingPoolRow";
import { useCurrentParkingSession } from "react/api/parking";
import { useCurrentUserVehicle } from "react/components/map/parkableMapView/web/google/pin.constants";
import { useSharingPoolBaysForUser } from "react/api/sharingPool/sharingPool.api";
import { BayActivityDTO } from "react/model/BayActivityDTO";
import { userIsOrgMember } from "react/constants/getUserMember";
import { useUserRoles } from "react/api/user/user.api";

const numberBaysShown = 6;

export default function SelectBay(props: NavigationProps<Routes.SelectBay>) {
    const { navigation } = props;

    const {
        title,
        showAllButton,
        baysAvailable: allBaysAvailable,
        onBaySelected,
        parkingType,
        bay,
        parkId,
        bayActivity,
    } = props.route.params;

    const { park } = usePark(parkId);
    const availability = park ? getParkAvailability(park) : undefined;
    const currentVehicle = useCurrentUserVehicle();
    const { userRoles } = useUserRoles();
    const { parkSession, isLoading: isLoadingParkSession } = useCurrentParkingSession();
    const onlyShowSharingPoolBays = !!bayActivity?.hold?.isSharingPool || !!parkSession?.isSharingPool;
    const { bays: sharingPoolBays, isLoading: isLoadingSharingPoolBays } = useSharingPoolBaysForUser(
        park?.organisation,
        park?.id,
        { feature: currentVehicle?.feature }
    );
    const sharingPoolBayIds = sharingPoolBays?.map((spb) => spb.id) ?? [];

    // allBaysAvailable includes all bays available to the user including sharing pool bays
    const eligibleBays = bay
      ? allBaysAvailable?.filter((b) => sharingPoolBayIds.includes(b.id) === onlyShowSharingPoolBays)
      : allBaysAvailable;

    const tandemBaysAvailable: BayWithTandemPod[] = [];
    const singleBaysAvailable: Bay[] = [];

    eligibleBays?.forEach((bay) => {
        bay.tandemPod ? tandemBaysAvailable.push(bay as BayWithTandemPod) : singleBaysAvailable.push(bay);
    });

    const sortedSingleBays = sortBaysBySignage(singleBaysAvailable);
    const sortedTandemBays = sortBaysBySignage(tandemBaysAvailable);

    const tandemPodIds = [...new Set(sortedTandemBays.map((bay) => bay.tandemPod!))];

    // Map each pod to an object containing the pod's name and its bays' signages
    const tandemPodsWithBaySignages = tandemPodIds.map((podId) => {
        return {
            id: podId,
            baySignages: sortedTandemBays.filter((bay) => bay.tandemPod === podId).map((bay) => bay.signage!),
        };
    });

    const [showAllPressed, setShowAllPressed] = useState<boolean>(false);
    const baysShown = showAllPressed ? sortedSingleBays : sortedSingleBays.slice(0, numberBaysShown);

    const onShowAllPress = useCallback(() => {
        setShowAllPressed(true);
    }, [showAllPressed, sortedSingleBays]);

    const onBaySelectedPress = useCallback((bay: Bay) => {
        navigation.pop();
        onBaySelected?.(bay);
    }, []);

    const handleTandemPodSelected = (podId: number) => {
        // User has selected a tandem pod. Select the first available bay in that tandem pod.
        const tandemBayInSelectedPod = tandemBaysAvailable.find((bay) => bay.tandemPod === podId);
        if (tandemBayInSelectedPod) {
            navigation.pop();
            onBaySelected?.(tandemBayInSelectedPod);
        }
    };

    const loading = isLoadingSharingPoolBays || isLoadingParkSession;

    return (
        <ParkableBaseView>
            <Text h1>{title}</Text>

            {loading ? (
                <Spinner />
            ) : (
                <View>
                    {onlyShowSharingPoolBays && userIsOrgMember(userRoles, park?.organisation) && <SharingPoolRow showChangeBayText />}

                    <Text small grey style={styles.text}>
                        {Strings.please_choose_a_space_to_park_in}
                    </Text>

                    <View style={styles.row}>
                        <Text grey strapline bold>
                            {Strings.spaces_available}
                        </Text>
                        {showAllButton && !showAllPressed && sortedSingleBays.length > numberBaysShown && (
                            <Text small style={styles.showAll} onPress={() => onShowAllPress()}>
                                {Strings.show_all}
                            </Text>
                        )}
                    </View>

                    {baysShown.map((bs: Bay) => (
                        <BayOption
                            key={bs.id}
                            bay={bs}
                            selected={bay?.id === bs.id}
                            availability={availability}
                            parkingType={parkingType}
                            onBaySelectedPress={onBaySelectedPress}
                        />
                    ))}

                    {tandemPodsWithBaySignages.map((podWithSignages) => (
                        <TandemPodOption
                            key={podWithSignages.id}
                            currentlySelected={podWithSignages.id === bay?.tandemPod}
                            tandemPodId={podWithSignages.id}
                            onTandemPodSelected={handleTandemPodSelected}
                            baySignages={podWithSignages.baySignages}
                        />
                    ))}
                </View>
            )}
        </ParkableBaseView>
    );
}

class SelectBayParams {
    title: string;
    showAllButton: boolean;
    baysAvailable: Bay[];
    onBaySelected?: (bay: Bay) => void;
    bay?: Bay | BayDTO | null;
    parkId: number;
    parkingType: ParkingType;
    bayActivity?: BayActivityDTO | null;
}

export const SelectBayRoute = createRoute({
    path: Routes.SelectBay,
    params: { type: SelectBayParams },
});

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        paddingLeft: 27,
        paddingRight: 27,
        paddingBottom: 27,
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    row: {
        justifyContent: "space-between",
        flexDirection: "row",
        paddingTop: 8,
    },
    showAll: {
        color: Colours.BLUE_300,
    },
    text: {
        paddingTop: 8,
        paddingBottom: 8,
    },
});
