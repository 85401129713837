import React, { PropsWithChildren } from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import { useSelector } from "../../redux/redux";
import Colours from "react/parkable-components/styles/Colours";
import useConfig from "../../hooks/useConfig";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useIsAdminImpersonating } from "../../hooks/useIsAdminImpersonating";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
    },
    appBorder: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "transparent",
        borderWidth: 2,
        zIndex: 100002
    },
    userEmail: {
        marginBottom: 2,
    },
});

export const MAX_WIDTH_WEB = 540;

export const RootContainer = ({ children }: PropsWithChildren<{}>) => {
    const config = useConfig();

    const { firebaseUser } = useSelector(state => state.auth);
    const isAdminImpersonating = useIsAdminImpersonating();
    const { top } = useSafeAreaInsets();

    return <View style={[
        styles.container,
        {backgroundColor:config?.borderColour ?? Colours.BLUE_300},
    ]}>
        {config?.borderColour && Platform.OS !== "web" && <View
            style={[styles.appBorder, {borderColor: config?.borderColour}]}
            pointerEvents="none" />
        }
        {isAdminImpersonating && (
            <Text style={[styles.userEmail, { marginTop: top }]}>
                {firebaseUser?.email}
            </Text>
        )}
        <View style={[{
            flex: 1,
            width: "100%",
            overflow: "hidden",
        }]}>
            {children}
        </View>
    </View>;
};
