import React from "react"
import Colours from "react/parkable-components/styles/Colours";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Strings from "../../../constants/localization/localization"

export type LocationRowProps = {
	displayName?: string | null;
	isLoading?: boolean;
	pinColor?: boolean;
}

export const CarParkNameRow = ({ displayName, isLoading, pinColor }: LocationRowProps) => {
	return <TableRow iconLeft={"pinparking"}
		iconLeftProps={!pinColor ? { color: Colours.GREEN_300 } : { color: Colours.NEUTRALS_BLACK }}
		label={Strings.car_park} >
		{isLoading ? Strings.loading : displayName}
	</TableRow>
}
