import { Platform, StyleSheet, View } from "react-native";
import Dialog from "../../../dialog/Dialog";
import Button from "react/parkable-components/button/Button";
import React, { useEffect, useState } from "react";
import { Colours, Input } from "react/parkable-components";
import Strings from "react/constants/localization/localization";
import { attachRfidToUser, detachRfidFromUser, OcpiTokensResponse } from "react/api/ev";
import { theme } from "react/constants/theme";
import { KeyedMutator } from "swr";

type EditRfidCardDialogProps = {
    open: boolean;
    handleOpenModal: (open: boolean) => void;
    rfid: string;
    mutateTokens: KeyedMutator<OcpiTokensResponse>;
};

export const EditRfidCardDialog = (props: EditRfidCardDialogProps) => {
    const { open, handleOpenModal, rfid, mutateTokens } = props;

    const [newRfidNumber, setNewRfidNumber] = useState<string>(rfid);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState<boolean>(true);
    const [remove, setRemove] = useState<boolean | undefined>(undefined);

    const validateInput = (input: string) => {
        const HEX_REGEX = /^\d+$/;
        const SEPARATOR_REGEX = /[-_:]+/gi;
        const sanitizedCardId = input.replace(SEPARATOR_REGEX, "").toUpperCase().trim();
        if (input.length < 1 || !HEX_REGEX.test(sanitizedCardId)) {
            return false;
        }
        return true;
    };

    const onUpdate = async () => {
        if (!newRfidNumber) {
            setRemoveLoading(false);
            setUpdateLoading(false);
            return;
        }

        try {
            if (remove) {
                await detachRfidFromUser(rfid);
                setNewRfidNumber("");
                handleOpenModal(false);
            }
            if (!remove) {
                await attachRfidToUser(newRfidNumber);
                setNewRfidNumber(newRfidNumber);
                handleOpenModal(false);
            }
        } catch (e: any) {
            setUpdateSuccess(false);
        } finally {
            await mutateTokens();
            setUpdateLoading(false);
            setRemoveLoading(false);
        }
    };

    useEffect(() => {
        setNewRfidNumber(rfid);
    }, [rfid]);

    useEffect(() => {
        if (remove === undefined) {
            return;
        }
        if (remove) {
            setRemoveLoading(true);
        } else {
            setUpdateLoading(true);
        }
        onUpdate();
    }, [remove]);

    const closeAndCleanModal = () => {
        handleOpenModal(false);
        setNewRfidNumber(rfid);
        setRemove(undefined);
        setUpdateSuccess(true);
    };

    return (
        <Dialog
            isVisible={open}
            negativeText={!updateSuccess ? Strings.okay : ""}
            onNegativePress={closeAndCleanModal}
            title={!updateSuccess ? Strings.rfid_not_found_message : ""}
            titleProps={{ style: styles.title, h2: undefined }}
            label={updateSuccess ? Strings.edit_rfid : Strings.rfid_not_found}
            labelProps={{ style: styles.label }}
            onBackdropPress={closeAndCleanModal}
        >
            <View style={{ width: Platform.OS === "web" ? 325 : "100%" }}>
                {updateSuccess && (
                    <>
                        <View>
                            <Input
                                keyboardType={"number-pad"}
                                value={newRfidNumber}
                                onChangeText={(num: string) => {
                                    validateInput(num);
                                    setNewRfidNumber(num);
                                }}
                            />
                        </View>
                        <View style={styles.buttonContainer}>
                            <Button
                                plain
                                border
                                center
                                textProps={{ h5: true, white: true }}
                                style={[styles.button, { marginRight: theme.spacing(1), backgroundColor: Colours.RED }]}
                                disabled={removeLoading || updateLoading}
                                loading={removeLoading}
                                onPress={() => {
                                    setRemove(true);
                                }}
                            >
                                {Strings.remove_card}
                            </Button>
                            <Button
                                center
                                textProps={{ h5: true }}
                                disabled={
                                    updateLoading ||
                                    removeLoading ||
                                    newRfidNumber === rfid ||
                                    !validateInput(newRfidNumber)
                                }
                                loading={updateLoading}
                                style={[styles.button, { backgroundColor: Colours.GREEN_300 }]}
                                onPress={() => {
                                    setRemove(false);
                                }}
                            >
                                {Strings.update}
                            </Button>
                        </View>
                    </>
                )}
            </View>
        </Dialog>
    );
};

const styles = StyleSheet.create({
    buttonContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        flexDirection: "row",
    },
    button: {
        flex: 1,
        borderRadius: 3,
    },
    label: {
        color: Colours.NEUTRALS_BLACK,
        textAlign: "left",
        marginBottom: 0,
        marginTop: theme.spacing(1),
    },
    title: {
        textAlign: "left",
        marginTop: theme.spacing(1),
        marginBottom: 0,
        paddingBottom: 0,
    },
});
