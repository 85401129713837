import React, { useRef } from 'react';
import { Linking, StyleSheet, TouchableOpacity, View, FlatList} from 'react-native';
import _ from 'lodash'
import connect from "../../../constants/connect";
import Strings from "../../../constants/localization/localization";
import moment from 'moment-timezone';
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Text from "react/parkable-components/text/Text";
import Icons from "react/parkable-components/icon/Icons";
import Dialog from "react/components/dialog/Dialog";
import localizeCurrency from "../../../constants/localization/localizeCurrency";
import { useStripePayments } from '../../../api/stripe/stripe.api';
import { ParkDTOWithTerritory } from '../../../redux/actions/parks';
import { StripeSubscriptionInvoice } from '../../../model/StripeSubscriptionInvoice';
import { usePark } from '../../../api/park/park.api';
import { useTerritory } from '../../../api/territory/territory.api';
import { useInvoiceToken } from '../../../api/user/user.api';
import { createRoute, NavigationProps } from "../../../navigation/constants";
import { Routes } from "../../../navigation/root/root.paths";
import { useSelector } from "../../../redux/redux";
import ParkableBaseView from "../../common/ParkableBaseView";

class SubscriptionInvoicesViewProps {
    park: ParkDTOWithTerritory;
    employeeSubscriptionId: number;
}

const invoiceDateFormat = "hh:mma, dddd DD MMMM YYYY";

const SubscriptionInvoicesView = (props: NavigationProps<Routes.SubscriptionInvoicesView>) => {
    const { employeeSubscriptionId } = props.route.params;
    const flatListRef       = useRef(null);
    const alertDialogRef    = useRef<DialogRef | null>(null);

    const { invoices } = useStripePayments(employeeSubscriptionId);

    const {token: invoiceToken} = useInvoiceToken();

    const {api} = useSelector(state => state.data);

    const renderItem = (item: { index: number, item: StripeSubscriptionInvoice }) => {
        return <InvoiceRow key={item.index} invoice={item.item}/>
    }

    const downloadInvoice = (invoice: StripeSubscriptionInvoice) => {
        if (!invoice) {
            return
        }

        const url = `${api}v2/employeesubscriptions`
                    +`/${invoice.parkableId}/invoice?token=${invoiceToken}`
                    + `&invoiceId=${invoice.id}`;

        (async () => {
            if (await Linking.canOpenURL(url)) {
                try {
                    await Linking.openURL(url);
                }
                catch (err)
                {
                    alertDialogRef.current?.show();
                }
            } else {
                alertDialogRef.current?.show();
            }
        })();
    };

    const InvoiceRow = (props: {invoice: StripeSubscriptionInvoice}) => {
        const { invoice } = props;

        const { park } = usePark(invoice.parkId);
        const { territory } = useTerritory(park?.territory);
        const { amountDue, processingFee } = invoice;
        const totalAmount = (amountDue ?? 0) + (processingFee ??0);
        return (
            <TouchableOpacity key={invoice.id} onPress={()=> downloadInvoice(invoice) }>
                <TableRow
                    label={moment(invoice?.date || undefined).tz(park?.timeZoneId??'UTC').format(invoiceDateFormat)}
                    chevronText={(invoice.amountDue !== null)?localizeCurrency(totalAmount, territory?.currencyCode, false): Strings.not_available}
                    iconRight={Icons.linkexpand}
                    iconRightProps={{small: true}}>
                    {park?.address?? Strings.cannot_find_location}
                </TableRow>
            </TouchableOpacity>
        );
    }

    return (
        <ParkableBaseView scrollable={false}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text h1 bold>{Strings.subscription_invoices}</Text>
                </View>
                <View style={[styles.section]}>
                {invoices?.length === 0 ?
                    <Text>{Strings.no_invoice_found}</Text>
                    :
                    <>
                        <View style={styles.row}>
                            <View style={styles.leftPart}>
                                <Text strapline bold>{Strings.date_and_location}</Text>
                            </View>
                            <View style={styles.rightPart}>
                                <Text strapline bold>{Strings.amount}</Text>
                            </View>
                        </View>

                        <FlatList
                            ref={flatListRef}
                            data={ _.chain(invoices || [])
                                .filter(r => r.paid === true)
                                .sortBy('date')
                                .value()
                                .reverse()
                            }
                            renderItem={renderItem}
                            keyExtractor={(item, index) => `${item?.id}_${index}`}
                        />
                    </>
                }
                </View>
            </View>
            <Dialog
                ref={alertDialogRef}
                title="Invoice download fail"
                positiveText={Strings.ok}
                onPositivePress={() => alertDialogRef.current?.hide()}>
                    <Text>{Strings.unable_find_invoice}</Text>
            </Dialog>
        </ParkableBaseView>
    );
}

export default connect(() => ({}))(SubscriptionInvoicesView) as React.FC;

export const SubscriptionInvoicesRoute = createRoute({
    path: Routes.SubscriptionInvoicesView,
    params: { type: SubscriptionInvoicesViewProps }
});

const styles = StyleSheet.create({
    header: {
    },
    section: {
        paddingTop: 9,
    flex: 1
    },
    footer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        bottom: 200
    },
    container: {
        flex: 1,
    },
    row: {
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 10
    },
    leftPart: {
        flex: 2
    },
    rightPart: {
        flex: 1,
        alignItems: 'flex-end'
    }
});

