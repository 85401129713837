import React, {useCallback, useState} from 'react';
import Button from "react/parkable-components/button/Button";
import CheckBox from "react/parkable-components/checkBox/CheckBox";
import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import {Linking, StyleSheet, TouchableOpacity, View} from "react-native";
import Strings from '../../constants/localization/localization'
import { acceptTsAndCs, useCurrentUser } from "../../api/user/user.api";
import {getCustomToken} from "../../api/auth";
import {showAlert} from "../../alerts";
import {FirebaseService} from "../../services/firebase.service";
import {createRoute, NavigationProps} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import ParkableBaseView from "../common/ParkableBaseView";
import { useAppDispatch, useSelector } from "../../redux/redux";
import { setUser } from "../../redux/actions/user";

class AcceptTsAndCsViewParams {
    userId: number;
    token: string;
}

export default function AcceptTsAndCsView(props: NavigationProps<Routes.AcceptTsAndCsView>) {
    const { api } = useSelector(state => state.data);
    const {user, isLoading} = useCurrentUser();
    const userId = user?.id ?? props.route.params?.userId;
    const token = props.route.params?.token;

    const dispatch = useAppDispatch();

    const [termsChecked, setTermsChecked] = useState(false);
    const [loading, setLoading] = useState(false);

    const onTermsClick = async () => {
        const URL = "https://www.parkable.com/terms-of-service";
        if (await Linking.canOpenURL(URL)) {
            await Linking.openURL(URL);
        }
    }

    const onContinue = useCallback(async () => {
        setLoading(true);

        try {
            const response = await acceptTsAndCs({ token, userId });
            dispatch(setUser(response.user));
            if (user) {
                props.navigation.reset({ routes: [{ name: Routes.ParkableMapView }] });
            } else {
                const customToken = await getCustomToken(api, token);
                await FirebaseService.signInWithCustomToken(customToken.firebaseCustomToken);
            }
        } catch(error){
            showAlert(Strings.error_persists_contact_org, Strings.error);
        } finally {
            setLoading(false);
        }
    }, [userId, token])

    const toggleTermsChecked = () => {
        setTermsChecked(!termsChecked);
    }

    return <ParkableBaseView scrollable={false} loading={isLoading}>
        <View style={styles.body}>
            <Text allowFontScaling={false} style={styles.welcome}>{Strings.review_terms}</Text>
            <Text>{Strings.account_created_please_review_terms_accept_to_continue}</Text>
            <View style={{ top: 20 }}>
                <View style={{ flexDirection: "row", marginBottom: 18 }}>
                    <View style={{ width: 30, overflow: "hidden", marginTop: 5, marginRight: 9 }}>
                        <CheckBox checked={termsChecked} onChange={toggleTermsChecked} />
                    </View>
                    <Text small style={{ fontWeight: "normal", paddingBottom: 6 }}>{Strings.by_signing_up}
                        <TouchableOpacity onPress={onTermsClick}>
                            <Text small style={{ color: Colours.BLUE_300, fontWeight: "normal", textDecorationLine: "underline" }}>{'\n'}{Strings.terms_conditions}</Text>
                        </TouchableOpacity>
                    </Text>
                </View>
                <Button center loading={loading} form textProps={{ h5: false, h3: true }} disabled={!termsChecked || loading} onPress={onContinue}>{Strings.continue}</Button>
            </View>
        </View>
    </ParkableBaseView>
}

export const AcceptTsandCsRoute = createRoute({
    path: Routes.AcceptTsAndCsView,
    params: {type: AcceptTsAndCsViewParams}
});


const styles = StyleSheet.create({
    body: {
        paddingBottom: 18,
    },
    welcome: {
        fontSize: 45,
        lineHeight: 49,
        marginTop: 14,
        marginBottom: 18,
        backgroundColor: Colours.NEUTRALS_WHITE,
        fontFamily: "GTEestiDisplay-Bold",
    },
    topLeft: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    topTitle: {
        position: 'absolute',
        paddingTop: 15,
        alignSelf: 'center'
    },
    subText: {
        top: 20,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    phoneContainer: {
        flex: 1,
    },
    phoneInputs: {
        flexDirection: "row"
    }
})
