import React, { useEffect, useState } from "react";
import {ScrollView, StyleSheet, View} from "react-native";
import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import Strings from "../../../constants/localization/localization";
import { connect } from "react-redux";
import { addUserVehicle, setUser, updateUserVehicle } from "react/redux/actions/user";
import { IRootReducer } from "react/redux/reducers/main";
import { Feature } from "react/model/Bay";
import { Routes } from "react/navigation/root/root.paths";
import { Vehicle } from "react/model/Vehicle";
import { logEvent } from "react/analytics";
import { useUserVehicles } from "react/api/vehicle/vehicle.api";
import { createRoute, NavigationProps } from "react/navigation/constants";
import ParkableBaseView from "../../common/ParkableBaseView";
import { useParkingPrice } from "react/api/parkingPrice/parkingPrice.api";
import { isCreditCardRequired } from "react/services/parkingPrice.service";
import { useCurrentParkingSession } from "react/api/parking";
import { updateParkSessionVehicle } from "react/api/parkSession/parkSession.api";
import { setCurrentParkingSession } from "react/redux/actions/session";
import { useAppDispatch } from "react/redux/redux";
import { useCurrentUser } from "react/api/user/user.api";
import { vehiclesSelected } from "react/redux/actions/vehicles";
import { useAlertSnackbar } from "../../../root/alert-snackbar/alert-snackbar";
import { validateAndSanitiseRego } from "../../vehicle/vehicle.util";
import { RegoInputBlock } from "../../vehicle/RegoInputBlock";
import { VehicleRadioBlock } from "../../vehicle/VehicleRadioBlock";
import {spacing} from "../../../constants/theme/spacing";

type Props = ReturnType<typeof getReduxProps> & typeof actions & NavigationProps<Routes.AddNewVehicleView>;

const topMargin = 49;

const AddNewVehicleView = (props: Props) => {
    const dispatch = useAppDispatch();
    const { user } = useCurrentUser();
    const { showSnackBar } = useAlertSnackbar();
    const { navigation, addUserVehicle, updateUserVehicle, userId, destination, hasCard, casualVoucher, park } = props;

    const [loading, setLoading] = useState(false);
    const { vehicles: userVehicles, mutate: mutateVehicles } = useUserVehicles();
    const { parkSession, mutate: mutateParkSession } = useCurrentParkingSession();
    const { pricePeriods } = useParkingPrice(parkSession?.parkingPrice ?? park?.parkingPrice);

    const [rego, setRego] = useState<string | undefined>(undefined);
    const [isMotorbike, setIsMotorbike] = useState("false");
    const [isElectricVehicle, setIsElectricVehicle] = useState("false");

    // TODO: remove all dispatch methods once redux is removed from active Session screen and confirm start parking screen
    useEffect(() => {
        if (user) {
            dispatch(setUser(user));
        }
    }, [user]);

    useEffect(() => {
        if (parkSession) {
            dispatch(setCurrentParkingSession(parkSession));
        }
    }, [parkSession]);

    const addVehicle = () => {
        if (!rego || !userId) return;
        const result = validateAndSanitiseRego(rego, userVehicles);
        if (!result.valid || !result.rego) {
            showSnackBar({
                text: result.message ?? Strings.oh_no_something_went_wrong_support,
                hideDismiss: false,
                style: { backgroundColor: Colours.RED },
            });
            return;
        }
        setLoading(true);
        const isEVBoolean = isElectricVehicle === "true";
        const isMotorbikeBoolean = isMotorbike === "true";
        const feature = isMotorbikeBoolean ? Feature.MOTORBIKE : Feature.SEDAN;
        addUserVehicle(userId, result.rego, feature, isEVBoolean, isMotorbikeBoolean, onSuccess, onFail);
    };

    const onSuccess = async (vehicle: Vehicle) => {
        const params = { userId: `${userId ?? 0}`, vehicleId: `${vehicle.id}`, registration: vehicle.registration };
        logEvent(undefined, "vehicle_added", params);
        updateUserVehicle(userId!, vehicle.id, onFinish, onFinish);
        dispatch(vehiclesSelected([vehicle.id]));
        if (parkSession) {
            await updateParkSessionVehicle(parkSession.id, vehicle.id);
        }
        mutateParkSession();
        mutateVehicles();
    };

    const onFail = (err: any) => {
        console.log("FAIL TO ADD VEHICLE: ", err);
        setLoading(false);
        showSnackBar({ text: err.message, hideDismiss: true, style: { backgroundColor: Colours.RED } });
    };

    const onFinish = () => {
        setLoading(false);
        if (!destination) {
            navigation.goBack();
        } else if (park && pricePeriods && isCreditCardRequired(park, pricePeriods, hasCard, casualVoucher)) {
            navigation.navigate(Routes.AddNewCard, { destination });
        } else {
            // @ts-ignore
            navigation.navigate(destination.route, destination.params);
        }
    };

    return (
        <ParkableBaseView scrollable={false} loading={loading} >
            <ScrollView showsVerticalScrollIndicator={false}>
                <Text h1 style={styles.titleText}>
                    {Strings.add_new_vehicle}
                </Text>
                <Text style={styles.helperText}>{Strings.what_is_your_rego}</Text>
                <RegoInputBlock value={rego} onChange={setRego} />
                <VehicleRadioBlock
                    isEV={isElectricVehicle}
                    onEVChange={(value) => setIsElectricVehicle(value)}
                    isMotorbike={isMotorbike}
                    onMotorbikeChange={(value) => setIsMotorbike(value)}
                />
                <View style={styles.spacer}/>
            </ScrollView>
            <View style={styles.saveButtonContainer}>
                <Button center disabled={!rego} onPress={addVehicle}>
                    {Strings.save}
                </Button>
            </View>
        </ParkableBaseView>
    );
};

const actions = {
    addUserVehicle,
    updateUserVehicle,
};

const getReduxProps = (state: IRootReducer, props: NavigationProps<Routes.AddNewVehicleView>) => {
    const vehicles = state.user.vehicles || [];
    const hasCard = !!state.user.currentCardId;
    const { destination } = props.route.params || {};
    const park = destination?.params?.park;

    return {
        vehicles,
        userId: state.data.userId,
        hasCard,
        destination,
        park,
        casualVoucher: state.user?.casualVoucher?.[park?.id],
    };
};

class AddNewVehicleRouteParams {
    destination?: {
        route: Routes;
        params: any;
    };
}

export const AddNewVehicleRoute = createRoute({
    path: Routes.AddNewVehicleView,
    params: {
        type: AddNewVehicleRouteParams,
        deserialize: (d) => ({
            destination: d.Json(),
        }),
    },
});

const styles = StyleSheet.create({
    titleText: {
        marginTop: topMargin,
        marginBottom: 15,
    },
    helperText: {
        fontSize: 18,
        color: Colours.NEUTRALS_500,
    },
    saveButtonContainer: {
        flex: 1,
        justifyContent: "flex-end",
    },
    // Offsetting top margin to display scroll view correctly on small screens
    spacer: {
        height: topMargin + spacing(2),
    },
});

export default connect(getReduxProps, actions)(AddNewVehicleView) as React.FunctionComponent<Props>;
