import React, { PropsWithChildren } from "react";
import { Provider } from "react-redux";
import * as Sentry from 'sentry-expo';
import { persistor, store } from "../redux/store/store";
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { AlertSnackbar } from "./alert-snackbar/alert-snackbar";
import { RootContainer } from "./container/container";
import { AuthProvider } from "./auth/AuthProvider";
import { NavigationProvider } from "react/navigation";
import { DrawerNavigator } from "react/navigation/drawer/drawer.navigator";
import useFontsLoaded from "../hooks/useFontsLoaded";
import { useSelector } from "../redux/redux";
import { IRootReducer } from "../redux/reducers/main";
import { PersistGate } from "redux-persist/integration/react";
import ModalContainer from "../components/common/modals/ModalContainer";
import { BeaconProvider } from "../components/accessControl/context";
import SplashScreenComponent from "react/components/SplashScreen";
import * as Device from "expo-device";
import UpdateModal from "../components/common/modals/UpdateModal";
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import {TeamsProvider} from "../components/teams/context";
import ReAcceptTsAndCsModal from "../components/common/modals/ReAcceptTsAndCsModal";

if (Device.isDevice) {
    Sentry.init({
        dsn: 'https://2fcb12c92ec940ff8fe6ccf9703fc501@o171321.ingest.sentry.io/6566103',
        enableInExpoDevelopment: true,
        debug: __DEV__, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
    });
}

export default function App() {
    return (
        <Provider store={store}>

                <SplashScreenController>
                    <BeaconProvider>
                        <SafeAreaProvider>
                            <ActionSheetProvider>
                                <RootContainer>
                                    <AlertSnackbar>
                                            <ModalContainer />
                                            <UpdateModal />
                                            <ReAcceptTsAndCsModal />
                                            <StatusBar />
                                            <NavigationProvider>
                                                <DrawerNavigator />
                                            </NavigationProvider>
                                    </AlertSnackbar>
                                </RootContainer>
                            </ActionSheetProvider>
                        </SafeAreaProvider>
                    </BeaconProvider>
                </SplashScreenController>

        </Provider>
    );
}

function SplashScreenController(props: PropsWithChildren<{}>) {
    const { children } = props;
    const fontsLoaded = useFontsLoaded();
    const { isAuthenticating } = useSelector((state: IRootReducer) => state.auth);
    const loading = !fontsLoaded || isAuthenticating;

    const splashScreen = <SplashScreenComponent/>;

    return (
        <PersistGate persistor={persistor} loading={splashScreen}>
            <TeamsProvider>
                <AuthProvider>
                    {loading ? splashScreen : children}
                </AuthProvider>
            </TeamsProvider>
        </PersistGate>
    );
}
