import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {Colours, Text} from 'react/parkable-components';
import {connect} from "react-redux";
import {IRootReducer} from "../../redux/reducers/main";
import { setFirebaseTokenAndUser } from "../../redux/actions/auth";
import Strings from "../../constants/localization/localization";
import LoadingOverlay_new from "../common/LoadingOverlay_new";
import {DispatchFunc} from "../../model/Types";
import {sendEmailVerificationLink, userLoggedOut} from "../../redux/actions/user";
import {FirebaseService} from "../../services/firebase.service";
import { createRoute, NavigationProps } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import ParkableBaseView from "../common/ParkableBaseView";

function VerifyEmailView(props: Props) {
    const {user, firebaseUser, dispatch} = props;
    const {emailVerified} = firebaseUser ?? {};
    const [loading, setLoading] = useState(false);

    async function onResendPress() {
        if(user?.id) {
            try {
                setLoading(true);
                await dispatch(sendEmailVerificationLink(user.id, "https://links.parkable.com"));
                alert("Sent!");
            } finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        if(!emailVerified) {
            //check every two seconds
            const intervalId = setInterval(check, 2000);
            return () => clearInterval(intervalId);
        }
    }, [emailVerified])

    async function check() {
        const newFirebaseUser = await FirebaseService.reloadUser();
        console.log("check emailVerified", newFirebaseUser);
        if (newFirebaseUser?.emailVerified) {
            try {
                const idTokenResult = await newFirebaseUser.getIdTokenResult(true);
                dispatch(setFirebaseTokenAndUser({idTokenResult, user: newFirebaseUser}));
            } catch (e) {
                console.log(e);
            }
        }
    }

    async function onLogoutPress() {
        try {
            setLoading(true);
            dispatch(userLoggedOut());
            await FirebaseService.logout();
        } finally {
            setLoading(false);
        }
    }

    if(loading || !user || !firebaseUser?.email) {
        return <LoadingOverlay_new loading={true}/>
    }

    return <ParkableBaseView>
        <View style={styles.main}>
            <Text style={styles.signup}>{Strings.sign_up}</Text>
            <Text style={styles.welcome}>{user?.firstName?.length ? Strings.welcome_user(user.firstName) : Strings.welcome}</Text>
            <Text style={styles.activationInstructions}>{Strings.email_activation_instructions(firebaseUser.email)}</Text>
            <Text style={styles.resendText}>{Strings.didnt_get_the_email} <Text style={styles.button} onPress={onResendPress}>{Strings.resend}</Text></Text>
            <Text style={[styles.logout, styles.button]} onPress={onLogoutPress}>{Strings.logout}</Text>
        </View>
    </ParkableBaseView>
}

function reduxProps(state: IRootReducer) {
    return {
        api: state.data.api,
        user: state.user.user,
        firebaseUser: state.auth.firebaseUser,
    }
}

type Props = {
    dispatch: DispatchFunc
} & NavigationProps<Routes.VerifyEmailView> & ReturnType<typeof reduxProps>;

export default connect(reduxProps)(VerifyEmailView);

export const VerifyEmailRoute = createRoute({
    path: Routes.VerifyEmailView,
})

const styles = StyleSheet.create({
    main: {
        paddingVertical: 20,
    },
    signup: {
        fontSize: 45,
        lineHeight: 49,
        marginBottom: 18,
        backgroundColor: Colours.NEUTRALS_WHITE,
        fontFamily: "GTEestiDisplay-Bold",
    },
    welcome: {
        marginTop: 20,
    },
    activationInstructions: {
        marginTop: 20,
    },
    resendText: {
        marginTop: 20,
    },
    logout: {
        marginTop: 20,
    },
    button: {
        color: Colours.BLUE_300,
        textDecorationLine: "underline",
    },
});
