import React, { Ref, useEffect, useImperativeHandle, useRef, useState } from "react";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import Strings from "../../constants/localization/localization";
import { ParkSessionDTO } from "../../model/ParkSessionDTO";
import Dialog from "react/components/dialog/Dialog";
import { EstimatedLeaveTimePicker } from "../../components/tandemParking/EstimatedLeaveTimePicker";
import { updateEstimatedLeaveTime } from "../../api/tandem/tandem.api";
import { useAlertSnackbar } from "../../root/alert-snackbar/alert-snackbar";

export interface EstimatedLeaveTimeModalRef {
    show(): void;
    hide(): void;
}

type EstimatedLeaveTimeModalProps = {
    onNegativePress: () => void;
    currentSession: ParkSessionDTO;
    mutate: () => void;
};

const EstimatedLeaveTimeModal = (props: EstimatedLeaveTimeModalProps, ref: Ref<EstimatedLeaveTimeModalRef>) => {
    const { currentSession, onNegativePress, mutate } = props;
    const dialogRef = useRef<DialogRef>(null);
    const { showSnackBar } = useAlertSnackbar();
    const [estimatedLeaveTime, setEstimatedLeaveTime] = useState<string | null>(null);

    useEffect(() => {
        setEstimatedLeaveTime(currentSession.estimatedLeaveTime);
    }, [currentSession]);

    const show = () => {
        dialogRef.current?.show();
    };

    const hide = () => {
        dialogRef.current?.hide();
    };

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide,
    }));

    const handleTimeUpdate = async () => {
        try {
            await updateEstimatedLeaveTime(currentSession.id, { estimatedLeaveTime });
            await mutate();
        } catch (error) {
            showSnackBar({
                text: Strings.tandem_parking.error_est_leave_time,
                hideDismiss: true,
                style: {
                    backgroundColor: Colours.RED,
                },
            });
        }
    };

    return (
        <Dialog
            ref={dialogRef}
            label={Strings.tandem_parking.estimated_leave_time}
            labelProps={{ style: { color: Colours.NEUTRALS_BLACK, textAlign: "left" } }}
            positiveText={Strings.update}
            positiveProps={{
                textProps: { h5: true, style: { color: Colours.NEUTRALS_WHITE } },
                style: { backgroundColor: Colours.GREEN_300 },
            }}
            negativeProps={{ textProps: { h5: true, style: { color: Colours.NEUTRALS_BLACK } } }}
            negativeText={Strings.cancel}
            onPositivePress={handleTimeUpdate}
            onNegativePress={onNegativePress}
        >
            <Text p>{Strings.tandem_parking.estimated_leave_time_info}</Text>
            <EstimatedLeaveTimePicker
                value={estimatedLeaveTime}
                handleChange={setEstimatedLeaveTime}
                parkId={currentSession.park}
            />
        </Dialog>
    );
};

export default React.forwardRef(EstimatedLeaveTimeModal);
