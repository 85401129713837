import React, {useState} from "react";
import {IRootReducer} from "react/redux/reducers/main";
import Colors from "../../constants/colors";
import {Constants} from "../../constants/constants";
import {connect} from "react-redux";
import {Image, StyleSheet, View} from 'react-native';
import Strings from "../../constants/localization/localization";
import {checkQRCode, checkSensorReceived} from "react/redux/actions/sensors";
import moment from 'moment';
import {useNavigation} from "react/navigation/constants";
import {Routes} from "react/navigation/root/root.paths";
import {ScanSensorProps} from "./ScanSensorQrCodeView";
import Colours from "react/parkable-components/styles/Colours";
import Button from "react/parkable-components/button/Button";
import Input from "react/parkable-components/input/Input";
import Text from "react/parkable-components/text/Text";
import ParkableBaseView from "../common/ParkableBaseView";

const qrCodeLockup = require("react/resources/qrCodeLockup.png");

const getReduxProps = (state: IRootReducer) => {
    const park = state.parks.parks[state.sensors.scannedSensor?.park ?? 0];
    let bay = undefined;
    if (!!park) {
        const bays = state.parks.bays[park.id];
        bay = bays?.[state.sensors.scannedSensor?.bay ?? 0];
    }
    return {
        sensor: state.sensors.scannedSensor,
        park,
        bay,
        dateformat: state.settings.dateformat
    }
}

const actions = {
    checkQRCode,
    checkSensorReceived,
};

const ScanSensorQrManualCodeView = (props: ScanSensorProps) => {

    const [loading, setLoading] = useState(false);
    const [scanned, setScanned] = useState(false);
    const [scannedCode, setScannedCode] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined)

    const navigation = useNavigation();

    const onBackPress = () => {
        if (props.sensor !== undefined) {
            props.checkSensorReceived(undefined);
            setScannedCode(undefined);
            setScanned(false);
        } else {
            navigation.reset({
                routes: [{
                    name: Routes.ParkableMapView,
                }],
            });
        }
    }

    const onSearchCode = () => {
        if (scannedCode !== undefined) {
            props.checkQRCode(scannedCode,
                () => {
                    setError(Strings.unrecognised_code);
                    setScanned(false);
                });
        }
    }

    return (<ParkableBaseView
            scrollable={false}
            loading={loading}
            onBackPress={onBackPress}>
        <View style={styles.base}>
        <Text h1 bold center style={styles.header}>{!!props.sensor ? Strings.sensor_info : Strings.scan_sensor_qr}</Text>
        {props.sensor
            ? <View style={{flex: 1}}>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey bold>{Strings.park}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey>{props.park && props.sensor ? props.park.address : Strings.unassigned}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey bold>{Strings.bay}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey>{props.bay && props.sensor ? props.bay.signage : Strings.unassigned}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey bold>{Strings.id}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey>{props.sensor.id}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey bold>{Strings.eui}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey>{props.sensor.eui}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey bold>{Strings.battery_level}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey>{!!props.sensor.batteryLevel ? props.sensor.batteryLevel : Strings.never}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey bold>{Strings.last_heartbeat}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey>{!!props.sensor.lastHeartbeat ? moment(props.sensor.lastHeartbeat).format(`${props.dateformat} hh.mma`) : Strings.never}</Text>
            </View>
            : <View style={styles.base}>
                <View style={styles.imageContainer}>
                    <Image
                        style={styles.image}
                        source={qrCodeLockup}/>
                </View>
                <Text
                    style={{
                        marginTop: 36,
                        textAlign: "center",
                        color: Colours.NEUTRALS_BLACK
                    }}>
                    {props.route.params?.instructions ?? Strings.new_qr_code_location_desc}
                </Text>

                {error && (
                    <Text
                        style={{
                            alignSelf: "center",
                            marginTop: 9,
                            marginBottom: 9,
                            color: Colours.RED,
                        }}>
                        {error}
                    </Text>
                )}

                <View>
                    <Input
                        style={{
                            marginRight: 9,
                            marginTop: 9,
                        }}
                        autoCorrect={false}
                        autoCapitalize={"characters"}
                        maxLength={7}
                        onChangeText={(v) => {
                            setScannedCode(v.trim().toUpperCase());
                            setScanned(true);
                        }}/>
                    <Button
                        disabled={scannedCode?.length !== 7}
                        center
                        style={{
                            marginTop: 9,
                        }}
                        textProps={{ h4: true }}
                        onPress={() => onSearchCode()}>
                        {Strings.search}
                    </Button>
                </View>
            </View>
        }
        </View>
        </ParkableBaseView>)
}

export default connect(getReduxProps, actions)(ScanSensorQrManualCodeView);

const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    imageContainer: {
        flexDirection: "row",
    },
    image: {
        flex: 1,
        zIndex: -1,
        resizeMode: "contain",
        aspectRatio: 249 / 153,
        height: 200,
    },
    buttonStyle: {
        width: 250,
        height: 40
    },
    containerStyle: {
        flex: 1,
        borderColor: '#000'
    },
    header: {
        paddingVertical: 14,
    },
    simpleViewStyle: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: 10,
        height: 60,
    },
    listView: {
        flex: 1,
        marginTop: 10,
        marginHorizontal: 15,
        backgroundColor: Colors.White,
        marginBottom: 10,
        alignSelf: 'stretch'
    },
    listViewItemText: {
        fontFamily: Constants.FONT_BOLD,
        fontSize: 26,
        paddingVertical: 5,
        paddingHorizontal: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
