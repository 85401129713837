import Colours from "react/parkable-components/styles/Colours";

export default {
  api: {
    name: "dev",
    url: "https://api-dev.parkable.com/api/",
    serviceUrl: (service) => `https://api-dev.parkable.com/service/${service}/api/`,
  },
  environmentDisplayName: "DEV",
  borderColour : Colours.ORANGE_DARK,
  teamsClientId: "49849e77-926b-490c-9656-78ad3fe94436",//"18cde738-d43b-4280-a669-40f7d5a84343",
  teamsAuthorityUrl: "https://login.microsoftonline.com/",
}
