import React, { useEffect, useRef } from "react";
import { View, StatusBar, StyleSheet, Animated } from "react-native";
import Colours from "react/parkable-components/styles/Colours";
import { range } from "lodash";

const NUMBER_OF_CIRCLES = 3;
const ANIMATION_DURATION = 400;
const ANIMATION_STAGGER = 200;
const MIN_CIRCLE_SIZE = 15;
const MAX_CIRCLE_SIZE = 20;
const MIN_CIRCLE_OPACITY = 0;
const MAX_CIRCLE_OPACITY = 1;
const USE_NATIVE_DRIVER = true;

function createAnimations() {
    const opacity = new Animated.Value(MIN_CIRCLE_OPACITY);
    const scale = new Animated.Value(MIN_CIRCLE_SIZE / MAX_CIRCLE_SIZE);
    const sequence = Animated.sequence([
        Animated.parallel([
            Animated.timing(opacity, {
                toValue: MAX_CIRCLE_OPACITY,
                duration: ANIMATION_DURATION,
                useNativeDriver: USE_NATIVE_DRIVER
            }),
            Animated.timing(scale, {
                toValue: 1,
                duration: ANIMATION_DURATION,
                useNativeDriver: USE_NATIVE_DRIVER
            })
        ]),
        Animated.parallel([
            Animated.timing(opacity, {
                toValue: MIN_CIRCLE_OPACITY,
                duration: ANIMATION_DURATION,
                useNativeDriver: USE_NATIVE_DRIVER
            }),
            Animated.timing(scale, {
                toValue: MIN_CIRCLE_SIZE / MAX_CIRCLE_SIZE,
                duration: ANIMATION_DURATION,
                useNativeDriver: USE_NATIVE_DRIVER
            })
        ])
    ]);
    return {
        opacity,
        scale,
        sequence,
    };
}

export default function LoadingOverlay_new(props: { loading: boolean, leaveStatusBarAlone?: boolean }) {

    const animations = useRef(range(NUMBER_OF_CIRCLES).map(createAnimations)).current;

    const startAnimation = () => {
        Animated.loop(
            Animated.stagger(ANIMATION_STAGGER, animations.map(value => value.sequence))
        ).start();
    };

    useEffect(() => {
        startAnimation();
    }, []);

    if (!props.loading) {
        return null;
    }

    return (
        <View style={styles.mainContainer}>
            {animations.map((value, i) =>
                <View key={i} style={styles.circleContainer}>
                    <Animated.View
                        style={[
                            styles.circle,
                            {
                                opacity: value.opacity,
                                transform: [
                                    { scale: value.scale }
                                ],
                            }
                        ]} />
                </View>
            )}
        </View>);
}

const styles = StyleSheet.create({
    mainContainer: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        elevation: 7,
        backgroundColor: "rgba(00, 00, 00, 0.8)"
    },
    circleContainer: {
        width: MAX_CIRCLE_SIZE,
        height: MAX_CIRCLE_SIZE,
        alignItems: "center",
        justifyContent: "center",
        margin: 10
    },
    circle: {
        width: MIN_CIRCLE_SIZE,
        height: MIN_CIRCLE_SIZE,
        borderRadius: MAX_CIRCLE_SIZE,
        backgroundColor: Colours.NEUTRALS_WHITE
    }
});
