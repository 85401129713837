import React, {useCallback, useEffect, useState} from 'react'
import {View, StyleSheet, TouchableOpacity, AppStateStatus, AppState, Keyboard} from "react-native";
import Text from "react/parkable-components/text/Text";
import Spinner from "react/parkable-components/spinner/Spinner";
import Colours from "react/parkable-components/styles/Colours";
import { connect } from "react-redux";
import { IRootReducer } from '../../redux/reducers/main';
import Strings from '../../constants/localization/localization';
import { createRoute, NavigationProps } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import {requestMagicLink} from "../../navigation/magicLinks/magicLinks";
import ParkableBaseView from "../common/ParkableBaseView";

type MagicLinkViewProps = NavigationProps<Routes.MagicLinkView> & ReturnType<typeof getReduxProps> & typeof actions;

const MagicLinkView = (props: MagicLinkViewProps) => {
    const {emailAddress} = props.route.params;
    const {api} = props;

    const [error, setError] = useState<string|undefined>(undefined);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        const fn = async () => {
            await doRequestLink();
        }

        void fn();
    }, [emailAddress]);

    const doRequestLink = useCallback(async () => {
        try {
            await requestMagicLink(api, emailAddress);
        } catch (e: any) {
            setError(e.message ?? Strings.error_try_again);
        }
    }, [emailAddress, api]);


    const onResend = async () => {
        setError(undefined);
        await doRequestLink();
    }

    const onAppStateChange = (nextAppState: AppStateStatus) => {
        if (nextAppState === 'active') {
            setShowSpinner(true);
        }
    }

    useEffect(() => {
        Keyboard.dismiss();
        const subscription = AppState.addEventListener('change', onAppStateChange);
        return () => subscription.remove();
    }, []);

    return  <ParkableBaseView scrollable={false}>
            <View style={styles.base}>
                <View style={{justifyContent: "space-between", flex: 1}}>
                    <View>
                        <View>
                            {!error && <View>
                                <Text small style={styles.welcome}>{Strings.check_your_inbox}</Text>
                                <View style={{flexDirection: "row"}}>
                                    <Text small>{Strings.click_link_sent_to}
                                        <Text small bold>{emailAddress}</Text>
                                        {Strings.to_sign_in}</Text>
                                </View>
                                <TouchableOpacity onPress={onResend} >
                                    <Text small style={{marginTop:18, marginBottom:18}}>{Strings.didnt_get_email  + " "}<Text small style={{color: Colours.BLUE_300, textDecorationLine: 'underline'}}  >{Strings.try_again}</Text></Text>
                                </TouchableOpacity>
                            </View>}
                            {error && <Text center red style={{marginTop:18, marginBottom:18}}>{error}</Text>}
                            {showSpinner &&
                            <View style={styles.spinnerContainer}>
                                <Spinner large />
                            </View>}
                        </View>
                    </View>
                </View>
            </View>
    </ParkableBaseView>
}

const styles = StyleSheet.create({
    top: {
        width: '100%',
        paddingVertical: 9,
        justifyContent: 'center',
    },
    welcome:{
        fontSize: 45,
        lineHeight: 49,
        marginBottom: 18,
        fontFamily: "GTEestiDisplay-Bold",
    },
    topLeft: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    topTitle: {
        position: 'absolute',
        paddingTop: 15,
        alignSelf: 'center'
    },
    base: {
        flex: 1,
    },
    subText: {
        top: 20,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    spinnerContainer: {
        width: 45,
        height: 45,
        alignSelf: 'center'
    }
})

const actions = {
    sendMagicLink: requestMagicLink
}

const getReduxProps = (state: IRootReducer) => {

    return {
        api: state.data.api
    };
}

class MagicLinkParams {
    emailAddress: string;
}

export default connect(getReduxProps, actions)(MagicLinkView);

export const MagicLinkRoute = createRoute({
    path: Routes.MagicLinkView,
    params: { type: MagicLinkParams },
});
