import React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { Feature } from "react/model/Bay";
import Text from "react/parkable-components/text/Text";
import Icon from "react/parkable-components/icon/Icon";
import Colours from "react/parkable-components/styles/Colours";
import { VehicleDTO } from "react/api/vehicle/dto/vehicleDTO";
import { spacing } from "react/constants/theme/spacing";

const Cross = require("react/resources/cross-on-red.png");
const EditGreen = require("react/resources/pencil.png");

type VehicleProps = {
    vehicle: VehicleDTO;
    selectVehicle: (vehicle: VehicleDTO) => void;
    removeVehicle: (vehicle: VehicleDTO) => void;
    editVehicle: (vehicle: VehicleDTO) => void;
    isSelected?: boolean;
    showRemoveButton?: boolean;
    showEditButton?: boolean;
    disabled?: boolean;
};

export const Vehicle = (props: VehicleProps) => {
    const {
        vehicle,
        selectVehicle,
        removeVehicle,
        editVehicle,
        isSelected,
        showRemoveButton,
        showEditButton,
        disabled,
    } = props;

    const vehicleIsEv = vehicle.feature === "ELECTRIC" || vehicle.isEV;
    const vehicleIsMotorbike = vehicle.feature === "MOTORBIKE" || vehicle.isMotorbike;

    return (
        <View style={styles.vehicles}>
            <TouchableOpacity
                style={[styles.vehicleItem, isSelected ? styles.selectedVehicle : undefined]}
                disabled={disabled}
                activeOpacity={1}
                onPress={() => selectVehicle(vehicle)}
            >
                {vehicleIsMotorbike &&
                    <Icon small name={"motorbike"} white={isSelected} style={styles.motorbikeIcon} />
                }
                {vehicleIsEv &&
                    <Icon small name={"electricvehicleplug"} white={isSelected} style={styles.evIcon} />
                }
                <Text numberOfLines={1} style={styles.vehicleText} white={isSelected}>
                    {vehicle.registration}
                </Text>
            </TouchableOpacity>

            {!isSelected && showRemoveButton && (
                <TouchableOpacity
                    style={styles.editAndDeleteIconContainer}
                    activeOpacity={1}
                    onPress={() => removeVehicle(vehicle)}
                >
                    <Image style={styles.pencilAndCrossIcons} source={Cross} />
                </TouchableOpacity>
            )}

            {showEditButton && (
                <TouchableOpacity
                    style={styles.editAndDeleteIconContainer}
                    activeOpacity={1}
                    onPress={() => editVehicle(vehicle)}
                >
                    <Image style={styles.pencilAndCrossIcons} source={EditGreen} />
                </TouchableOpacity>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    vehicles: {
        width: "48%",
        marginRight: spacing(1),
    },
    editAndDeleteIconContainer: {
        position: "absolute",
        top: -6,
        right: -6,
        zIndex: 500,
    },
    pencilAndCrossIcons: {
        height: spacing(2),
        width: spacing(2),
    },
    motorbikeIcon: {
        width: spacing(2),
        height: spacing(2),
    },
    evIcon: {
        width: 15,
        height: 15,
    },
    vehicleItem: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        columnGap: spacing(0.66),
        padding: spacing(1),
        borderRadius: 2,
        borderWidth: 1,
        borderColor: Colours.NEUTRALS_300,
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    vehicleText: {
        flexShrink: 1,
    },
    selectedVehicle: {
        borderColor: Colours.BLUE_300,
        backgroundColor: Colours.BLUE_300,
    },
});
