import React, {useCallback, useEffect, useState} from 'react';
import {StyleSheet, View, Keyboard} from "react-native";
import Text from "react/parkable-components/text/Text";
import Spinner from "react/parkable-components/spinner/Spinner";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Icons from "react/parkable-components/icon/Icons";
import Colours from "react/parkable-components/styles/Colours";
import Strings from "../../constants/localization/localization";
import {IFilteredPrediction} from "../../dto/IPrediction";
import {User} from "../../model/User";
import {connect} from "react-redux";
import {IRootReducer} from "../../redux/reducers/main";
import _ from 'lodash';
import { getActiveSubscriptions } from '../../constants/getActiveSubscriptions';
import { getParkAvailabilityInfo } from "../../constants/getParkAvailability";
import { ParkingType } from '../../model/Types';
import { ParkInfo2 } from "../../model/ParkInfo2";
import {useUserRoles} from "../../api/user/user.api";
import {getPrivateOrgs} from "../../constants/getUserMember";
import { Routes } from "../../navigation/root/root.paths";
import { useNavigation } from "../../navigation/constants";
import { updateUserOptions } from "../../redux/actions/userOptions";
import { useAppDispatch } from "../../redux/redux";
import { requestLocationData } from "../../redux/actions/google";
import { MapScreenParams } from "../map/parkableMapView/ParkableMapView_root";

type Props = {
    searchPredictions: IFilteredPrediction[],
    searchResults: {[key:string]: ParkInfo2[]},
    user: User | null,
    push: Function,
    searchText:string,
    sessiontoken: string,
    parkingType: ParkingType
}

function SearchResults (props: Props & ReturnType<typeof getReduxProps> & typeof actions) {

    const navigation = useNavigation();
    const dispatch = useAppDispatch();

    const {
        searchPredictions,
        searchResults,
        push,
        searchText,
        currentSession,
        activeSubscriptions,
        parkingType,
        sessiontoken
    } = props;

    const [userPrivateOrganisationIds, setUserPrivateOrganisationIds] = useState(new Set());
    const [allFilteredUnique, setAllFilteredUnique] = useState<Array<{park: ParkInfo2, available: boolean}>>([]);
    const [firstPrediction, setFirstPrediction] = useState<IFilteredPrediction>();
    const {userRoles} = useUserRoles();

    const onParkPress = useCallback((park:ParkInfo2) => {
        Keyboard.dismiss();
        const subInPark = activeSubscriptions.filter(s => s.park === park.parkId)[0];
        if (!!currentSession && !!currentSession.startedAt && currentSession.park === park.parkId) {
            push(Routes.ActiveSession);
        } else if(!!subInPark) {
            push(Routes.SingleSubscriptionView, {subscriptionId: subInPark.id});
        } else {
            dispatch(updateUserOptions({search: park.address, parkId: park.parkId}));
            push(Routes.ParkDetailView, {parkId: park.parkId, parkingType: parkingType});
        }
    }, [activeSubscriptions, currentSession, parkingType]);

    const onPredictionPress = useCallback(async (prediction: IFilteredPrediction) => {
        Keyboard.dismiss();

        const { result: place } = await requestLocationData(prediction.place_id, sessiontoken);
        const { lat, lng } = place.geometry.location;

        const location = {
            latitude: typeof lat === "function" ? lat() : lat,
            longitude: typeof lng === "function" ? lng() : lng
        };

        const placeString = place.structured_formatting?.main_text || place.formatted_address || "";

        updateUserOptions({
            search: placeString,
            ...location
        });

        const params: MapScreenParams = {
            animatedToSearchLocation: false,
            bySearch: true,
            ...location,
        }

        //we cant put the deltas into our search object BAC-3785
        if (place.geometry.viewport) {
            params.latitudeDelta = 0.01;
            params.longitudeDelta = 0.01;
        }

        navigation.reset({
            routes: [{
                name: Routes.ParkableMapView,
                params
            }]
        })

    },[sessiontoken]);

    const renderSearchedItem = useCallback((key: string, label: string, description: string | null, park: {park: ParkInfo2, available: boolean} | null, prediction: IFilteredPrediction | null) => {
        if (!label) {
            return null;
        }
        const parkInfo = park?.park;
        const parkAvailable = park?.available;
        return <TableRow iconLeft={parkInfo ? Icons.pinparking : Icons.pinlocation1}
                         iconLeftProps={{iconStyle: {color: parkAvailable ? Colours.BLUE_400 : Colours.GREY_20}}}
                         key={key}
                         labelBottom={description || undefined}
                         textProps={{numberOfLines:1}}
                         onPress={() => {
                             Keyboard.dismiss();
                             parkInfo ? onParkPress(parkInfo) : onPredictionPress(prediction!);
                             }}>
            {label}
        </TableRow>
    }, [parkingType]);

    useEffect(() => {
        if (!userRoles) {
            return;
        }
        const orgIds = getPrivateOrgs(userRoles);
        setUserPrivateOrganisationIds(new Set(orgIds));
    }, [userRoles]);

    useEffect(() => {
        const firstPrediction: IFilteredPrediction | undefined = searchPredictions[0];
        const filteredCloseParks = (searchResults[firstPrediction?.place_id] || [])
            .filter(p => p.organisationId === null
                    || (p.numberOfPublicDynamicBays??0) > 0
                    || (p.numberOfPublicSubscriptionBays??0) > 0
                    || userPrivateOrganisationIds.has(p.organisationId));

        const filteredSearchParks = (searchResults[searchText] || [])
            .filter(p => p.organisationId === null
                || (p.numberOfPublicDynamicBays??0) > 0
                || (p.numberOfPublicSubscriptionBays??0) > 0
                || userPrivateOrganisationIds.has(p.organisationId));

        const allFiltered = filteredSearchParks.concat(filteredCloseParks);

        const allFilteredUnique:Array<{park: ParkInfo2, available: boolean}> = []
        _.uniqBy(allFiltered, 'parkId')
        .forEach(p => {
            const {hasLongTerm, hasShortTerm, hasEVCharger, parkIsOpen, parkLTFull, parkSTFull} = getParkAvailabilityInfo(p);
            const { hideCasualBays, hideSubscriptionBays, hideEVBays } = p;
            switch (parkingType) {
                case ParkingType.ELECTRIC:
                    if (hideEVBays) {
                        break;
                    }
                    if(hasEVCharger) {
                        allFilteredUnique.push({park: p, available: parkIsOpen})
                    }
                    break; //todo
                case ParkingType.LONG_TERM: {
                    if (hideSubscriptionBays) {
                        break;
                    }
                    if(hasLongTerm) {
                        allFilteredUnique.push({park: p, available: parkIsOpen && !parkLTFull})
                    }
                    break;
                }
                case ParkingType.CASUAL:
                    if (hideCasualBays) {
                        break;
                    }
                default:
                    if(hasShortTerm) {
                        allFilteredUnique.push({park: p, available: parkIsOpen && !parkSTFull})
                    }
                    break;
            }
        });
        setFirstPrediction(firstPrediction)
        setAllFilteredUnique(allFilteredUnique);
    }, [searchPredictions, searchText, searchResults, parkingType, userPrivateOrganisationIds])

    if ((searchPredictions.length + allFilteredUnique.length) === 0) {
        if(searchText.length >= 4){
            return <Text small>{Strings.no_places_found(searchText)}</Text>
        }else{
            return <View style={{alignSelf: "center"}}><Spinner /></View>
        }
    }

    return (
        <View>
            {allFilteredUnique.slice(0, 2).map((park, i) =>
                renderSearchedItem(`park${i}`, park.park.address, park.park.address, park, null)
            )}
            {!!firstPrediction && renderSearchedItem(`firstPrediction`, firstPrediction.structured_formatting.main_text, firstPrediction.description, null, firstPrediction)}
            {searchPredictions.slice(1, 5).map((prediction, i) =>
                renderSearchedItem(`prediction${i}`, prediction.structured_formatting.main_text || prediction.description, prediction.description, null, prediction)
            )}
        </View>
    )
}

const getReduxProps = (state: IRootReducer) => {
    const activeSubscriptions = getActiveSubscriptions(state.subscriptions.employeeSubscriptions);
    return {
        currentSession: state.parking.currentSession,
        activeSubscriptions,
    }
};

const actions = {
};

export default connect(
    getReduxProps,
    actions
)(SearchResults) as React.FunctionComponent<Props>;

const styles = StyleSheet.create({
    parkIcon: {
        color: Colours.BLUE_400
    }
});
