import React from "react"
import TableRow from "react/parkable-components/tableRow/TableRow";
import Strings from "../../../constants/localization/localization"
import { DayAvailabilityDTO } from "../../../model/DayAvailability"
import { getOpeningHoursFromAvailString } from "../../../constants/availability/convert"

export type OpeningHoursRowProps = {
	dayAvailability?: DayAvailabilityDTO;
	isLoading?: boolean;
}

export const OpeningHoursRow = (props: OpeningHoursRowProps) => {
	const [opening, closing] = props.dayAvailability?.hourlyAvailability ?
		getOpeningHoursFromAvailString(props.dayAvailability?.hourlyAvailability)
		: [undefined, undefined]
	const isClosed = props.dayAvailability?.available === false || opening === closing

	return <TableRow label={Strings.opening_hours}
		iconLeft="stopwatch">
		{isClosed && Strings.closed}
		{!isClosed && <>
			{props.isLoading ? Strings.loading : `${opening} - ${closing}`}
		</>}
	</TableRow>
}
