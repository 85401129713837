import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import List from "../common/List";
import { connect } from "react-redux";
import Strings from "../../constants/localization/localization";
import { getVouchers } from "../../redux/actions/vouchers";
import _ from "lodash";
import { getTerritory } from "../../redux/actions/territories";
import { IRootReducer } from "../../redux/reducers/main";
import { Voucher } from "../../model/Voucher";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import VoucherCard from "../common/voucher/VoucherCard";
import { getId, voucherIsRedeemed } from "../../constants/Util";
import Spinner from "../common/Spinner";
import { getPark } from "../../redux/actions/parks";
import { Routes } from "react/navigation/root/root.paths";
import { createRoute, NavigationProps } from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";

type VouchersViewProps = typeof actions & ReturnType<typeof getReduxProps> & NavigationProps<Routes.VouchersView>;

const VouchersView = (props: VouchersViewProps) => {
    const [loading, setLoading] = useState(true);

    const {
        territories,
        parks,
        vouchers,
        getVouchers,
        getTerritory,
        getPark,
        navigation
    } = props;

    const _getVouchers = () => {
        setLoading(true);
        getVouchers((vouchers: Voucher[]) => {
            setLoading(false);
            const territoryIds = [...new Set(vouchers.map(v => v.territory).filter(t => t))];

            territoryIds.map(territoryId =>
                new Promise((resolve, reject) =>
                    getTerritory(territoryId!, territory => resolve(territory), err => reject(err))));

            const parkIds = [...new Set(vouchers.map(v => getId(v.park)).filter(t => t))];
            parkIds.forEach(parkId => getPark(parkId!));
        }, () => {
            setLoading(false);
        });
    };

    useEffect(() => {
        _getVouchers();
    }, []);

    const enterVoucher = () => {
        navigation.navigate(Routes.AddVoucherCode, {});
    }

    const renderVoucherRow = ({item: voucher}: {item: Voucher}) => {
        return <VoucherCard style={styles.voucherCard}
                            parkId={typeof voucher.park === "number" ? voucher.park : voucher.park?.id ?? undefined}
                            voucher={voucher}
        />;
    }

    const selectedVouchers = _.chain(vouchers)
        .values()
        .sortBy(v => v.createdAt)
        .reverse()
        .sortBy(v => voucherIsRedeemed(v))
        .value();

    return (<ParkableBaseView scrollable={false}>
        <ScrollView style={styles.innerContainer}>
            {loading ? <Spinner/> :
                <View style={styles.mainContainer}>
                    {selectedVouchers.length ? <List
                          renderRow={renderVoucherRow}
                          data={selectedVouchers}/> :
                        <View style={styles.noVoucherCard}>
                            <Text h2 style={styles.labelNoVouchers}>{Strings.no_vouchers}</Text>
                            <Text small>{Strings.no_vouchers_explanation}</Text>
                        </View>}
                </View>}
        </ScrollView>

        <View style={styles.bottomBtn}>
            <Button onPress={enterVoucher}
                    iconRight={"arrowboldright"}>{Strings.enter_voucher_code}</Button>
        </View>
    </ParkableBaseView>);
}

const actions = {
    getVouchers,
    getTerritory,
    getPark
}

const getReduxProps = (state: IRootReducer) => {
    return {
        vouchers: (state.vouchers || {}).vouchers || {},
        territories: (state.territories || {}).territories || {},
        parks: (state.parks || {}).parks,
    }
}

export default connect(getReduxProps, actions)(VouchersView as React.FunctionComponent<VouchersViewProps>);

export const VouchersRoute = createRoute({
    path: Routes.VouchersView,
});

const styles = StyleSheet.create({
    innerContainer: {
        flex: 1,
        paddingBottom:36
    },
    mainContainer: {
        flex: 1,
        paddingBottom: 121,
    },
    bottomBtn: {
        paddingTop: 18,
        paddingLeft: 18,
        paddingRight: 18,
    },
    footer: {
        height: 60,
        width: "100%",
        justifyContent: "flex-end",
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    buttonsView: {
        marginVertical: 10,
    },
    voucherView: {
        paddingLeft: 15,
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#DDDDDD',
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    noVouchersCard: {
        borderRadius: 5,
    },
    noVouchersText: {
        backgroundColor: '#EEEEEE',
        paddingHorizontal: 10,
        paddingTop: 20,
        paddingBottom: 40,
    },
    voucherCard: {
        elevation: 2,
        borderRadius: 5,
        marginBottom: 9
    },
    noVoucherCard: {
        backgroundColor: Colours.NEUTRALS_WHITE,
        borderColor: Colours.GREY_BORDER,
        borderWidth: 1,
        elevation: 2,
        borderRadius: 5,
        paddingTop: 18,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 36
    },
    labelNoVouchers:{
        borderColor: Colours.GREY_BORDER,
        borderBottomWidth: 1,
        paddingBottom: 9,
        marginBottom: 18,
    },
});
