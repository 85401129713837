import TableRow from "react/parkable-components/tableRow/TableRow";
import React, { FunctionComponent, useMemo } from "react";
import Strings from "../../../constants/localization/localization";
import localizeCurrency from "../../../constants/localization/localizeCurrency";
import { EmployeeSubscriptionDTO } from "react/dto/EmployeeSubscriptionDTO";

type Props = {
    subscription: EmployeeSubscriptionDTO,
    lastTermAmount: number,
    loadingLastTermAmount:boolean,
    currencyCode?: string,
    daysUsed: number
}

function SubscriptionAdditionalDaysDueNowRow(props: Props) {
    const { subscription, currencyCode, lastTermAmount, daysUsed } = props;

    const termPriceText = useMemo(():string => {
        return `${localizeCurrency(lastTermAmount, currencyCode??'NZD', false)}`;
    }, [lastTermAmount, subscription, currencyCode]);
    return <TableRow iconLeft={'dollar'} label={Strings.additional_days_due_now} >{`${termPriceText} (${daysUsed} ${Strings.days})`}</TableRow>
}
export default SubscriptionAdditionalDaysDueNowRow as FunctionComponent<Props>
