import {useMySWR} from "../http/use-swr";
import {ParkingCostResponse} from "react/api/parkingPrice/dto/ParkingCost.response";
import {CalculateEstimatedAmountRequest} from "react/api/parkingPrice/dto/CalculateEstimatedAmount.request";
import {ParkingPriceResponse} from "react/api/parkingPrice/dto/ParkingPrice.response";
import {generateQueryString, get} from "react/api/http/rest";
import { Nully } from "react/constants/nully";

export const useParkingPrice = (parkingPriceId?: number | null) => {
    return useMySWR<ParkingPriceResponse>(
        parkingPriceId ? `v1/parking-price/${parkingPriceId}` : null
    );
};

export const useCalculateEstimatedAmount = (request: CalculateEstimatedAmountRequest) => {
    const queryString = generateQueryString(request);
    return useMySWR<ParkingCostResponse>(
        request.parkId && (request?.start || request?.sessionId)
            ? `v1/parking-price/park/${request.parkId}/calculateEstimatedSessionAmount${queryString}`
            : null,
        get, {refreshInterval: 1000 * 60}
    );
};

export const useCalculateEstimatedAmountByParkingPrice = (parkingPrice: Nully<number>, start: Nully<string>, end: Nully<string>, bayId: Nully<number>) => {
    const queryString = generateQueryString({start, end, bayId});
    console.log('queryString', queryString)
    return useMySWR<ParkingCostResponse>(
        parkingPrice && start
            ? `v1/parking-price/${parkingPrice}/calculate-estimated-amount${queryString}`
            : null
    );
};
