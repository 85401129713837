import Text from "react/parkable-components/text/Text";
import React from "react";
import {Image, View} from "react-native";
import {theme} from "../../../constants/theme";
import {styles} from "./styles";
import {priorityBadgeStyles} from "../day-tile/styles";
import priorityBadge from "../../../resources/priorityBadge.png";
import arrowDoubleUp from "../../../resources/arrowDoubleUp.png"


export type LegendItemProps = {
    text: string;
    color: string;
    border?: string;
    badge?: "priority" | "waitlist";
};

export const LegendItem = (props: LegendItemProps) => {
    return (
        <View style={styles.container}>
            <View
                style={[styles.legendBox,
                    {
                        backgroundColor: props.color,
                        borderColor: props.border ?? theme.palette.NEUTRALS_WHITE,
                        borderWidth: 1
                    },
                ]}
            >
                {props.badge === 'priority' && <Image style={priorityBadgeStyles.container} source={priorityBadge}/>}
                {props.badge === 'waitlist' && <Image style={priorityBadgeStyles.container} source={arrowDoubleUp}/>}
            </View>
            <View>
                <Text small grey style={styles.text}>
                    {props.text}
                </Text>
            </View>
        </View>
    );
};
