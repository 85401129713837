import { useMySWR } from "../http/use-swr";
import { TokenResponse } from "../generic/tokenResponse";
import {LastUpdatedResponse, RetrieveUserRolesResponse, UserDTOResponse} from "./dto/user.dto";
import {put, get} from "../http/rest";
import {mutate} from "swr";
import {Token} from "../rest";

export const useInvoiceToken = () => {
    return useMySWR<TokenResponse>(`v2/users/invoiceToken`);
}

export const useCurrentUser = () => {
    return useMySWR<UserDTOResponse>(`v2/users`);
}

export const getCurrentUser = () => {
    return get<UserDTOResponse>(`v2/users`);
}

export const refreshCurrentUser = () => {
    mutate(`v2/users`).catch(console.error);
}

export const useUserRoles = () => {
    return useMySWR<RetrieveUserRolesResponse>(`/v4/users/roles`);
};

export const acceptTsAndCs = ({ token, userId }: { token: string, userId: number }): Promise<UserDTOResponse> => {
    const headers = token ? { Authorization: "Bearer " + token } : undefined;
    return put(`/v2/users/acceptTCs`, undefined, headers);
};

export const reAcceptTsAndCs = ( userId: number): Promise<UserDTOResponse> => {
    return put(`/v2/users/${userId}/reAccept-Ts-And-Cs`);
};

export const useLatestTsAndCs = () => {
    const { data: user } = useCurrentUser();
    return useMySWR<LastUpdatedResponse>(user ? "/v2/users/latest-Ts-And-Cs" : null);
}
