import Spinner from "react/parkable-components/spinner/Spinner";
import Text from "react/parkable-components/text/Text";
import { TouchableOpacity, Image, LayoutRectangle } from "react-native";
import React, { useState } from "react";
import { View } from "react-native";
import {
    containerStyles,
    dayNumberStyles,
    dayTextStyles,
    getTileColors,
    priorityBadgeStyles, spinnerStyles,
} from "./styles";
import { DayTileProps, FutureBookingStatus } from "./types";
import { spacing } from "react/constants/theme/spacing";
import Strings from "react/constants/localization/localization";
import priorityBadge from "../../../resources/priorityBadge.png";
import arrowDoubleUp from "../../../resources/arrowDoubleUp.png"


export const DayTile = (props: DayTileProps) => {
    const [layout, setLayout] = useState<LayoutRectangle | undefined>();

    const { status, loading, dayName, dayNumber, isToday, isInPast, hasActiveSession, onClick } = props;

    const hasPriorityBadge =
        !isToday && status === FutureBookingStatus.PriorityRequested;

    const hasWaitlistTopBadge = status === FutureBookingStatus.WaitListedNearTop

    const tileColors = getTileColors(status, isToday);

    const showDay = (layout?.width ?? 0) > 60;

    const hasStatusMessage = hasActiveSession || !!status

    const getRequestStatus = () => {
        if (hasActiveSession && isToday) {
            return Strings.parking;
        }

        if (!status) {
            return;
        }

        switch (status) {
            case FutureBookingStatus.Confirmed: {
                return Strings.future_booking.status.confirmed;
            }
            case FutureBookingStatus.WaitListed:
            case FutureBookingStatus.WaitListedNearTop: {
                return Strings.future_booking.status.wait_list
            }
            case FutureBookingStatus.Requested:
            case FutureBookingStatus.PriorityRequested: {
                return Strings.future_booking.status.requested
            }
            default: return;
        }
    }

    if (loading) {
        return <View
            style={[
                containerStyles.base,
                containerStyles.isLoading,
                { minHeight: layout?.width }
            ]}>
            <View style={[spinnerStyles.spinnerContainer]}>
                <Spinner />
            </View>
        </View>
    }

    return (
        <TouchableOpacity
            disabled={isInPast}
            activeOpacity={0.8}
            onPress={(e) => {
                onClick?.({
                    // For some reason layout.y is always 0 so using touch location instead
                    left: e.nativeEvent.pageX,
                    top: e.nativeEvent.pageY,
                });
            }}
            onLayout={(e) => setLayout(e.nativeEvent.layout)}
            style={[
                containerStyles.base,
                tileColors.container,
                isInPast ? containerStyles.isInPast : {},
            ]}
        >
            <View
                collapsable={false}
                style={[
                    dayTextStyles.container,
                    tileColors.dayTextContainer,
                ]}
            >
                {hasPriorityBadge && (
                    <Image style={priorityBadgeStyles.container} source={priorityBadge} />
                )}
                {(hasWaitlistTopBadge && !isToday) && (
                    <Image style={priorityBadgeStyles.container} source={arrowDoubleUp} />
                )}
                <Text
                    center
                    bold
                    strapline
                    style={[dayTextStyles.text, tileColors.dayText]}>
                    {showDay ? dayName : dayName.slice(0, 1)}
                </Text>
            </View>
            <View style={dayNumberStyles.container}>
                <View style={{ marginTop: spacing(1) }}>
                    <Text center bold style={[dayNumberStyles.text, tileColors.dayNumber]}>
                        {dayNumber}
                    </Text>
                </View>
                {hasStatusMessage && (
                    <Text
                        center
                        style={
                            [tileColors.statusText,
                            { lineHeight: 8, fontSize: 8 }]}>
                        {getRequestStatus()}
                    </Text>
                )}
            </View>
        </TouchableOpacity>
    );
};
