import Colours from "react/parkable-components/styles/Colours";
import FAB from "react/parkable-components/fab/FAB";
import Icons from "react/parkable-components/icon/Icons";
import React, {useCallback} from "react";
import {LayoutChangeEvent, StyleSheet} from "react-native";
import { useMapSafeArea } from "../../../hooks/useSafeArea";
import {useDrawerActions} from "../../../navigation/constants";

const styles = StyleSheet.create({
    drawerButton: {
        position: 'absolute',
        left: 18,
    },
});

type AllProps = {
    onLayout?: (event: LayoutChangeEvent) => void
}

function MenuButton({onLayout}: AllProps) {
    const drawerActions = useDrawerActions();
    const { top } = useMapSafeArea();

    const toggleDrawer = useCallback(() => {
        drawerActions.toggleDrawer();
    }, []);

    return (
        <FAB
            onLayout={onLayout}
            style={[{top},styles.drawerButton]}
            onPress={toggleDrawer}
            testID={'ToggleDrawerButton'}
            icon={Icons.hamburger}
            iconProps={{
                color: Colours.BLUE_300,
                iconStyle: {
                    fontSize: 18
                }
            }}
        />
    );
}

export default React.memo(MenuButton) as React.FunctionComponent<AllProps>;
