import React from 'react';
import TableRow from "react/parkable-components/tableRow/TableRow";
import Spinner from "react/parkable-components/spinner/Spinner";
import Strings from '../../constants/localization/localization';
import { useBaysUserIsMember, useBaysUserIsMemberManyParks, useUserParkingPreference } from '../../api/bay/bay.api';
import { Routes } from "../../navigation/root/root.paths";
import { useNavigation } from "../../navigation/constants";

type PreferredBaysRowProps = {
    orgId: number,
    parkId?: number,
    campusParksIds?: number[],
}

export default function PreferredBaysRow(props: PreferredBaysRowProps) {
    const navigation = useNavigation();
    const { orgId, parkId, campusParksIds } = props;
    const {preferences: parkingPreferences} = useUserParkingPreference();
    // When showing a preference summary from Campus View, we need to show preferences across all parks in the campus.
    const { bays } = parkId ? useBaysUserIsMember(parkId) : useBaysUserIsMemberManyParks(campusParksIds);

    if (!bays || !parkingPreferences) {
        return (
            <TableRow iconLeft="star" chevron>
                <Spinner small />
            </TableRow>
        )
    }

    const parkingPreferencesInPark = parkingPreferences.filter(pref => bays.some(bay => bay.id === pref.bay && pref.park === bay.park));

    const handlePress = () => {
        if (parkId) {
            navigation.push(Routes.PreferredBaysView, { orgId, parkId });
        } else if (campusParksIds) {
            navigation.push(Routes.SelectParkView, { orgId, campusParksIds });
        }
    }

    return (
        <TableRow
            iconLeft="star"
            label={Strings.preferred_bays.preferred_bays}
            chevron
            onPress={handlePress}
        >
            {!parkingPreferencesInPark.length ?
                Strings.preferred_bays.no_preferred_bays
                : Strings.preferred_bays.bays_selected(parkingPreferencesInPark.length)
            }
        </TableRow>
    );
};
