import React from 'react';
import { ParkingType } from '../../model/Types';
import { TableRow } from 'react/parkable-components';
import Strings from "../../constants/localization/localization";
import localizeCurrency from "../../constants/localization/localizeCurrency";
import { View } from 'react-native';
import {ParkDTO} from "../../model/ParkDTO";
import {TerritoryDTO} from "../../api/territory/dto/TerritoryDTO";
import {Territory} from "../../model/Territory";
import {ParkDTOWithTerritory} from "../../redux/actions/parks";
import {Routes} from "react/navigation/root/root.paths";
import {useNavigation} from "react/navigation/constants";
import {useParkingPrice} from "react/api/parkingPrice/parkingPrice.api";
import {
    getCurrentDailyPricePeriod,
    getCurrentHourlyPricePeriod,
} from "react/services/parkingPrice.service";
import {BayDTO} from "react/model/BayDTO";
import {useBayGroup} from "react/api/bayGroup/bayGroup.api";
import {Bay} from "react/model/Bay";
import moment from "moment-timezone";
import {Moment} from "moment";
import Spinner from "../../parkable-components/spinner/Spinner";
import Text from "../../parkable-components/text/Text";
import { useUserRoles } from "../../api/user/user.api";
import { Role } from "../../model/User";
import { useCalculateProcessingFee } from "../../api/processingFees/processingFees.api";
import { useIsPublicBay } from "../../api/parkSession/parkSession.api";

type Props = {
    parkingType?: ParkingType,
    park?: Pick<ParkDTO| ParkDTOWithTerritory, "id" | "availability" | 'parkingPrice' | 'organisation' | 'timeZoneId'>,
    territory?: TerritoryDTO | Territory,
    bay?: Pick<BayDTO | Bay, "id" | "group">,
    parkingPrice?: number | null;
    time?: string;
    date?: Moment;
}

const casualCharge = (props: Props) => {
    const { parkingType, park, bay, parkingPrice: parkingPriceProp, territory, time, date } = props;

    const { bayGroup } = useBayGroup(park?.organisation, bay?.group);
    const parkingPrice = parkingPriceProp || bayGroup?.parkingPrice || park?.parkingPrice
    const { pricePeriods } = useParkingPrice(parkingPrice);
    const { userRoles } = useUserRoles();
    const { data: isPublicBay } = useIsPublicBay(park?.id, bay?.id);
    const isPublicMember =  !(userRoles??[])
        .filter(ur => !!park?.organisation && ur.organisation === park?.organisation)
        .filter(ur => ur.role.name !== Role.PUBLIC)
        .some(ur => !ur.suspended);
    const isPublic = !!bay && !!park ?  isPublicBay : isPublicMember;

    const navigation = useNavigation();

    const timeZone = park?.timeZoneId ?? "Pacific/Auckland";
    const timeForPeriod = time??moment().tz(timeZone).format("HH:mm")

    const currentHourlyPricePeriod = !!pricePeriods ? getCurrentHourlyPricePeriod(pricePeriods) : null;
    const currentDailyPricePeriod = !!pricePeriods ? getCurrentDailyPricePeriod(pricePeriods, timeForPeriod, date) : null;
    const pricePerHour = currentHourlyPricePeriod?.price;
    const pricePerDay = currentDailyPricePeriod?.price;
    const { data: processingFeeAmount } = useCalculateProcessingFee(pricePerHour, territory?.id, "Casual", isPublic);

    const cap = currentDailyPricePeriod?.cap ?? 24;

    const parkFreePeriodInMinutes = pricePeriods?.find(p => p.freePeriodMinutes !== null)?.freePeriodMinutes ?? 0;
    const freePeriodToHours = parkFreePeriodInMinutes ? Math.floor(parkFreePeriodInMinutes / 60) : undefined;
    const freePeriodToMinutes = parkFreePeriodInMinutes ? parkFreePeriodInMinutes % 60 : undefined;

    if (!park) {
        return (
            <TableRow
                contentLeft={
                    <View>
                        <Spinner />
                    </View>
                }
                label={Strings.pricing}
            >
                {Strings.loading}
            </TableRow>
        );
    }

    if (!parkingPrice || !currentDailyPricePeriod || !currentHourlyPricePeriod) {
        return <View/>
    }

    if (parkingType == ParkingType.LONG_TERM) {
        return <View/>
    }

    const localPricePerHour = localizeCurrency(pricePerHour ?? 0, territory?.currencyCode, false);
    const pricePerHourString = Strings.pricePerHour(localPricePerHour);
    const localPricePerDay = localizeCurrency(pricePerDay ?? 0, territory?.currencyCode, false);
    const pricePerDayString = `${localPricePerDay} ${Strings.for_up_to_X_hours(cap)}`;
    const localFreeParkingPrice = localizeCurrency(0, territory?.currencyCode, false)

    const hoursText = freePeriodToHours === 1 ? Strings.hr : Strings.hrs;
    const minutesText = freePeriodToMinutes === 1 ? Strings.min : Strings.mins;

    const getHoursAndMinutesText = () => {
        if (freePeriodToHours === 0 && (freePeriodToMinutes ?? 0) > 0) {
            return `${freePeriodToMinutes} ${minutesText}`
        }
        if (freePeriodToMinutes === 0) {
            return `${freePeriodToHours} ${hoursText}`
        }
        return `${freePeriodToHours} ${hoursText} ${freePeriodToMinutes} ${minutesText}`
    }

    const getPriceDisplayText = () => {
        if(parkFreePeriodInMinutes > 0) {
            return `${Strings.pricing_with_free_period(
                getHoursAndMinutesText(),
                localPricePerHour,
                localPricePerDay,
                cap)}`
        } else if(pricePerDay === 0 && pricePerHour === 0) {
            return `${localFreeParkingPrice}`
        }
        return `${pricePerHourString}\n${pricePerDayString}`
    }

    const onCasualPricePress = () =>{
        navigation.push(Routes.PricingView, {
            parkId: park.id,
            parkingPrice: parkingPrice,
            bayId: bay?.id
        });
    }

    return (<View>
        <TableRow
            style={{ marginRight: 20 }}
            textProps={{ small: true, numberOfLines: 2 }}
            iconLeft={"dollarfilled"}
            label={Strings.pricing}
            onPress={onCasualPricePress}
            chevron
            labelBottom={processingFeeAmount ? Strings.plus_processing_fee(localizeCurrency(processingFeeAmount, territory?.currencyCode, false)) :""}
        >
            <Text
              small
              numberOfLines={2}
              style={{ width: "70%" }}
            >
                {getPriceDisplayText()}
            </Text>
        </TableRow>
    </View>
    );

}

export default casualCharge;
