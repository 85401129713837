import { Platform, StyleSheet } from "react-native";
import Colours from "react/parkable-components/styles/Colours";
import { theme } from "../../constants/theme";

export const snackbarStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: Colours.GREEN_300,
        marginHorizontal: theme.spacing(2),
        paddingHorizontal: theme.spacing(2),
        paddingVertical: theme.spacing(1),
        borderRadius: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    dismiss: {
        paddingLeft: theme.spacing(1),
    },
    droidSafeArea: {

        position: "absolute",
        left: 0,
        right: 0,
        paddingTop: Platform.OS === 'android' ? 35 : 0
    }
})
