import React from "react";
import { StyleSheet, View } from "react-native";
import Text from "react/parkable-components/text/Text";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "react/parkable-components/styles/Colours";
import Strings from "../../../constants/localization/localization";
import { ActivityType, ValidForParking, Voucher, VoucherType } from "../../../model/Voucher";
import localizeCurrency from "../../../constants/localization/localizeCurrency";
import moment from "moment";
import { voucherIsRedeemed } from "../../../constants/Util";
import { useTerritory } from "../../../api/territory/territory.api";
import { usePark } from "../../../api/park/park.api";

const VoucherCard = (props: Props) => {

    const { voucher, parkId, activity, voucherApplied, style } = props;
    const { park } = usePark(parkId);
    const { territory } = useTerritory(voucher?.territory);
    const currencyCode = (territory || {}).currencyCode;

    const voucherRedeemed = voucherIsRedeemed(voucher);

    const activityVoucher = !!activity ? activity :
        !!voucher.validForParking && voucher.validForParking.find(v => v === ValidForParking.ClassicCasual || v === ValidForParking.PfBCasual) ? ActivityType.Casual : ActivityType.LongTerm;

    let label = null;
    let remainingLabel = null;
    switch (voucher.type) {
        case VoucherType.FREE_SESSION:
            if (voucher.originalSessionCount! > 1) {
                remainingLabel = Strings.number_remaining(voucher.remainingSessionCount!)
            }

            label = Strings.free_casual_parking_session(voucher.originalSessionCount!);
            break;

        case VoucherType.FREE_CREDIT:
            if (voucher.remainingCreditAmount !== voucher.originalCreditAmount) {
                remainingLabel = Strings.remaining_casual_parking_credit(localizeCurrency(voucher.remainingCreditAmount!, currencyCode),
                    localizeCurrency(voucher.originalCreditAmount! - voucher.remainingCreditAmount!, currencyCode))
            }
            label = Strings.free_casual_parking_credit(localizeCurrency(voucher.originalCreditAmount!, currencyCode));
            break;

        case VoucherType.PERCENT_DISCOUNT:
            if (activityVoucher == ActivityType.Casual) {
                label = Strings.percent_off_parking_session(voucher.percentDiscount!);
            }
            else {
                label = Strings.percent_off_parking_subscription(voucher.percentDiscount!);
            }
            break;
        default: break;
    }

    if (!label) {
        console.log("invalid voucher", voucher);
        return null;
    }

    let validUntil = null;
    let validFrom = null;

    if (!!voucher.validTo) {
        validUntil = moment(voucher.validTo!).format("Do MMMM YYYY");
    }

    if (!!voucher.validFrom && moment(voucher.validFrom).isAfter(moment())) {
        validFrom = moment(voucher.validFrom!).format("Do MMMM YYYY");
    }


    let validDateLabel = null;
    let validDateString = null;

    if (!validFrom && !!validUntil) {
        validDateLabel = Strings.voucher_active_until;
        validDateString = validUntil;
    } else if (!!validFrom && !!validUntil) {
        validDateLabel = Strings.voucher_valid_between;
        validDateString = `${validFrom} - ${validUntil}`;
    } else if (!!validFrom && !validUntil) {
        validDateLabel = Strings.voucher_valid_from;
        validDateString = validFrom;
    }

    return <View style={[styles.container, style]}>
        <View style={styles.header}>
            <Text h2 green={voucherApplied && !voucherRedeemed} style={styles.label}>{voucherApplied ? Strings.voucher_applied_label : `${Strings.voucher} - ${territory?.name}`}</Text>
            {voucherRedeemed && <Text style={styles.label} green bold>{Strings.redeemed}</Text>}
        </View>
        <TableRow iconLeftProps={{ iconStyle: { color: Colours.NEUTRALS_BLACK } }} iconLeft={"voucher"} label={Strings.voucher_description}>
            {label}
        </TableRow>
        {!!remainingLabel && <TableRow iconLeftProps={{ iconStyle: { color: Colours.NEUTRALS_BLACK } }} iconLeft={"voucher"} label={Strings.total_remaining}>
            {remainingLabel}
        </TableRow>}

        {(!!park || !!voucher.park) && <TableRow label={Strings.car_park_location} iconLeft={"pinlocation2filled"} iconLeftProps={{ color: Colours.GREEN_300 }}>
            {!!park ? park.address : Strings.loading}
        </TableRow>}
        {(!!validFrom || !!validUntil) && <TableRow iconLeftProps={{ iconStyle: { color: Colours.NEUTRALS_BLACK } }} iconLeft={"calendar"}
                                                    label={validDateLabel!}>
            {validDateString}
        </TableRow>}
    </View>
};

type Props = {
    style?: any,
    voucherApplied?: boolean,
    voucher: Voucher;
    parkId?: number,
    activity?: ActivityType;
}

export default VoucherCard as React.FunctionComponent<Props>;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colours.NEUTRALS_WHITE,
        borderColor: Colours.GREY_BORDER,
        borderWidth: 1,
        paddingTop: 9,
        paddingLeft: 9,
        paddingRight: 9,
        paddingBottom: 36

    },
    header: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: 9,
        paddingLeft: 9,
        paddingRight: 9,
        paddingBottom: 9,
        marginBottom: 9,
        borderColor: Colours.GREY_BORDER,
        borderBottomWidth: 1,
    },
    label: {
        marginBottom: 0,
    }
});
