import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { IRootReducer } from "../../redux/reducers/main";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import { StyleSheet, View } from "react-native";
import Strings from "../../constants/localization/localization";
import { Term } from "../../model/Term";
import localizeCurrency from "../../constants/localization/localizeCurrency";
import SupportFooterView from "../common/SupportFooterView";
import { Bay } from "../../model/Bay";
import { ActivityType } from "../../model/Voucher";
import SelectedVoucherCard from "../common/voucher/SelectedVoucherCard";
import { useBayGroup } from "../../api/bayGroup/bayGroup.api";
import { getLongTermPrices } from "../../constants/price.util";
import { useTerritory } from "../../api/territory/territory.api";
import { usePark } from "../../api/park";
import { createRoute, NavigationProps, useNavigation } from "react/navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import ParkableBaseView from "../common/ParkableBaseView";
import { SubscriptionDetailsViewPrams } from "./SubscriptionDetailsView";
import { useBay } from "../../api/bay/bay.api";
import { useUserRoles } from "../../api/user/user.api";
import { Role } from "../../model/User";
import { useCalculateProcessingFee } from "../../api/processingFees/processingFees.api";

class SelectSubscriptionPlanViewProps {
    parkId: number;
    bayId: number | undefined;
    feature: string;
    baysAvailable: Bay[];
}

const SelectSubscriptionPlanView = (props: ReduxProps & NavigationProps<Routes.SelectSubscriptionPlanView>) => {
    const navigation = useNavigation();
    const { parkId, bayId, feature, baysAvailable, hasCard, hasVehicles, hasDefaultVehicle, voucher } = props;

    const { park } = usePark(parkId);
    const { territory } = useTerritory(park?.territory);
    const { bay } = useBay(parkId, bayId);
    const { bayGroup } = useBayGroup(park?.organisation, bay?.group);
    const { userRoles } = useUserRoles();
    const isPublicMember =  !(userRoles??[])
        .filter(ur => !!park?.organisation && ur.organisation === park?.organisation)
        .filter(ur => ur.role.name !== Role.PUBLIC)
        .some(ur => !ur.suspended);

    const { pricePerWeek, pricePerMonth } = getLongTermPrices(park, bayGroup);

    const { data: weeklyProcessingFeeAmount } = useCalculateProcessingFee(pricePerWeek, territory?.id, "LongTerm", isPublicMember);
    const { data: monthlyProcessingFeeAmount } = useCalculateProcessingFee(pricePerMonth, territory?.id, "LongTerm", isPublicMember);

    const onSelectPlan = useCallback((term: Term) => {
        const params: SubscriptionDetailsViewPrams = {
            parkId,
            term,
            bayId,
            feature,
            baysAvailable
        }

        const destination = { route: Routes.SubscriptionDetailsView, params };

        if (!hasCard) {
            navigation.push(Routes.AddNewCard, { destination });
        } else if (!hasVehicles) {
            navigation.push(Routes.AddNewVehicleView, { destination });
        } else if (!hasDefaultVehicle) {
            navigation.push(Routes.VehiclesView, {});
        } else {
            navigation.push(Routes.SubscriptionDetailsView, { ...params });
        }
    }, [park, bay, feature, baysAvailable, hasCard, hasVehicles, hasDefaultVehicle]);

    return (
        <ParkableBaseView>
            <View style={styles.container}>
                <View style={styles.innerContainer}>
                    <View style={styles.selectTermContainer}>
                        <Text h1>{Strings.long_term_parking}</Text>
                        <Text strapline bold style={styles.labelSelectPlan}>{Strings.select_from_available_options}</Text>
                        {pricePerWeek !== undefined && pricePerWeek !== null &&
                            <TableRow
                                style={styles.selectTermRow}
                                iconLeft={"key"}
                                label={Strings.weekly_subscription}
                                labelBottom={weeklyProcessingFeeAmount ?
                                    Strings.plus_processing_fee(localizeCurrency(weeklyProcessingFeeAmount, territory?.currencyCode, false))
                                    : ""}
                                textProps={{ small: true, numberOfLines: 2}}
                                chevron
                                onPress={() => onSelectPlan(Term.Week)}
                            >
                                <Text small numberOfLines={2}>
                                    {`${localizeCurrency(pricePerWeek, territory?.currencyCode, false)}${Strings.per_week}`}
                                </Text>
                            </TableRow>}

                        {pricePerMonth !== undefined && pricePerMonth !== null &&
                            <TableRow
                                style={styles.selectTermRow}
                                iconLeft={"key"}
                                label={Strings.monthly_subscription}
                                labelBottom={monthlyProcessingFeeAmount ?
                                    Strings.plus_processing_fee(localizeCurrency(monthlyProcessingFeeAmount, territory?.currencyCode, false))
                                    : ""}
                                textProps={{ small: true, numberOfLines: 2}}
                                chevron
                                onPress={() => onSelectPlan(Term.Month)}
                            >
                                <Text small numberOfLines={2}>
                                    {`${localizeCurrency(pricePerMonth, territory?.currencyCode, false)}${Strings.per_month}`}
                                </Text>
                            </TableRow>}


                        {!!voucher && <View>
                            <Text strapline bold style={styles.labelVoucher}>{Strings.voucher}</Text>

                            {!!park && <SelectedVoucherCard territory={territory} voucher={voucher} park={park} activity={ActivityType.LongTerm} />}
                        </View>}

                    </View>
                    <View style={styles.footerContainer}>
                        <SupportFooterView />
                    </View>
                </View>
            </View>
        </ParkableBaseView>
    );
};

const getReduxProps = (state: IRootReducer, props: NavigationProps<Routes.SelectSubscriptionPlanView>) => {
    const { parkId, bayId, feature, baysAvailable } = props.route.params;

    const hasCard = !!state.user.currentCardId;
    const hasVehicles = (state.user.vehicles?.length ?? 0) > 0;
    const userVehicleIds = state.user.vehicles?.map((v) => v.id) ?? [];
    const hasDefaultVehicle = !!state.user.currentVehicleId && userVehicleIds?.includes(state.user.currentVehicleId);
    const voucher = state.user?.longTermVoucher?.[parkId] ?? null;
    return {
        parkId,
        voucher,
        bayId,
        feature,
        baysAvailable,
        hasCard,
        hasVehicles,
        hasDefaultVehicle,
    }
};

type ReduxProps = ReturnType<typeof getReduxProps>;

export default connect(getReduxProps)(SelectSubscriptionPlanView) as any as React.FC<SelectSubscriptionPlanViewProps>;

export const SelectSubscriptionPlanRoute = createRoute({
    path: Routes.SelectSubscriptionPlanView,
    params: { type: SelectSubscriptionPlanViewProps },
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative'
    },
    innerContainer: {
        flex: 1
    },
    backgroundImage: {
        width: '100%',
        height: 275
    },
    selectTermContainer: {
        paddingTop: 27,
        paddingBottom: 27,
    },
    selectTermRow: {
        margin: 0,
        padding: 0
    },
    footerContainer: {
        paddingBottom: 27,
        paddingTop: 16,
    },
    labelSelectPlan: {
        paddingTop: 9,
        paddingBottom: 8,
        textTransform: "none",
    },
    labelVoucher: {
        paddingTop: 18,
        paddingBottom: 8,
        textTransform: "uppercase",
    },
    scrollDownTooltipContainer: {
        width: '100%',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        zIndex: 1000,
    },
    selectButton: {
        marginLeft: -30,
        padding: 0,
    },
});
