import {useMySWR} from "../http/use-swr";
import {Nully} from "../../constants/nully";
import { Activity } from "../../model/Types";
import EntityResponse from "../../dto/EntityResponse";
import { generateQueryString } from "../http/rest";

export const useCalculateProcessingFee = (amount: Nully<number>, territoryId: Nully<number>, activity: Nully<Activity>, isPublic: Nully<boolean>) => {
    const params = generateQueryString({ amount, activity, isPublic })
    return useMySWR<EntityResponse<number>>(
        territoryId && amount && activity && isPublic ? `processing-fee/calculate/${territoryId}${params}` : null);
};
