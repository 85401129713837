import TableRow from "react/parkable-components/tableRow/TableRow";
import { IconName } from "react/parkable-components/icon/Icons";
import React, { useEffect, useState } from "react";
import Strings from "../../constants/localization/localization";
import { ParkingType } from "../../model/Types";
import getParkAvailability from "../../constants/getParkAvailability";
import { ParkDTO } from "../../model/ParkDTO";

type Props = {
    availableBayCount: number|undefined,
    parkingType: ParkingType,
    park: ParkDTO | undefined
    hideCasualBays: boolean | undefined
}

export function RemainingBaysTableRow(props: Props) {
    const [currentBaysLeftText, setCurrentBaysLeftText] = useState("");
    const [icon, setIcon] = useState<IconName>("caroutlined");

    useEffect(() => {
        if (props.availableBayCount === undefined || !props.park) {
            setIcon("stopwatch");
            setCurrentBaysLeftText(Strings.loading_bays);
        } else {
            const isOpenNow = !!props.park && getParkAvailability(props.park).isOpenNow;

            if (props.parkingType === ParkingType.LONG_TERM) {
                setIcon("keyoutlined");
                setCurrentBaysLeftText(Strings.longTermBaysLeftText(props.availableBayCount));
            } else if (props.parkingType === ParkingType.ELECTRIC) {
                setIcon("caroutlined");
                setCurrentBaysLeftText(Strings.evBaysLeftText(props.availableBayCount));
            } else if (props.parkingType === ParkingType.CASUAL && props.hideCasualBays) {
                setIcon("caroutlined");
                setCurrentBaysLeftText(Strings.no_bays_available)

            }else {
                setIcon("caroutlined");
                setCurrentBaysLeftText(Strings.baysLeftText(props.availableBayCount, !isOpenNow));
            }
        }
    }, [props.availableBayCount, props.parkingType, props.park, props.hideCasualBays]);

    return (
      <TableRow iconLeft={icon} textProps={{ red: !props.hideCasualBays && (props.availableBayCount || 2) <= 1, small: true }}>
          {currentBaysLeftText}
      </TableRow>
    );
}
