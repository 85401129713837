import React, { useCallback, useMemo, useState } from "react";
import { StyleSheet, TouchableOpacity, View, ViewProps, LayoutChangeEvent } from "react-native";
import Spinner from "react/parkable-components/spinner/Spinner";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import moment, { Moment } from "moment";
import Strings from "../../../constants/localization/localization";
import _ from "lodash";
import { useUnshareBayContext } from "./context";
import { EmployeeSubscriptionDTO } from "../../../dto/EmployeeSubscriptionDTO";

type AllProps = {
    date: Moment,
    subscription: EmployeeSubscriptionDTO,
    style: {},
    updateEmployeeSubscription: (subscriptionId: number, availDays: string[], showConfirmation: boolean, onDone: () => void) => void,
    onLayout?: (e: LayoutChangeEvent) => void,
    setUpdating: (disable: boolean) => void,
    disabled: boolean,
    confirmedBooking?: boolean;
    sharingToday?: boolean;
    activeSession?: boolean
}

export default function calendarDayComponent(props: AllProps & ViewProps) {

    const { setUpdating, confirmedBooking, sharingToday, activeSession } = props;

    const context = useUnshareBayContext();

    const disabled = props.disabled;

    const [loading, setLoading] = useState(false);

    const dayKey = props.date.format("YYYY-MM-DD");
    const [textHeight, setTextHeight] = useState<number>(0)

    const sharing = useMemo(() => {
        const availableTo = [...new Set(((props.subscription?.availabilityRules || {}).availableTo || [])
            .map(day => moment(day.date).format("YYYY-MM-DD")))];

        return availableTo.indexOf(dayKey) > -1;

    }, [props.subscription])

    const updateSubscriptionAvailability = useCallback((subscription: EmployeeSubscriptionDTO, dayKey: string, share: boolean) => {

        console.log("availTo - inside: ", subscription?.availabilityRules?.availableTo);
        const availTo = subscription?.availabilityRules?.availableTo;
        const availDays = _.chain(availTo || [])
            .map((availDay) => moment(availDay.date).format("YYYY-MM-DD"))
            .uniq()
            .filter(dayString => dayString !== dayKey).value();

        if (share) {
            availDays.push(dayKey);
        }

        props.updateEmployeeSubscription(subscription.id, availDays, false, () => {
            setLoading(false);
            setUpdating(false);
        });
    }, [props.updateEmployeeSubscription]);

    const updateAvailability = useCallback(() => {
        setLoading(true);
        setUpdating(true);
        updateSubscriptionAvailability(props.subscription, dayKey, !sharing);

    }, [dayKey, sharing, props.subscription]);

    const onTextLayout = useCallback((event: LayoutChangeEvent) => {
        const { height } = event.nativeEvent.layout;
        setTextHeight(height)
    }, [])

    const styleBlue = confirmedBooking || activeSession
    const colors = {
        mainBackground: styleBlue ? Colours.BLUE_100 : sharing ? Colours.GREEN_100 : Colours.NEUTRALS_WHITE,
        mainBorder: styleBlue ? Colours.BLUE_300 : sharing ? Colours.GREEN_300 : Colours.GREY_10,
        dateText: sharing || styleBlue ? Colours.NEUTRALS_WHITE : Colours.NEUTRALS_BLACK,
        textBackground: styleBlue ? Colours.BLUE_300 : sharing ? Colours.GREEN_300 : Colours.GREY_BORDER,
        dateNumText: styleBlue ? Colours.BLUE_300 : sharing ? Colours.GREEN_300 : Colours.NEUTRALS_BLACK,
    }

    return (
        <TouchableOpacity
            onLayout={props.onLayout} disabled={loading || disabled}
            style={[styles.mainView, props.style, { backgroundColor: colors.mainBackground, borderColor: colors.mainBorder }]}
            onPress={() => {
                const sessionStartDate = moment(context?.activeSession?.startedAt).format("YYYY-MM-DD")
                if (context?.activeSession && sessionStartDate === dayKey) {
                    return context?.setBayInUseDialog(true)
                }
                const requestOnDay = context?.parkingRequests.find((pr) => pr.date.includes(context?.unshareDate))
                if (props.confirmedBooking && requestOnDay) {
                    context?.setShowUnshareDialog(true)
                    context?.setUnshareDate(dayKey)
                    context?.setSelectedDateRequest(requestOnDay)
                } else {
                    updateAvailability()
                }
            }}
        >
            <Text center strapline bold
                style={[
                    styles.dateBox, {
                        color: colors.dateText,
                        backgroundColor: colors.textBackground,
                    }
                ]}>
                {props.date.format("ddd").toUpperCase()}
            </Text>
            <View style={styles.body}>
                {loading
                    ? (
                        <View style={{ flex: 1, height: textHeight }}>
                            <Spinner small color={Colours.GREY_20} />
                        </View>
                    )
                    : (
                        <Text onLayout={onTextLayout} bold
                            style={{
                                fontSize: 24,
                                color: colors.dateNumText,
                                marginTop: sharing ? 5 : 0,
                            }}>
                            {props.date.format("DD")}
                        </Text>
                    )}
                {!!confirmedBooking && (
                    <Text style={{ fontSize: 14, color: Colours.BLUE_300 }}>{Strings.bay_booked}</Text>
                )}
                {sharing && !confirmedBooking && !activeSession && (
                    <Text style={{ fontSize: 14, color: Colours.GREEN_300 }}>{Strings.sharing}</Text>
                )}
                {!!activeSession && (
                    <Text style={{ fontSize: 14, color: Colours.BLUE_300 }}>{Strings.bay_occupied}</Text>
                )}
            </View>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    mainView: {
        borderWidth: 1,
        borderRadius: 3,
        flex: 1,
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: "center",
    },
    body: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        minHeight: 56,
    },
    dateBox: {
        marginBottom: 0,
        borderTopRightRadius: 3,
        borderTopLeftRadius: 3,
        width: "100%",
    },
});
