import { Nully } from "../../constants/nully";
import { generateQueryString, get, post, put } from "../http/rest";
import { useMySWR } from "../http/use-swr";
import { ParkingRequestResponse } from "../parking";
import { CreateParkingRequestRequest } from "./dto/create-parking-request.request";
import { ParkingRequestsResponse } from "./dto/parking-requests.response";
import { UpdateParkingRequestRequest } from "./dto/update-parking-request.request";
import { Moment } from "moment";
import { BayDTO } from "react/model/BayDTO";

export const createFutureBookingParkingRequest = (data: CreateParkingRequestRequest) => {
    return post<ParkingRequestResponse>(`v1/parkingrequests/future`, data);
};

/**
 * either parkId
 * or organisationId + campusId is required
 */
export const createParkTomorrowParkingRequest = (
                                       parkId: Nully<number>,
                                       organisationId: Nully<number>, campusId: Nully<number>,
                                       vehicleId: Nully<number>) => {
    return post<ParkingRequestResponse>(`v1/parkingrequests`, {
        parkId,
        campusId,
        organisationId,
        vehicleId
    });
}

export const updateParkingRequest = (
    requestId: number,
    updates: UpdateParkingRequestRequest
) => {
    return put<ParkingRequestResponse>(
        `v1/parkingrequests/${requestId}`,
        updates
    );
};

export const useParkingRequest = (parkingRequestId: Nully<number>) => {
    //todo add to api
    const r = useMySWR<ParkingRequestResponse>(parkingRequestId ? `v1/parkingrequests/${parkingRequestId}` : null)

    return {
        ...r,
        parkingRequest: r.data?.parkingRequest
    }
}

export const useNextConfirmedParkingRequestForCurrentUser = (date?: Nully<Moment>) => {
    const queryParam = date ? `?date=${date.format("YYYY-MM-DD")}` : "";
    return useMySWR<ParkingRequestResponse>(`v1/parkingrequests${queryParam}`);
}

export const getNextConfirmedParkingRequestForCurrentUser = () => {
    return get<ParkingRequestResponse>(`v1/parkingrequests`);
}

export const useParkingRequestsForUser = (userId: Nully<number>) => {
    const { data, ...rest } = useMySWR<ParkingRequestsResponse>(
        userId ? `v1/parkingrequests/user/${userId}` : null
    );
    return {
        ...rest,
        loading: !userId || data === undefined,
        parkingRequests: data?.requests,
    };
};

export const useParkingRequestsForUserInOrganisation = (
    organisationId: Nully<number>,
    userId: Nully<number>
) => {
    const { data, ...rest } = useMySWR<ParkingRequestsResponse>(
        organisationId && userId
            ? `v1/parkingrequests/organisation/${organisationId}/user/${userId}`
            : null
    );
    return {
        ...rest,
        parkingRequests: data?.requests,
    };
};

export const useParkingRequestsInOrganisation = (
    organisationId: Nully<number>,
    parkId: Nully<number>,
    campusId: Nully<number>,
    date: Nully<Moment>,
) => {
    const { data, ...rest } = useMySWR<ParkingRequestsResponse>(
        organisationId && date && (parkId || campusId) ?
            `v1/parkingrequests/organisation/${organisationId}?${parkId ? `parkId=${parkId}` : `campusId=${campusId}`}&date=${date.format("YYYY-MM-DD")}` : null
    );
    return {
        ...rest,
        parkingRequests: data?.requests,
    };
};

export const useParkingRequestsInBay = (parkId: Nully<number>, bayId: Nully<number>) => {
    const { data, ...rest } = useMySWR<ParkingRequestsResponse>(
        parkId && bayId ? `v1/parkingrequests/${parkId}/${bayId}` : null, get);

  return {
        ...rest,
        parkingRequests: data?.requests,
    };
}

export const useParkingRequestsInSubscriptionBay = (subscriptionId: Nully<number>) => {
    const { data, ...rest } = useMySWR<ParkingRequestsResponse>(
        subscriptionId ? `v1/parkingrequests/${subscriptionId}` : null, get);

  return {
        ...rest,
        parkingRequests: data?.requests,
    };
}


export const changeParkingRequestBay = (requestId: number, bayId: number, parkId?: number) => {
    const query = generateQueryString({ bayId, parkId })
    console.log(query)
    return put<ParkingRequestResponse>(`v1/parkingrequests/${requestId}/changebay${query}`)
}

export const useBaysAvailableForParkingRequest = (requestId: Nully<number>) => {
    const {data, ...rest} = useMySWR<{ bays: BayDTO[] }>(
        requestId ? `v1/parkingrequests/${requestId}/baysAvailable` : null, get)

    return {
        ...rest,
        availableBays: data?.bays,
    };
}
