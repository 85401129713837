import React, { useState } from "react";
import { StyleSheet, View, Image, Platform } from "react-native";
import { Colours, TableRow, Text } from "react/parkable-components";
import { Button } from "react/parkable-components";
import Strings from "react/constants/localization/localization";
import { Routes } from "react/navigation/root/root.paths";
import { OcpiToken } from "react/model/ocpi/OcpiToken";
import { createRoute } from "react/navigation/constants";
import ParkableBaseView from "react/components/common/ParkableBaseView";
import { AddRfidCardDialog } from "./AddRfidCardDialog";
import { EditRfidCardDialog } from "./EditRfidCardModal";
import { useTokensForUsers } from "react/api/ev";

export const RfidCardsView = () => {
    const [addCardModal, setAddCardModal] = useState(false);
    const [currentRfid, setCurrentRfid] = useState<string>("");
    const [editCardModal, setEditCardModal] = useState(false);

    const { tokens, isLoading, mutate } = useTokensForUsers();
    const wirelessImage = require("react/resources/wireless.png");

    const AddNewCardButton = (props: { onPress(...args: any): void }) => (
        <View style={styles.addNewButton}>
            <Button border center textProps={{ h5: true }} style={styles.button} onPress={props.onPress}>
                {Strings.add_new_rfid}
            </Button>
        </View>
    );

    const Card = (props: { card: OcpiToken }) => (
        <View style={styles.cardRow}>
            <TableRow
                chevron
                hideBorder
                onPress={() => {
                    setCurrentRfid(props.card.uid);
                    setEditCardModal(true);
                }}
            >
                <View style={styles.rowContainer}>
                    <Image style={styles.image} source={wirelessImage} />
                    <Text style={[{ fontSize: 16 }, Platform.OS === "web" ? { paddingTop: 3 } : {}]}>
                        {props.card.uid}
                    </Text>
                </View>
            </TableRow>
        </View>
    );

    return (
        <ParkableBaseView scrollable={false} loading={isLoading}>
            <View style={styles.content}>
                <View style={{ marginTop: 20, paddingTop: 20 }}>
                    <Text h2 bold>
                        {Strings.rfid_cards_for_evs}
                    </Text>
                </View>
                <View>
                    <Text p grey>
                        {Strings.add_new_and_manage_existing_rfids}
                    </Text>
                </View>
                <View style={styles.cardsContainer}>
                    <View style={{ flex: 1 }}>
                        {tokens ? tokens.map((card, i) => <Card card={card} key={i} />) : ""}
                    </View>
                </View>
                <AddNewCardButton onPress={() => setAddCardModal(true)} />
            </View>
            <AddRfidCardDialog
                open={addCardModal}
                handleOpenModal={(open) => setAddCardModal(open)}
                mutateTokens={mutate}
            />
            <EditRfidCardDialog
                open={editCardModal}
                handleOpenModal={(open) => setEditCardModal(open)}
                rfid={currentRfid}
                mutateTokens={mutate}
            />
        </ParkableBaseView>
    );
};

export const RfidCardsRoute = createRoute({
    path: Routes.RfidCardsView,
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    content: {
        flex: 1,
    },
    cardsContainer: {
        flex: 1,
        marginTop: 16,
    },
    rfidCardName: {
        fontSize: 12,
    },
    rowContainer: {
        flexDirection: "row",
    },
    image: {
        alignSelf: "center",
        height: 20,
        width: 20,
        marginRight: 10,
    },
    cardRow: {
        borderBottomWidth: 1,
        borderBottomColor: Colours.GREY_10,
        paddingVertical: 4,
        marginVertical: 4,
    },
    cardIdText: {
        fontSize: 16,
    },
    addNewButton: {
        flexDirection: "row",
        paddingTop: 27,
    },
    button: {
        width: "100%",
        height: 54,
        borderColor: Colours.GREY_BORDER,
        borderRadius: 5,
        borderWidth: 1,
        paddingVertical: 10,
        marginVertical: 5,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
    },
});
