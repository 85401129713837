import React from 'react';
import Strings from "../../constants/localization/localization";
import { Colours, TableRow } from 'react/parkable-components'
import { Bay } from '../../model/Bay';
import { useVacateBayDeadline } from "../../api/bay/bay.api";
import moment from "moment";
import { ParkAvailability } from "../../constants/getParkAvailability";
import { ParkingType } from "../../model/Types";

export default function BayOption(props: Props) {
    const { bay, selected, onBaySelectedPress, availability, parkingType } = props;

    const isOpenAfterMidnight = availability?.isOpen24hours || availability?.otherDayClosing;
    const showVacateBayDeadLine = parkingType != ParkingType.LONG_TERM && isOpenAfterMidnight;
    const { vacateBayDeadline } = useVacateBayDeadline(showVacateBayDeadLine ? bay.park : null, showVacateBayDeadLine ? bay.id : null);
    const twenty4HoursFromNow = moment().add({ hours: 24 });
    const vacateBayDeadlineIsLessThan24HoursFromNow = isOpenAfterMidnight && !!vacateBayDeadline && moment(vacateBayDeadline)
        .subtract({ seconds: 1 })
        .isBefore(twenty4HoursFromNow);
    const vacateBayDeadLine = showVacateBayDeadLine && vacateBayDeadlineIsLessThan24HoursFromNow ? Strings.available_till_midnight : '';

    return (
        <TableRow
            key={bay.id}
            textProps={{ style: { fontWeight: "500" } }}
            buttonText={selected ? Strings.selected : Strings.select}
            buttonProps={{
                iconRight: (selected) ? undefined : "cheveronright",
                style: {
                    width: 120,
                    backgroundColor: selected
                        ? Colours.GREY_20
                        : Colours.GREEN_300
                },
            }}
            labelBottom={vacateBayDeadLine}
            onButtonPress={selected ? undefined : () => onBaySelectedPress(bay)}
        >
            {bay.signage}
        </TableRow>
    );
};

type Props = {
    bay: Bay,
    selected: boolean,
    onBaySelectedPress: (bay: Bay) => void,
    availability?: ParkAvailability,
    parkingType: ParkingType
}
