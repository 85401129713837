import React from "react";
import {Platform, StyleSheet} from "react-native";
import { useNavigation } from "react/navigation/constants";
import { Routes } from "react/navigation/root/root.paths";
import Colours from "react/parkable-components/styles/Colours";
import {FAB} from "../../parkable-components";
import Icons from "../../parkable-components/icon/Icons";
const styles = StyleSheet.create({
    base: {
        backgroundColor: Colours.NEUTRALS_WHITE,
        position: "absolute",
        borderRadius: 23,
        bottom: 45,
        left: (Platform.OS === "web" ? 65 : 18),
        height: 45,
        justifyContent: "center",
        alignItems: "center",
        elevation: 0,
        flexDirection: undefined, //workaround for issue in react-native component library
    },
});

export default function () {

    const navigation = useNavigation()
    const onPress = () => {
        navigation.push(Routes.QrScannerView, {});
    }

    return (
        <FAB
            style={styles.base}
            icon={Icons.qrscanner}
            onPress={onPress}
            iconProps={{
                color: Colours.BLUE_300,
                iconStyle: {
                    fontSize: 30,
                }
            }}
        />
    );
}
