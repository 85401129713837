import Button from "react/parkable-components/button/Button";
import Spinner from "react/parkable-components/spinner/Spinner";
import Colours from "react/parkable-components/styles/Colours";
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {LayoutChangeEvent, StyleSheet, TextInput, View} from "react-native";
import {connect} from "react-redux";
import Strings from "../../constants/localization/localization";
import {getPredictionData, setSessionToken} from "../../redux/actions/google"
import {searchParkAddress} from '../../redux/actions/parks'
import {getFavouriteParks, getUserOptions} from "../../redux/actions/userOptions";
import {IRootReducer} from "../../redux/reducers/main";
import FavouriteParks from "./FavouriteParks";
import RecentSearches from './RecentSearches'
import SearchResults from "./SearchResults";
import {IFilteredPrediction, IPrediction} from "../../dto/IPrediction";
import {ParkingType} from "../../model/Types";
import ParkingTypeButtonsGroup from "../map/ParkingTypeButtonsGroup";
import {uuid} from "../../constants/Util";
import _ from "lodash";
import {Feature} from "../../model/Bay";
import {createRoute, useNavigation} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import Constants from "../../constants/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import {useAppDispatch} from "../../redux/redux";
import {ParkInfo2} from "../../model/ParkInfo2";
import {ParkInfo2sResponse} from "../../api/park";
import {setParkingType} from "react/redux/actions/map";

const FindParkView = (props: ReduxProps) => {

    const {
        loadingPredictions,
        sessiontoken,
        user,
        lastMapLocation,
        favouriteParks,
        userOptions,
        defaultLocation,
        currentVehicle
    } = props;

    const [searchText, setSearchText] = useState('');
    const [searchFailed, setSearchFailed] = useState<boolean>(false);
    const [inputLayout, setInputLayout] = useState<{height: number}>({height: 18});
    const [inputFocus, setInputFocus] = useState<boolean>(false);
    const [changingParkingType, setChangingParkingType] = useState<boolean>(false);
    const [parkingType, setParkingType] = useState(props.parkingType);
    const [localNavStack, setLocalNavStack] = useState<number>(0);
    const [searchPredictions, setSearchPredictions] = useState<IPrediction[]>([]);
    const [searchResults, setSearchResults] = useState<{[key:string]: ParkInfo2[]}>({});

    const navigation = useNavigation();
    const dispatch = useAppDispatch();
    const { pop, push} = navigation;

    const filteredSearchPredictions = useMemo(() => (searchPredictions)
        .filter(p => !!p.place_id) as IFilteredPrediction[], [searchPredictions]);

    let inputRef: TextInput | null;

    useEffect(() => {
        inputRef?.focus();
        dispatch(setSessionToken(uuid()));

        return () => {
            dispatch(setSessionToken(""));
        }
    }, []);

    useEffect(() => {
        if (!userOptions) {
            dispatch(getUserOptions());
        }
    }, [userOptions]);

    useEffect(() => {
        if (!favouriteParks) {
            dispatch(getFavouriteParks());
        }
    }, [favouriteParks]);

    useEffect(() => {
        setSearchFailed(false);

        if (searchText === null || searchText.length < 5) {
            return;
        }

        dispatch(searchParkAddress(searchText)).then( (result: ParkInfo2sResponse) => {
            setSearchResults(prevState => ({...prevState, [searchText]: result.parkInfo}));
        });

        try {
            let latitude = defaultLocation?.latitude ?? 0;
            let longitude = defaultLocation?.longitude ?? 0;

            if (!!lastMapLocation) {
                latitude = lastMapLocation.latitude;
                longitude = lastMapLocation.longitude;
            }
            let isCancelled = false;
            getPredictionData(searchText, undefined, latitude, longitude, sessiontoken).then(results => {
                if (!isCancelled) {
                    setSearchPredictions(results);
                }
            });
            return () => {
                isCancelled = true;
            };
        } catch (error) {
            setSearchFailed(true);
            console.error("Not able to find address", searchText, error);
        }
    }, [searchText, defaultLocation, lastMapLocation, sessiontoken]);

    const renderRecentSearchesAndFavouriteParks = () => {
        return <View>
            <RecentSearches recentSearches={userOptions?.recentSearch ?? []} push={push} parkingType={parkingType} />
            <FavouriteParks favouriteParks={favouriteParks} push={push} parkingType={parkingType} />
        </View>
    };

    const renderSearchResults = () => {
        if (searchFailed) {
            return null;
        }
        if (loadingPredictions) {
            return <Spinner/>
        }

        return (
            <SearchResults searchPredictions={filteredSearchPredictions}
                           searchResults={searchResults}
                           user={user}
                           push={push}
                           parkingType={parkingType}
                           searchText={searchText}
                           sessiontoken={sessiontoken}
            />);
    };

    const onLayout = useCallback((event:LayoutChangeEvent) => {
        const {height} = event.nativeEvent.layout;
        setInputLayout({height})
    }, [setInputLayout]);

    const onPressChangeParkingType = () => {
        setChangingParkingType(true);
        setLocalNavStack(1);
    }

    const finishChangeParkingType = (parkingType: ParkingType|undefined) => {
        setChangingParkingType(false);
        if(!!parkingType){
            setParkingType(parkingType);
            props.setParkingType(parkingType);
        }
        setLocalNavStack(0);
    }

    const backButtonOverride = useCallback(() => {
        if(localNavStack === 0) {
            pop();
        } else {
            finishChangeParkingType(undefined);
        }
    }, [localNavStack, pop])

    const isMotorBikeDefault = currentVehicle?.feature === Feature.MOTORBIKE;
    return (
        <ParkableBaseView backButtonOverride={backButtonOverride}>
            {changingParkingType ? (
                <ParkingTypeButtonsGroup
                    title={Strings.search_filter}
                    fullHeight
                    isMotorBikeDefault={isMotorBikeDefault}
                    currentParkingType={parkingType}
                    label={Strings.select_filter.toUpperCase()}
                    onChangeParkingType={finishChangeParkingType}
                    displayCasual={true}
                    displaySubscription={true}
                    displayEV={false}
                />) : (
                <>
                    <View style={[styles.searchTextBox, {borderColor: inputFocus ? Colours.BLUE_300 : Colours.GREY_20}]}>
                        <Button small style={{width: 150}} onLayout={onLayout} iconRight={'cheverondown'}
                                onPress={onPressChangeParkingType}>{parkingType === ParkingType.ELECTRIC ? Strings.ev_charger
                            : parkingType === ParkingType.CASUAL ? Strings.casual : Strings.long_term}</Button>
                        <TextInput style={[styles.textInput, {...inputLayout}]}
                                   onChangeText={setSearchText}
                                   placeholder={Strings.start_typing}
                                   ref={ref => inputRef = ref}
                                   onFocus={() => setInputFocus(true)} onBlur={() => setInputFocus(false)}
                                   autoCorrect={false}
                                   placeholderTextColor={Colours.NEUTRALS_BLACK}/>
                    </View>
                    <View style={styles.searchResultBox}>
                        {searchText.length > 2 ? renderSearchResults() : renderRecentSearchesAndFavouriteParks()}
                    </View>
                </>
            )}
        </ParkableBaseView>
    );

};

const getReduxProps = (state: IRootReducer) => {
    const currentVehicle = _.first(state.user.vehicles?.filter(v => v.id === state.user.currentVehicleId)) || null;

    return {
        user: state.user.user,
        lastMapLocation: state.maps.lastMapLocation,
        sessiontoken: state.google.sessiontoken,
        favouriteParks: state.userOptions.favouriteParks,
        userOptions: state.userOptions.userOptions,
        loadingPredictions: state.google.loading,
        defaultLocation: state.settings.defaultLocation,
        parkingType: state.maps.preferences.parkingType,
        currentVehicle
    }
};

const actions = {
    setParkingType,
}

type ReduxProps = ReturnType<typeof getReduxProps> & typeof actions;

export default connect(getReduxProps, actions)(FindParkView as React.FunctionComponent);

export const FindParkRoute = createRoute({
    path: Routes.FindParkView,
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colours.NEUTRALS_WHITE,
        minHeight: '100%'
    },
    navigation: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 72,
        backgroundColor: Colours.NEUTRALS_WHITE,
        paddingRight: 9
    },
    searchTextBox: {
        flexDirection: 'row',
        padding: 1,
        marginTop: 9,
        borderWidth: 1
    },
    searchResultBox: {
        paddingTop: 9,
    },
    textInput: {
        flex: 1,
        paddingBottom:2,
        paddingTop: 2,
        padding: 5,
        color: Colours.NEUTRALS_BLACK,
        fontFamily: Constants.FONT_REGULAR,
        fontSize: 15
    },
});
