import React from 'react';
import connect from "../../constants/connect";
import { DispatchFunc } from "../../model/Types";
import { IRootReducer } from "../../redux/reducers/main";
import { ChatProvider } from "./Context";
import { MainSupport } from "./problem-type/Main";
import { VehicleInMyBay } from "./problem-type/VehicleInMyBay";
import { MessageParkable } from "./problem-type/MessageParkable";
import { EVFaqs } from "./problem-type/EVFaqs";
import { SomethingWrongWithBay } from "./problem-type/SomethingWrongWithBay";
import {MessageTandemParkers} from "./problem-type/MessageTandemParkers";
import {MessageOrganisation} from "./problem-type/MessageOrganisation";
import {createRoute, NavigationProps} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import { EmployeeSubscriptionDTO } from "../../dto/EmployeeSubscriptionDTO";

export type AllProblemViewProps = ReturnType<typeof getReduxProps> & {dispatch: DispatchFunc} & NavigationProps<Routes.Problem>;

const ProblemView = (props: AllProblemViewProps) => {
    return (
        <ChatProvider>
            <MainSupport {...props}/>
            <MessageParkable {...props}/>
            <MessageTandemParkers {...props}/>
            <MessageOrganisation {...props}/>
            <VehicleInMyBay {...props}/>
            <SomethingWrongWithBay {...props}/>
            <EVFaqs {...props}/>
        </ChatProvider>
    )
}

class ProblemViewParams {
    isOrganisationPark?: boolean;
    parkId: number;
    bayId?: number;
    sessionId?: number;
    subscription?: EmployeeSubscriptionDTO;
}

const getReduxProps = (state: IRootReducer, props: NavigationProps<Routes.Problem>) => {
    const {isOrganisationPark, parkId, bayId, sessionId, subscription} = props.route.params;

    return {
        user: state.user,
        territories: state.territories.territories,
        parks: state.parks,
        parkId,
        bayId,
        sessionId,
        isOrganisationPark,
        subscription
    };
};

export default connect(getReduxProps)(ProblemView);

export const ProblemRoute = createRoute({
    path: Routes.Problem,
    params: {type: ProblemViewParams}
});
