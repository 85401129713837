import { classnames, Colours, Icon, IconName, Icons } from 'react/parkable-components';
import React from 'react';
import { Platform, StyleSheet, Text, View } from "react-native";
import { Marker } from "react-native-maps";
import strings from '../../constants/localization/localization';
import { CasualDisplayType, IMarker, LTDisplayType, MapPreferences, ParkingType } from "../../model/Types";

const out = (s?: string | null) => s || '';
export type MarkerTextAndIconProps = {
    markerText: string,
    iconName: IconName,
}
export const getMarkerTextAndIcon = (marker: IMarker, preferences: MapPreferences, isMotorBikeDefault: boolean, parkingType?: ParkingType): MarkerTextAndIconProps => {

    const { casualDisplayType, ltDisplayType } = preferences;

    const result: MarkerTextAndIconProps = {
        markerText: "",
        iconName: "" as IconName,
    };

    switch (parkingType ?? preferences.parkingType) {
        case ParkingType.LONG_TERM: {
            result.iconName = Icons.key;
            if (marker.extra.parkIsFull) {
                result.markerText = marker.extra.favPark ? '0' : strings.full;
                break;
            }

            switch (ltDisplayType) {
                case LTDisplayType.PRICE_PER_MONTH:
                    result.markerText = out(marker.extra.perMonthText);
                    break;
                case LTDisplayType.SPOTS_LEFT:
                    result.markerText = out(marker.extra.spotsLeftText);
                    break;
                case LTDisplayType.PRICE_PER_WEEK:
                default:
                    result.markerText = out(marker.extra.perWeekText);
                    break;
            }
            break;
        }
        case ParkingType.ELECTRIC: {
            //todo
            if (marker.extra.hasEVCharger) {
                result.iconName = marker.extra.userIsOrgMember ? Icons.key : Icons.electricvehicleplug
            }
            else {
                //make sure that we always have an icon.
                result.markerText = '';
                result.iconName = isMotorBikeDefault ? Icons.motorbike : Icons.car
            }

            break;
        }
        case ParkingType.CASUAL: {
            result.iconName = isMotorBikeDefault ? Icons.motorbike : Icons.car;
            if (marker.extra.parkIsFull) {
                result.markerText = marker.extra.favPark ? '0' : strings.full;
                break;
            }

            switch (casualDisplayType) {
                case CasualDisplayType.SPOTS_LEFT:
                    result.markerText = out(marker.extra.spotsLeftText);
                    break;
                case CasualDisplayType.PRICE_PER_HOUR:
                default:
                    result.markerText = out(marker.extra.perHourText);
                    break;
            }
            break;
        }
    }

    if (marker.extra.userIsOrgMember) {
        if (result.iconName !== Icons.electricvehicleplug) {
            result.markerText = '';
            result.iconName = Icons.buildingwork;
        }
    } else if (marker.extra.favPark) {
        if (result.iconName !== Icons.electricvehicleplug) {
            result.iconName = Icons.star;
        }
    }

    return { iconName: marker.isCampus ? Icons.buildingwork : result.iconName, markerText: result.markerText };
};

const styles = StyleSheet.create({
    outer: {
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 4,
        paddingVertical: 2,
    },
    container: {
        width: 42,
        borderRadius: 3,
        alignItems: 'stretch',

        ...Platform.select({
            ios: {
                shadowColor: Colours.NEUTRALS_BLACK,
                shadowOffset: {
                    width: 0,
                    height: 1,
                },
                shadowOpacity: 0.4,
                shadowRadius: 2,
            },
            android: {
                elevation: 1
            }
        }),
    },

    textPanel: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        width: '100%',
        height: 17,
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    inactiveTextPanel: {
        backgroundColor: Colours.BUTTON_DISABLED
    },
    userIsOrgMember: {
        backgroundColor: Colours.NEUTRALS_WHITE,
        height: 6,
    },
    icon: {
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        alignItems: "center",
        justifyContent: 'center',
    },
    activeIconView: {
        height: 31,
        backgroundColor: Colours.GREEN_300,
        paddingTop: 4,
    },
    activeIconOrgMemberView: {
        height: 42,
        backgroundColor: Colours.PINK,
        paddingTop: 4,
    },
    favoritePark: {
        height: 31,
        backgroundColor: Colours.PURPLE,
        paddingTop: 4,
    },
    hidePriceIconView: {
        height: 37
    },
    inactiveIcon: {
        backgroundColor: Colours.BUTTON_DISABLED,
    },
    arrowDown: {
        width: 0,
        height: 0,
        top: 0,
        alignSelf: 'center',
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderLeftWidth: 5,
        borderRightWidth: 5,
        borderBottomWidth: 6,
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: Colours.NEUTRALS_WHITE,
        transform: [
            { rotate: '180deg' }
        ]
    },
    inactiveArrowDown: {
        borderBottomColor: Colours.BUTTON_DISABLED,
    },
    orgHidePriceArrowDown: {
        borderBottomColor: Colours.PINK,
    },
    casualHidePriceArrowDown: {
        borderBottomColor: Colours.GREEN_300,
    },
    markerText: {
        color: Colours.NEUTRALS_BLACK,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: 12,
        letterSpacing: -0.7
    },
    inactiveMarkerText: {
        color: Colours.GREY_50
    },
});

export type MarkerProps = {
    marker: IMarker,
    preferences: MapPreferences & { parkingType?: ParkingType };
    onMarkerPress: (e: { nativeEvent: { id: string } }) => void;
    parkingType: ParkingType,
    isMotorBikeDefault: boolean,
    isHidePrice?: boolean,
}

const cn = classnames(styles);

type Props = Omit<MarkerProps, 'parkingType'> & { parkingType?: ParkingType };

const MyMarker = (props: Props) => {

    const { onMarkerPress, marker, preferences, parkingType, isMotorBikeDefault, isHidePrice } = props;

    const { markerText, iconName } = getMarkerTextAndIcon(marker, preferences, isMotorBikeDefault, parkingType,);

    const { parkIsFull, parkIsOpen, isOrgOrFav, userIsOrgMember, favPark, userIsParkOwner } = marker.extra;

    const parkUnavailable = (!userIsOrgMember && parkIsFull) || !parkIsOpen;

    //icon colour for Campus and Org Pins
    const iconStyle = [styles.icon, cn.styles(
        (userIsOrgMember
            ? "activeIconOrgMemberView"
            : (favPark ? "favoritePark" : "activeIconView")
        ), {
        inactiveIcon: parkUnavailable,
        hidePriceIconView: isHidePrice && !userIsOrgMember
    })];

    const arrowStyle = cn.styles("arrowDown", {
        casualHidePriceArrowDown: isHidePrice,
        orgHidePriceArrowDown: isHidePrice && userIsOrgMember,
        inactiveArrowDown: parkUnavailable,
    });

    const textPanelStyle = cn.styles("textPanel", {
        userIsOrgMember: userIsOrgMember,
        inactiveTextPanel: parkUnavailable,
    });

    const textStyle = cn.styles('markerText', {
        inactiveMarkerText: parkUnavailable
    });
    const iconBackgroundColour = (userIsOrgMember || userIsParkOwner) ? Colours.PINK : (favPark ? Colours.PURPLE : Colours.GREEN_300);
    const topColour = parkUnavailable ? Colours.GREY_50 : Colours.NEUTRALS_WHITE;
    return (
        <Marker
            key={marker.id}
            identifier={`${marker.id}`}
            zIndex={marker.extra.zIndex}
            coordinate={marker.coordinate}
            onPress={onMarkerPress}
            tracksViewChanges={false}
            flat={true}
            //@ts-ignore
            markerType={{
                type: 'pin',
                topText: iconName,
                isOrgOrFav,
                topColour: topColour,
                topBackgroundColour: parkUnavailable ? Colours.BUTTON_DISABLED : iconBackgroundColour,
                bottomText: !isHidePrice ? markerText : "",
                bottomColour: parkUnavailable ? Colours.GREY_50 : Colours.NEUTRALS_BLACK,
                bottomBackgroundColour: parkUnavailable ? Colours.BUTTON_DISABLED : Colours.NEUTRALS_WHITE,
            }}>
            <View style={styles.outer}>
                <View style={styles.container}>
                    <View style={iconStyle}>
                        <Icon iconProps={{ allowFontScaling: false }} name={iconName as any} color={parkUnavailable ? Colours.GREY_50 : Colours.NEUTRALS_WHITE} />
                    </View>
                    {!isHidePrice && <View style={textPanelStyle}>
                        <Text allowFontScaling={false} style={textStyle}>{markerText}</Text>
                    </View>}
                </View>
                <View style={arrowStyle} />
            </View>
        </Marker>
    );
};

export default React.memo(MyMarker, (prevProps: Props, nextProps: Props) => {
    return prevProps.marker.id === nextProps.marker.id;
}) as React.FunctionComponent<Props>;
