import React, { useEffect, useRef, useState } from "react";
import { Image, Platform, StyleSheet, View } from "react-native";
import Spinner from "react/parkable-components/spinner/Spinner";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Colours from "react/parkable-components/styles/Colours";
import Dialog from "react/components/dialog/Dialog";
import WebView from "react-native-webview";
import { WebViewMessageEvent } from "react-native-webview/lib/WebViewTypes";
import { Routes } from "react/navigation/root/root.paths";
import { loadParkableUser, setCreateUserData } from "../../redux/actions/user";
import { getCustomToken } from "../../api/auth";
import Strings from "../../constants/localization/localization";
import { IRootReducer } from "../../redux/reducers/main";
import { Token } from "../../api/rest";
import { connect } from "react-redux";
import { FirebaseService } from "../../services/firebase.service";
import { createRoute, NavigationProps } from "../../navigation/constants";
import Toolbar from "../widgets/toolbar/Toolbar";
import { PADDING } from "../../root/root.constants";
import useSafeArea from "../../hooks/useSafeArea";
import { updateSamlEmailAddress, useSsoClientConfig } from "../../api/sso/sso";
import { useAppDispatch } from "../../redux/redux";
import useConfig from "../../hooks/useConfig";

const parkableStack = require("../../resources/parkableStack.png");

class SamlLoginParams {
    email: string;
}

type SamlLoginViewProps = ReturnType<typeof getReduxProps> & NavigationProps<Routes.SamlLoginView>;

const SamlLoginView = (props: SamlLoginViewProps) => {
    const { top, bottom } = useSafeArea();
    const { navigation, apiName, api, route } = props;

    const dispatch = useAppDispatch();
    const dialogRef = useRef<DialogRef>(null);
    const config = useConfig();

    const {data: ssoConfig} = useSsoClientConfig(route.params?.email);

    const startURI = ssoConfig
        ? `https://${config.firebase.authDomain}?providerId=${ssoConfig.samlIdpId}&config=${apiName}`
        : null;

    const [error, setError] = useState<string|undefined>(undefined);

    useEffect(() => {
        if (FirebaseService.getCurrentUser()) {
            navigation.reset({
                routes: [{
                    name: Routes.ParkableMapView,
                }],
            });
            return;
        }
        if (Platform.OS !== "web" || !ssoConfig) {
            return;
        }
        const providerId = ssoConfig.samlIdpId;
        if (!providerId) {
            console.error("Provider not set");
            return;
        }
        FirebaseService.signInWithPopup(providerId)
            .catch((e) => {
                console.error(e);
                setError(e.message);
            });
    }, [ssoConfig]);

    const onWebViewMessage = async (message: WebViewMessageEvent) => {

        //@ts-ignore
        const data = JSON.parse(message.nativeEvent?.data);

        if(!!data.log){
            console.log("WEB: ", data.message);
        }
        else if(!data.result){
            console.log("WEB ERROR: ", data.error);
            setError(data.error);
        }
        else{
            await setUser(data.user);
        }
    }

    const onAcknowledgeError = () => {
        navigation.pop();
    }

    useEffect(() => {
        if(!!error){
            dialogRef.current?.show();
        }
        else{
            dialogRef.current?.hide();
        }
    }, [error]);

    const setUser = async (fbUser: any) => {

        const token = {firebaseToken: fbUser.stsTokenManager.accessToken, parkableToken: null};
        try {
            await updateSamlEmailAddress(api, token);
        }
        catch (e) {
            //nothing to be done
            console.log(e);
        }

        //Receive the user details from SAML
        const user = await loadParkableUser(api, token);
        if(!user) {
            const data = {
                email: fbUser.email,
                firstName: fbUser.providerData.firstName,
                lastName: fbUser.providerData.lastName
            }
            dispatch(setCreateUserData(data));
        }
        else if(!user.hasAcceptedTsAndCs){
            navigation.push(Routes.AcceptTsAndCsView, {userId: user.id, token: fbUser.stsTokenManager.accessToken});
        }
        else {
            console.log("got parkable user");
            try {
                const customToken = await getCustomToken(api, fbUser.stsTokenManager.accessToken);
                if (!!customToken) {
                    await FirebaseService.signInWithCustomToken(customToken.firebaseCustomToken);
                }
            } catch (e) {
                console.log(e);
                setError((e as any)?.message);
            }
        }
    }

    const toolBarStyle = [{marginLeft: PADDING}, Platform.OS === "ios" ? {paddingTop: "17%" } : {top}]

    if (!startURI) {
        return null;
    }

    return <View style={[{flex: 1, backgroundColor: Colours.NEUTRALS_WHITE}, {bottom}]}>
        <Toolbar style={toolBarStyle} showBackButton/>
        {Platform.select({
            native: (
                <WebView
                    style={{ marginTop: 40 }}
                    source={{ uri: startURI }}
                    javaScriptEnabledAndroid={true}
                    javaScriptEnabled={true}
                    domStorageEnabled={true}
                    cacheEnabled={false}
                    originWhitelist={["*"]}
                    incognito={true}
                    onMessage={onWebViewMessage}
                    scalesPageToFit={true} />
            ),
            web: (
                <View style={styles.web}>
                    <Image style={styles.logo}
                           source={parkableStack}
                    />
                    <View>
                        <Spinner />
                    </View>
                </View>
            )
        })}

        <Dialog ref={dialogRef} label={Strings.error} labelProps={{red: true}} title={error} positiveText={Strings.ok} onPositivePress={onAcknowledgeError}/>
    </View>
}

const styles = StyleSheet.create({
    web: {
        justifyContent: "center",
        alignItems: "center",
        height: "80%",
    },
    logo: {
        width: "50%",
        aspectRatio: 512/404,
    },
});

const getReduxProps = (state: IRootReducer) => {

    const tokenObject = {
        firebaseToken: state.auth.fireBaseToken
    } as Token;

    return {
        api: state.data.api,
        apiName: state.data.apiName,
        token: tokenObject,
    };
}

export const SamlLoginRoute = createRoute({
    path: Routes.SamlLoginView,
    params: {
        type: SamlLoginParams,
        deserialize: d => ({
            email: d.String()
        })
    },
})

export default connect(getReduxProps)(SamlLoginView);
