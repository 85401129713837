import React, {useCallback, useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {
    Platform,
    StyleSheet,
    View,
    Image,
    AppState,
    Keyboard, AppStateStatus,
} from "react-native";
import Strings from "../../../constants/localization/localization";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import Input from "react/parkable-components/input/Input";
import Dialog from "react/components/dialog/Dialog";
import {getQRAction} from "react/redux/actions/qrAction";
import QRAction, { ActionStatus, ActionType } from "../../../model/QRAction";
import { createRoute, NavigationProps, useNavigation } from "react/navigation/constants";
import {Routes} from "react/navigation/root/root.paths";
import {BarCodeEvent, BarCodeScanner} from 'expo-barcode-scanner';
import {IRootReducer} from "react/redux/reducers/main";
import {Camera, PermissionStatus} from "expo-camera";
import QRActionHandler from "./QRActionHandler";
import ParkableBaseView from "../ParkableBaseView";
import { useAppDispatch, useReduxMaps } from "react/redux/redux";
import { useUserVehicles } from "react/api/vehicle/vehicle.api";
import { useCurrentUser } from "react/api/user/user.api";
import { useBaysAvailableToUser } from "react/api/bay/bay.api";
import { Feature } from "react/model/Bay";
import { getUserCards, getUserVehicles } from "react/redux/actions/user";
import { setBaysAvailableForVehicleRedux } from "react/redux/reducers/confirmStartParkingView";
const qrCodeLockup = require("react/resources/qrCodeLockup.png");

export class QrScannerViewParams {
    instructions?: string;
}

export type AllViewProps = NavigationProps<Routes.QrScannerView>

function QrScannerView(props: AllViewProps & typeof actions & ReturnType<typeof getReduxProps>) {
    enum EntryMode {
        Code,
        Camera
    }

    const { preferences } = useReduxMaps();
    const dispatch = useAppDispatch();
    const navigation = useNavigation();

    const invalidCodeDialogRef = useRef<DialogRef|null>(null);
    const permissionDialogRef = useRef<DialogRef|null>(null);
    const [torchOn, setTorchOn] = useState(false);
    const [entryMode, setEntryMode] = useState(EntryMode.Camera);
    const [hasPermission, setHasPermission] = useState<boolean|null>(null);
    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const [scanned, setScanned] = useState(false);
    const [qrAction, setQrAction] = useState<QRAction | null>(null);
    const [showBayUnavailableDialog, setShowBayUnavailableDialog] = useState(false);

    const { bays: baysAvailable } = useBaysAvailableToUser(qrAction && qrAction.actionParams && qrAction.actionParams.parkId
      ? Number(qrAction.actionParams.parkId)
      : null, { feature: Feature.ELECTRIC });

    useEffect(() => {
        if (qrAction && baysAvailable && qrAction.actionType === ActionType.StartCharging) {
            dispatch(getUserCards());
            dispatch(getUserVehicles());
            dispatch(setBaysAvailableForVehicleRedux(baysAvailable));
            const bay = baysAvailable.find((b) => b.id === Number(qrAction.actionParams.bayId));
            if (!bay) {
                setShowBayUnavailableDialog(true);
            } else {
                QRActionHandler(qrAction, navigation, preferences, bay);
            }
        } else if (qrAction) {
            QRActionHandler(qrAction, navigation, preferences);
        }
    }, [qrAction, baysAvailable]);

    const resetToMap = useCallback(() => {
        navigation.reset({
            routes: [{
                name: Routes.ParkableMapView
            }]
        });
    }, [navigation]);

    useEffect(() => {
        checkCameraPermission('active');
        const subscription = AppState.addEventListener('change', checkCameraPermission);
        return () => {
            subscription.remove();
        }
    }, []);

    const setErrorWrapper = (err: string) => {
        setError(err);
    };

    const permissionCheck = useCallback(async () => {
        const { status } = await Camera.getCameraPermissionsAsync()
        const isPermissionGranted = status === PermissionStatus.GRANTED
        setHasPermission(isPermissionGranted);

        if (!isPermissionGranted) {
            permissionDialogRef.current?.show();
        } else {
            permissionDialogRef.current?.hide();
        }
    },[]);

    const checkCameraPermission = (appState: AppStateStatus) => {
        void permissionCheck();
    };

    const onScanSuccess = useCallback(async (scanResult: BarCodeEvent) => {
        const url = scanResult?.data;
        if (!url) {
            return;
        }
        const index = url.indexOf("https://links.parkable.com");

        const indexOfLastSlash = url.indexOf("/", url.indexOf("//") + 2);
        if(index === 0){
            submitCode(url.substring(indexOfLastSlash+1));
        }
        else{
            console.log("ignore as not Parkable link");
            setErrorWrapper(Strings.unrecognised_code);
        }
    }, []);

    const submitCode = useCallback((code: string) => {
        Keyboard.dismiss();
        //submit code should call an API endpoint that returns a qrcode object that tells the app what to do. eg. could be a 'park type' that contains park id, or a 'charger type' that contains a park ID and a bay/charger id
        props.getQRAction(code, (qrAction: QRAction) => {
                if(qrAction === null){
                    setError(Strings.uninitialised_code);
                }
                else if(qrAction.status !== ActionStatus.Active){
                    setError(Strings.code_disabled);
                }
                else{
                    setQrAction(qrAction);
                }
            },
            () => {
                setError(Strings.network_error);
            });
    }, []);

    const requestCameraPermission = async () => {
        const { status } = await BarCodeScanner.requestPermissionsAsync()
        setHasPermission(status === PermissionStatus.GRANTED)
        if (status === PermissionStatus.DENIED) {
        }
    };

    return (<ParkableBaseView>
        {entryMode === EntryMode.Camera
            ? (hasPermission
                ? <View style={styles.base}>
                        <Text style={{alignSelf: "center"}}>{props.route.params?.instructions??Strings.please_scan_code}</Text>
                        <View style={styles.qrScannerContainer}>
                            <Camera
                                onBarCodeScanned={scanned ? undefined : (e) => onScanSuccess(e)}
                                barCodeScannerSettings={{
                                    barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                                }}
                                style={styles.qrScanner}
                                flashMode={torchOn ? (Camera.Constants.FlashMode as any).torch : (Camera.Constants.FlashMode as any).off}
                            />
                        </View>
                        {error.length>0 && <Text style={{alignSelf: "center", marginTop: 0, marginBottom: 9, marginHorizontal: 36, color: Colours.RED}}>{error}</Text>}
                    </View>
                : <View>
                        <Text style={{alignSelf: "center"}}>{Strings.camera_permission_required}</Text>
                    </View>)
            : (<View style={styles.manualScanContainer}>
                    <View style={styles.imageContainer}>
                        <Image style={styles.image} source={qrCodeLockup}/>
                    </View>
                        <View style={styles.bottomButtons}>
                            <Input style={styles.codeInput} autoCorrect={false} autoCapitalize={"characters"} maxLength={7} onChangeText={v => {setErrorWrapper("");setCode(v.trim().toUpperCase())}}/>
                            <View style={styles.spacer}/>
                            <Button style={styles.button} disabled={code.length !== 7} center textProps={{h5:true}} onPress={() => submitCode(code)}>{Strings.submit}</Button>
                        </View>
                        {error.length>0 && <Text style={styles.error}>{error}</Text>}
                        <Text style={styles.instructions}>{props.route.params?.instructions??Strings.qr_code_location_desc}</Text>
                    </View>
                )}

        <View style={styles.bottomButtons}>
            <Button style={styles.button} plain border center textProps={{h5:true}}
                    onPress={() => {setError(""); setEntryMode(entryMode === EntryMode.Camera ? EntryMode.Code : EntryMode.Camera)}}>
                {entryMode !== EntryMode.Camera ? Strings.scan_qr : Strings.enter_code}
            </Button>
            <View style={styles.spacer}/>
            <Button disabled={entryMode !== EntryMode.Camera} style={styles.button} plain border center textProps={{ style: {paddingTop: 5,fontFamily: "parkableicons", fontSize:32}}}
                    onPress={() => setTorchOn(!torchOn)}>L</Button>
        </View>
        <Dialog
          isVisible={showBayUnavailableDialog}
          label={Strings.bay_not_available}
          labelProps={{
              style: {
                  color: Colours.NEUTRALS_BLACK, textAlign: "left", borderBottomWidth: 1, borderBottomColor: Colours.GREY_10,
              },
          }}
          title={Strings.bay_not_available_desc}
          titleProps={{ h2: undefined, small: true, style: { textAlign: "left", lineHeight: 24 } }}
          positiveProps={{
              textProps: {
                  h5: true,
                  style: { color: Colours.NEUTRALS_BLACK },
              },
              style: { backgroundColor: Colours.ORANGE },
          }}
          positiveText={Strings.close}
          onPositivePress={() => {
              setShowBayUnavailableDialog(false);
              resetToMap();
          }}
        />
        <Dialog ref={invalidCodeDialogRef}
                label={Strings.stop_parking}
                labelProps={{style: {color: Colours.NEUTRALS_BLACK, textAlign: 'left'}}}
                title={Strings.confirm_end_parking}
                titleProps={{style: {textAlign: 'left'}, h2: undefined}}
                positiveText={Strings.end_session}
                positiveProps={{red: true, textProps: {h5: true}}}
                onPositivePress={() => invalidCodeDialogRef.current?.hide()} />
        <Dialog
            ref={permissionDialogRef}
            label={Strings.request_permission}
            labelProps={{style: {color: Colours.NEUTRALS_BLACK, textAlign: 'left', borderBottomWidth: 1, borderBottomColor: Colours.GREY_10}}}
            title={Strings.camera_permission_required}
            titleProps={{h2: undefined, small:true, style: {textAlign: 'left', lineHeight: 24}}}
            positiveProps={{textProps: {style: {color: Colours.NEUTRALS_BLACK}, h5:true}, style: {backgroundColor: Colours.ORANGE}}}
            positiveText={Strings.request_permission}
            onPositivePress={requestCameraPermission}/>
    </ParkableBaseView>)
}

const getReduxProps = (state: IRootReducer) => {
    return {
    };
};

const actions = {
    getQRAction
};

export default connect(getReduxProps,  actions)(QrScannerView);

export const QrScannerRoute = createRoute({
    path: Routes.QrScannerView,
    params: {type: QrScannerViewParams}
})

const styles = StyleSheet.create({
    mapLinkTableRow : {
        borderBottomWidth: 1,
        borderBottomColor: Colours.GREY_BORDER,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 9,
    },
    imageScrollView: {
        marginTop: 9,
        marginBottom: 9,
        height: 120,
        width: '100%'
    },
    standardIcon: {
        fontFamily: Platform.OS === "ios" ? "parkable-icon-library" : "parkable-icon-library",
        fontSize: 30
    },
    reservation: {
        backgroundColor: Colours.ORANGE
    },
    qrScanner: {
        alignSelf: "center",
        paddingHorizontal: 36,
        flex: 1,
        borderRadius: 9,
        height: 270,
    },
    qrScannerContainer: {
        flex: 1,
        flexDirection: 'row',
        paddingVertical: 14,
    },
    base: {
        flex: 1,
    },
    manualScanContainer: {
        alignSelf: "center",
        flex: 1,
        borderRadius: 9,
    },
    imageContainer: {
        flexDirection: "row",
    },
    image: {
        flex: 1,
        zIndex: -1,
        aspectRatio: 125 / 77,
        height: 77,
    },
    bottomButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 28,
    },
    codeInput: {
        width: 170,
        height: 54,
    },
    spacer: {
        width: 7,
    },
    button: {
        flex: 1,
    },
    error: {
        alignSelf: "center",
        marginTop: 9,
        color: Colours.RED,
    },
    instructions: {
        alignSelf: "center",
        marginTop: 36,
    },
});
