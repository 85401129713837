import React, {useEffect, useState} from 'react'
import { View, StyleSheet, TouchableOpacity, Keyboard } from "react-native";
import Button from "react/parkable-components/button/Button";
import CheckBox from "react/parkable-components/checkBox/CheckBox";
import Spinner from "react/parkable-components/spinner/Spinner";
import Input from "react/parkable-components/input/Input";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import Icon from "react/parkable-components/icon/Icon";
import { connect } from "react-redux";
import { IRootReducer } from '../../redux/reducers/main';
import Strings from '../../constants/localization/localization'
import {
    MIN_PASSWORD_LENGTH_FOR_LOG_IN,
    MIN_PASSWORD_LENGTH_FOR_SIGN_UP
} from '../../constants/constants';
import validateEmail from '../../constants/validateEmail';
import {showAlert, showConfirmation} from '../../alerts';
import { logEvent } from "react/analytics";
import { useSsoClientConfig } from "../../api/sso/sso";
import {Token} from "../../api/rest";
import {FirebaseService} from "../../services/firebase.service";
import { createRoute, NavigationProps } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import { useAppDispatch } from "../../redux/redux";
import ParkableBaseView from "../common/ParkableBaseView";
import { PasswordHelperBlock } from "react/components/landing/passwordHelperBlock/PasswordHelperBlock";
import { setCredentials } from "../../redux/actions/credential";

type Props = ReturnType<typeof getReduxProps> & NavigationProps<Routes.LoginOrSignUpView>;

const LoginOrSignupView = (props: Props) => {
    const dispatch = useAppDispatch();
    const {navigation, route} = props;
    const [viewMode, setViewMode] = useState<"signup"|"login"|undefined>(route.params?.viewMode);
    const [password, setPassword] = useState<string>('');
    const [repassword, setRepassword] = useState<string>('');
    const [hidePassword, setHidePassword] = useState(true);
    const [inputEmail, setInputEmail] = useState<string|undefined>(undefined);
    const [email, setEmail] = useState<string|undefined>(undefined);
    const [incorrectPassword, setIncorrectPassword] = useState<string|undefined>(undefined);
    const [passwordError, setPasswordError] = useState<string | undefined>();
    const [passwordComplexityLetterError, setPasswordComplexityLetterError] = useState<string | undefined>(undefined);
    const [passwordComplexityCharacterError, setPasswordComplexityCharacterError] = useState<string | undefined>(undefined);
    const [repasswordError, setRepasswordError] = useState<string | undefined>();
    const [emailError, setEmailError] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const [showPasswordEntry, setShowPasswordEntry] = useState(false);
    const [usingSSO, setUsingSSO] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [emailSpinnerVisible, setEmailSpinnerVisible] = useState(false);

    const {data: ssoConfig} = useSsoClientConfig(email);

    const login = () => {
        Keyboard.dismiss();

        setLoading(true);

        FirebaseService.signInWithEmailAndPassword(email!, password)
        .catch(error => {
            setLoading(false);
            console.log(error.code);

            if (error.code === 'auth/invalid-email') {
                console.log('That email address is invalid!');
                setEmailError(Strings.invalid_email_longer);
            }
            else if (error.code === 'auth/user-disabled') {
                console.log('The user is disabled!');
                setEmailError(Strings.account_disabled);
            }
            else if (error.code === 'auth/user-not-found') {
                console.log('User not found!');
                setEmailError(Strings.email_not_found);
            }
            else if (error.code === 'auth/wrong-password') {
                console.log('Wrong password!');
                setIncorrectPassword(Strings.incorrect_password);
            }
            else if(error.code === "auth/network-request-failed"){
                showAlert(Strings.check_data_connection);
                return;
            }
            else{
                setEmailError(Strings.failed_to_login);
            }
        });
    }

    useEffect( () => {
        if (passwordComplexityLetterError || passwordComplexityCharacterError || passwordError || repasswordError || emailError || !inputEmail) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [passwordComplexityLetterError,passwordComplexityCharacterError,passwordError,repasswordError, emailError]);

    useEffect( () => {

        setShowPasswordEntry(false);
        if(!inputEmail){
            return;
        }

        setEmailError(undefined);
        const trimmed = inputEmail.trim();
        const emailErr = validateEmail(trimmed);

        if(!!emailErr) {
            setDisableButton(true);
            setUsingSSO(false);
            return;
        }

        setEmailSpinnerVisible(true);
        const timer = setTimeout( ()=> {
            setEmail(trimmed);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [inputEmail]);

    useEffect(() => {
        if (!ssoConfig) {
            return;
        }
        try {
            if (ssoConfig.scimEnabled && !ssoConfig.userExists) {
                //throw error stating user not provisioned.
                showConfirmation(Strings.domain_uses_sso_and_requires_provisioning, Strings.provisioning_required, false, undefined, Strings.ok);
                return;
            }
            setUsingSSO(ssoConfig.samlEnabled);
            setDisableButton(false);
            setEmailSpinnerVisible(false);
        } catch (err) {
            console.error(err);
        }
    }, [ssoConfig]);

    useEffect(() => {
        setPassword('')
    }, [viewMode])

    const onButtonPress = () => {
        if(disableButton) {
            return;
        }
        // No space are allowed on both sides of the password string
        if (password.startsWith(' ') || password.endsWith(' ')) {
            showAlert(Strings.password_no_space_notice, Strings.password_error);
            return;
        }
        if(usingSSO){
            //show SAML log in view
            navigation.push(Routes.SamlLoginView, {email: email!});
        }
        else if(!showPasswordEntry){
            setViewMode(ssoConfig!.userExists ? "login" : "signup");
            setShowPasswordEntry(true);
            setDisableButton(true);
        }
        else if(viewMode === 'login'){
            login();
        }
        else {
            dispatch(setCredentials(email, password));
            navigation.push(Routes.CreateAccountView, {});
        }
    }

    const forgotPassword = () => {
        navigation.push(Routes.ForgotPasswordView, {});
    }

    const onPasswordValueChange = (password: string) => {
        const regex_letter = /^(?=.*[a-z])(?=.*[A-Z])/
        const regex_character = /^(?=.*[0-9])(?=.*[\W_])/
        if (!regex_letter.test(password) && !regex_character.test(password) && viewMode === "signup") {
            setPasswordComplexityLetterError(Strings.password_complexity_letter);
            setPasswordComplexityCharacterError(Strings.password_complexity_character);
        } else if (!regex_letter.test(password) && regex_character.test(password) && viewMode === "signup") {
            setPasswordComplexityLetterError(Strings.password_complexity_letter);
            setPasswordComplexityCharacterError(undefined);
        } else if (regex_letter.test(password) && !regex_character.test(password) && viewMode === "signup") {
            setPasswordComplexityCharacterError(Strings.password_complexity_character);
            setPasswordComplexityLetterError(undefined);
        } else {
            setPasswordComplexityLetterError(undefined);
            setPasswordComplexityCharacterError(undefined);
        }
    };

    const onPasswordChange = (password: string, repeatPassword: string) => {
        setPassword(password);
        setRepassword(repeatPassword);
        const passwordsDontMatch = password !== repeatPassword;
        if (passwordsDontMatch && viewMode === "signup") {
            setRepasswordError(Strings.passwords_dont_match);
        } else {
            setRepasswordError(undefined);
        }
        if (password.length < MIN_PASSWORD_LENGTH_FOR_SIGN_UP && viewMode === "signup") {
            setPasswordError(Strings.minimum_password_length);
        } else if (password.length < MIN_PASSWORD_LENGTH_FOR_LOG_IN && viewMode === "login") {
            setDisableButton(true);
        } else if (viewMode === "login"){
            setPasswordError(undefined);
            setIncorrectPassword(undefined);
            setDisableButton(false);
        } else {
            setPasswordError(undefined);
            setIncorrectPassword(undefined);
        }
    }

    const onTryAnotherWay = () => {
        navigation.push(Routes.MagicLinkView, {emailAddress: email!});
    }

    const onSwitch = () => {

        let facebookEventName = '';
        let firebaseEventName = '';

        if(viewMode === "login") {
            firebaseEventName = facebookEventName = 'launch_signup';
            setViewMode("signup");
        }
        else{
            firebaseEventName = facebookEventName = 'launch_login';
            setShowPasswordEntry(false)
            setViewMode("login");
        }
        logEvent(undefined, facebookEventName, {});
    } ;

    const title = viewMode === "signup" ? Strings.sign_up : Strings.login;

    const backButtonOverride = () => {
        navigation.reset({
            routes: [{
                name: Routes.LandingView,
            }],
        });
    };

    return  <ParkableBaseView loading={loading} backButtonOverride={backButtonOverride}>
            <View style={styles.base}>
                <View style={{flex: 1}}>
                    <View>
                    <Text allowFontScaling={false} style={styles.welcome}>{title}</Text>
                    {viewMode === 'signup' && showPasswordEntry && <Text style={styles.password_description}>{Strings.password_restrictions}</Text>}
                        <View style={styles.inputContainer}>
                            <Input containerStyle={{marginBottom: 6, flex: 2}}
                                   importantForAutofill={"yes"}
                                   autoCorrect={false}
                                   autoComplete={"username"}
                                   keyboardType={"email-address"}
                                   autoCapitalize={"none"}
                                   label={Strings.email__}
                                   returnKeyType={"next"}
                                   onSubmitEditing={onButtonPress}
                                   onChangeText={setInputEmail}
                                   placeholder={Strings.email__}
                                   value={inputEmail}/>
                            <View style={styles.spinner}>
                                {emailSpinnerVisible && <Spinner />}
                            </View>
                        </View>
                        {emailError && <Text red>{emailError}</Text>}
                        {usingSSO && <View style={{flexDirection: "row"}}>
                            <Icon style={{height: 20, margin: 0, padding: 0, width: 18}} iconStyle={{
                                fontSize: 12,
                                height: 19,
                                margin: 0,
                                padding: 0,
                                textAlignVertical: "center"
                            }} name={'lock'} large/>
                            <Text small grey style={{
                                alignContent: "center",
                                textAlignVertical: "center",
                                height: 20
                            }}>{Strings.single_sign_on_enabled}</Text>
                        </View>}
                        {showPasswordEntry && <View>
                            <Input autoFocus autoCorrect={false} autoCapitalize={"none"} autoComplete={"password"}
                                   importantForAutofill={"yes"}
                                   style={{marginBottom: 9}}
                                   label={viewMode === "signup" ? Strings.create_password : Strings.password}
                                   password={hidePassword}
                                   placeholder={Strings.password}
                                   error={incorrectPassword}
                                   onChangeText={(value) => {
                                       onPasswordValueChange(value);
                                       onPasswordChange(value, repassword);
                                   }}
                                   returnKeyType={"go"}
                                   onSubmitEditing={onButtonPress} />
                            {viewMode === 'signup' && password?.length > 0 && <PasswordHelperBlock title={passwordError ? passwordError : Strings.minimum_password_length} pass={passwordError === undefined} />}
                            {viewMode === 'signup' && password?.length > 0 && <PasswordHelperBlock title={passwordComplexityLetterError ? passwordComplexityLetterError : Strings.password_complexity_letter} pass={passwordComplexityLetterError === undefined} />}
                            {viewMode === 'signup' && password?.length > 0 && <PasswordHelperBlock title={passwordComplexityCharacterError ? passwordComplexityCharacterError : Strings.password_complexity_character} pass={passwordComplexityCharacterError === undefined} />}
                            {viewMode === "signup" && <Input autoCorrect={false} autoCapitalize={"none"} autoComplete={"password"}
                                   style={{marginBottom: 9}}
                                   label={Strings.repeat_password}
                                   password={hidePassword}
                                   placeholder={Strings.password}
                                   onChangeText={(value) => onPasswordChange(password, value)}
                                   returnKeyType={"go"}
                                   onSubmitEditing={onButtonPress}/>}
                              {viewMode === 'signup' && repassword?.length > 0 && <PasswordHelperBlock title={repasswordError ? repasswordError : Strings.passwords_dont_match} pass={repasswordError === undefined} />}
                            <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 9}}>
                                <View style={styles.base}>
                                    <CheckBox checkStyle={!hidePassword && {borderColor: Colours.GREY_BORDER}}
                                              initialChecked={hidePassword}
                                              onChange={hidePassword => setHidePassword(hidePassword)}>
                                        {Strings.hide_password}
                                    </CheckBox>
                                </View>
                                {viewMode === 'login' && <View style={{flex: 1, top: 5}}>
                                    <TouchableOpacity onPress={forgotPassword}>
                                        <Text small style={{
                                            color: Colours.BLUE_300,
                                            fontWeight: "normal",
                                            textDecorationLine: "underline",
                                            textAlign: "right"
                                        }}>{Strings.forgot_password}</Text>
                                    </TouchableOpacity>
                                </View>}
                            </View>
                        </View>}
                    </View>

                    <View style={{marginTop: 20, width: "50%"}}>
                        <Button center form textProps={{h5: false, h3: true}}
                                disabled={disableButton || (password !== repassword && viewMode === 'signup')}
                                onPress={onButtonPress}>
                            {showPasswordEntry && viewMode === 'login' ? Strings.log_in : Strings.continue}
                        </Button>
                    </View>

                    {usingSSO && ssoConfig?.magicLinksEnabled && (
                        <TouchableOpacity onPress={onTryAnotherWay}>
                            <Text small style={{ marginTop: 18, marginBottom: 18 }}>
                                {Strings.having_trouble + " "}
                                <Text small
                                      style={styles.link}>
                                    {Strings.try_another_way}
                                </Text>
                            </Text>
                        </TouchableOpacity>
                    )}
                </View>

                <TouchableOpacity onPress={onSwitch} style={styles.footer}>
                    {viewMode === "login" && (
                        <Text small>
                            {Strings.not_having_account + " "}
                            <Text small style={styles.link}>
                                {Strings.sign_up}
                            </Text>
                        </Text>
                    )}
                    {viewMode === "signup" && (
                        <Text small>
                            {Strings.already_a_member + " "}
                            <Text small style={styles.link}>
                                {Strings.login}
                            </Text>
                        </Text>
                    )}
                </TouchableOpacity>
            </View>
     </ParkableBaseView>
}


const getReduxProps = (state: IRootReducer) => {

    const tokenObject = {
        firebaseToken: state.auth.fireBaseToken
    } as Token;

    return {
        api: state.data.api,
        apiName: state.data.apiName,
        token: tokenObject,
    };
}

const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    inputContainer: {
        flexDirection: "row",
    },
    welcome:{
        fontSize: 45,
        lineHeight: 49,
        marginBottom: 18,
        backgroundColor: Colours.NEUTRALS_WHITE,
        fontFamily: "GTEestiDisplay-Bold",
    },
    password_description: {
        fontSize: 18,
        marginBottom: 18
    },
    footer: {
        marginTop: 40,
    },
    link: {
        color: Colours.BLUE_300,
        textDecorationLine: "underline"
    },
    spinner: {
        justifyContent: "center",
        marginRight: -12,
        width: 50,
    },
})

export class LoginOrSignupViewParams {
    viewMode: "signup"|"login";
}

export default connect(getReduxProps)(LoginOrSignupView);

export const LoginOrSignupRoute = createRoute({
    path: Routes.LoginOrSignUpView,
    params: {type: LoginOrSignupViewParams},
});
