import {ILocation} from "../constants/Util";

export enum ParkingType {
    CASUAL = 'CASUAL',
    ELECTRIC = 'ELECTRIC',
    LONG_TERM = 'LONG_TERM'
}

export interface ISomeDay {
    isNextWeek: boolean,
    text: string
}

export type DispatchFunc = (dispatchedFunc: any) => void;

export enum CasualDisplayType {
    PRICE_PER_DAY = 'PRICE_PER_DAY',
    SPOTS_LEFT = 'SPOTS_LEFT',
    PRICE_PER_HOUR = 'PRICE_PER_HOUR',
}

export enum LTDisplayType {
    SPOTS_LEFT = 'SPOTS_LEFT',
    PRICE_PER_WEEK = 'PRICE_PER_WEEK',
    PRICE_PER_MONTH = 'PRICE_PER_MONTH',
}

export type MapPreferences = {
    casualDisplayType: CasualDisplayType,
    ltDisplayType?: LTDisplayType,
    // availableParksOnly: boolean,
    parkingType: ParkingType,
    hideClosedParks: boolean,
    hideFullParks: boolean
}

export const defaultMapPreferences = (): MapPreferences => {
    return ({
        casualDisplayType: CasualDisplayType.PRICE_PER_HOUR,
        ltDisplayType: LTDisplayType.SPOTS_LEFT,
        // longTermOnly: false,
        // availableParksOnly: false,
        parkingType: ParkingType.CASUAL,
        hideClosedParks: false,
        hideFullParks: false
    })
};

export type IMarkerExtra = {
    id: number,
    parkIsOpen?: boolean,
    favPark?: boolean,
    userIsParkOwner?: boolean,
    userIsOrgMember?: boolean,
    isOrgOrFav?: boolean,
    parkIsFull?: boolean,
    longTermOnlyText?: string | null,
    perDayText?: string,
    perHourText?: string,
    perWeekText?: string,
    perMonthText?: string,
    spotsLeftText?: string | null,
    hasEVCharger?: boolean,
    hasLongTerm?: boolean,
    hasShortTerm?: boolean,
    hideCasualBays?: boolean | null,
    hideSubscriptionBays?: boolean | null,
    hideEVBays: boolean | null,
    territory?: number,
    parkIds?: number[],
    zIndex: number,
}

export type IMarker = {
    id: string,
    time: number,
    location: ILocation,
    coordinate: ILocation,
    extra: IMarkerExtra,
    isCampus: boolean
};

export type BluetoothState = 'off' | 'on' | 'unauthorized' | 'unsupported' | 'unknown';

export type IInputEvent  = React.ChangeEvent<HTMLInputElement>;
export type IButtonEvent = React.MouseEvent<HTMLButtonElement>;

export type IValidationState = "error" | "success" | "warning" | null | undefined;

export type CallProvider = "TWILIO" | "ACULAB";

export type AccessGateStatus = "Operational" | "OutOfOrder" | "Deleted";

export type AccessGateType = "IoTStream" | "GSM";

export type Activity =
    "Casual"
    | "LongTerm"
    | "Infringement"
    | "Deposit"
    | "DepositRefund"
    | "SaaSInvoice"
    | "UserCharge"
    | "EventParking"
    | "Other";

export enum ParkingActivity {
    Casual = 'Casual',
    LongTerm = 'LongTerm'
}

export type AffiliateInvoiceStatus = "Paid" | "Refunded" | "Failed" | "Pending";

export type BankFeeStatus = "Active" | "Disabled";

export type BayType = "Dynamic" | "Leasable" | "Visitor" | "PublicDynamic" | "PublicSubscription";

export type BayStatus = "Deleted" | "Disabled" | "OutOfOrder" | "Enabled";

export type BayGroupType = "Standard" | "Visitor" | "PublicDynamic" | "PublicSubscription";

export function BayGroupTypeIsPublic(type: BayGroupType | undefined | null) {
    if (!type) {
        return false;
    }
    return type === "PublicDynamic" || type === "PublicSubscription";
}

export type SensorModel = "TBS220" | "PlacePod";

export type ConnectMethod = "Platform" | "Destination" | "SeparateTransfer" | "ManualPayment";

export type Country = "NZ" | "CHINA" | "AUSTRALIA" | "USA";

export type Currency = "GBP" | "EUR" | "SGD" | "NZD" | "CNY" | "AUD" | "USD";

export type DepositStatus = "Paid" | "PaymentFailed" | "RefundPending" | "RefundedToHost" | "RefundedToUser";

export type EmployeeSubscriptionStatus = "Invited" | "Active" | "HostEnding" | "LeaserEnding" | "PastDue" | "Deleted" | "Pending";

export type EntityType = "COMPANY" | "INDIVIDUAL";

export type Feature = "SEDAN" | "MOTORBIKE" | "ELECTRIC";

export type HostStatusVerification = "unverified" | "pending" | "verified";

export type InviteStatus = "INVITED" | "CANCELLED" | "USER_CREATED";

export type InvoiceType = "InvoiceRefundFailed" | "Invoice";

export type LeaseStatus = "Active" | "Processing" | "HostEnding" | "LeaserEnding" | "Ended" | "PastDue" | "Unpaid";

export type MessageKey = "youHaveReachedYourLimitOfBays" | "youAreNearLimitOfBays" | "youHaveReachedYourLimitOfSites";

export type NoteStatus = "Active" | "Deleted";

export type NoteType = "Park" | "Group" | "Bay" | "Infringement";

export type AffiliateRole = "NONE" | "AFFILIATE" | "AFFILIATED";

export type OrganisationStatus = "Active" | "Suspended";

export type OrganisationStatusVerification = "unverified" | "pending" | "verified";

export type Management = "Managed" | "Unmanaged" | "Leased";

export type ParkType = "Private" | "Public";

export type ChurnStatus = "ON" | "UNAVAILABLE" | "DELETED";

export type RequestStatus = "Accepted" | "Confirmed" | "Expired" | "CancelledUser" | "CancelledAdmin" | "Spent" | "WaitList";

export type PayoutSchedule = "Weekly" | "Monthly";

export type SourceOfLead = "Referral" | "AA_Pilot_Campaign" | "Car_parks_for_rent" | "Channel_Partner" | "Chat_Widget" | "Competitor_Listing" | "Deal_Extention" | "Email_in_inbound" | "Event_Lead_List" | "Facebook_Lead_Ad" | "Gumtree" | "Inbound_Call" | "Linkedin_Lead_Ad" | "Marketing_Email" | "Media" | "Network" | "Organic_Listing" | "Organic_SaaS" | "Outbound_Call" | "Oz_YoYo" | "Refer_a_host_campaign" | "Relationship" | "SaaS" | "SkyKiwi" | "Spark" | "Vacancy" | "Web_form" | "WOM" | "SDR_process";

export type LeadType = "ParkableForBusiness" | "ParkableClassic";

export type Role = "ADMINISTRATOR" | "ORGANISATION_ADMIN" | "TEAM_LEADER" | "SENSOR_ADMIN" | "PUBLIC" | "BILLING" | "INVESTOR" | "INVESTOR_PENDING" | "SUPER_INVESTOR" | "SUPER_INVESTOR_PENDING" | "ENFORCEMENT" | "VISITOR_PARKING";

export type SaaSInvoiceType = "Paid" | "Refunded" | "Failed" | "Pending";

export type PlanType = "Default" | "Custom" | "VisitorExpress" | "Public" | "ParkableSite";

export type StatusPlan = "Active" | "Disabled";

export type SaaSSubscriptionStatus = "Active" | "Ending" | "PastDue" | "Ended" | "Pending";

export type StripeDynamicChargeType = "Paid" | "Refunded" | "Failed" | "RefundFailed";

export type SubType = "EmployeeSubscription";

export type Type = "InvoiceRefundFailed" | "Invoice";

export type Method = "DELETE" | "GET" | "HEAD" | "POST" | "PUT" | "PULL";

export type TaskOptionsStatus = "Active" | "TaskCreated" | "Deleted";

export type TaxComponentStatus = "Active" | "Disabled";

export type Term = "Week" | "Month";

export type TimeoutEvent = "Timeout" | "End";

export type UserNoteStatus = "Included" | "Deleted";

export type ValidForParking = "ClassicCasual" | "ClassicLease" | "PfBCasual" | "PfBSubscription" | "PfBSaaSSubscription";

export type PayloadType = "VehicleDetect" | "VehicleCount";

export type BayProblemResponseFullStatus = "UpdatedBay" | "SessionEnded" | "AdminContacted" | "BayReturnedToGroup" | "SessionCreated";

export type NotificationCodes = "HostParkingStarted" | "HostParkingEnded" | "HostReservationStarted" | "ParkingStarted" | "ParkingEnded" | "ParkingRequestUpdated" | "ReservationTimedOut" | "ReservationCancelled" | "CurrentParkIsGoingUnavailable" | "SessionEndedInUnavailablePark" | "MessageReceived" | "AdminMessageReceived" | "EmployeeSubscriptionCreated" | "EmployeeSubscriptionUpdated" | "EmployeeSubscriptionCancelled" | "BayMadeAvailableInGroup" | "EmployeeSubscriptionPastDue" | "UnpaidEmployeeSubscription" | "EmployeeSubscriptionWentUnavailable" | "StillParkingAfterHours" | "LeaseCreated" | "UnpaidLeaseEnded" | "UnpaidLease" | "LeaseWentUnavailable" | "LeaseEnded" | "WarningSessionRunning" | "AutoEndSession" | "WarningVacateBay";

export type SharingReminderDay = "Off" | "Daily" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday" | "Weekdays"
