import Colours from "react/parkable-components/styles/Colours";
import Spinner from "react/parkable-components/spinner/Spinner";
import TableRow from "react/parkable-components/tableRow/TableRow";
import React from "react";
import { useBaysAvailableToUser } from "react/api/bay/bay.api";
import { useBayGroup } from "react/api/bayGroup/bayGroup.api";
import { useTandemPod } from "react/api/tandem/tandem.api";
import Strings from "../../../constants/localization/localization";
import { Nully } from "react/constants/nully";
import { Routes } from "react/navigation/root/root.paths";
import { ActivityOrigin } from "react/model/ActivityOrigin";
import { Bay } from "react/model/Bay";
import { Feature, ParkingType } from "react/model/Types";
import { useNavigation } from "react/navigation/constants";
import { getBaySignage } from "react/constants/baySignage";

export type ChangeableBayRowProps = {
    currentBay: Bay;
    organisationId?: number | null;
    onChange: (bayId: Nully<number>) => any;
    hideEVBays: boolean;
    changeBayOptions: {
        parkId: number;
        parkingType?: ParkingType;
        requestDate?: string;
        feature?: Feature;
        origin?: ActivityOrigin;
    };
};

const ChangeableBayRow = (props: ChangeableBayRowProps) => {
    const { currentBay, organisationId, onChange, changeBayOptions, hideEVBays } = props;
    const navigation = useNavigation();
    const { bays, isLoading } = useBaysAvailableToUser(changeBayOptions.parkId, {
        feature: changeBayOptions.feature,
        origin: changeBayOptions.origin,
        requestDate: changeBayOptions.requestDate,
    });
    const { bayGroup } = useBayGroup(organisationId, currentBay.group);
    const { tandemPod } = useTandemPod(currentBay?.tandemPod);

    if (isLoading) {
        return (
            <TableRow iconLeft="baysign" iconLeftProps={{ color: Colours.ORANGE }}>
                <Spinner small />
            </TableRow>
        );
    }

    const handleChangePress = () => {
        const availableBays = hideEVBays ? bays?.filter((b) => !b.evse) : bays;
        const params = {
            title: Strings.select_bay,
            showAllButton: true,
            baysAvailable: availableBays ?? [],
            onBaySelected: (bay: Bay) => onChange(bay.id),
            bay: currentBay,
            parkId: changeBayOptions.parkId,
            parkingType: changeBayOptions?.parkingType ?? ParkingType.CASUAL,
        };
        navigation.push(Routes.SelectBay, params);
    };

    const { rowLabel, label } = getBaySignage(currentBay, bayGroup, tandemPod);

    return (
        <TableRow
            iconLeft={"baysign"}
            iconLeftProps={{ color: Colours.ORANGE }}
            label={rowLabel}
            chevronText={Strings.change}
            chevron
            onPress={handleChangePress}
        >
            {label}
        </TableRow>
    );
};

export default ChangeableBayRow;
