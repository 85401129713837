/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 15/01/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

// @ts-ignore
import moment, { Moment } from "moment";
import { Term } from "../../../model/Term";
import { ISomeDay } from "../../../model/Types";
import { Nully } from "../../nully";
import Strings from "react/constants/localization/localization";

// ONLY CAPITALISE THE FIRST LETTER OF A SENTENCE || CAR PARK IS TWO WORDS || ONLY USE 'PARK' INSTEAD OF 'CAR PARK' WHERE BUTTON SPACE IS LIMITED

const formatNumberAsString = (n: number) =>
    ["zero", "one", "two", "three", "four", "five", "six", "seven"][n] ?? `${n}`;

const en = {
    refine_location: "Refine location",
    customise_display_name: "Customise display name",
    display_name: "Display name",
    return_to_map: "Return to map",
    view_anyway: "View anyway",
    carpark_is_full: "Car park is full",
    please_do_not_park_here: "Please do not park here",
    i_can_see_empty_bay_title: "What if I can see an empty bay?",
    i_can_see_empty_bay_body: " - the bay has been reserved and the driver will arrive soon.",
    session_at_another_parkable_location_title: "What if I have started a session at another Parkable location?",
    session_at_another_parkable_location_body: " - your session is valid for that location only.",
    my_vehicle_is_parking_here_title: "My vehicle is currently parking at this location.",
    my_vehicle_is_parking_here_body_red: "Return to your vehicle and move it immediately",
    my_vehicle_is_parking_here_body: " - Failure to do so may result in your vehicle being towed.",
    checkEmail: "Check your email inbox",
    passwordResetSuccess: "Check your inbox! A link to reset your password has been sent to ",
    checkSpam: "If you don't see a message in your inbox, please check your spam filter.",
    resend: "Resend",
    send_reset_link: "Send reset link",
    _bay: "bay",
    _bays: "bays",
    _price_per_day: "Price per day",
    _total: "Total",
    accept_invitation: "Accept invitation",
    account: "Account",
    receive_payments: "Receive payments",
    account_settings: "Account settings",
    action_settings: "Settings",
    active: "Active",
    active_sessions: "Active sessions",
    add: "Add",
    add_additional_operating_hours: "Add additional operating hours",
    add_car: "Add car",
    add_carpark: "Add car park",
    add_credit_card: "Add new credit card",
    add_new: "+ Add new",
    add_vehicle: "Add vehicle",
    vehicle_already_exists: "This vehicle already exists",
    remove_vehicle: "Remove vehicle",
    added: "added",
    address: "Address",
    address_placeholder: "e.g. 1 Sesame St, CBD",
    addressing_complaint: "Reporting problem",
    admin: "Admin",
    all_day: "All day",
    already_a_member: "Already a member?",
    already_registered: "Already registered?",
    and: "and",
    app_name: "Parkable",
    approval_help: "Your car park will be reviewed very soon.  Thanks for hosting with Parkable.",
    are_you_sure_cancel_subscription: "Are you sure you wish to cancel your subscription?",
    are_you_sure_cancel_subscription_at_end_of_term:
        "Are you sure you want to cancel your subscription? Your subscription will end after one complete subscription term.",
    are_you_sure_cancel_subscription_immediately:
        "Are you sure you want to cancel your subscription? It will end immediately!",
    are_you_sure_cancel_pending_subscription: "Are you sure you want to cancel your pending subscription?",
    are_you_sure_logout: "Are you sure you want to log out?",
    at: "at",
    automatically_book_same_park: "Automatically book the same park every day",
    avail_rule_updated: "Availability rule has been updated.",
    avail_rules_updated: "Availability rules have been updated.",
    availability: "Availability",
    available: "Available",
    available_all_day: "Available all day",
    available_until: "Park available until:",
    average_rating: "Average rating",
    awaiting_approval: "Awaiting approval",
    awesome_news: "Awesome news!",
    b: "click here",
    next_page: "Next page",
    back: "Back",
    bank_account: "Bank account number",
    bsb_number: "BSB number",
    bay: "Bay",
    bay_number: "Bay number",
    bays: "Bays",
    by_clicking_confirm_you_have_read:
        "By clicking confirm you have read and accept both the parking instructions above and the ",
    by_signing_up: "I have read and agreed to Parkable's",
    calendar: "Calendar",
    cancel: "Cancel",
    cancel_booking: "Cancel booking",
    cancel_request: "Cancel request",
    are_you_sure_you_want_to_cancel_your_request: "Are you sure you want to cancel your request?",
    are_you_sure_you_want_to_cancel_your_booking: "Are you sure you want to cancel your booking?",
    cancel_reservation: "Cancel reservation",
    are_you_sure: "Are you sure?",
    cancel_subscription: "Cancel subscription",
    confirm_cancellation: "Confirm cancellation",
    cant_find_bay: "I can’t find my bay",
    card: "Card",
    card_cvc_invalid: "The CVC number you have entered is invalid.  Please check and try again.",
    card_details: "Card details",
    card_expiry_invalid: "Expiry date is in the past.  Please check and try again.",
    card_help_body:
        "Parkable makes paying for parking so much easier. The next time you finish using our service, all you need to do is press 'Stop parking' and we will do all the calculations and payments for you!  How great is that?",
    card_help_title: "Why do you need my credit card details?",
    card_number: "Card number",
    card_number_invalid: "Card number is invalid. Please check and try again.",
    card_type: "Card type",
    card_types: ["Mastercard", "Visa"],
    casual_parking: "Casual parking",
    centre_map: "Centre map",
    change: "Change",
    change_your_password: "Change your password",
    check: "Check",
    check_my_vehicles: "Check my vehicles",
    check_operating_hours: "Please check operating hours",
    check_times: "Check times",
    choose_another_bay: "Choose another bay",
    choose_server: "Choose server",
    live: `Live`,
    demo: `Demo`,
    demo_server: `Demo server`,
    demoExplanation: `Please note, the demo server is for product testing only; no money is exchanged, and parking on the demo server is not valid for real-world parks.\n\nOnce you have finished testing you will need to change back to the Live server to park normally.\n\nThe app will restart upon selection.`,
    click_here: "Click here",
    close: "Close",
    closed: "Closed",
    sorry_this_park_is_closed: "Sorry this park is currently closed",
    closes: "Closes",
    closes_at: "Closes at ",
    closing_time: "Closing time",
    closing_time_: "Closing time: ",
    colleague_let_down: "Someone has let you down!",
    company: "Company",
    company_details: "Company details",
    company_name: "Company name",
    company_number: "Company number",
    complete: "Complete",
    confirm: "Confirm",
    go_back: `Go back`,
    confirm_booking: "Confirm booking",
    confirm_end_parking: "Please confirm that you would like to end your parking session",
    confirm_end_reservation: "Please confirm that you would like to end your reservation",
    confirm_parking: "Confirm parking",
    confirm_request: "Confirm request",
    confirm_reservation: "Confirm reservation",
    confirm_subscription: "Confirm Subscription",
    connection_problem:
        "There is an issue with your data connection.  Please check that data is turned on and that you have enough credit.",
    cool: "Cool",
    create_account: "Create account",
    create_account_button_label: "Create account",
    create_carpark: "Create car park",
    create_park: "Create car park",
    credit: "Credit",
    credit_card_: "Credit card:",
    crop_image: "Crop image",
    current_password: "Current password",
    currently_available: "Currently available",
    currently_available_until: "Currently available until: ",
    currently_closed: "Currently closed",
    currently_full: "Reserved or full",
    currently_open: "Currently open",
    cvv: "CVV",
    date_of_birth: "Date of birth (dd/mm/yyyy)",
    day: "day",
    days: "days",
    delete: "Delete",
    delete_account: `Delete my account`,
    are_you_sure_you_want_to_delete_your_account: `Are you sure you want to delete your Parkable account?`,
    delete_card: `Delete card`,
    delete_card_question: "Please confirm that you would like to delete this credit card",
    delete_vehicle: "Delete vehicle",
    delete_vehicle_question: "Please confirm that you would like to remove vehicle",
    details: "Details",
    details_instructions: "Details / instructions",
    did_you_forget_to_stop_parking_question: "Did you forget to stop parking?",
    directions: "Directions",
    discount: "Discount",
    only_pay_for_the_time_youre_parking_and_nothing_more: `Only pay for the time you're parking and nothing more`,
    enabled_explanation: `Enabled: you will receive notifications every 12 hours when parking with the option to continue`,
    disabled_explanation: `Disabled: you won't receive these notifications and your sessions will continue until you end them`,
    by_disabling_this_feature_i_accept_responsibility: `By disabling this feature you accept all responsibility for ending your sessions`,
    auto_end_sessions: `Auto end sessions`,
    disabled: `Disabled`,
    enabled: `Enabled`,
    display_carpark: "Display car park",
    display_price_per_day: "Display price per day",
    display_price_per_hour: "Display price per hour",
    display_spots_remaining: "Display spots remaining",
    distance_suffix: "m away",
    do_not_park_in_your_bay: "Do not park in your bay today",
    done: "Done",
    edit: "Edit",
    edit_card_details: "Edit card details",
    edit_credit_card: "Edit credit card",
    edit_park: " Edit car park",
    edit_vehicle: "Edit vehicles",
    email: "Email address",
    email_: "Email: ",
    email_or_username: "Email or username",
    email_taken_toast: "Email is already taken, please reset your password instead",
    end_parking: "End parking",
    end_reservation: "End reservation",
    ended: "Ended",
    ended_due_to_unpaid: "Ended due to payment failure",
    enjoy_your_day: "Enjoy your day!",
    ensure_details_are_correct: "Please ensure the following details are kept up to date.",
    enter_address: "Enter address",
    enter_your_email: "ENTER YOUR EMAIL ADDRESS",
    enter_first_name: "Please enter your name",
    enter_last_name: "Please enter your last name",
    enter_other_price: "Custom price",
    enter_their_plate_number: "Enter the number plate of the vehicle occupying your bay.",
    type_number_plate_here: `Type number plate here...`,
    enter_voucher: "Enter Voucher",
    enter_voucher_code: "Enter voucher code",
    error: "Error",
    error_loading_image: "Error loading image",
    error_persists: "If this continues to happen please contact Parkable support.",
    error_persists_contact_org: "Your access has been removed. Please contact your organisation.",
    contact_parkable_support: "Please contact Parkable support.",
    error_processing_image: "Error processing image",
    your_last_payment_failed_please_retry: "Your last payment failed, please retry",
    error_taking_payment: "Error taking payment: ",
    expiry_date: "Expiry date",
    failed_to_delete_card: "Failed to delete card.",
    filter: "Filter",
    find: "Find",
    find_alternative_parking_and_talk_to_team_lead:
        "You'll need to find a bay outside this parking lot - try checking the map for green pins! Speak to your admin team about how to limit parking mishaps in the future.",
    find_casual_park: "Find casual park",
    find_longterm_park: "Find longterm park",
    find_nearest_park: "Find a park near me",
    fine_tune_instruction: "Tap and slide around the clock to fine tune availability",
    first_name: "First name",
    first_name_: "First name: ",
    for_long_term_parking: "For long-term parking",
    forgot_login_or_password: "Forgot login or password",
    forgot_password: "Forgot password",
    forgot_password_button_label: "Forgot password",
    forgot_username_button_label: "Forgot username",
    four_wheel_bays_reserved_or_full: "4 wheel bays reserved or full",
    four_wheels: "4 wheels",
    free: "Free!",
    free_parking: "Free parking",
    free_session: "Free session",
    fri: "Fri",
    friday: "Friday",
    general_subscription_terms: "General subscription terms",
    get_directions: "Get directions",
    get_money_get_paid: "Get money, get paid. We need your details",
    get_started: "Get started!",
    getting_paid: "Getting paid",
    getting_paid_instructions:
        "Simply fill out the form on the next page and Parkable will be able to pay you each time somebody uses your park.",
    go: "Go!",
    go_: "Go",
    gst_number_NZ: "GST number (Optional)",
    gst_number_AU: "ABN number (Optional)",
    hello_world: "Hello world!",
    help: "Help",
    hide_nearest_parks: "Hide nearest parks",
    host_ending_subscription: "Host ending subscription",
    hosting: "Hosting",
    hour: "hour",
    hours: "hours",
    minutes: "minutes",
    minute: "minute",
    hr: "hr",
    hrs: "hrs",
    how_many_bays_available: "How many bays will be available?",
    i_have_a_problem: "I have a problem",
    i_have_read_and_accept_the_: "I have read and accept the ",
    i_have_read_and_accept_the_Subscription_Terms_above: "I have read and accept the subscription terms above",
    i_want_to_park_here: "I want to park here today",
    i_want_to_park_tomorrow: "I'd like to park tomorrow",
    ie_abc123: "i.e. ABC123",
    ignore: "Ignore",
    im_not_using_bay: "I’m not using my bay",
    im_using_bay: "I’m using my bay",
    in: "In",
    incomplete: "Incomplete",
    individual: "Individual",
    info: "Info",
    internal_error_if_persists:
        "An internal server error has occurred.  If this issue persists please contact Parkable support.",
    invalid_email: "Invalid email",
    invalid_email_toast: "Email is invalid, please correct it and try again",
    invalid_password: "Invalid password",
    invitation_cancelled: "Invitation cancelled",
    invited: "Invited",
    keep_session_going: "Keep session going",
    key_locations: "Key locations",
    km_distance_suffix: "km away",
    last_name: "Last name",
    last_name_: "Last name: ",
    learn_more: "Learn more",
    lets_do_this: "Let's do this",
    license_plate: "License plate:",
    loading: "Loading...",
    loading_card: "loading cards..",
    location: "Location ",
    location_: "Location",
    log_in: "Log in",
    log_out: "Log out",
    logged_out: "Logged out",
    logged_out_body: "You have been logged out.  Please log in again to continue.",
    login: "Log in",
    you_have_a_failed_payment: `You have a failed payment that needs resolving before your booking can start.`,
    resolve_payment: `Resolve payment`,
    login_help_email_input_hint: "Email",
    login_help_email_sent: "Please check your email for instructions on how to access your account",
    login_help_instructions: "Enter your email to recover your password",
    login_help_login_again_button_label: "Back to log in",
    login_help_submit_button_label: "Submit",
    login_help_submit_failed_unknown: "Failed to request password reset, please try again",
    login_warning_parkable_login_failed: "Parse username/password log in failed, exception: ",
    login_warning_parse_signup_failed: "Parse sign up failed, exception: ",
    login_warning_password_reset_failed: "Parse password reset failed, exception: ",
    logout: "Log out",
    long_term_desc: "Parkable members can now find long-term parking agreements through our platform",
    long_term_introduction:
        "Great news! Parkable members can now book the same park every day with one of our flexible long-term parking subscription plans. All plans automatically roll over (unless requested not to) so parking with Parkable is easier than ever!",
    long_term_parking: "Long-term parking",
    long_term_parking_subs: "Long-term parking subscriptions",
    long_term_toggle_text: "Only show properties with long-term parking bays",
    make_bay_avail: "Share my park",
    map_filter: "Map filter",
    map_pref_pin_display: "map_pref_pin_display",
    map_pref_show_long_term_only: "map_pref_show_long_term_only",
    map_pref_show_unavailable: "map_pref_show_unavailable",
    menu: "Menu",
    message_admin: "Message admin",
    message_car_owner: "Message car owner",
    message_host: "Message host",
    message_organisation: `Message organisation`,
    contact_organisation: (title: string) => `Contact ${title}`,
    message_parkable: "Message Parkable",
    message_support: "Message support",
    messages: "Messages",
    min: "min",
    mins: "mins",
    mon: "Mon",
    mon_fri_revolving_subscription: "Mon-Fri recurring subscription",
    formatAllocationHourOfDay: (allocationHourOfDay: number) =>
        allocationHourOfDay < 11
            ? `${allocationHourOfDay + 1}am`
            : `${allocationHourOfDay === 11 ? "12" : allocationHourOfDay - 11}pm`,
    dayOfWeekForIndex: (dayIndex: number) =>
        ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"][dayIndex] ?? "",
    monday: "Monday",
    monthly_revolving_subscription: "Monthly recurring subscription",
    my_hosted_parks: "My hosted parks",
    name_on_card: "Name on card",
    navigate: "Navigate",
    nearest_to_me: "Nearest to me",
    need_details_for_payment: "Click to update your details and ensure you are paid",
    new_bay_found: "An alternative bay has been found for you to use, the new bay is: ",
    new_bay_found_subscription:
        "A temporary bay has been found for you to use today and we have created a session for you in it. You won't be charged for this session. The new bay is: ",
    new_credit_card_number: "New credit card number",
    new_password: "New password",
    new_password_again: "New password again",
    new_password_cant_be_the_same: "New password can't be the same as your old password",
    next: "Next",
    no: "No",
    no_active_session: "Currently no active session",
    no_card_configured:
        "You currently have no bank card configured. Please configure a valid card in the account panel.",
    no_card_configured_error:
        "You do not have a credit card configured. You will need to configure one before parking.",
    no_cards: "No cards",
    no_email_toast: "Please enter your email",
    no_further_details: "No further details",
    no_hosted_parks: "No hosted parks",
    no_hosted_parks_text:
        "Make some spare cash by adding your car park onto Parkable. You will be notified via the App (you will see their name and their plate number) when somebody is parking in your space. ",
    no_password_toast: "Please enter your password",
    no_thanks: "No thanks",
    select_vehicles_for_subscription: `Select your vehicles for the subscription`,
    getSubscriptionVehiclesHelperText: (vehicleLimit: number) => {
        return vehicleLimit === 1
            ? `This subscription has a vehicle limit of ${vehicleLimit}. Select your vehicle for the subscription.`
            : `This subscription has a vehicle limit of ${vehicleLimit}. Select your vehicles for the subscription.`;
    },
    no_username_toast: "Please enter your username",
    no_vehicle_configured_error: "You do not have a vehicle configured.  Please configure one before parking.",
    no_vehicles: "No vehicles",
    not_a_member: "Not a member yet?",
    not_all_fields_configured: "All fields must be completed before saving park.",
    not_connected_to_network:
        "You do not currently have data connectivity turned on. Parkable requires an active internet connection to function.",
    not_currently_available: "Not currently available",
    not_currently_available_until: "Not currently available until: ",
    note_: "Note: ",
    number_of_bays: "Number of bays",
    bays_available: "bays available",
    bayAvailable: "bay available",
    availableBays: (numberOfBays: number) =>
        `${numberOfBays} bay${numberOfBays > 1 || !numberOfBays ? "s" : ""} available`,
    number_of_parks: "Number of parks",
    object_in_my_bay: "An object is in my bay",
    off: "Off",
    ok: "Ok",
    on: "On",
    one_month: "1 month",
    one_week: "1 week",
    only_long_term: "Only show properties with long-term parking bays",
    oops_please_use_only_alphanumeric_characters: 'Oops, please use alphanumeric characters, "-" or "/"',
    sorry_registration_is_blocked: "Sorry, this vehicle has been blocked. Please contact admin team for assistance.",
    open: "Open",
    open_the_gate_at: "Open the gate at ",
    open_247: "Open 24/7",
    open_247_num: "24/7",
    open_7_days: "Open 7 days",
    open_session_was_ended: "Please note that your parking session has been ended with no charge.",
    opening_time: "Opening time",
    opening_time_: "Opening time: ",
    opens_at: "Opens at",
    operating_hours: "Operating hours",
    other: "Other",
    outstanding_balance: "You have an outstanding balance for the following parking session:",
    park: "Park",
    park_availability: "Park availability",
    park_created: "Park created",
    park_details: "Park details",
    park_in_bay: "Park in bay: ",
    park_in_bay_number: "You can park in the following bay number:",
    park_in_correct_bay: "Please park in the correct bay",
    park_instructions: "Park instructions",
    park_location: "Park location",
    park_tomorrow: "Park tomorrow",
    park_updated: "Park updated",
    park_with_any_vehicle: "Park using any of your vehicles",
    park_with_correct_vehicle: "Please ensure you drive the correct vehicle",
    parkable_terms_of_service: "Parkable terms of service",
    parker_book_your_bay: "A parker has booked your bay today",
    parking: "Parking",
    parking_history: "Parking history",
    parking_instructions: "Parking instructions",
    parking_request_cancelled: "Parking request cancelled",
    parking_session_ended: "Your parking session has ended",
    parking_session_started: "Your parking session has been automatically started",
    parks: "Parks:",
    parks_in_use: "Parks in use:",
    parks_near_me: "Parks near me",
    parks_you_host: "Parks you host",
    parse_login_button_label: "Log in",
    parse_login_failed_unknown_toast: "Could not log in, please try again later",
    parse_login_invalid_credentials_toast: "The username and password you entered don't match",
    parse_signup_button_label: "Sign up",
    password: "Password",
    password_: "Password: ",
    password_email_sent: "An email has been sent with instructions on how to reset your password",
    password_input_hint: "Password",
    password_successfully_changed: "Password changed successfully",
    passwords_dont_match: "Passwords must match",
    payment_error_occurred_if_persist_contact:
        "An error occurred processing your payment. If this problem persists, please contact Parkable support.",
    payment_date: "Payment date",
    payment_type: "Payment type",
    payment_failed: "Payment failed",
    booking_failed: "Booking failed",
    organisation_id_required: "Organisation id is required to make this booking",
    youve_already_got_a_request_on_this_day: "You’ve already got a request on this day. View my bookings and requests",
    booking_unavailable_park_is_open: "Future booking is no longer available once the car park opens. Try starting a session instead!",
    request_successful: "Request successful",
    booking_confirmed: "Booking confirmed",
    added_to_waitlist: "Added to the waitlist",
    auth_failure_explanation:
        "Authentication has not completed successfully.  You will need to authenticate this payment to continue using Parkable.",
    auth_failure_again: "Authentication has failed.  If this problem persists, please contact Parkable support.",
    payment_successful: "Your payment was successfully processed.",
    per: "per",
    per_month: "/month",
    per_week: "/week",
    permanently_available: "Permanently available",
    perMonth: "per month",
    perWeek: "per week",
    pfb_here_is_your_bay: "Here is your bay number",
    pfb_here_is_your_bay_text:
        "Note: Please check that you are parking in the correct bay and only during the hours assigned to you.",
    pfb_how_long_can_i_park: "How long can I park here?",
    pfb_how_long_can_i_park_text: "This bay will close at",
    pfb_park_tomorrow: "I’d like to park tomorrow",
    pfb_park_tomorrow_text:
        "Your organisation lets you reserve a park one day in advance. Check your details and tap ‘Confirm request’ to see if a park is available!",
    pfb_request_accepted: "Request accepted!",
    see_bay_below: "See bay below",
    check_app_later_to_see_bay:
        "You will receive your bay number 5 minutes before the park opens, please check back here again then.",
    pfb_request_accepted_text: "Your request has been successful. ",
    pfb_request_accepted_text_time: "Please confirm booking before 7am.",
    pfb_request_received: "Request received",
    pfb_request_received_text: "Your request has been received. We will notify you if you are successful.",
    pfb_successful:
        "You will notified via the app and email. You will need to accept the invitation before 7am or the bay will be made available for someone else.",
    pfb_what_happens_when_i_confirm: "What happens when I confirm my booking?",
    pfb_what_happens_when_i_confirm_text:
        "You will be charged $15. You will be given a bay number. The bay will be available for you between 7am - midnight.",
    pfb_when_can_i_park_text: "When can I park here?",
    pfb_when_will_i_know: "When will I know if I am successful by?",
    phone_number: "Phone number",
    phone_number_: "Phone number: ",
    phone_number_error: "Intl. format required eg. ",
    photos: "Photos",
    plate_number: "Plate number",
    please_check_bay_number: `Please check bay number`,
    please_check_you_are_trying_to_park: `Please check that you are trying to park in the correct bay.`,
    please_enter_voucher_code: "Please enter a voucher code",
    please_enter_year: "Please enter the expiry year",
    please_load_card: "Please load card",
    please_park_here: "Please park here",
    price_per_day: "Price per day",
    price_per_day_suffix: "/24h",
    price_per_hour: "Price per hour",
    price_per_subscription_term: "Price per subscription term:",
    price_per_term: "Price per term",
    privacy_policy_here: "Privacy policy here",
    privacy: "Privacy",
    problem_q: "Problem?",
    problem_text: "If you have a problem with the app please ",
    processing: "Processing",
    progress_dialog_text: "Loading...",
    quick_set_all_days: "Quick set all days",
    rate: "Rate:",
    rate_this_park: "Rate this park",
    recent: "Recent",
    recent_parks: "Recent parks:",
    referral_code: "Referral Code",
    refine_operating_hours: "Refine operating hours",
    remaining: "Remaining",
    remove: "Remove",
    removed: "removed",
    repeat_password: "Re-enter your password",
    request_a_Bay: "Request a bay",
    request_bay: "Request a bay",
    reservation: "Reservation",
    reservation_cancelled: "Reservation cancelled",
    reservation_expired: "Reservation expired",
    reservation_time: "Reservation time:",
    reservation_timed_out: "Reservation cancelled/timed out",
    reserve_park: "Reserve park",
    reserved_or_full: "Reserved or full",
    reset_password: "Reset password",
    reset_password_description:
        "Enter the email address associated with your account, and we'll email you a link to reset your password",
    resolve: `Resolve`,
    retry: "Retry",
    retry_transaction: "Retry transaction",
    authenticate_transaction: "Authenticate payment",
    right_on: " Right on! ",
    running_total: "Running total",
    sat: "Sat",
    saturday: "Saturday",
    save: "Save",
    saving: "Saving...",
    search: "Search",
    search_address: "Search or enter address",
    search_for_alternative_park: "Search for an alternative park",
    search_results: "Search results:",
    select_plan: "Select plan",
    select_closing_time: "Select closing time",
    select_company_type: "Select Company Type",
    select_opening_time: "Select opening time",
    select_photos: "Select photos",
    select_price: "Select price",
    select_price_24_hours: "Select price per 24 hours",
    select_price_per_day: "Select price per day",
    select_price_per_hour: "Select price per hour",
    select_time: "Select time",
    select_time_: "Select time:",
    select_vehicle_size_: "Select vehicle size:",
    select_your_current_credit_card: "Select your current credit card",
    select_your_current_vehicle: "Select your current vehicle",
    selected_vehicle: "Selected vehicle",
    send: "Send",
    sorry_we_could_not_find_the_required_payment_information: `Sorry, we could not find the required payment information. Please contact Parkable for assistance`,
    session_already_ended: "This parking session was ended on another device.",
    session_completed: "Session completed",
    session_details: "Session details",
    session_ended: "Session ended",
    session_ended_: "Session ended:",
    session_finished: "Session finished:",
    session_history: "Session history",
    session_started: "Session started",
    session_started_: "Session started:",
    session_still_running: "Session still running",
    session_was_auto_closed: "Session was auto-closed",
    session_was_auto_closed_details:
        "Your session was auto-closed. We stop all parking sessions after 13 hours. If you are still parking please start a new session.",
    set_availability: "Set availability",
    settings: "Settings",
    settings_no_colon: "Settings",
    share_my_bay: "Share my bay",
    share_bay: "Share bay",
    unshare_bay: "Unshare bay",
    unshare: "Unshare",
    could_not_unshare: "Cannot unshare",
    choose_sharing_duration: "Choose sharing duration",
    choose_days: "Choose days",
    must_be_a_number: "Must be a number",
    max_number: (number: number) => `Max. ${number}`,
    sharing_my_bay: "Sharing my bay",
    show_all: "Show all",
    show_unavailable_pins_on_map: "Show unavailable pins on map",
    sign_up: "Sign up",
    signup_agreement: "By signing up to Parkable you are agreeing to our terms and conditions.",
    something_wrong_with_bay: "There is something wrong with my bay",
    sorry_no_available_bays: "We're very sorry, no bays are available at present.",
    sorry_no_other_available_bays: "Sorry, there are no more parking bays available.",
    sorry_we_are_unable_to_show_this_view: `Sorry, we are unable to show this view`,
    sort_it_later: "I'll sort it later (car parks will be switched off)",
    special_offer: "Special offer",
    start_parking: "Start parking",
    view_reservation: "View reservation",
    open_gate_start_parking: "Open gate & start parking",
    gate_out_of_range: "Gate out of range",
    confirm_and_open_gate: "Confirm & open gate",
    start_search: "Start search",
    start_subscription: "Start subscription",
    status: "Status:",
    still_parking: "You have been parking for 24 hours - did you mean to stop this parking session?",
    stop_parking: "Stop parking",
    contact_support: "Contact support",
    charger_is_offline: "Charger is offline",
    charger_is_offline_description:
        "Sorry, the EV charger you're trying to use is currently offline. Please try again later, book another parking space, or contact support for assistance.",
    submit: "Submit",
    subs_available_where: "Subscriptions are available on parks wth the following icons",
    subscription_confirmed: "Subscription confirmed",
    subscription_ended_on: "Subscription ended on",
    subscription_ending: "Subscription ending:",
    subscription_ending_on: "Subscription ending on:",
    subscription_not_created: "Sorry, a subscription could not be created: ",
    subscription_payment_failed: "Subscription payment failed",
    subscription_renews_on: "Subscription renews on:",
    subscription_started_: "Subscription started:",
    subscription_terms: "Subscription terms",
    subscription_updated: "Subscription updated",
    subscription_cancelled: "Subscription cancelled",
    subscriptions: "Subscriptions",
    subscriptions_only: "Showing subscription parks",
    success: "Success",
    successful_applicants_will_be: " ",
    sun: "Sun",
    sunday: "Sunday",
    tap: "Tap",
    term_length: "Term length",
    terms_conditions: "Terms and conditions",
    terms_condition_update: "Update to our terms of service",
    terms_condition_update_label: "Before you can continue you must read and accept the new ",
    terms_condition_update_label_link: "terms of service.",
    terms_condition_update_checkbox: "I have read and agree to the updated terms of service",
    terms_of_service: "Terms of service",
    thank_you_transaction_successful: "The transaction was successful. Thank you for using Parkable.",
    thanks_for_parking: "Thanks for parking with Parkable",
    thanks_for_using_parkable: "Thanks for using Parkable!",
    their_plate_number: "Their plate number",
    this_carpark_is: "This car park is",
    this_park_will_open_at: "This park will open at ",
    this_park_will_open_on: "This park will open on ",
    this_park_will_open_tomorrow: "This park will open tomorrow at ",
    thu: "Thu",
    thurs: "Thurs",
    thursday: "Thursday",
    time: "Time",
    time_elapsed_: "Time elapsed:",
    time_elapsed: "Time elapsed",
    timeout_occurred: "Server timed out.  If this issue persists please contact Parkable support.",
    times: "Times",
    tip: "Tip:",
    title_activity_account: "Account activity",
    title_activity_availability: "AvailabilityActivity",
    title_activity_bank_account: "BankAccountActivity",
    title_activity_conversation_list: "ConversationListActivity",
    title_activity_create_park: "Create car park",
    title_activity_credit_card_setup: "CreditCardSetupActivity",
    title_activity_details: "DetailsActivity",
    title_activity_fine_tune: "Fine tune",
    title_activity_image_selection: "ImageSelectionActivity",
    title_activity_message_list: "MessageListActivity",
    title_activity_park_detail: "ParkDetailActivity",
    title_activity_park_location: "ParkLocationActivity",
    title_activity_parks_map: "Available parks",
    title_activity_voucher: "Vouchers",
    today: "Today",
    total_: "Total:",
    total_cost: "Total cost",
    try_again: "Try again",
    tue: "Tue",
    tues: "Tues",
    tuesday: "Tuesday",
    tuning_tip: "Tip: You can select multiple time blocks by tapping on different sections",
    turn_park_off: "Turn park off",
    turn_park_on: "Turn park on",
    two_wheel_bays_reserved_or_full: "2 wheel bays reserved or full",
    two_wheels: "2 wheels",
    type_helpful_info_here: "Type helpful information or other details here",
    unable_to_find_date: "Unable to find date",
    unavailable: "Unavailable",
    unfortunately_no_alternative_bays: "Unfortunately there are no alternative bays available for you to park in.",
    unknown_error_occurred: "An unknown error has occurred",
    unknown_status: "Unknown status",
    update_carpark: "Update car park",
    uploading_photos: "Uploading photos, please wait...",
    use_any_vehicle: "You can park here using any one of your registered vehicles",
    user_ended_subscription: "User ending subscription",
    username: "Enter a username",
    username_: "Username: ",
    username_email_sent: "An email has been sent with your username.",
    username_input_hint: "Username",
    username_taken_toast: "Username is already taken, please choose a different one",
    valid_til: "Valid til",
    vehicle_help_body:
        "Parkable makes parking so much easier for both the parker and the host. The next time you use our service we will send your details to the host so they will know your car is supposed to be there.  How great is that?",
    vehicle_help_button: "Nice one!",
    vehicle_help_title: "Why do you need my car registration details?",
    vehicle_in_my_bay: "A vehicle is in my bay",
    vehicle_registration: "Vehicle registration",
    vehicles: "Vehicles",
    view: "View",
    view_details: "View details",
    view_park: "View park",
    view_session: "ViewSession",
    view_subscription: "View subscription",
    view_invoice: "View invoices",
    voucher: "Voucher",
    voucher_applied: "Voucher applied!",
    voucher_codes: "Voucher codes",
    voucher_help_body:
        "Using a voucher is super easy. The next time you park with Parkable, the app will automatically search through your vouchers and redeem them on your behalf. Parking made simple!",
    voucher_help_title: "How do I use a voucher?",
    voucher_used: "(Voucher used)",
    vouchers: "Vouchers",
    warning_reminder_running_session:
        "A friendly reminder that you have a session running. If you are still parking here please tap the button below to keep your session going.",
    wed: "Wed",
    wednesday: "Wednesday",
    weekly_revolving_subscription: "Weekly recurring subscription",
    welcome_sub_text: "Welcome to the future of parking",
    welcome_text: "Hello!",
    what_if_successful: "What happens if I am successful?",
    wheels2: "2 Wheels",
    wheels4: "4 Wheels",
    whoops: "Whoops!",
    unable_to_change_vehicle: "Unable to change vehicle",
    unable_to_change_bay: "Unable to change bay",
    unable_to_redeem_reward: "Unable to redeem reward",
    will_this_park_be_open: "Will this car park be open?",
    will_this_park_be_open_all_day: "Will this car park be open all day?",
    will_this_park_be_operating_24_7: "Will this car park be operating 24/7?",
    would_you_like_to_subscribe_to_this_bay: "Would you like to subscribe to this bay?",
    yes: "Yes",
    unable_to_send_message: "Unable to send message",
    new_message: "New message",
    copied: `Copied`,
    tandem_parking: {
        tandem: "Tandem",
        tandem_chat: "Tandem chat",
        tandem_parking: "Tandem parking",
        tandem_bays: "Tandem bays",
        allocated: "You've been allocated a tandem bay",
        information_para1: "This means you’ll be sharing a park with other members of your organisation.",
        information_para2:
            "To arrange leaving times and to move vehicles, you'll be able to communicate through the Parkable app.",
        estimated_leave_time: "Estimated leave time",
        leave_time_dont_know: "I don’t know yet",
        estimated_leave_time_info:
            "Let other parkers in the tandem bays know when you plan on leaving the car park. You can change this at any time during your session.",
        estimated_leave_time_dropdown_label: "When do you think you might leave the car park?",
        error_est_leave_time: "There was an error in updating your estimated leave time. Please try again.",
        number_other_parkers: (participants: number) =>
            participants > 0 ? `${participants} other parkers` : `No other parkers`,
        open_chat: "Open chat",
        view_tandem_chat: "View tandem chat",
        contact_tandem_parkers: "Contact tandem parkers",
        your_contact_details_are_shared: "Your contact details are shared",
        your_contact_details_are_not_shared: "Your contact details are not shared",
        org_does_not_allow_contact_sharing: "Your organisation does not allow contact details to be shared",
        left_at: (time: string) => `Left at ${time}`,
        leaving_around: (time: string) => `Leaving around ${time}`,
        leave_time_unknown: "Leave time unknown",
        hide_inactive_parkers: `Hide inactive parkers`,
        show_inactive_parkers: `Show inactive parkers`,
    },
    privacy_settings: {
        privacy_settings: "Privacy settings",
        settings_intro_1:
            "When you park in a tandem bay, you can choose to display your email address and phone number to whoever is sharing your tandem as another way to get in touch.",
        settings_intro_2: "Choose which contact details to share below.",
    },
    you_are_currently_parking: "You are already parking",
    you_can_refine_on_next_screen: "(You can refine each day on next screen)",
    you_can_start_parking_here_in: (days: number, hours: number, mins: number) => {
        const parts = [] as string[];
        if (days > 0) {
            parts.push(`${days} days`);
        }
        if (hours > 0) {
            parts.push(`${hours} hours`);
        }
        const parts2 = [parts.join(", ")].filter((s) => !!s);
        if (mins > 0) {
            parts2.push(`${mins} minutes`);
        }
        return `You can start parking here in ${parts2.join(" and ")}`;
    },
    you_can_start_parking_here_tomorrow: "You can start parking here tomorrow",
    you_have_active_subscription: "You have an active subscription",
    you_have_active_subscriptions: "You have active subscriptions",
    you_have_made_your_bay_available: "You have made your bay available on the system (nobody is parking here - yet)",
    you_have_no_subscriptions: "You have no subscriptions",
    you_have_outstanding_request: "You have a request pending",
    you_have_paid: "You have paid. Enjoy your day!",
    you_have_pending_subscription: "You have a pending subscription",
    you_have_reservation: "you have a reservation",
    you_have_session: "You have an active session",
    you_have_an_active_reservation: "You have an active reservation",
    you_made_your_bay_available_to_others:
        "It seems that you have made your bay available for others today.  You may attempt to use a dynamic bay by starting a session (subject to availability).",
    your_bay_number: "Your bay number is",
    your_cars: "Your cars",
    your_credit_cards: "Your credit cards",
    your_details: "Your details",
    your_not_using_your_bay_to: "You are currently not using your bay.",
    your_problem_was_reported: "Problem reported",
    your_redeemed_vouchers: "Your redeemed vouchers:",
    your_subscription_is_active: "Your subscription is active",
    your_unredeemed_vouchers: "Your unredeemed vouchers:",
    your_vehicle: "Your vehicle",
    your_vehicles_: "Your vehicles: ",
    youve_earned: "You've earned",
    sharing_pool: "Sharing pool",
    sharing_pool_row: "This space is from the sharing pool",
    all_these_spaces_from_sharing_pool: "All of these spaces are from the sharing pool",
    please_choose_a_space_to_park_in: "Please choose a space to park in.",
    sharing_pool_description: {
        part1: "The sharing pool allows you to park in spaces which would usually be private.",
        part2: "If you’re an employee at a building with multiple tenants, keep in mind that when you park in the sharing pool, the price of parking may be different to what your workplace offers, and the space you’re allocated may be in another part of the car park.",
        part3: "These spaces are available to book casually from when the car park opens each day.",
    },
    select_gate_to_open: "Select the access gate to open. You may only open gates that are within range of your phone",
    access_control: "Access control",
    view_carpark_details: `View park details`,
    open_gate_question: "Would you like to open car park gate?",
    not_in_range: "Depending on your device this may take up to 10 seconds",
    range_description: "To open the car park gate you must move closer to the gate controller",
    report_problem: "Report a problem",
    open_gate: "Open gate",
    scanning_gate: "Scanning...",
    open_caps: "Enter car park",
    open_a_gate: "Open a gate",
    tap_button_below_to_open_gate: "Tap the button below to open the access gate",
    opening_gate: "Opening gate...",
    may_take_20_seconds: "This may take up to 10 seconds",
    gate_should_be_opening: "The gate should now be opening",
    scanning_details: "Scanning for gate controller...",
    bluetooth_off: "Bluetooth off!",
    scan_depending_on_device: "Depending on your device this may take up to 10 seconds",
    bluetooth_required_for_access_gate: "Bluetooth is required to open the access gate at this park.",
    enable: "Enable",
    success_exclaim: "Success!",
    schedule_availability: `Schedule availability`,
    view_calendar: `View calendar`,
    todays_sessions: `Today's sessions`,
    plate_no: "Plate No.",
    earned: `Earned`,
    start: `Start`,
    end: `End`,
    total: `Total`,
    reserved: `Reserved`,
    load_more: `Load more`,
    date: `Date`,
    sessions: `Sessions`,
    unable_to_retrieve_subscriptions: `Unable to retrieve subscriptions`,
    plates: `Plates`,
    term: `Term`,
    term_started: `Term started`,
    active_now: `Active now`,
    completed: `Completed`,

    charge: "Charge",
    paid: "Paid",
    payment_required: "Payment required",
    authentication_required: "Authentication required",
    auth_required: "Auth required",
    no_internet_connection: "Server unavailable",
    enable_network_connection:
        "Cannot reach the Parkable network. Please ensure a data connection is available to continue.",
    dismiss: "Dismiss",
    change_bay: "Change bay",

    comment: `Comment`,
    your_message: `Your message...`,
    you: `You`,
    redeemed: `Redeemed`,
    no_vouchers: `No Vouchers`,
    no_vouchers_explanation: `Aw man. You have no vouchers at the moment. To add a voucher tap on the button below. FYI, all of our vouchers are used automatically when you park - it’s that simple`,
    description: `Description`,
    valid_at: `Valid at`,
    valid_to: `Valid to`,
    credit_remaining: `Credit remaining`,
    sessions_remaining: `Sessions remaining`,

    live_whos_parking_now: `Live: Who's parking now?`,
    casual_sessions: `Casual sessions`,
    casual_session: `Casual session`,
    deposit: `Deposit`,
    infringement: "Infringement",
    find_plate_number: `Find plate number`,
    time_remaining: `Time remaining`,
    overdue: `Overdue`,
    bay_no: `Bay No.`,
    subscription_started: `Subscription started`,
    subscription_ends: `Subscription ends`,
    date_paid: `Date paid`,
    parker: `Parker`,
    message_parker: `Message parker`,
    reservation_started: `Reservation started`,
    session_is_active: `Session is active`,
    reservation_is_active: `Reservation is active`,
    total_time: `Total time`,
    total_earned: `Total earned`,
    yesterday: `Yesterday`,
    subscription_ending_soon: `Subscription ending soon`,
    bay_available: `Bay available`,
    subscription_ended: "Subscription ended",
    are_you_sure_discard_modifications: `Are you sure you want to discard your changes?`,
    enter_first_name_bank: "Please enter first name",
    enter_last_name_bank: "Please enter last name",
    enter_date_of_birth_bank: "Please enter date of birth (dd/mm/yyyy)",
    enter_valid_bank_account_number: "Please enter a valid bank account number",
    enter_valid_bsb_number: "Please enter a valid BSB number",
    select_from_drop_down: "Please select an address from the drop down list",
    preview: "Preview",
    location_display_name: "Location display name",
    edit_pin_position: "Edit pin position",
    user_already_in_bay:
        "Another user has recently starting parking in your bay. You may start a casual session on the following screen (subject to availability)",
    hold_up: "Hold up",
    delete_park: `Delete park`,
    really_delete_park_question: `Really delete park?`,
    yes_delete_my_park: `Yes, delete my park`,
    oops_no_thanks: `Oops, no thanks`,
    find_alternative_carpark: "Find alternative car park",
    host: "Host",
    price_per_day_is_less_than_price_per_hour: `Price per day cannot be less than price per hour`,
    review_settings: `Review settings`,
    address_cannot_be_empty: `Address cannot be empty`,
    label_cannot_be_empty: `Park label cannot be empty`,
    image_uploaded_failed: "Oops, some of the photos didn't upload",
    can_we_access_your_camera: `Can we access your camera, so you can upload park images?`,
    can_we_access_your_photos: `Can we access your photos, so you can upload park images?`,
    please_allow_access_to_photos: `Please allow access to photos in the settings menu`,
    please_allow_access_to_camera: `Please allow access to the camera in the settings menu`,
    parkable_needs_camera_access_so_you_can_upload_park_photos: `Parkable needs camera access so you can upload park photos`,
    open_settings: `Open settings`,
    okay: "Okay",
    camera: `Camera`,
    camera_roll: `Camera roll`,
    bank_account_number_invalid: "Bank account number is invalid. Please check and try again.",

    images: `Images`,
    easilyViewParkDetails: "Easily view park details with a single click",
    viewOpeningAndClosingTimes: "View opening and closing times",
    oneTapToStartParking: "One tap to start parking - no paper ticket required!",

    cancellationTerms: `Cancellation terms`,
    cancellation: `Cancellation`,
    cancellationTermsExplanation: `When you cancel a subscription, you retain the park for one more full term from the day of cancellation (a week or a month, depending on your term type). `,
    seeCancellationPolicyToLearnMore: `See the cancellation policy to learn more.`,
    max_price_per_day: `Max price per day`,
    question_mark: `?`,
    our_pricing_model: `Our pricing model`,
    our_pricing_model_description: `With Parkable, you're only charged for the minutes when you park, rather than paying for a certain number of hours in advance.  Just push 'Start parking' to begin your session, and 'Stop parking' to end it. The app keeps track of how long you're parking for so you don't have to!`,
    _what_does_max_price_per_day_means: ` is the maximum you will pay for parking in this space, within the available hours stated for the park`,
    changeYourBankAccount: "If you want to change your bank account, click here.",
    park_later_today: "Park later today",
    pfb_park_later_today: "I’d like to park later today",
    rememberToEndSession: `Remember to end session when you leave`,
    reservation_only: `Reservation only`,
    the_park_could_not_be_loaded: `The park could not be loaded`,
    post_code: "Post code",
    enter_post_code: "Please enter post code",
    restart_gate_scan: "Restart gate scan",
    sorry_no_available_in_your_country: `Sorry, Parkable is not available yet in this country`,
    state: `State`,
    enter_state: `Please enter state`,
    please_ensure_select_location_in_your_country: `Please ensure you select a location in your country`,
    location_required_for_access_gate: "Location services are required to open the access gate at this park.",
    ios_or_android_for_location:
        "To open this gate, we need to know your location. Please download the app on your iOS or Android device and give permission to use your location.",
    date_of_birth_invalid: "Date of birth is invalid. Please check and try again.",
    routing_number_invalid:
        "The routing number does not correspond with a recognized bank. Please check and try again.",
    postal_code_invalid: "Postal code is invalid. Please check and try again",
    you_have_an_active_session: "You have an active session",
    you_have_a_parking_request: "You have a parking request",
    you_have_a_booking_tomorrow: "You have a booking tomorrow",
    filter_map: "Filter map",
    available_parks_only: "Show available parks only",
    show_only_long_term: "Show only long term parks",
    pin_display: "Pin display",

    oopsCheckCardNumber: "Oops, please check the card number",
    oopsCheckExpiryMonth: "Oops, please check the expiry month",
    oopsCheckExpiryYear: "Oops, please check the expiry year",
    oopsCheckCVV: "Oops, please check the CVV",

    my_subscriptions: `My subscriptions`,
    your_park_cannot_have_zero_bays: `Your park cannot have zero bays`,

    enter_dev_eui: "Start entering DevEUI",
    assign_sensor_qr: "Link Sensor QR code",
    scan_sensor_qr: "Scan sensor",
    id: "ID",
    eui: "Device EUI",
    battery_level: "Battery level",
    unknown: "Unknown",
    unassigned: "Unassigned",
    sensor_info: "Sensor info",
    assign: "Assign",
    reassign: "Reassign",
    assign_sensor: "Assign bay sensor",
    administration: "Administration",
    bay_name: "Bay :",
    sensor: "Sensor :",
    not_available: "Not available",
    scan_bay_qr: "Scan QR code",
    scan_bay_qr_hint: "Put the QR code/bar code into the box and you can scan automatically",
    flash_on: "Flash on",
    flash_off: "Flash off",
    fail_to_assign: "Failed to assign. Please try again",
    qrCode: "Qr code: ",
    no_bay_sensor: "Sensor is not registered with Parkable. Use assign sensor QR from main menu.",

    userEnterIncorrectCardNumber: "User entered incorrect card number",
    userEnterIncorrectExpireMon: "User entered incorrect expire month",
    userEnterIncorrectExpireYear: "User entered incorrect expire year",
    userEnterIncorrectCVVNumber: "User entered incorrect CVV number",
    full: `Full`,

    would_you_like_to_subscribe_to_this_park: "Would you like to subscribe to this park?",
    please_read_the_instructions_before_parking: "Please read the instructions before parking",
    sending: "Sending...",
    last_update: "Last updated:",
    not_seen: "Last seen: Never",
    not_set: "Not set",
    never: "Never",
    last_heartbeat: "Last seen:",
    no_sensor: "No sensor",
    invoice: "Invoice",
    invoices: "Invoices",
    download: "Download",
    pending: "Pending",
    amount: `Amount`,
    subscription_begins_on: "Subscription begins on:",
    continue: "Continue",
    your_bay_will_be_available_when: `Your bay will be available when the subscription begins`,
    Select_Start_Date: "Select start date",
    begin_immediately: "Begin immediately",
    specify_date: "Select start date",
    review_details: "Review details",
    select_date: "Select date...",
    feel_free_to_stay_overnight:
        "Feel free to stay overnight. If the Host needs the parking bay back, we’ll give you 24 hours notice.",
    pay_hourly: "Pay hourly",
    view_map: "View map",
    view_pricing: "View pricing",
    pricing: "Pricing",
    plus_processing_fee: (processingFeeAmount: string) => `+ ${processingFeeAmount} processing fee`,
    calculate_parking_cost: "Calculate parking costs at this car park",
    calculate_parking_cost_description: "Use this tool to estimate how much you'll pay to park here.",
    casual_pricing_description: (periods?: string[]) =>
        `Sessions are charged hourly until the ${periods?.includes("EarlyBird") ? "early bird, " : ""}${
            periods?.includes("Night") ? "day or night" : "day"
        } maximum rate is reached and run up to the time cap before resetting to the current hourly rate.`,
    period_description: (type: string, startTime: string, endTime: String, cap: number) =>
        `${type} rates apply to sessions that start between ${startTime} and ${endTime} and run for up to ${cap} hours before resetting to the current rate.`,
    park_session_duration: (hours?: number, minutes?: number) => {
        const hoursText = hours === 1 ? "hour" : "hours";
        const minutesText = minutes === 1 ? "minute" : "minutes";
        return `${hours} ${hoursText}, ${minutes} ${minutesText}`;
    },
    how_our_rates_work: `How our rates work`,
    standard_rates: "Standard rates",
    free_parking_period: "Free parking period",
    free_parking_period_description: (hoursAndMinutes?: string) => {
        return `This park has a ${hoursAndMinutes} free period at the start of each parking session. The free period can only be used once per day. If you park for longer than the free period, the standard hourly and day rates apply.`;
    },
    pricing_with_free_period: (
        hoursAndMinutesText?: string,
        pricePerHour?: string,
        pricePerDay?: string,
        cap?: number
    ) => {
        return `${hoursAndMinutesText} free then ${pricePerHour}/hr\n${pricePerDay} max for up to ${cap} hrs`;
    },
    other_rates: "Other rates",
    other_rates_description: "The maximum charge is based on your parking session start time.",
    hourly: "Hourly",
    maximum: "Maximum",
    max: "max",
    earlyBird: "Early bird",
    night: "Night",
    estimate_parking_cost: "Estimate parking cost",
    cost_is_an_estimate_and_may_change: "*Cost is an estimate only. Please check rates as of time of actual entry.",
    incl_processing_fee: (processingFeeAmount: string) => `incl. ${processingFeeAmount} processing fee`,
    reserve_bay: "Reserve bay",
    reserve: "Reserve",
    reservation_details: "Reservation details",
    opening_hours: "Opening hours",
    parker_faq: "Parker FAQ",
    how_tos: "How-tos",
    save_to_favourites: "Save to favourites",
    remove_from_favourites: "Remove from favourites",
    remove_car_park_from_your_favourites:
        "Are you sure you want to remove this car park from your favourites? You can always save it again if you change your mind.",
    more_information: "More information",
    support: "Support",
    parkable: "Parkable",
    reached_parkable_support: "Hi, you’ve reached Parkable support. What type of problem are you experiencing?",
    support_ticket_created: (caseNumber: string) => `Thanks, we’ve created a case for you - ${caseNumber}.\n\n` +
    `If your enquiry is urgent, please call us on NZ (09) 320 3418 or AU (07) 3135 7429.`,
    add_details_to_case_question: "Do you have any more details to add?",
    final_msg_chat_support: "Thanks, we have updated your case, and our support team will reply to your ticket soon.",
    tandem_chats: "Tandem chats",
    away: "away",
    open_all_day: "Open all day",

    calculating_drive_time: "Calculating drive time",

    casual: `Casual`,
    ev_charger: `EV charger`,
    coming_soon: "Coming soon",
    long_term: `Long-term`,
    where_are_you_going: `Where are you going?`,
    loading_bays: "Loading bays",
    bays_left: "bays left",
    bay_left: "bay left",
    all_pricing_options: "All pricing options",
    select: "Select",
    casual_2_wheel: "Casual 2-wheel",
    ev_charging_bay: "Electric charging bay",
    midnight: "midnight",
    scroll_down_for_more: "Scroll down for more",
    label_carpark: "Label car park",
    love_to_save_carpark: "Love this car park? Save it to your favourites so you can find it easily next time!",
    connect_the_nozzle_to_vehicle: "Please plug in the connectors",
    connect_the_nozzle_description:
        "When you're plugged in, tap the button below and the EV charger will start charging",
    connected: "I am plugged in",
    connect_cable_heading: "Connect charging cable to your EV",
    initialising: "Initialising",
    please_ensure_cable_connected: "Please ensure your charging cable is connected to the charge point and the vehicle",
    initialising_charge_experience:
        "Depending on the charge point, this can take up to a minute, please stay on this screen.",
    beginning_charge_experience: "Beginning your Parkable charging experience",
    one_moment_please: "One moment please…",
    something_wrong_with_ev: "Oh no, something went wrong…",
    something_wrong_with_ev_desc:
        "Please remove your charging cable from the vehicle and the charge point and try again",
    something_wrong_with_ev_desc_reason: (err_code: string) =>
        "Please remove your charging cable from the vehicle and the charge point and try again (" + err_code + ").",
    fingers_crossed: "Fingers crossed!",
    select_charger: "Select charger",
    select_one: "Select one",
    spaces_available: "Spaces available",
    in_use: "In use",
    add_new_vehicle: "Add a new vehicle",
    vehicle_type: "Vehicle type",
    what_type_of_vehicle: "What type of vehicle is this?",
    this_is_car: "This is a car",
    this_is_motorbike_or_scooter: "This is a motorbike / scooter",
    what_is_your_rego: `What is your vehicle registration plate?`,
    this_is_ev: `This is an electric vehicle`,
    electric_vehicle: `Electric vehicle`,
    is_this_plug_in_electric_vehicle: `Is this a plug-in electric vehicle?`,
    it_will_save_next_time: `It'll only take one minute and then your details will be saved for next time!`,
    select_vehicle: `Select vehicle`,
    how_to_park_here: `How to park here`,
    card_ending_in: `Card ending in`,
    card_ending: `Card ending`,
    saved_cards: `Saved cards`,
    your_cards: `Your cards`,
    expiry: `Expiry`,
    whats_your_credit_card: `What are your credit card details?`,
    Secure_payments_via_Stripe: "Secure payments via Stripe",
    useThisCard: "Use this card",
    add_new_card: "+ Add new",
    add_new_rfid: "Add RFID card",
    edit_rfid: "Edit RFID number",
    rfid_number: "RFID number",
    rfid_not_found: "RFID not found",
    rfid_not_found_message: "Try another RFID, or contact Parkable support to have your card loaded.",
    remove_card: "Remove card",
    defaultCard: "Default card",
    welcome_to_parkable: `Welcome to Parkable`,
    signed_on_need_a_few_more_details: "We have successfully signed you in and just need a few more details.",
    our_mission: `Our mission is to make parking better for everyone. It's great to have you along for the ride.`,
    hide_password: `Hide password`,
    failed_to_signup: `Failed to sign up`,
    country: `Country`,
    area_code: `Area code`,
    mobile: `Mobile`,
    already_have_an_account: `Already have an account?`,
    invalid_phone: `Invalid phone number format`,
    payment_received: `Payment received`,
    receipt_has_been_sent: `A copy of this receipt has been emailed to you.`,
    please_find_details_below: `Please find details for your session below. If you have any questions, feel free to get in touch.`,
    re_authenticate_instructions:
        "Your bank requires you to re-authenticate in order to complete your last payment. Please check the details and click 'Authenticate' below.",
    your_receipt: `Your receipt`,
    credit_card: `Credit card`,
    vehicle_plate_number: `Vehicle plate number`,
    vehicle: `Vehicle`,
    total_charged: `Total charged`,
    eg_work: `E.g. Work`,
    accept: `Accept`,
    decline: `Decline`,
    terms_and_conditions: `Terms & conditions`,
    park_closed: `Park closed`,
    private_parking: `Private parking`,
    not_having_account: `Don't have an account?`,
    enable_location: `Enable location?`,
    you_have_a_sub: `You have a subscription`,
    share_your_bay: `Share your bay?`,
    are_you_using_bay_today: `Are you using your park today?`,
    share_bay_earn_money: `If not, share your bay and create an easy morning for someone else!`,
    not_today_thanks: `Not today thanks`,
    tap_x_remove_vehicle: `Tap the red cross to remove a vehicle.`,
    your_vehicles: "YOUR VEHICLES",
    manage_vehicles_for_this_account: "Manage vehicles and select the default licence plate for your account.",
    empty_subscription_list_display: `You don't have any active subscriptions. If you would like to secure a subscription, simply go back to the map, tap 'Long-term' at the bottom of the page, and find and subscribe to a car park that suits you!`,
    no_subs_available: "No subscriptions available",
    back_to_map: `Back to map`,
    end_session: `End session`,
    keep_parking: `Keep parking`,
    amount_charged: `Amount charged`,
    approximate_charge: "Approximate charge pending",
    approximate_charges: "Approximate charges",
    approximate_charge_explanation:
        "We are still waiting for the exact electricity usage so have estimated the final cost.  This doesn't include vouchers or discounts.  You will receive an email receipt with the exact charge shortly.",
    view_my_subscription: `View my subscription`,
    park_casually: `Park casually`,
    your_bay_has_been_shared: `Your bay has been shared`,
    default_parking_instructions_string: `Before you park here, remember to check the hours of availability and tap ‘Start parking’. (If you can’t tap ‘Start parking’, please don’t park here - it has been reserved by someone else). When you leave the car park, tap ‘Stop parking’. Easy!`,
    failed_to_update_user: `Failed to update user`,
    running: `Running`,
    member_invite_description: `Look out for purple pins on the map and added to your favourites.`,
    org_assign_you_bay: (org: string) => `${org} has invited you to use this allocated bay`,
    org_invite_you: (org: string) => `${org} has invited you to their car park`,
    this_park_closes_at: (time: string) => `This car park closes at ${time}, so feel free to stay until then.`,
    youll_need_to_leave_by: (dateTime: string, tomorrow: boolean) =>
        `You’ll need to leave this parking bay by ${dateTime}${
            tomorrow ? " tomorrow" : ""
        }. We’ll send you a reminder before then, so you aren’t caught off guard.`,
    future_subscription_desc: (date: Moment) =>
        `The subscription is set to start on ${date.format(
            "DD/MM/YYYY"
        )}.  Your card will not be charged until this date`,
    future_subscription_begins_bay_desc: (date: Moment) =>
        `Your subscription is set to start on ${date.format(
            "DD/MM/YYYY"
        )} and your bay number will display here once the subscription has begun.  Please note, your card will not be charged until this date.`,
    active_sessions_func: (numActiveSessions: number) => `${numActiveSessions} Active sessions`,
    number_of_bays_func: (numOfBays: number) => `${numOfBays} Bays`,
    photos_uploaded: (num: number, totalNum: number) => `${num}/${totalNum} photos uploaded.`,
    photos_selected: (num: number) => `${num} photos selected`,
    open_X_days: (days: number) => `Open ${days} days`,
    pfb_when_will_i_know_text: (allocationHour: number) => {
        const allocationTime = moment().hour(allocationHour);
        return `Successful applications will be notified at ${allocationTime.format(
            "hh:mma"
        )}. If you have not received a notification then you have not been successful.`;
    },
    percent_discount: (percent: number) => `(incl. ${percent}% discount)`,
    percent_off: (percent: number) => `${percent}% OFF`,
    amount_free_parking: (formattedAmount: string) => `${formattedAmount} free parking`,
    format_days_hours_mins: (days: number, hours: number, mins: number) =>
        `${days > 0 ? `${days}d ` : ""}${hours}h${mins}m`,
    format_mins: (mins: number | string) => `${mins} mins`,
    n_more: (n: number) => `${n} more`,
    n_months: (n: number) => `${n} month${n !== 1 ? "s" : ""}`,
    n_weeks: (n: number) => `${n} week${n !== 1 ? "s" : ""}`,
    orgname_parking: (organisationName: string) => `${organisationName} Parking`,
    minimum_dob: (n: number) => `Minimum age required is ${n} years old`,
    carpark_open_on: (day: string) => `Car park open on ${day}?`,
    keep_editing_func: (percentageDone: number) => `Keep editing... (${percentageDone}%)`,
    park_on_someday: (someday: ISomeDay) => (someday.isNextWeek ? `Park ${someday.text}` : `Park on ${someday.text}`),
    want_to_park_on_someday: (someday: ISomeDay) =>
        someday.isNextWeek ? `Want to park ${someday.text}?` : `Want to park on ${someday.text}?`,
    park_next_openday: (someday: ISomeDay) =>
        someday.isNextWeek ? `I'd like to park next ${someday.text}` : `I'd like to park on ${someday.text}`,
    baysLeftText: (bayCount: number, parkClosed?: boolean) =>
        parkClosed
            ? bayCount === 1
                ? "1 bay"
                : `${bayCount} bays`
            : bayCount === 1
            ? "Only 1 bay available!"
            : `${bayCount} bays available`,
    longTermBaysLeftText: (bayCount: number) =>
        bayCount === 1 ? "1 long-term bay available!" : bayCount + " long-term bays available",
    evBaysLeftText: (bayCount: number) => (bayCount === 1 ? "1 EV bay available!" : bayCount + " EV bays available"),
    pricePerHour: (amount: string) => amount + "/hr",
    pricePerDay: (amount: string, closingTime: string) => amount + " max till " + closingTime,
    per_day: "per day",
    pricePerTerm: (amount: string, term: Term) => amount + " per " + term,
    sorry_no_parks_available: "Sorry, there are no parks available",
    check_later_to_see_if_changed: `Currently all spots in the car park have been booked. Check again later to see if anything has changed!`,
    made_in_new_zealand: `With 💙 from New Zealand`,
    version_number: (version: string) => `Version ${version}`,
    start_typing: `Start typing...`,
    favourite_car_parks: `Favourite car parks`,
    no_places_found: (searchText: string) => `Your search - ${searchText} did not match any places.`,
    reserve_bay_description: (reservationTime: number) =>
        `It's free to reserve a bay for up to ${reservationTime} minutes. After ${reservationTime} minutes your session will automatically start, so remember to cancel if you no longer want to park.`,
    reservation_error: "Reservation error",
    cannot_find_location: `We can't find your location`,
    turnLocationSettings: `Parkable uses your location to provide a better in app experience.`,
    minutesRemaining: (minutes: number) => minutes + " minutes remaining",
    weekly_subscription: "Weekly subscription",
    monthly_subscription: "Monthly subscription",
    monthly_parking: "Monthly parking",
    weekly_parking: "Weekly parking",
    price: "Price",
    enter_now: "Enter now",
    enter_vehicle_details: "Enter your vehicle details",
    edit_your_vehicle: "Edit your vehicle",
    update_your_vehicle_details: "Update your vehicle details.",
    update_vehicle_rego_and_type: "Update your registration plates and vehicle types.",
    date_of_first_payment: "Date of first payment",
    monthly_subscription_payment_details: (dom: string) => {
        if (/^\d+$/.test(dom)) {
            //is only a number
            if (["1", "21", "31"].includes(dom)) {
                dom = `${dom}st`;
            } else if (["2", "22"].includes(dom)) {
                dom = `${dom}nd`;
            } else if (["3", "23"].includes(dom)) {
                dom = `${dom}rd`;
            } else {
                dom = `${dom}th`;
            }
        }
        return `Payments will go out on the ${dom} of each month until the subscription is cancelled.`;
    },
    weekly_subscription_payment_details: (dow: string) =>
        `Payments will go out every ${dow} until the subscription is cancelled.`,
    review_Details: "Review details",
    week: "week",
    month: "month",
    edit_start_date: "Edit start date",
    up_to_X_days: (days: number) => `(up to ${days} days)`,
    for_up_to_X_hours: (hours: number) => `max for up to ${hours} hrs`,
    max_till: (closingTime: string) => `max till ${closingTime}`,
    confirm_and_start: "Confirm and start",
    confirm_details: "Confirm details",
    please_read_the_instructions: "Please read the instructions",
    phone_services_required: "Phone services required",
    open_map: "Open map",
    parking_details: "Parking details",
    total_time_parking: "Total time parking",
    creating_your_subscription: "Creating your subscription",
    subscription_created: "Subscription successful",
    view_options: "View options",
    parks_from: (cost: string, term: string) => `From ${cost} per ${term}`,
    subscription_details: "Subscription details",
    next_payment_will_be_on: "Next payment will be on",
    options: "Options",
    view_car_park: "View car park",
    pay_now: "Pay now",
    thank_you: "Thank you",
    not_using_bay_tap_to_share_1: "Not using your park? Select days to share it",
    not_using_bay_tap_to_share_2:
        " and you'll earn a credit on your subscription next month when another parker uses it.",
    sharing: "Sharing",
    select_the_days_you_dont_need_park:
        "Select the days that you don't need your park! By sharing your spot, someone else will be able to park there.",
    cannot_unshare_bay_due_to_booking: "One or more days this week could not be unshared, due to confirmed bookings",
    unshare_success: "Bay successfully unshared",
    unshare_fail: "Sorry, we could not move this booking to another available bay. Your bay could not be unshared.",
    favourites: "Favourites",
    this_sub_is_free: "This subscription is free",
    term_renews: "Term renews",
    you_have_a_sub_here: "You have a subscription here",
    click_to_view: "Click to view",
    welcome_back_to_parkable: "Welcome to Parkable!",
    been_a_few_changes:
        "You might notice that our app has a fresh new look! If you have questions about using Parkable, head to the How-tos.",
    continue_to_app: "Continue to Parkable",
    go_to_howtos: "Go to How-tos!",
    your_bay_being_used: "Your bay is being used!",
    heres_the_session_details: "Here's the session details:",
    session_length: "Session length",
    so_far_youve_earned: "So far you've earned...",
    can_i_park_here_today: "Can I park here today?",
    bay_shared: "Bay shared",
    bay_booked: "Booked",
    bay_occupied: "Occupied",
    your_bay_is_booked: "Your bay is booked",
    unshare_modal_message1: "Thanks for sharing!",
    unshare_modal_message2: "If no other bays are available, you won’t be able to unshare your bay.",
    unshare_modal_sorry_nobay: "Sorry, there was no other available bay for this booking. Your bay cannot be unshared.",
    bay_shared_in_use_description:
        "Because someone is using your bay, you won’t be able to park here until their session has ended.",
    nice_your_bay_shared: "Nice! Your bay has been shared.",
    well_let_you_know: "We'll let you know when someone is parking.",
    bay_shared_description:
        "You cannot use your car park while you have shared it.  If you need to use it today, click the date icon to un-share it.  Please note, you can only do this because no-one else has started to use your bay yet.",
    drive_to_gate: "Please drive to gate",
    you_are_in_range: "You are in range",
    OUT_OF_RANGE: "OUT OF RANGE",
    noBaysAvailableText: "Sorry! There are currently no bays available at this car park. ",
    no_bays_available: "No bays available",
    now_all_you_need_to_do: "Now all you have to do is tap the button!",
    even_if_you_see_a_bay: ", even if you see an empty bay - it has been reserved by someone else.",
    reservation_has_begun: "Reservation has begun",
    currently_to_far_away_for_access:
        "You are currently too far away to open the gate. Please get a little closer to gain access!",
    oh_no_something_went_wrong: "Something went wrong...",
    oh_no_something_went_wrong_support:
        "Something went wrong. Click 'Retry' to try again. If this issue persists, please contact Parkable support.",
    cant_open_the_gate:
        "We can't contact the gate at the moment. You can try again and contact us if the problem won't go away.",
    gate_opened: "Gate opened",
    gate_will_open_within_5_seconds:
        "The gate should have opened within 5 seconds. Please contact us if you are experiencing difficulties.",
    open_24_hours: "Open 24 hours",
    time_unavailable: "Drive time unavailable",
    perKwh: "per kWh",
    select_bay: "Select bay",
    charging_time_elapsed: "Charge time",
    charging_started_successfully: "Charging started successfully",
    charging_started_successfully_desc:
        "Charging will continue until battery is full, or you stop your parking session",
    click_to_start: "Click to start",
    charger_idle: "Charger idle",
    pending_charge: "Pending",
    kwh_added: " kWh added",
    selected: "Selected",
    not_charging: "Not charging",
    charging: "charging",
    holding_bay: "Holding bay",
    holding_bay_for: "Holding bay for:",
    any_bay: "Any available bay",
    pricePerKwh: "Price per  kWh",
    electricity_price: "Electricity price",
    est_distance_added: "Est. distance added",
    kms_charged: (kms: number) => {
        return `${kms} ${kms <= 1 ? "km" : "kms"}`;
    },

    subscription_shared_for_x_days: (numberOfDays: number) => {
        const dayString = numberOfDays === 1 ? "day" : "days";
        return `Your bay is currently available to others a total of ${numberOfDays} ${dayString}`;
    },
    any_bay_in_group: (groupName: string) => `Any bay in ${groupName}`,
    please_park_anywhere_in: (groupName: string) => `Please park anywhere in ${groupName}`,
    location_permissions_required: "Location permission required",
    download_app_to_open_gate: "Please download the iOS or Android app to open this gate",
    request_permission: "Request permission",
    reservations: "Reservations",
    reservations_timed_out: "Your reservation has timed out",
    reservations_about_to_start: (minutes: number) => `Your parking session starts in ${minutes} minutes`,
    reservations_cancelled: "Your reservation got cancelled",
    waiting_for_charger: "Waiting for charger",
    charger_timeout: "The charger has timed out",
    search_filter: "Search filter",
    select_filter: "select filter",
    want_to_park_tomorrow: "Want to book a park for tomorrow?",
    want_to_park_later_today: "Want to park later today?",
    you_have_active_bay_request: "You have an active bay request",
    cancel_my_request: "Cancel my request",
    uh_oh: "Uh oh",
    uh_oh_: "Uh oh!",
    missing_payment_method: "Missing payment method",
    to_start_charging_you_must_add_a_payment_method: "To start charging, you must add a payment method.",
    add_payment: "Add payment",
    sorry_there_are_no_bays_available: "Sorry, there are no bays available",
    tomorrow: "tomorrow",
    unfortunately_your_last_payment_failed: `Unfortunately your last payment failed to go through. You will need to complete this payment in order to continue using Parkable.`,
    lowercase_on: "on",
    lowercase_next: "next",
    continue_message_organisation: `Continue conversation with organisation`,
    continue_message_host: `Continue conversation with host`,
    continue_message_parkable: `Continue conversation with Parkable`,
    charge_request_accepted: "Charge request accepted",
    error_persists_support_below: "If this continues to happen please contact Parkable support below.",
    email_in_use: "Email address is already in use",
    invalid_email_longer: "Email address is invalid",
    failed_to_login: "Failed to log in",
    vouchers_help_text:
        "Vouchers are automatically applied. If you have more than one voucher, we match the voucher to give you the best chance of the biggest discount.",
    voucher_applied_label: "Voucher applied",
    voucher_credit: "Voucher credit",
    voucher_discount: "Voucher discount",
    voucher_description: "Voucher description",
    voucher_active_until: "Voucher active until",
    car_park_location: "Car park location",
    total_remaining: "Total remaining",
    total_amount: "Total amount",
    total_to_be_charged: "Total to be charged",
    voucher_valid_between: "Voucher valid between",
    voucher_valid_from: "Voucher valid from",
    free_casual_parking_session: (remaining: number) => `${remaining} free casual parking session`,
    number_remaining: (remaining: number) => `${remaining} remaining`,
    percent_off_parking_session: (percent: number) => `${percent}% off your parking session`,
    percent_off_parking_subscription: (percent: number) => `${percent}% off your subscription`,
    free_casual_parking_credit: (amount: string) => `${amount} free casual parking credit`,
    remaining_casual_parking_credit: (remainingAmount: string, spentAmount: string) =>
        `${remainingAmount} (Previously spent ${spentAmount})`,
    session_went_unavailable: `Your session ended as the park became unavailable`,
    park_going_unavailable: `You are parked in a park that is about to become unavailable`,
    city: "City",
    enter_city: "Enter city",
    your_bay_has_been_changed_to: (newSignage: string) =>
        `Your current parking session bay has been change to ${newSignage}`,
    enter_code: "Enter code",
    new_qr_code_location_desc: "Enter the 7 digit QR code which is located below the QR code.",
    please_scan_code: "Please scan QR code",
    uninitialised_code: "Code not initialised",
    unrecognised_code: "Code unrecognised",
    network_error: "Network error",
    code_disabled: "Code disabled",
    qr_code_location_desc: "The 7 digit code is located below the QR code.",
    scan_qr: "Scan QR",
    enter_qr_code: "Enter QR code",
    sorry_times_up: "Sorry... time's up.",
    time_limit_hold_bay: "The time limit gives all parkers a fair shot at the available bays.",
    now_what: "Now what?",
    try_again_to_hold_bay:
        "Try again to see if there are any available bays left at this car park, or go back to the map to find car parks nearby.",
    what_is_your_voucher: `What's your voucher code?`,
    voucher_code: `Voucher code`,
    voucher_not_applicable: "Voucher is not applicable for this park",
    error_updating_password: "Unable to update password.  If this persists, please contact Parkable support",
    you_will_be_paying_twice: `You'll be paying twice`,
    just_drive_into_your_bay: `Hey 👋 When you have a subscription, you don't need to tap Start Parking. Just drive into your bay!`,
    i_knew_that: `I knew that`,
    want_to_park_a_second_vehicle: `Want to park a second vehicle, or need to park casually because you shared your bay? That's cool! Go ahead.`,
    exclamation_mark: `!`,
    subscription_start_date: `Subscription start date`,
    please_try_again: "Please try again",
    reset_password_error: "Unable to reset password.  If this persists, please contact Parkable support",
    account_disabled: "Account disabled",
    email_not_found: "Email address not found",
    incorrect_password: "Incorrect password",
    minimum_password_length: `Minimum password length is 8 characters`,
    check_data_connection: "Check data connection",
    voucher_code_place_holder: `e.g. PARKG123`,
    notifications_preferences: `Notifications & preferences`,
    manage_your_app_and_email_notifications_and_preferences: `Manage your app and email notifications and preferences`,
    marketing_emails: `Marketing emails`,
    if_you_would_like_to_unsubscribe_from_Parkable_marketing_emails: `If you’d like to unsubscribe from Parkable marketing emails, `,
    please_update_your_email_preferences: `please update your email preferences.`,
    if_you_would_like_to_turn_push_notifications_from_the_Parkable_app_on_or_off: `If you’d like to turn push notifications from the Parkable app on or off, `,
    change_your_system_notification_settings: `change your system notification settings.`,
    waitlisted_requests: `Waitlisted requests`,
    dont_want_a_request_on_the_waitlist_to_convert_into_a_booking_at_the_last_moment_q: `Don’t want a request on the waitlist to convert into a booking at the last moment?`,
    set_a_time_to_cancel_your_waitlisted_requests: `Set a time to cancel your waitlisted requests.`,
    time_options: {
        dont_cancel_in_advance: `Don't cancel in advance`,
        six_pm_night_before: `6:00pm (night before)`,
        seven_pm_night_before: `7:00pm (night before)`,
        eight_pm_night_before: `8:00pm (night before)`,
        nine_pm_night_before: `9:00pm (night before)`,
        ten_pm_night_before: `10:00pm (night before)`,
        eleven_pm_night_before: `11:00pm (night before)`,
        midnight: `Midnight`,
    },
    change_password: `Change password`,
    update_password: `Update password`,
    map_settings: `Map settings`,
    map: `Map`,
    my_details: `My details`,
    payment_methods: `Payment methods`,
    payment_method: `Payment method`,
    my_vehicles: `My vehicles`,
    invitations: `Invitations`,
    email_address: `Email address`,
    name: `Name`,
    campany_detail_caption: `This appears on company invoices along with your name and email address.`,
    edit_my_details: `Edit my details`,
    required: `Required`,
    password_too_short: `Password too short`,
    password_should_be_less_than_: (maxLength: number) => `Password should be less than ${maxLength} characters`,
    i_forgot_my_password: `I forgot my password`,
    user_not_found: `User not found`,
    camera_permission_required: `Camera permission required to scan the QR code`,
    bay_not_available: `Bay not available`,
    bay_not_available_desc: `Bay has been occupied or suspended, or you are not eligible to park in this bay`,

    start_charging: "Start charging",
    currently_charging_elsewhere: "Currently charging elsewhere",
    open_gate_start_charging: "Open gate and start charging",
    idle: "Idle",
    charging_complete: "Charging complete",
    charge_added: "Charge added",
    ev_faqs: `EV FAQs`,
    start_session: "Start session",
    stop_session: "Stop session",
    i_cannot_open_gate: "I cannot open gate",
    lets_try_the_following: "Oh no! 🤔 Let's try the following:",
    turn_on_Bluetooth: "1. Turn on Bluetooth",
    drive_up_to_the_barrier_arm: "2. Drive up to the barrier arm",
    if_that_doesnt_work_give_us_a_call: "If that doesn't work, give us a call 🤙",
    call_support: "Call support",
    this_subscription_has_ended: "This subscription has ended",
    password_updated: "Password updated",
    password_updated_success: "Password successfully updated",
    session_reminder: "Forgetting to end your parking sessions?",
    session_reminder_desc:
        "We'll send you a notification an hour before your session auto-ends and you can opt to extend it.",
    remind_me_every_: (hour: number) => `Auto-end after ${hour} hours`,
    auto_end_after_1_week: "Auto-end after 1 week",
    casual_pin_labels: "Casual pin labels",
    lt_pin_labels: "Long-term pin labels",
    display_weekly_sub_price: "Display weekly subscription price",
    display_monthly_sub_price: "Display monthly subscription price",
    hide_closed_carparks: "Hide closed car parks",
    hide_full_carparks: "Hide full car parks",
    display_bays_available: "Display bays available",
    display_subscriptions_available: "Display subscriptions available",
    first_time_setup_one_minute: "It'll only take one minute and then your details will be saved for next time!",
    more: `more`,
    rfid_cards_for_evs: "RFID cards",
    add_new_and_manage_existing_rfids: "Add and manage RFID cards for parking and EV charging.",
    enter_your_rfid_card_details: "Enter your RFID card details",
    card_name: "card name",
    card_name_placeholder: `e.g. John Smith's card`,
    card_id: "card id",
    card_id_placeholder: "e.g. ABCD123456789",
    are_you_sure_remove_rfid: "Are you sure you want to permanently remove this card?",
    rfid_remove_warning: [
        "By permanently removing this card, ",
        "all activity and history linked to this card will be lost and can not be recovered.",
        "If you've lost your card, or believe it may be stolen, you can temporarily lock your card until you find it." +
            "Locked cards can not be used to start a charging session and can be unlocked from the card detail screen.",
    ],
    whats_your_rfid: "What’s your RFID number?",
    permanently_remove_card: "Permanently remove card",
    temporarily_lock_card: "Temporarily lock card",
    rename_card: "Rename card",
    card_id_number: "Card ID number",
    authenticate: "Authenticate",
    payment_authentication_required: "Payment authentication required",
    experiencing_network_difficulties: "We are experiencing network difficulties",
    please_check_your_connection_if_persists: "Please check your connection if this error persists",
    update_available: "Update available",
    update_available_description: "To ensure the best experience, please update Parkable to the most recent version.",
    update_available_description_2: "Click 'Update' to go to the store and get the new version.",
    later: "Later",
    update: "Update",
    staying_longer_question: (count: number) => `Staying for more than ${count} hours?`,
    change_your_settings: "Change your settings",
    change_your_settings_sentence_end: " so that your session isn't auto-ended before you're ready.",
    notify_when_bay_available: "Notify me when a bay becomes available",
    stop_notifying_me_at: "Stop notifying me at",
    you_have_an_active_bay_notification: " You have an active bay notification",
    you_have_an_active_bay_notifications: " You have active bay notifications",
    show: "Show",
    hide: "Hide",
    email_sent_to_user: "Email has been sent to user.",
    park_724: "This is a 24/7 car park",
    available_till_midnight: "Available until midnight",
    park_closes_at_: (time: string, day: string) => `This park closes at ${time}${day}`,
    bay_available_till_midnight_tonight: "This bay is available until midnight tonight",
    need_to_leave_this_parking_bay_by_midnight_tonight_warning_message: `You'll need to leave this parking bay by midnight tonight. We'll send you a reminder before then, so you aren't caught off guard.`,
    car_park: `Car park`,
    car_park_name: "Car park name",
    midnight_tonight: "midnight tonight",
    sharing_recurrence: "Sharing recurrence",
    what_is_your_email: "What's your email address?",
    subscription_will_end_on: "Subscription will end on (one term from today)",
    want_to_message_parkable: "Want to message Parkable?",
    before_you_cancel: "Before you cancel",
    why_are_you_leaving: "Why are you leaving?",
    additional_days_due_now: "Additional days due now",
    subscription_cancelled_after_one_term_payment_done: (amount: string, subWillEndOn: string) =>
        `Your card was charged ${amount} and your subscription will end on ${subWillEndOn}.`,
    we_were_unable_to_process_your_payment_please_check_your_payment_details_and_try_again: `We were unable to process your payment. Please check your payment details and try again.`,
    subscription_cancelled_immediately_amount_refunded: (amount: string) =>
        `Your subscription has ended and ${amount} has been refunded for the unused days of your last term.`,
    subscription_cancelled_after_one_term_no_payment: (subWillEndOn: string) =>
        `Your subscription has been cancelled and will end on ${subWillEndOn}.`,
    subscription_has_ended: `Your subscription has ended.`,
    paid_until: (until: string) => `Paid until ${until}`,
    domain_uses_sso_and_requires_provisioning:
        "This email domain uses single sign on and requires accounts to be provisioned first.",
    sso_not_configured_correctly:
        "Single sign-on has been configured incorrectly, please contact your Parkable administrator.",
    provisioning_required: "Provisioning required",
    single_sign_on_enabled: "Single sign-on enabled",
    press_back_try_again: "Press back and try again.",
    create_password: "Password",
    enter_your_password: "ENTER YOUR PASSWORD",
    my_bookings_and_requests: "Bookings and requests",
    manage_bookings: "Manage all of your future bookings and requests.",
    no_bookings_or_requests: "You have no upcoming bookings or requests.",
    bookings_and_requests: "Bookings and requests",
    case_number: (caseNumber: string) => `Case #${caseNumber}`,
    case_details: `Case details`,
    created_date: `Created date`,
    case_number_label: `Case number`,
    subject: `Subject`,
    last_activity: `Last activity`,
    preferred_bays: {
        select_park: "Select a park",
        preferred_bays: "Preferred bays",
        no_preferred_bays: "No bays selected",
        bays_selected: (number: number) => `${number} bay(s) selected`,
        select_preferred_bays: "Which parking bays do you prefer to use?",
        preferred_bays_intro:
            "Select your favourite bays in the list below, and our allocation algorithm will take your preferences into account when assigning parks!",
        all_bays: "All bays",
        select_all: "Select all",
        deselect_all: "Deselect all",
        all_bays_in_group: "All bays in this group",
        save: "Save my bay preferences",
        success: "Bay preferences saved",
        error: "Bay preferences were not saved successfully, please try again.",
    },
    rewards: {
        view_rewards: "View my points",
        my_rewards: "My points",
        rewards_info_text: "Once you have 10 points, you can redeem them for rewards of your choice.",
        your_balance_is: "Your balance is",
        redeem_reward: "Redeem points",
        activity: "Activity",
        amount_reward: (amount: number) => `+ ${amount}`,
        balance_reward: (amount: number) => `${amount}`,
        no_rewards_found: `No rewards found`,
        how_it_works: `How rewards work`,
        how_it_works_describe: (bbMargin: number, currencyCode: string) =>
            `Every time someone else uses your shared park, ${bbMargin}% of their parking payment (minus transaction and Parkable fees) is returned to you as sharing points (1 point = 1 ${currencyCode}).`,
        how_it_works_describe_once_you_have: (minimumPoints: number) =>
            `Once you have ${minimumPoints} points, you can redeem your points as rewards of your choice.`,
        are_you_sure_you_want_to_redeem_x_points: (amount: number) =>
            `Are you sure you want to redeem ${amount} points?`,
        redeemed: `Balance redeemed`,
        not_using_bay_tap_to_share: "Not using your park? Share it with others to",
        earn_rewards: "earn rewards",
        simply_select_the_days_to_share: "Simply select the days when you want to share.",
        congratulations: `Congratulations`,
        rewards_sent_to_email: `Thanks so much for sharing. We’ve sent you an email about how to select your rewards.`,
        back_to_my_rewards: `Back to my points`,
    },
    common: {
        requested: "Requested",
        ok_got_it: "Ok, got it!",
        next_week: "Next week",
        this_week: "This week",
        today: "Today",
        how_it_works: "How it works",
        increase_your_chances: "Increase your chances",
        do_: "Do...",
        dont_: "Don't...",
        are_you_sure_q: "Are you sure?",
        confirm: "Confirm",
        limit_reached: "Limit reached",
        upcoming_weeks: "Upcoming weeks",
    },
    future_booking: {
        future_booking: "Future booking",
        allocated_days_for_next_week: `Allocated days for next week`,
        info_text: (allocationDayOfWeek: string, allocationHourOfDay: string) =>
            `Select the days when you would like to park. Each ${allocationDayOfWeek} at ${allocationHourOfDay} you’ll be notified if your requests for the following week are successful!`,
        legend_caption: "*Tap a date twice to make a priority request",
        youre_on_the_waitlist: "You're on the waitlist!",
        well_let_you_know: "We'll let you know if your request is confirmed",
        tap_again_to_make_it_a_priority_request: `Tap again to make it a priority request`, // (you only get one of these per week)`,
        prioritise_this_request: "Prioritise this request",
        deprioritise_this_request: "De-prioritise this request",
        priority_requests_remaining: (num: number) => `${num} priority requests remaining`,
        remove_request: `Remove request`,
        cancel_booking: `Cancel booking`,
        booking_for: `Booking for`,
        more_weeks: `More weeks`,
        booking_for_this_week: "Booking for this week",
        upcoming_weeks: "Booking for upcoming weeks",
        status: {
            requested: "Requested",
            confirmed: "Confirmed",
            wait_list: "Waitlist",
            requested_priority: "Requested (priority)",
            wait_list_near_top: "Waitlist (near top)",
        },
        status_detail: {
            requested: "Awaiting allocation.",
            confirmed: "Your booking is confirmed.",
            wait_list: "You’re on the waitlist.",
        },
        how_to: {
            upcoming_weeks_p1: "Select the days when you would like to park.",
            upcoming_weeks_p2: (allocationDayOfWeek: string, allocationHourOfDay: string) => [
                "Each ",
                `${allocationDayOfWeek} at ${allocationHourOfDay}`,
                ", parking for the following week is allocated and you are notified of whether your requests are successful.",
            ],
            upcoming_weeks_p3: (priorityRequests: number) => [
                `You get ${formatNumberAsString(priorityRequests)} `,
                priorityRequests === 1 ? `priority request` : `priority requests`,
                ` each week. To use ${
                    priorityRequests === 1 ? "it" : "them"
                }, tap on a day you have requested and select 'Prioritise this request' in the menu. This increases your chance of a successful booking!`,
            ],
            follow_guidelines_helper: "Follow these tips and increase your likelihood of successful bookings!",
            this_week_p1: [
                "Select the days when you would like to park.",
                "If a park is available, you will have a booking ",
                "confirmed",
                " right away.",
                "If there are no parks available, you will go on the ",
                "waitlist",
                " and be notified if a park becomes available.",
            ],
        },
        behaviours: {
            good_show_up: "Show up when you have a booking",
            good_cancel_booking: "Cancel bookings that you no longer need",
            bad_not_yours: "Park in a bay that's not yours",
            bad_not_show_up: "Forget to cancel unwanted bookings",
        },
        confirmation: {
            title: "Confirmed booking",
            info_text:
                "Your parking session will start automatically when the car park opens on the day of your booking",
        },
    },
    date_and_location: "Date and Location",
    no_parking_session: "No park sessions to display",
    receipt: "Receipt",
    service_dialog: {
        title: "Select Service",
        message: "Please select the backend service you want to connect to.",
        service_name_placeholder: "Service name",
        default: "Default",
        custom: "Custom",
    },
    download_failed: "Sorry Download failed",
    welcome: `Welcome!`,
    welcome_user: (firstName: string) => `Welcome ${firstName}!`,
    email_activation_instructions: (email: string) =>
        `We've sent an email to ${email}, please click the link in the email to activate your account.`,
    didnt_get_the_email: "Didn't get the email?",
    thanks_for_reporting: (alternativeBay: string) =>
        `Thanks for reporting the problem, sorry for the inconvenience. ${alternativeBay}`,
    bay_has_been_changed: "Bay has been changed, your new bay is ",
    session_already_in_this_bay: "Your session is already in this bay.",
    error_message_in_support: "An error has occurred. If this persists, please message Parkable.",
    park_closed_error_message: "This car park is now closed. If you need support, please contact Parkable.",
    error_message_evfaq:
        "Sorry, we are unable to open web links, please manually browse to the following website: faq_url",
    please_check_parking_bay: "Please check that you are trying to park in the correct bay. Are you in bay",
    no_its_wrong_one: "No, it was the wrong one",
    yes_its_correct: "Yes, that's correct",
    no_worries_thanks: "No worries, have a great day and thanks for parking with Parkable.",
    is_there_anything: "Is there anything else we can help you with?",
    no_thats_all: "No, that's all.",
    yes_message_parkable: "Yes, I'd like to message Parkable.",
    please_choose_one_bay: "Please choose from one of the following bays",
    support_greeting_msg: "Hi there, thanks for using Parkable. How can we help?",
    redirecting_to_faq: "Redirecting you to our Electric vehicle FAQ",
    take_me_to_my_active_session: "No, that's all. Take me to my active session",
    take_me_to_my_session_summary: "No, that's all. Take me to my session summary",
    no_invoice_found: "No invoice found",
    subscription_invoices: "Subscription invoices",
    subscription: "Subscription",
    unable_find_invoice: "We are unable to download invoice, Please contact Parkable for assistance.",
    park_anywhere_in: "Park anywhere in",
    park_furthest_forward_in: "Park furthest forward in",
    email_change_instructions: (email: string) =>
        `We sent an email to ${email}. Please click the link in the email to confirm the change.`,
    message_parkable_support_message:
        "Thanks for getting in touch! We’ve received your request and will get in touch with you as soon as we can. You’ll receive an email when we’ve responded.",
    no_messages_found: "No messages found",
    sharing_credit: {
        parker_used_your_park_for: `A parker used your park for `,
        view_sharing_credit: "View sharing credit",
        my_sharing_credit: "My sharing credit",
        sharing_credit_description:
            "Get a credit on your subscription next month by sharing your park with other Parkable users.",
        your_sharing_credit_is: "Your sharing credit is",
        no_activity: "No activity :(",
        a_parker_used_your_park_for: (duration: string) => `A parker used your park for ${duration}`,
        credit_applied: "Credit applied",
    },
    chat: "Chat",
    default_contact_message: "Please contact your organisation directly.",
    organisation: "Organisation",
    account_created_please_review_terms_accept_to_continue:
        "Please take a moment to review the Parkable terms and conditions. You must accept these before continuing.",
    review_terms: "Review terms",
    session_auto_end: "Session auto-end",
    email__: "Email",
    push_notifications: "Push notifications",
    email_desc: "If you’d like to unsubscribe from emails, ",
    change_your_email_preferences: "change your email preferences.",
    push_notifications_desc: "If you’d like to turn push notifications on or off, ",
    change_your_notification_settings: "change your notification settings.",
    autoend_preference_message: "Please note that auto-end settings only apply at public car parks.",
    chat_details: "Chat details",
    sharing_reminder: "Sharing reminder",
    sharing_reminder_description: "Set the frequency and time and we'll send you a notification to share your bay.",
    set: "Set",
    frequency: "Frequency",
    choose_time: "Choose time",
    choose_frequency: "Choose frequency",
    sharing_reminder_is_set:
        "Your sharing reminder is set! You'll receive push notifications through your iOS or Android app.",
    time_is_not_set: "Time is not set!",
    parkable_contact_message:
        "Thanks for your message, the team will get back to you as soon as they can. If you need help urgently, ",
    please_call_us: "Please call us",
    call_24_7_line: "on our 24/7 line.",
    this_registration_is_already_added: "This registration is already added",
    having_trouble: "Having trouble logging in?",
    try_another_way: "Try another way",
    email_sign_in: "Email log in",
    send_link: "Send link",
    check_your_inbox: "Check your inbox",
    click_link_sent_to: "Click the link we sent to ",
    to_sign_in: " to sign in.",
    didnt_get_email: "Didn't get the email?",
    link_already_used_or_expired: "The link has been used or has expired. Click 'Send again' to get a new one.",
    link_error: "Link error",
    send_again: "Send again",
    link_sent: "Link sent",
    please_check_email: "Please check your email.",
    email_links_same_device: "The email provided does not match the sign-in email address.",
    already_signed_in: "Already signed in",
    error_try_again: "An error has occurred, please try again",
    email_link_generic_error:
        "An error has occured with this link, please try again.  If this problem persists, please contact Parkable.",
    password_changes_disabled_for_account: "Password changes are disabled for this account",
    login_as: "Log in as...",
    enter_password_title: "Enter password",
    enter_password_desc: "Please enter your password to prove it's you.",
    important_update: "Update available",
    important_update_desc: "Please update your app to continue.",
    open_in_google_maps: "Open in Google Maps",
    open_in_apple_maps: "Open in Apple Maps",
    copy_address: "Copy address",
    select_from_available_options: "Select from the available options.",
    could_not_add_card: "Could not add card",
    password_error: "Password Error",
    password_complexity_letter: "Must contain at least one uppercase and lowercase letter",
    password_complexity_character: "Must contain at least one number and special character",
    password_no_space_notice: "White space cannot be included at the start or end of passwords.",
    password_restrictions:
        "Password must contain at least one uppercase and lowercase letter, one number and special character, and at least 8 characters long",
    teams_signup_info:
        "Hi, thanks for installing the Parkable Teams app. It looks like your domain hasn't been verified or your company currently isn't using Parkable. If you do use Parkable at your company, contact your Parkable admin to verify your domain and to start using our Teams app. If you don't use Parkable, to learn more about us visit our website at ",
    teams_permissions_required: "You must accept the terms and conditions in order to use Parkable for Teams.",
    vehicle_limit_reached: "You have reached your maximum number of vehicles. Please remove a vehicle to add another.",
    popups_blocked: "The consent pop-up has been blocked. Please allow pop-ups for this site and try again.",
    an_error_occured: (details: string) => "An error has occurred (" + details + ")",
};

export type IStrings = typeof en;

export default en as IStrings;
