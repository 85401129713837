import React, { useCallback, useMemo, useState } from "react";
import Dialog from "react/components/dialog/Dialog";
import Strings from "../../../constants/localization/localization";
import { Linking, StyleSheet, View } from "react-native";
import { useAppDispatch, useSelector } from "../../../redux/redux";
import { showAlert } from "../../../alerts";
import CheckBox from "../../../parkable-components/checkBox/CheckBox";
import Text from "../../../parkable-components/text/Text";
import Colours from "../../../parkable-components/styles/Colours";
import { IRootReducer } from "../../../redux/reducers/main";
import { reAcceptTsAndCs, useLatestTsAndCs } from "../../../api/user/user.api";
import { setUser } from "../../../redux/actions/user";
import moment from "moment/moment";
import { spacing } from "react/constants/theme/spacing";

export default function ReAcceptTsAndCsModal() {
    const dispatch = useAppDispatch();
    const { user } = useSelector((state: IRootReducer) => state.user);

    const [termsChecked, setTermsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const { lastUpdated } = useLatestTsAndCs();

    const show = useMemo(() => {
        if (!lastUpdated || !user) {
            return false;
        }
        if (user.lastAcceptedTsAndCs === null) {
            return true;
        }
        const lastUpdatedDate = moment(lastUpdated).format("YYYY-MM-DD");
        const lastAcceptedDate = moment(user.lastAcceptedTsAndCs).format("YYYY-MM-DD");
        return lastAcceptedDate < lastUpdatedDate;
    }, [user, lastUpdated]);

    const onContinue = useCallback(async () => {
        if (!user) {
            return;
        }
        setLoading(true);
        try {
            const response = await reAcceptTsAndCs(user.id);
            dispatch(setUser(response.user));
        } catch (error) {
            showAlert(Strings.error, Strings.error);
            setLoading(false);
        }
    }, [user]);

    const onTermsClick = async () => {
        const URL = "https://www.parkable.com/terms-of-service";
        if (await Linking.canOpenURL(URL)) {
            await Linking.openURL(URL);
        }
    };

    const toggleTermsChecked = () => {
        setTermsChecked(!termsChecked);
    };

    return (
        <Dialog
            disableCloseOnBackdropPress
            isVisible={show}
            style={[styles.mainView]}
            positiveText={Strings.continue}
            positiveProps={{ textProps: { small: true }, loading, disabled: !termsChecked || loading }}
            onPositivePress={onContinue}
            cardContentStyle={{ paddingTop: 0 }}
        >
            <View>
                <Text h2 style={{ marginTop: spacing(2) }}>
                    {Strings.terms_condition_update}
                </Text>
            </View>
            <View style={styles.modalContents}>
                <Text small grey>
                    {Strings.terms_condition_update_label}
                    <Text onPress={onTermsClick} small grey style={{ textDecorationLine: "underline" }}>
                        {Strings.terms_condition_update_label_link}
                    </Text>
                </Text>
            </View>
            <CheckBox initialChecked={termsChecked} onChange={toggleTermsChecked}>
                <Text small>{Strings.terms_condition_update_checkbox}</Text>
            </CheckBox>
        </Dialog>
    );
}

const styles = StyleSheet.create({
    mainView : {
        borderColor: Colours.GREY_BORDER,
        borderWidth: 1,
        borderRadius: 3,
        alignItems: 'center'
    },
    modalContents: {
        marginBottom: 16
    },
    checkbox: {
        flexDirection: "column"
    }
});
