import { Colours } from 'react/parkable-components';

export const pickColor = (index: number) => {
    switch (index) {
        case 0:
            return Colours.BLUE_300;
        case 1:
            return Colours.PURPLE;
        case 2:
            return Colours.ORANGE;
        case 3:
            return Colours.GREEN_300;
        default:
            return Colours.GREY_20
    }
};
