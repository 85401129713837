/**
 * Central location for pathnames to make refactoring paths easier,
 * compared to using string literals
 */
import TeamsAuthStart from "../../components/teams/TeamsAuthStart";

export enum Routes {
	LandingView = "landing",
	TeamsLandingPage = "teams", //web only route
	TeamsAuthStart = "teams-auth-start",
	TeamsAuthEnd = "teams-auth-end",//web only route
	LoginOrSignUpView = "login",
	CreateAccountView = "login/create-account",
	ChangeServerView = "change-server",
	UpdateCheckView = "update-check-view",
	SamlLoginView = "saml-login-route",
	ForgotPasswordView = "forgot-password",
	VerifyEmailView = "verify-email",
	MagicLinkView = "magic-link",

	ParkableMapView = "explore",
	MapSettingsView = "map/settings",
	FindParkView = "map/search",
	QRScannerView = "map/qr",

	AccountView = "account", // Index account page
	MyDetailsView = "account/details",
	SessionHistoryDetailView = "account/history/:sessionId",
	SessionHistoryView = "account/history",
	ChangePasswordView = "account/details/change-password",
	UserBookingsAndRequestsView = "account/bookings",
	SubscriptionListView = "account/subscriptions",
	MessagesView = "account/messages",
	VouchersView = "account/vouchers",
	AddVoucherCode = "account/vouchers/add",
	VoucherDetailsView = "account/vouchers/:id",
	VehiclesView = "account/vehicles",
	CreditCardView = "account/payment",
	AddNewCard = "account/add-new-card",
	AddNewVehicleView = "account/vehicles/add",
	EditVehicleView = "account/vehicles/edit/:id",
	RfidCardsView = "account/rfid",
	ConfirmRemoveRfidView = "account/rfid/:cardId/remove",
	NotificationSettingsView = "account/notifications",

	AssignSensorQrCodeView = "admin/qr/assign",
	ScanSensorQrCodeView = "admin/sensor/scan",

	CampusScreenView = "campus/:campusId",
	ParkDetailView = "park/:parkId",
	FutureBookingView = "park/:parkId/book",
	ConfirmedBookingView = "booking/:parkingRequestId",
	HowItWorks_UpcomingWeeksView = "bookings/help/upcoming-weeks",
	FutureBooking_ThisWeek = "bookings/help/current-week",

	SelectSubscriptionPlanView = "park/:parkId/select-subscription-plan",
	SubscriptionDetailsView = "park/:parkId/subscription-details",
	StartSubscriptionSummaryView = "park/:parkId/start-subscription-summary",
	SingleSubscriptionView = "subscriptions/:subscriptionId",
	SubscriptionCancelView = "subscriptions/:subscriptionId/cancel",
	SubscriptionInvoicesView = "subscriptions/:subscriptionId/invoices",

	ConfirmStartReservationView = "session/reservation",
	ActiveSession = "session/active",
	SessionSummary = "session/summary",
	ConfirmStartParkingView = "park/:parkId/confirm-start-parking",
	ParkTomorrowView = "park/:parkId/park-tomorrow",
	ActiveParkingRequest = "active-parking-request",
	ConnectEvNozzleView = "park/:parkId/connect-ev-nozzle",
	StartChargingByLinkView = "park/:parkId/start-charging",

	Problem = "problem",
	MessageView = "problem/message",

	RetryPaymentRequest = "session/payment-retry",

	AutoEndSessionView = "AutoEndSessionView",

	ParkAvailabilityView = "ParkAvailabilityView",
	SharingPoolView = "SharingPoolView",
	SubscriptionSharingCreditView = "SubscriptionSharingCreditView",
	ProblemVehicleInBay = "ProblemVehicleInBay",
	ProblemObjectInBay = "ProblemObjectInBay",
	ProblemCantFindBay = "ProblemCantFindBay",
	ProblemOther = "ProblemOther",
	AcceptTsAndCsView = "AcceptTsAndCsView",
	AnimationView = "AnimationView",
	MakeBayAvailable = "MakeBayAvailable",
	WarningOpenSessionView = "WarningOpenSessionView",
	ChangeBayView = "ChangeBayView",
	AssignSensorQrCode = "AssignSensorQrCode",
	AssignSensorBayList = "AssignSensorBayList",
	AssignQRForBay = "AssignQRForBay",
	ConnectEVNozzle = "ConnectEVNozzle",
	LoadingView = "LoadingView",
	SelectBay = "SelectBay",
	VoucherDetail = "VoucherDetail",
	QrScannerView = "QrScannerView",
	PrivacySettingsView = "PrivacySettingsView",
	HowItWorks_ThisWeekView = "HowItWorks_NextWeekView",
	SelectParkView = "SelectParkView",
	PreferredBaysView = "PreferredBaysView",
	PreferredBaysSelectionView = "PreferredBaysSelectionView",
	MyRewardsView = "MyRewardsView",
	HowItWorks_Rewards = "HowItWorks_Rewards",
	ConfirmStartTandemParkingView = "ConfirmStartTandemParkingView",
	TandemChatView = "TandemChatView",
	ChatDetailsView = "ChatDetailsView",
	AdminLoginAsUserView = "account/login-as",
	PricingView = "PricingView",

	SupportView = "support/cases",
	AddNewCaseView = "support/cases/add",
	CaseDetailsView = "support/cases/:caseId/details",
}
