import { FontAwesome } from "@expo/vector-icons";
import * as Font from "expo-font";

const fonts = {
    "GTEestiDisplay-Regular": require("../../assets/fonts/GTEesti/GTEestiDisplay-Regular.ttf"),
    "GTEestiDisplay-Bold": require("../../assets/fonts/GTEesti/GTEestiDisplay-Bold.ttf"),
    "GTEestiDisplay-Regular-Italic": require("../../assets/fonts/GTEesti/GTEestiDisplay-Regular-Italic.ttf"),
    "SourceSansPro-Bold": require("../fonts/SourceSansPro-Bold.ttf"),
    "SourceSansPro-SemiBold": require("../fonts/SourceSansPro-Semibold.ttf"),
    "SourceSansPro-Regular": require("../fonts/SourceSansPro-Regular.ttf"),
    parkableicons: require("../../react/fonts/parkableicons.ttf"),
    ...FontAwesome.font,
    "space-mono": require("../../assets/fonts/SpaceMono-Regular.ttf"),
};

export default function useFontsLoaded() {
    const [loaded] = Font.useFonts(fonts);
    return loaded;
}
