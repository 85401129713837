import {Image, View} from "react-native";
import React, {useEffect} from "react";
import {createRoute, NavigationProps} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import * as MicrosoftTeams from "@microsoft/teams-js";
import Colours from "react/parkable-components/styles/Colours";
import {style} from "./constants";
import useConfig from "../../hooks/useConfig";
import {PublicClientApplication} from "@azure/msal-browser";
const splash = require("assets/meta/splash.png");


type Props = NavigationProps<Routes.TeamsAuthStart>;

const TeamsAuthEnd =  (props: Props) => {
    const config = useConfig();
    useEffect(() => {
        let hashParams = getHashParameters();
        console.log("TEAMS LOGGING", hashParams);
        if (hashParams["error"]) {
            // Authentication/authorization failed
            MicrosoftTeams.authentication.notifyFailure(hashParams["error"]);
        }
        else {
            MicrosoftTeams.authentication.notifySuccess('success');
        }
    }, []);

    const  getHashParameters = (): any => {
        let hashParams = {};
        location.hash.substring(1).split("&").forEach(function(item) {
            let s = item.split("="),
                k = s[0],
                v = s[1] && decodeURIComponent(s[1]);
            // @ts-ignore
            hashParams[k] = v;
        });
        return hashParams;
    }

    return (
        <View style={[{flex: 1, backgroundColor: Colours.NEUTRALS_WHITE, margin: 10}]}>
            <Image style={style.image} source={splash} />
        </View>
    );
};

export default TeamsAuthEnd;

export const TeamsAuthEndRoute = createRoute({
    path: Routes.TeamsAuthEnd,
})



