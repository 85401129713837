import React, { useState } from "react";
import { Routes } from "react/navigation/root/root.paths";
import { createRoute, NavigationProps } from "react/navigation/constants";
import ParkableBaseView from "react/components/common/ParkableBaseView";
import { createCase, updateCase, useSupportAccount } from "react/api/support/support.api";
import { SupportCaseDetails } from "react/api/support/dto/SupportCase.dto";
import { useCurrentUser } from "react/api/user/user.api";
import moment from "moment";
import { ChatContainer } from "react/components/messages/ChatContainer";
import { ChatMessageDTO, MessageType } from "react/model/chat/ChatMessageDTO";
import { ChatMessage } from "react/components/messages/ChatMessage";
import { PADDING } from "react/root/root.constants";
import Strings from "react/constants/localization/localization";

class AddNewCaseViewParams {
    organisationId?: number;
    bayGroupId?: number;
    sessionId?: number;
    subscriptionId?: number;
}

const AddNewCaseView = (props: NavigationProps<Routes.AddNewCaseView>) => {
    const {sessionId, subscriptionId } = props.route.params ?? {};
    const { user } = useCurrentUser();
    useSupportAccount();
    const now = moment();

    const initialMessage: ChatMessageDTO = {
        body: Strings.reached_parkable_support,
        type: MessageType.User,
        receiverHasRead: true,
        createdAt:now.toISOString()
    };

    const [messages, setMessages] = useState<ChatMessageDTO[]>([initialMessage]);
    const [subject, setSubject] = useState<string |undefined>();
    const [newCase, setNewCase] = useState<SupportCaseDetails|undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [hideInput, setHideInput] = useState(false);

    const onSendPress = async (text: string, onSuccess: any) => {
        if (!text.length) {
            return;
        }
        setLoading(true);

        const userMessage: ChatMessageDTO = {
            body: text,
            type: MessageType.User,
            senderId: user?.id,
            receiverHasRead: true,
            createdAt: moment().toISOString()
        };

        if (!subject) {
            setSubject(text);
            const {supportCase} = await createCase({subject: text, sessionId, subscriptionId})
            setNewCase(supportCase);

            const parkableMessages = [{
                    body: Strings.support_ticket_created(supportCase.caseNumber),
                    type: MessageType.User,
                    receiverHasRead: true,
                    createdAt: now.toISOString()
                }, {
                    body: Strings.add_details_to_case_question,
                    type: MessageType.User,
                    receiverHasRead: true,
                    createdAt: now.toISOString()
            }]

            setMessages(previous => [...previous, userMessage, ...parkableMessages]);
        } else {
            const description = text;
            const {supportCase} = await updateCase(newCase!.id, {description})
            setNewCase(supportCase);
            setHideInput(true);
            setMessages([...messages, userMessage, {
                body: Strings.final_msg_chat_support,
                type: MessageType.User,
                receiverHasRead: true,
                createdAt: now.toISOString()
            }]);
        }

        onSuccess();
        setLoading(false);
    }

    const renderMessage = (message: ChatMessageDTO, index: number, previousMessage?: ChatMessageDTO) => {
        const currentMessageTime = moment(message.createdAt).seconds(0).milliseconds(0);
        const displayDateTime = !previousMessage || !currentMessageTime.isSame(moment(previousMessage.createdAt).seconds(0).milliseconds(0));

        return (
            <ChatMessage
                key={index}
                index={index}
                hideDateTime={!displayDateTime}
                message={message}
                isCurrentUser={message.senderId === user?.id}
                participantIndex={message.senderId === user?.id ? 1 : -1}
                sender={{ userName: message.senderId === user?.id ? user?.email : Strings.parkable }}
            />
        );
    };

    return (
        <ParkableBaseView
            scrollable={false}
            toolbarStyle={{marginLeft: PADDING}}
            removeStandardMargins
            toolbarTitleText={Strings.support}>
            <ChatContainer
                hideInput={hideInput}
                messages={messages}
                onSendPress={onSendPress}
                renderMessage={renderMessage}
                loading={loading}
            />
        </ParkableBaseView>
    );
};

export default AddNewCaseView;

export const AddNewCaseViewRoute = createRoute({
    path: Routes.AddNewCaseView,
    params: {
        type: AddNewCaseViewParams,
    },
});