import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Platform, StyleSheet, View } from "react-native";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Icons from "react/parkable-components/icon/Icons";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Text from "react/parkable-components/text/Text";
import Dialog from "react/components/dialog/Dialog";
import Strings from "../../../constants/localization/localization";
import { connect } from "react-redux";
import { Bay } from "../../../model/Bay";
import UserDetailsCard from "../../common/UserDetailsCard";
import {
    cancelReservation,
    getCurrentParkingSession,
    startParkingFromReservation
} from "../../../redux/actions/parking";
import { showAlert, showConfirmation } from "../../../alerts";
import { IRootReducer } from "../../../redux/reducers/main";
import ParkImagesCarousel from "../../common/ParkImagesCarousel";
import InstructionsCard from "../../common/InstructionsCard";
import { ParkingType } from "../../../model/Types";
import AccessGateComponent, { ComponentType } from "../../accessControl/AccessGateComponent";
import { getBay } from "../../../redux/actions/parks";
import localizeCurrency from "../../../constants/localization/localizeCurrency";
import SelectedVoucherCard from "../../common/voucher/SelectedVoucherCard";
import { ActivityType, Voucher } from "../../../model/Voucher";
import { ParkSessionDTO } from "../../../model/ParkSessionDTO";
import { ParkableError } from "../../../model/ParkableError";
import useSafeArea from "../../../hooks/useSafeArea";
import { getUserOptions } from "../../../redux/actions/userOptions";
import { logEvent } from "react/analytics";
import { ParkAvailabilityViewParams } from "../ParkAvailabilityView";
import { getAvailabilityInfo } from "../confirmStartParking/availabilityInfo";
import ReservationTableRow from "../../parkDetails/ReservationTableRow";
import { Park } from "../../../model/Park";
import { getUserCards, getUserVehicles } from "../../../redux/actions/user";
import { retrieveLargestDiscountVoucher } from "../../../redux/actions/vouchers";
import { usePark } from "../../../api/park";
import { useTerritory } from "../../../api/territory/territory.api";
import DriveTimeTableRow from "../DriveTimeTableRow";
import SelectedBayCard from "../../common/SelectedBayCard";
import TandemBayAllocatedAlert from "../../tandemParking/TandemBayAllocatedAlert";
import { createRoute, NavigationProps, useNavigation } from "react/navigation/constants";
import { Routes } from "../../../navigation/root/root.paths";
import ParkableBaseView from "../../common/ParkableBaseView";
import { CarParkNameRow } from "react/components/widgets/table-rows/car-park-row";
import { useAccessGatesInPark } from "../../../api/accessGate/accessGate.api";
import {useBayGroup} from "react/api/bayGroup/bayGroup.api";
import {useCurrentParkingSession} from "react/api/parking";
import CasualCharge from "react/components/parkDetails/CasualCharge";
import { SharingPoolRow } from "react/components/parkDetails/sharing-pool/SharingPoolRow";
import {handleFailedTransaction} from "react/constants/ExceptionHandler";
import { useUserRoles } from "react/api/user/user.api";
import { userIsOrgMember } from "react/constants/getUserMember";

class ConfirmStartReservationViewProps {
}

function ConfirmStartReservationView(props: ReduxProps & typeof mapDispatchToProps & NavigationProps<Routes.ConfirmStartReservationView>) {
    const { top } = useSafeArea();
    const navigation = useNavigation();
    const {
        userVehicles,
        userCards,
        currentVehicleId,
        startParkingFromReservation,
        getBay,
        voucher,
        userOptions,
        getUserOptions,
        userId,
        getUserVehicles,
        parkId,
        retrieveLargestDiscountVoucher,
        getUserCards,
        getCurrentParkingSession
    } = props;

    const { parkSession: currentSession } = useCurrentParkingSession();
    const {park} = usePark(parkId);
    const {territory} = useTerritory(park?.territory);

    const [vacateBayDeadline] = useState<string | null>(null);
    const [startParkingError, setErrorStartParking] = useState<ParkableError>();
    const [bay, setBay] = useState<Bay | undefined>(undefined);
    const voucherDialogRef = useRef<DialogRef|null>(null);
    const reserveBayDialogRef = useRef<DialogRef|null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const {gates, isLoading: gatesLoading} = useAccessGatesInPark(park?.id);
    const { bayGroup } = useBayGroup(park?.organisation, bay?.group);
    const { userRoles } = useUserRoles();

    useEffect(() => {
        if (!parkId) {
            return;
        }
        if(!userVehicles){
            getUserVehicles();
        }
        getCurrentParkingSession();
        getUserCards();
        retrieveLargestDiscountVoucher(parkId, ActivityType.Casual);
        retrieveLargestDiscountVoucher(parkId, ActivityType.LongTerm);
    }, [parkId, userVehicles]);

    useEffect(() => {
        if(!!currentSession && !!currentSession.bay) {
            (async () => {
                // @ts-ignore
                const bay:Bay = await getBay(currentSession.park, currentSession.bay!);
                setBay(bay)
            })()
        }
    }, [currentVehicleId, userVehicles.length, bay, currentSession]);



        /**
     * called after errorStartParking is called
     * */
    useEffect(() => {
        if (startParkingError) {
            if(!handleFailedTransaction(startParkingError, navigation)){
                showAlert(startParkingError.message??Strings.internal_error_if_persists, Strings.error);
            }
        }
    }, [isLoading, startParkingError]);

    const myVoucher = useRef<Voucher>(voucher);

    useEffect(() => {
        if(!myVoucher.current && !!voucher ||
                !!myVoucher.current && !!voucher && myVoucher.current.id != voucher.id) {
            voucherDialogRef.current?.show();
        }
    }, [voucher, myVoucher, voucherDialogRef]);

    const isParkPFB = !!park?.organisation;

    const onConfirmStartParkingPress = (gateId: string|undefined, estimatedLeaveTime: string | null) => {

        const disabled =  (!currentSession && isParkPFB);

        console.log("disabled = ", disabled, isParkPFB);

        if (isLoading || disabled) {
            console.log("leaving early");
            return;
        }

        setIsLoading(true);

        const paramsEvent = { userId: `${userId??0}`, parkId: `${parkId}` };
        logEvent(undefined,'confirmdetails_confirm',paramsEvent,'confirmdetails_confirm',paramsEvent);

        const onSuccess = async (session: ParkSessionDTO | null) => {
            if (!!session) {
                await finishStartParking(session);
            }
        };

        const userHasCards = userCards?.length > 0;

        if(currentSession) {
            startParkingFromReservation(
                currentSession.id,
                gateId,
                territory,
                userHasCards,
                estimatedLeaveTime,
                onSuccess, errorStartParking);
        }
    };

    const handleStartSessionPress = () => {
        if (bay?.tandemPod) {
            navigation.push(Routes.ConfirmStartTandemParkingView, {
                parkId: bay.park,
                onConfirmTandemParking: (estimatedLeaveTime: string | null) =>
                    onConfirmStartParkingPress(undefined, estimatedLeaveTime)
            });
        }
        else {
            onConfirmStartParkingPress(undefined, null);
        }
    }

    const errorStartParking = useCallback((err: any) => {
        console.log('Error on start parking ', err);
        //navigation.pop();

        setIsLoading(false);
        /*
        * hook is called after this [isLoading, startParkingError]
        * */
        setErrorStartParking(err);
    }, [navigation]);

    const showActiveSessionView = () => {
        navigation.reset({
            routes: [{
                name: Routes.ActiveSession
            }]
        })
    };

    const showParkAvailabilityView = () => {
        if (availabilityInfo) {
            navigation.push(Routes.ParkAvailabilityView, availabilityInfo);
        }
    };

    const finishStartParking = (session: ParkSessionDTO) => {
        if (!bay) {
            return;
        }
        setIsLoading(false);

        if(!gates?.length && props.parkingType === ParkingType.ELECTRIC && parkId){
            navigation.push(Routes.ConnectEVNozzle, {
                bayId: bay.id,
                parkId,
                sessionId: session.id,
                overrideBackButton: true
                });
        }
        else {
            showActiveSessionView();
        }
    };

    useEffect(() => {
        if (!userOptions) {
            getUserOptions();
        }
    }, [userOptions]);

    const availabilityInfo = useMemo((): ParkAvailabilityViewParams | null => {
        if (!park || !bay) {
            return null;
        }
        return getAvailabilityInfo(park, bay?.id, vacateBayDeadline);
    }, [park, bay, vacateBayDeadline]);

    let buttonText = Strings.start_session;

    const cancelReservation = useCallback(() => {
        if (!currentSession || !parkId) {
            return;
        }
        setIsLoading(true);
        props.cancelReservation(currentSession.id, parkId, () => {
            navigation.reset({
                routes: [{
                    name: Routes.ParkDetailView,
                    params: {parkId:parkId}
                }]
            })
            setIsLoading(false);
        }, () => {
            setIsLoading(false);
        });
    }, [currentSession]);

    const confirmButtonDisabled = (!currentSession && (isParkPFB && !bay)) || (!!currentSession && (!!currentSession.bay && !bay));
    const showSharingPoolRow = currentSession?.isSharingPool && userIsOrgMember(userRoles, park?.organisation)

    return (<ParkableBaseView loading={isLoading}>
            <View style={{flex: 1}}>
                    <View style={styles.mainContainer}>
                        <Text h1>
                            {Strings.reservation_details}
                        </Text>

                        <TandemBayAllocatedAlert
                            bay={bay}
                            onConfirmTandemParking={(estimatedLeaveTime: string | null) =>
                                onConfirmStartParkingPress(undefined, estimatedLeaveTime)
                            }
                        />

                        <ReservationTableRow reservationMinutes={park?.reservationMinutes}/>

                        {showSharingPoolRow && <SharingPoolRow />}

                        {availabilityInfo && <TableRow style={{alignContent: "flex-start"}}
                                  chevron
                                  backgroundColor={availabilityInfo.bonusBayStyle ? Colours.ORANGE : Colours.GREY_10}
                                  iconLeft={"parkingfilled"}
                                  disabled={availabilityInfo.disabled}
                                  onPress={showParkAvailabilityView}>
                            {availabilityInfo.info}
                        </TableRow>}

                        {parkId && <SelectedBayCard bay={bay} parkId={parkId} parkingType={ParkingType.CASUAL} />}

                        <UserDetailsCard hideCreditCard />

                        {park && territory &&
                            <CasualCharge
                                bay={bay}
                                parkingType={ParkingType.CASUAL}
                                territory={territory}
                                park={park}
                                parkingPrice={currentSession?.parkingPrice}
                            />
                        }

                        <UserDetailsCard hideVehicle />

                        <CarParkNameRow displayName={park?.displayName}/>

                        <DriveTimeTableRow showDriveTime={false} endLatitude={park?.latitude} endLongitude={park?.longitude} park={park || null} showLocationLabel={true}/>

                        {props.parkingType === ParkingType.ELECTRIC && <TableRow label={Strings.electricity_price} textProps={{small:true}} iconLeft={"electricvehicleplug"}>
                            {localizeCurrency(currentSession?.pricePerKWH ?? bayGroup?.pricePerKwh ?? park?.pricePerKwh ?? 0, territory?.currencyCode, false) + " " + Strings.perKwh}
                        </TableRow>}

                        {!!park && <SelectedVoucherCard territory={territory} voucher={voucher} park={park} activity={ActivityType.Casual}/>}

                        <Text h2 style={{marginTop:29, marginBottom:18}}>
                            {Strings.how_to_park_here}
                        </Text>

                        {!!park && <ParkImagesCarousel park={park} />}

                        {!!park && <InstructionsCard park={park as unknown as Park} />}
                    </View>

                <View style={styles.bottomBtn}>
                    <View style={styles.paddingBottom}>
                        {park &&
                            <AccessGateComponent
                                showEntranceGates={true}
                                componentType={ComponentType.Button}
                                buttonDisabled={confirmButtonDisabled}
                                buttonIcon={"arrowboldright"}
                                buttonText={buttonText}
                                onSelectGate={(gateId: string|undefined) => onConfirmStartParkingPress(gateId, null)}
                                selectOnly={true}
                                parkId={park.id}
                                displayModalWhenNotInRange={false}
                            />}

                        {!gatesLoading && !gates?.length &&
                            <Button
                                onPress={handleStartSessionPress}
                                disabled={confirmButtonDisabled}
                                iconRight={"arrowboldright"}
                                style={{justifyContent: 'space-between'}}>
                                {buttonText}
                            </Button>}
                    </View>
                    <View style={styles.paddingTop}>
                        <Button red center plain
                                textProps={{center:true, h5:true, white:true}}
                                onPress={() => reserveBayDialogRef.current?.show()}>
                            {Strings.cancel_reservation}
                        </Button>
                    </View>
                </View>
            </View>
            <Dialog style={[{top}, styles.voucherDialog]}
                    ref={voucherDialogRef}
                    icon={Icons.tick} iconProps={{white:true}}
                    label={Strings.voucher_applied}/>
            <Dialog
                ref={reserveBayDialogRef}
                label={Strings.end_reservation}
                labelProps={{style: {color: Colours.NEUTRALS_BLACK, textAlign: 'left'}}}
                title={Strings.confirm_end_reservation}
                titleProps={{style: {textAlign: 'left'}, h2: undefined}}
                positiveText={Strings.ok}
                positiveProps={{textProps: {h5: true, style: {color: Colours.NEUTRALS_BLACK}}, style: {backgroundColor: Colours.ORANGE}}}
                negativeProps={{textProps: {h5: true, style: {color: Colours.NEUTRALS_BLACK}}}}
                negativeText={Strings.cancel}
                onPositivePress={() => cancelReservation()}
                onNegativePress={() => reserveBayDialogRef.current?.hide()}
            />
        </ParkableBaseView>
    );
}

const getReduxProps = (state: IRootReducer, props: NavigationProps<Routes.ConfirmStartReservationView>) => {
    const {session} = props.route.params;
    const currentSession = session??state.parking.currentSession

    const parkId = currentSession?.park;
    return {
        parkId,
        parkingType: state.maps.preferences.parkingType,
        api: state.data.api,
        currentSession,
        userCards: state.user.cards,
        currentCardId: state.user.currentCardId,
        currentVehicleId: state.user.currentVehicleId,
        userVehicles: state.user.vehicles,
        voucher: parkId ? state.user?.casualVoucher?.[parkId]: null,
        userOptions: state.userOptions.userOptions,
        userId: state.user.user?.id,

    }
};

const mapDispatchToProps = {
    startParkingFromReservation,
    getBay,
    getUserOptions,
    cancelReservation,
    getUserVehicles,
    retrieveLargestDiscountVoucher,
    getUserCards,
    getCurrentParkingSession
};


type ReduxProps = ReturnType<typeof getReduxProps>;

export default connect(getReduxProps, mapDispatchToProps)(ConfirmStartReservationView) as any as React.FC<ConfirmStartReservationViewProps>;

export const ConfirmStartReservationRoute = createRoute({
    path: Routes.ConfirmStartReservationView,
    params: {type: ConfirmStartReservationViewProps},
});


const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        paddingTop: 14,
        paddingBottom: 121,
    },
    imageScrollView: {
        marginTop: 27,
        height: 120,
        width: '100%'
    },
    bottomBtn: {
        position: 'absolute',
        backgroundColor: Colours.NEUTRALS_WHITE,
        bottom: 0,
        left: 0,
        right: 0,
        paddingBottom: Platform.OS == "ios" ? 70 : 40,
        paddingTop: 9,
    },
    paddingTop:{
        paddingTop: 9,
    },
    paddingBottom:{
        // paddingLeft: 28,
        // paddingRight: 28,
    },
    modal:{
        backgroundColor: "rgba(0,0,0,0.1)",
    },
    openView: {
        borderWidth: 1,
        borderColor: Colours.GREY_BORDER,
        borderRadius: 3,
        padding:18,
        backgroundColor: Colours.NEUTRALS_WHITE,
        marginHorizontal: 9,
        marginVertical: 28,
    },
    modalButtonView : {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 27,
    },
    textLine:{
        marginBottom:18,
    },
    lineBreak: {
        height: 1,
        width: '100%',
        marginBottom:18,
        backgroundColor: Colours.GREY_BORDER
    },
    voucherDialog: {
        position:'absolute',
        width:'100%'
    },
    sharingPoolRowContainer: {
        marginBottom: 9,
    },
    sharingPoolRowText: {
        maxWidth: 120,
        alignContent: "flex-start",
    },
});
