import React from "react";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import Icon from "react/parkable-components/icon/Icon";
import { StyleSheet, View } from "react-native";
import Strings from "../../constants/localization/localization";
import { OcpiSession } from "../../model/ocpi/OcpiSession";
import { ParkSessionDTO } from "../../model/ParkSessionDTO";
import { useCalculateEstimatedAmount } from "react/api/parkingPrice/parkingPrice.api";
import localizeCurrency from "react/constants/localization/localizeCurrency";
import { ParkDTOWithTerritory } from "react/redux/actions/parks";
import { ParkingPriceBreakdownItemType } from "../../api/parkingPrice/dto/ParkingCost.response";

type CurrentChargeCarouselItemProps = {
    session: ParkSessionDTO,
    style: {},
    currencyCode: string,
    ocpiSessions: Array<OcpiSession> | undefined
    park: ParkDTOWithTerritory;
}

export default function CurrentChargeCarouselItem(props: CurrentChargeCarouselItemProps) {
    const { session, style, currencyCode, park } = props;

    const { cost, breakdown } = useCalculateEstimatedAmount({
        organisationId: park.organisation,
        parkId: park.id,
        sessionId: session?.id,
    });

    const processingFee = breakdown?.find(p => p.type === ParkingPriceBreakdownItemType.ProcessingFee)
        ?.costInCents ?? 0;

    if(cost === undefined) {
        return <View />;
    }
    const isFreeSession = cost === 0 && !!session.freeSession;
    const totalCost = (cost ?? 0) + processingFee;

    return (
        <View style={[styles.mainStyle, style]}>
            <Icon color={Colours.GREY_50} style={{width: "100%", flex: 2}} iconStyle={{fontSize: 81,}} name={'dollarandcalculator'}/>
            <View style={styles.subView}>
                <Text grey>{Strings.running_total}</Text>
                <Text bold >{isFreeSession ? Strings.free_session :
                    localizeCurrency(totalCost, currencyCode ?? undefined, false, true)}</Text>
            </View>
        </View>);
}

const styles = StyleSheet.create({
    mainStyle: {
        backgroundColor: Colours.GREY_10,
        paddingTop: 18,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 18,
        flex: 1,
        alignItems:"center",
        justifyContent: "space-between"
    },
    subView: {
        alignItems:"center",

    }
})
