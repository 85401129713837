import React, { useCallback } from 'react';
import Strings from "../../../constants/localization/localization";
import { Routes } from "react/navigation/root/root.paths";
import { TableRow, Colours, IconProps, IconName } from 'react/parkable-components'
import { useNavigation } from "../../../navigation/constants";
import { useUserCards } from "../../../api/stripe/stripe.api";

function AccountCreditCardsCardItem({
    icon,
    iconProps
}: Props) {

    const navigation = useNavigation();
    const { cards, currentCardId, isLoading } = useUserCards()

    const currentCard = currentCardId
        ? cards?.find(x => x.id === currentCardId)
        : cards?.[0];

    const onEditCreditCardPress = useCallback(() => {
        if (cards?.length) {
            navigation.push(Routes.CreditCardView, {});
        } else {
            navigation.push(Routes.AddNewCard, {});
        }
    }, [cards]);

    return (
        <TableRow
            label={Strings.payment_method}
            chevron iconLeft={icon ?? "shieldwithtick"}
            chevronText={"Change"}
            iconLeftProps={{ color: Colours.GREEN_300, ...(iconProps ?? {}) }}
            onPress={onEditCreditCardPress} >
            {isLoading && Strings.loading}
            {!isLoading && <>
                {currentCard
                    ? `${Strings.card_ending} ${currentCard.last4}`
                    : Strings.no_cards
                }
            </>}
        </TableRow>
    );
}

interface OwnProps {
    hideVehicle?: boolean;
    hideCreditCard?: boolean;
    updateSession?: boolean;
    icon?: IconName;
    iconProps?: Omit<IconProps, "name">;
}

type Props = OwnProps;
export default (AccountCreditCardsCardItem) as React.FunctionComponent<OwnProps>;
