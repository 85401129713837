import React, { useCallback, useEffect, useState } from "react";
import { Image, Keyboard, Platform, StyleSheet, View } from "react-native";
import Strings from "../../../constants/localization/localization";
import Button from "react/parkable-components/button/Button";
import Input from "react/parkable-components/input/Input";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import QRActionHandler from "./QRActionHandler";
import { getQRAction } from "react/redux/actions/qrAction";
import QRAction, { ActionStatus, ActionType } from "../../../model/QRAction";
import { useNavigation } from "react/navigation/constants";
import { AllViewProps } from "./QrScannerView";
import ParkableBaseView from "../ParkableBaseView";
import { useAppDispatch, useReduxMaps } from "react/redux/redux";
import { Feature } from "react/model/Bay";
import { useBaysAvailableToUser } from "react/api/bay/bay.api";
import { setBaysAvailableForVehicleRedux } from "react/redux/reducers/confirmStartParkingView";
import { getUserCards, getUserVehicles } from "react/redux/actions/user";
import Dialog from "react/components/dialog/Dialog";
import { Routes } from "react/navigation/root/root.paths";

const qrCodeLockup = require("react/resources/qrCodeLockup.png");

const QrManualScannerView = (props: AllViewProps) => {
    const dispatch = useAppDispatch();
    const navigation = useNavigation();
    const { preferences } = useReduxMaps();

    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const [showBayUnavailableDialog, setShowBayUnavailableDialog] = useState(false);
    const [qrAction, setQrAction] = useState<QRAction | null>(null);

    const { bays: baysAvailable } = useBaysAvailableToUser(qrAction && qrAction.actionParams && qrAction.actionParams.parkId
      ? Number(qrAction.actionParams.parkId)
      : null, { feature: Feature.ELECTRIC });

    useEffect(() => {
        if (qrAction && baysAvailable && qrAction.actionType === ActionType.StartCharging) {
            dispatch(getUserCards());
            dispatch(getUserVehicles());
            dispatch(setBaysAvailableForVehicleRedux(baysAvailable));
            const bay = baysAvailable.find((b) => b.id === Number(qrAction.actionParams.bayId));
            if (!bay) {
                setShowBayUnavailableDialog(true);
            } else {
                QRActionHandler(qrAction, navigation, preferences, bay);
            }
        } else if (qrAction) {
            QRActionHandler(qrAction, navigation, preferences);
        }
    }, [qrAction, baysAvailable]);

    const resetToMap = useCallback(() => {
        navigation.reset({
            routes: [{
                name: Routes.ParkableMapView
            }]
        });
    }, [navigation]);

    const submitCode = useCallback((code: string) => {
        Keyboard.dismiss();
        //submit code should call an API endpoint that returns a qrcode object that tells the app what to do. eg. could be a 'park type' that contains park id, or a 'charger type' that contains a park ID and a bay/charger id
        dispatch(getQRAction(
            code,
            (qrAction: QRAction) => {
                if (qrAction === null) {
                    setError(Strings.uninitialised_code);
                } else if (qrAction.status !== ActionStatus.Active) {
                    setError(Strings.code_disabled);
                } else {
                    setQrAction(qrAction);
                }
            },
            () => {
                setError(Strings.network_error);
            }
        ))
    }, []);

    return (
        <ParkableBaseView>
            <View>
                <View style={styles.imageContainer}>
                <Image
                    style={styles.image}
                    source={qrCodeLockup}/>
                </View>
                <Text
                    style={{
                        marginTop: 36,
                        marginHorizontal: 0,
                        textAlign: "center"
                    }}>
                    {props.route.params?.instructions ?? Strings.new_qr_code_location_desc}
                </Text>

                {error.length > 0 && (
                    <Text
                        style={{
                            alignSelf: "center",
                            marginTop: 9,
                            marginBottom: 9,
                            color: Colours.RED,
                        }}>
                        {error}
                    </Text>
                )}

                <View>
                    <Input
                        style={{
                            marginRight: 9,
                            marginTop: 9,
                        }}
                        autoCorrect={false}
                        autoCapitalize={"characters"}
                        maxLength={7}
                        onChangeText={(v) => {
                            setCode(v.trim().toUpperCase());
                        }}/>

                    <Button
                        disabled={code.length !== 7}
                        center
                        style={{
                            marginTop: 9,
                        }}
                        textProps={{ h4: true }}
                        onPress={() => submitCode(code)}>
                        {Strings.submit}
                    </Button>
                </View>
            </View>
            <Dialog
                isVisible={showBayUnavailableDialog}
                label={Strings.bay_not_available}
                labelProps={{
                    style: {
                        color: Colours.NEUTRALS_BLACK, textAlign: "left", borderBottomWidth: 1, borderBottomColor: Colours.GREY_10,
                    },
                }}
                title={Strings.bay_not_available_desc}
                titleProps={{ h2: undefined, small: true, style: { textAlign: "left", lineHeight: 24 } }}
                positiveProps={{
                    textProps: {
                        h5: true,
                        style: { color: Colours.NEUTRALS_BLACK },
                    },
                    style: { backgroundColor: Colours.ORANGE },
                }}
                positiveText={Strings.close}
                onPositivePress={() => {
                    setShowBayUnavailableDialog(false);
                    resetToMap();
                }}
            />
        </ParkableBaseView>
    );
}

export default QrManualScannerView as React.FunctionComponent<AllViewProps>;

const styles = StyleSheet.create({
    mapLinkTableRow: {
        borderBottomWidth: 1,
        borderBottomColor: Colours.GREY_BORDER,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingVertical: 9,
    },
    imageScrollView: {
        marginTop: 9,
        marginBottom: 9,
        height: 120,
        width: "100%",
    },
    standardIcon: {
        fontFamily:
            Platform.OS === "ios"
                ? "parkable-icon-library"
                : "parkable-icon-library",
        fontSize: 30,
    },
    reservation: {
        backgroundColor: Colours.ORANGE,
    },
    imageContainer: {
        flexDirection: "row",
    },
    image: {
        flex: 1,
        zIndex: -1,
        resizeMode: "contain",
        aspectRatio: 249 / 153,
        height: 200,
    },
});
