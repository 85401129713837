import React from "react";
import { useTandemPod } from "../../api/tandem/tandem.api";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Spinner from "react/parkable-components/spinner/Spinner";
import Colours from "react/parkable-components/styles/Colours";
import Strings from "../../constants/localization/localization";

type Props = {
    currentlySelected: boolean;
    tandemPodId: number;
    onTandemPodSelected: (podId: number) => void;
    baySignages: string[];
};

const TandemPodOption = (props: Props) => {
    const { currentlySelected, tandemPodId, onTandemPodSelected, baySignages } = props;
    const { tandemPod, isLoading } = useTandemPod(tandemPodId);

    if (isLoading || !tandemPod) {
        return <Spinner />;
    }

    const handleButtonPress = () => {
        onTandemPodSelected(tandemPodId);
    };

    return (
        <TableRow
            textProps={{ style: { fontWeight: "500" } }}
            label={Strings.tandem_parking.tandem}
            buttonText={currentlySelected ? Strings.selected : Strings.select}
            buttonProps={{
                iconRight: currentlySelected ? undefined : "cheveronright",
                style: {
                    width: 110,
                    backgroundColor: currentlySelected
                        ? Colours.GREY_20
                        : Colours.GREEN_300,
                },
            }}
            onButtonPress={currentlySelected ? undefined : handleButtonPress}
        >
            {baySignages.filter(item => item !== null).join(", ")}
        </TableRow>
    );
};

export default TandemPodOption;
