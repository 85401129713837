import {connect} from "react-redux";
import {IRootReducer} from "../../redux/reducers/main";
import {Image, StyleSheet, View} from "react-native";
import React from "react";
import Text from "react/parkable-components/text/Text";
import Strings from '../../constants/localization/localization';
import Colours from "react/parkable-components/styles/Colours";
import {useUserRewards} from "../../api/rewards/reward.api";
import {SharingCreditActivityView} from "./SharingCreditActivityView";
import {usePark} from "../../api/park/park.api";
import {useTerritory} from "../../api/territory/territory.api";
import { createRoute, NavigationProps } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import ParkableBaseView from "../common/ParkableBaseView";
import {PADDING} from "../../root/root.constants";
import { useParkSessions } from "react/api/parkSession/parkSession.api";
import _ from "lodash";
import { EmployeeSubscriptionDTO } from "../../dto/EmployeeSubscriptionDTO";

const MaskGroup = require("../../resources/Mask-Group-15478.png");

const getReduxProps = (state: IRootReducer) => {
    const currentUser = state.user.user;
    return {
        currentUser,
    };
}

class SubscriptionSharingCreditViewProps {
    employeeSubscription: EmployeeSubscriptionDTO;
    parkId: number;
}

type OwnProps = NavigationProps<Routes.SubscriptionSharingCreditView>
    & ReturnType<typeof getReduxProps>

const _SubscriptionSharingCreditView = (props: OwnProps) => {

    const {employeeSubscription, parkId} = props.route.params ?? {}

    const {bonusBays,rewards: _rewards}= useUserRewards(props.currentUser?.id)
    const {park} = usePark(parkId)
    const {territory} = useTerritory(park?.territory)
    const currencySymbol = territory?.currencySymbol ?? '$'

    const bonusBaysBySubscriptionId = bonusBays?.filter(bb => bb.employeeSubscription === employeeSubscription?.id)
    const bonusBayIds = bonusBaysBySubscriptionId?.map(bb => bb.id)
    const rewards = _.orderBy(
        _rewards?.filter(r => r.rewardType === "DiscountNextPayment" && bonusBayIds?.includes(r.bonusBay)), ['availableAt'], ['desc'])

        const activeRewards = rewards?.filter(r => r.status === "Active") //include only sharing for discount
    const redeemedRewards = rewards?.filter(r => r.status === "FullyRedeemed") //include only sharing for discount

    const currentBalance = activeRewards?.length ? activeRewards.map(r => r.amount - r.redeemedAmount).reduce((sum,amount) => sum + amount)/100 : 0;

    return <ParkableBaseView removeStandardMargins toolbarStyle={{marginLeft: PADDING}}>

        <View style={{flex: 1}}>
            <View style={{paddingHorizontal: PADDING}}>
                <Text bold h1>{Strings.sharing_credit.my_sharing_credit}</Text>
                <Text p grey style={styles.sharingCreditDescription}>{Strings.sharing_credit.sharing_credit_description}</Text>

                <Text center bold>{Strings.sharing_credit.your_sharing_credit_is}</Text>
                <Text bold center style={styles.currentCreditNumber} allowFontScaling={false}>
                    { currencySymbol + currentBalance}
                </Text>
            </View>

            <View style={{flexDirection: "row"}}>
                <Image style={styles.handsImage} source={MaskGroup}/>
            </View>

            <View style={{paddingHorizontal: PADDING}}>
                <Text bold h2 style={styles.activityLabel}>{Strings.rewards.activity}</Text>

            {activeRewards?.map(reward => {
                return <SharingCreditActivityView
                creditReward={reward}
                currencySymbol={currencySymbol}
                />
            })}

            {!!redeemedRewards?.length && <SharingCreditActivityView
                discountRewards={redeemedRewards}
                currencySymbol={currencySymbol}
                />
                }
            </View>
        </View>
    </ParkableBaseView>
}

export const SubscriptionSharingCreditView = connect(getReduxProps)(
    _SubscriptionSharingCreditView
)

export const SubscriptionSharingCreditRoute = createRoute({
    path: Routes.SubscriptionSharingCreditView,
    params: { type: SubscriptionSharingCreditViewProps }
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    sharingCreditDescription: {
        lineHeight: 26
    },
    currentCreditNumber: {
        fontSize: 100,
        marginTop: 18,
        lineHeight: 100,
    },
    handsImage: {
        flex: 1,
        aspectRatio: 375/168,
        zIndex: 1,
    },
    activityLabel:{
        marginTop:27,
        marginBottom:0
    },
    noActivity:{
        marginTop:10
    }
});
