import React, { useCallback, useEffect, useReducer, useState } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import Colours from "react/parkable-components/styles/Colours";
import Spinner from "react/parkable-components/spinner/Spinner";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Text from "react/parkable-components/text/Text";
import SubscriptionView from "./SubscriptionView";
import { connect } from "react-redux";
import { IRootReducer } from "react/redux/reducers/main";
import * as Push from "../../../navigation/pushNotifications/constants";
import { addNotificationListener } from "react/navigation/pushNotifications/notificationListener";
import { getBay } from "react/redux/actions/parks";
import { getEmployeeSubscription, getEmployeeSubscriptionsByUser } from "react/redux/actions/subscriptions";
import { showConfirmation } from "react/alerts";
import Strings from "../../../constants/localization/localization";
import Constants from "../../../constants/constants";
import { retrieveLargestDiscountVoucher } from "react/redux/actions/vouchers";
import { ActivityType } from "react/model/Voucher";
import { getNextPaymentDate } from "./getNextPaymentDate";
import AppReview from "../../common/AppReview";
import { createRoute, NavigationProps } from "react/navigation/constants";
import { Routes } from "react/navigation/root/root.paths";
import ParkableBaseView from "../../common/ParkableBaseView";
import { PADDING } from "react/root/root.constants";
import {useUserOptions} from "../../../api/userOptions";
import { Platform } from "expo-modules-core";
import { EmployeeSubscriptionDTO } from "../../../dto/EmployeeSubscriptionDTO";

type AllProps = NavigationProps<Routes.SubscriptionListView> & ReturnType<typeof getReduxProps> & typeof actions;

function ListSubscriptionView(props: AllProps) {
    const {
        getBay,
        getEmployeeSubscription,
        getEmployeeSubscriptionsByUser,
        retrieveLargestDiscountVoucher,
        employeeSubscriptions
    } = props;

    const limit = 2;
    const [data, setData] = useState<Array<EmployeeSubscriptionDTO>>([]);
    const { userOptions } = useUserOptions();
    const [page, setPage] = useState(1);
    const [didInitialLoad, setDidInitialLoad] = useReducer(() => true, false);
    const [loading, setLoading] = useState(false);
    const [voucherFetched, setVoucherFetched] = useState<{[subscriptionId: number]: boolean}>({});

    const retrieveVoucherForNextPayment = (subscription: EmployeeSubscriptionDTO) => {
        const isEnded = (subscription.status === "Ended" || subscription.status === "Deleted" || subscription.status === "Unpaid");
        if(!voucherFetched[subscription.id] && !isEnded) {
            voucherFetched[subscription.id] = true;
            setVoucherFetched(voucherFetched);
            const parkId = subscription?.park;
            retrieveLargestDiscountVoucher(parkId, ActivityType.LongTerm, subscription.id, getNextPaymentDate(subscription.term, subscription.anchorDay, subscription.weekAnchorDay));
        }
    };

    const getSubscriptionsByUser = () => {
        getEmployeeSubscriptionsByUser((subscriptions: EmployeeSubscriptionDTO[]) =>{
            Object.values(subscriptions || {})
                .forEach((subscription) => {
                    retrieveVoucherForNextPayment(subscription)
                })
        });
    };

    useEffect(() => {
        getSubscriptionsByUser();
    }, [props.user]);


    const compareDate = useCallback((d1:string|null, d2:string|null): number => {
        if(!d1 || !d2) return 0;
        const t1 = Date.parse(d1);
        const t2 = Date.parse(d2);
        if(isNaN(t1)) return -1;
        if(isNaN(t2)) return 1;
        return t1 - t2
    }, []);

    const subscriptions = employeeSubscriptions
        .sort((s1, s2) => {
            switch (s1.status) {
                case Constants.Subscription_Status_Invited: {
                    if (s2.status !== Constants.Subscription_Status_Invited) {
                        return -1;
                    } else {
                        return compareDate(s1.termStartedAt, s2.termStartedAt);
                    }
                }
                case Constants.Subscription_Status_Pending: {
                    if (s2.status !== Constants.Subscription_Status_Pending) {
                        return -1;
                    } else {
                        return compareDate(s1.termStartedAt, s2.termStartedAt);
                    }
                }
                case Constants.Subscription_Status_PastDue: {
                    if (s2.status !== Constants.Subscription_Status_PastDue) {
                        return -1;
                    } else {
                        return compareDate(s1.termStartedAt, s2.termStartedAt);
                    }
                }
                case Constants.Subscription_Status_Active:{
                    if (s2.status !== Constants.Subscription_Status_Active) {
                        return -1;
                    } else {
                        return compareDate(s1.termStartedAt, s2.termStartedAt);
                    }
                }
                case Constants.Subscription_Status_HostEnding:{
                    if (s2.status !== Constants.Subscription_Status_HostEnding) {
                        return -1;
                    } else {
                        return compareDate(s1.termStartedAt, s2.termStartedAt);
                    }
                }
                case Constants.Subscription_Status_LeaserEnding:{
                    if (s2.status !== Constants.Subscription_Status_LeaserEnding) {
                        return -1;
                    } else {
                        return compareDate(s1.termStartedAt, s2.termStartedAt);
                    }
                }
                default: {
                    if(Constants.Ended_Subscription_Status_Array.includes(s2.status)){
                        return compareDate(s1.termStartedAt, s2.termStartedAt);
                    }else{
                        return 1;
                    }
                }
            }
        }
    );

    const loadMore = (thePage: number) => {
        return subscriptions.slice((thePage - 1) * limit, thePage * limit);
    }

    useEffect(() => {
        if (employeeSubscriptions.length && !didInitialLoad) {
            setData(loadMore(page));
            setDidInitialLoad();
        }
    }, [employeeSubscriptions]);

    useEffect(() => {
        const notifListener = addNotificationListener(onNotificationReceived, 'SubscriptionsListView');

        return () => { notifListener.remove()};
    }, []);

    const onNotificationReceived = useCallback((code: string, notif: any) => {
        if (code === Push.EmployeeSubscriptionCreated || code === Push.EmployeeSubscriptionUpdated || code === Push.EmployeeSubscriptionCancelled) {
            if(code === Push.EmployeeSubscriptionUpdated) {
                showConfirmation(notif.body);
            }
            getEmployeeSubscription(notif.employeeSubscriptionId, onSuccessGetEmployeeSubscription);
        }

        if(code === Push.UnpaidEmployeeSubscription) {
            getSubscriptionsByUser();
        }
    }, [props.user]);

    const onSuccessGetEmployeeSubscription = useCallback((subscription: EmployeeSubscriptionDTO) => {
        if (!!subscription.park && !!subscription.bay) {
            getBay(subscription.park, subscription.bay);
            retrieveVoucherForNextPayment(subscription);
        }
    }, []);

    const renderItem = (data: any) => {
        return <View style={{flex:1}}>
            <SubscriptionView style={styles.item}
                                 key={`${data.index}_${data.item.id}`}
                                 subscriptionId={data.item.id}
            />
        </View>
    }

    const handleLoadMore = async () => {
        if(!loading){
            await setLoading(true);
            await setPage(page + 1);
            await setData(data.concat(loadMore(page + 1)));
            await setLoading(false);
        }
    }

    const renderFooterList = () => <View style={{flex:1}}>
        {subscriptions.length !== data.length && <TableRow contentLeft={<Spinner/>}>
            {Strings.loading}
        </TableRow>}
    </View>

    return (
        <ParkableBaseView scrollable={Platform.OS === "web"} removeStandardMargins toolbarStyle={{marginLeft: PADDING}}>
            <View>
                <Text h1 bold style={styles.header}>{Strings.my_subscriptions}</Text>
            </View>
            {subscriptions.length === 0 &&
                <Text style={styles.noSubscriptions}>{Strings.empty_subscription_list_display}</Text>}
            {!!subscriptions.length &&
                <View style={styles.subscriptionsList}>
                    {Platform.OS === "web" ? subscriptions.map((sub) => (
                        <SubscriptionView 
                            style={{}}
                            key={`${sub.id}`}
                            subscriptionId={sub.id}
                        />
                    )) :  
                    <FlatList
                        data={data}
                        renderItem={renderItem}
                        keyExtractor={(item, index) => `${item.id}_${index}`}
                        initialNumToRender={limit}
                        showsVerticalScrollIndicator={false}
                        onEndReached={handleLoadMore}
                        onEndReachedThreshold={0.5}
                        ListFooterComponent={renderFooterList}
                    />}
                </View>
            }
            {!!userOptions && subscriptions.length > 0 && <AppReview userOptions={userOptions}/>}
        </ParkableBaseView>
    );
}

const getReduxProps = (state: IRootReducer) => {

    const employeeSubscriptions = Object.values(state.subscriptions.employeeSubscriptions);
    return {
        user: state.user.user,
        data: state.data,
        parks: state.parks.parks,
        employeeSubscriptions: employeeSubscriptions,
        api: state.data.api
    }
};

const actions = {
    getBay,
    getEmployeeSubscription,
    getEmployeeSubscriptionsByUser,
    retrieveLargestDiscountVoucher
};

export default connect(getReduxProps, actions)(ListSubscriptionView);

export const SubscriptionListRoute = createRoute({
    path: Routes.SubscriptionListView,
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    header: {
        paddingBottom: 10,
        marginLeft: PADDING,
    },
    noSubscriptions: {
        paddingVertical: 10,
        paddingHorizontal: PADDING,
    },
    subscriptionsList: {
        flex: 1,
        backgroundColor: Colours.GREY_10
    },
    item: {
        marginBottom: 18
    },
});
