import Strings from "../../constants/localization/localization";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import Dialog from "react/components/dialog/Dialog";
import { Button, Colours, Text } from "react/parkable-components";
import { useUnshareBayContext } from "../parkingViews/Subscriptions/context";
import { changeParkingRequestBay, useBaysAvailableForParkingRequest } from "react/api/parking-request/parking-request.api";
import moment from "moment";


type UnshareBayDialogProps = {
    updateSharedDays?: () => void;
    updateSubscriptionDay?: (subscriptionId: number, availDays: string[], showConfirmation: boolean, onDone: () => void) => void,
    mutate: () => void;
}

export const UnshareBayDialog = (props: UnshareBayDialogProps) => {
    const { updateSharedDays, updateSubscriptionDay, mutate } = props

    const [unshareResult, setUnshareResult] = useState<boolean>(false);
    const [unshareLoading, setUnshareLoading] = useState<boolean>(false);
    const [unshareSuccess, setUnshareSuccess] = useState<boolean>(false);

    const context = useUnshareBayContext();
    const { bays } = useBaysAvailableForParkingRequest(context?.selectedDateRequest?.id)

    //remove the date from the selected days list that is being sent to backend
    const availableDaysWithoutUnsharedDates = context?.subscription ? context.subscription.availabilityRules?.availableTo
        ?.map((a) => a.date).filter((updatedDates) => updatedDates !== context?.unshareDate) : undefined


    const currentAvailableDays = context?.subscription?.availabilityRules?.availableTo.filter((day) => moment(day.date).isAfter(moment()))

    const onConfirmPress = async () => {
        setUnshareLoading(true)
        if (bays && bays?.length > 0) {
            if (!!updateSharedDays) {
                updateSharedDays()
                setUnshareLoading(false)
                context?.setShowUnshareDialog(false)
            }
            if (updateSubscriptionDay) {
                try {
                    if (context?.selectedDateRequest && context?.selectedDateRequest.park) {
                        await changeParkingRequestBay(context.selectedDateRequest.id, bays[0].id, context?.selectedDateRequest.park)
                        if (availableDaysWithoutUnsharedDates && context?.subscription) {
                            return updateSubscriptionDay(context.subscription.id, availableDaysWithoutUnsharedDates, false, () => {
                                setUnshareSuccess(true)
                                setUnshareLoading(false)
                                setUnshareResult(true)
                                mutate()
                            })
                        }
                    }
                } catch (error) {
                    setUnshareSuccess(false)
                    setUnshareLoading(false)
                    setUnshareResult(true)
                }
            }
        }
        else {
            setUnshareSuccess(false)
            setUnshareLoading(false)
            setUnshareResult(true)
        }
    }

    const onCancelPress = () => {
        context?.setUnshareDate('')
        context?.setShowUnshareDialog(false)
    }

    const showUnshareResultMessage = () => {
        if (unshareResult) {
            return unshareSuccess ? Strings.subscription_updated : Strings.could_not_unshare
        }
    }

    const showUnshareTitle = () => {
        if (unshareResult) {
            return unshareSuccess ? Strings.subscription_shared_for_x_days(currentAvailableDays?.length ?? 0) : Strings.unshare_fail
        }
    }

    return (
        <Dialog
            isVisible={context?.showUnshareDialog}
            label={showUnshareResultMessage()}
            labelProps={{ style: unshareSuccess ? styles.successMessage : styles.failMessage }}
            title={showUnshareTitle()}
            titleProps={{ style: styles.titleProp, h2: undefined }}
        >
            {!unshareResult && (
                <View>
                    <View style={{ paddingTop: 20, paddingHorizontal: 10, paddingBottom: 10 }}>
                        <Text h2 style={{ marginBottom: 10, textAlign: "left" }}>{Strings.your_bay_is_booked}</Text>
                        <Text h5 style={{ marginBottom: 15, color: Colours.GREY_80 }}>{Strings.unshare_modal_message1}</Text>
                        <Text h5 style={{ marginBottom: 8, color: Colours.GREY_80 }}>{Strings.unshare_modal_message2}</Text>
                    </View>
                    <View style={styles.buttonContainer}>
                        <Button plain style={styles.cancel} center border textProps={{ h4: true }} onPress={() => onCancelPress()}>
                            {Strings.cancel}
                        </Button>
                        <Button loading={unshareLoading} style={styles.unshare} center border textProps={{ h4: true }} onPress={() => onConfirmPress()}>
                            {Strings.unshare}
                        </Button>
                    </View>
                </View>
            )}

            {unshareResult && (
                <View>
                    <View style={styles.dialogView}>
                        <Button
                            center
                            plain
                            onPress={() => {
                                setUnshareSuccess(false)
                                context?.setShowUnshareDialog(false)
                                setTimeout(() => setUnshareResult(false), 700)
                            }}
                            style={unshareSuccess ? styles.successButton : styles.failButton}
                            textProps={unshareSuccess ? { white: true } : { white: false }}
                        >
                            {Strings.ok}
                        </Button>
                    </View>
                </View>
            )}
        </Dialog>
    )
}

const styles = StyleSheet.create({
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    unshare: {
        flex: 1,
        width: 'auto',
        color: Colours.NEUTRALS_WHITE,
        backgroundColor: Colours.GREEN_300,
        margin: 10
    },
    cancel: {
        flex: 1,
        width: 'auto',
        color: Colours.NEUTRALS_BLACK,
        backgroundColor: Colours.NEUTRALS_WHITE,
        margin: 10
    },
    successMessage: {
        textAlign: "left",
        color: Colours.GREEN_300
    },
    failMessage: {
        textAlign: "left",
        color: Colours.NEUTRALS_BLACK
    },
    failButton: {
        width: "auto",
        borderRadius: 3,
        borderWidth: 1,
        borderColor: Colours.GREY_BORDER,
        backgroundColor: Colours.NEUTRALS_WHITE
    },
    successButton: {
        width: "auto",
        backgroundColor: Colours.GREEN_300,
    },
    titleProp: {
        textAlign: "left",
    },
    dialogView: {
        paddingBottom: 15
    }
});
