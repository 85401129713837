/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 9/12/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { PropsWithChildren } from "react";
import { Dimensions, StyleSheet, View, ViewStyle } from "react-native";
import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import Card from "react/parkable-components/card/Card";
import Icon from "react/parkable-components/icon/Icon";
import Icons from "react/parkable-components/icon/Icons";
import classnames from "react/parkable-components/util/classnames";

const styles = StyleSheet.create({
    base: {
        backgroundColor: Colours.NEUTRALS_WHITE,
        alignItems: "center",
        flexDirection: "column",
        overflow: "hidden",
        // marginHorizontal: 10,
        borderColor: Colours.GREY_BORDER,
        borderWidth: 1,
        borderStyle: 'solid',
        paddingTop: 6,
        paddingBottom: 12,

    },
    green: {
        backgroundColor: Colours.GREEN_300,
        borderColor: 'transparent',
    },
    disabled: {
        backgroundColor: Colours.BUTTON_DISABLED,
        borderColor: 'transparent',
    },

    textBase: {
        marginBottom: 0,
        flexWrap: "wrap",
        fontSize: 15,
        lineHeight: 15,
        textAlign: "center",
    },
});

const cn = classnames(styles);

export type ButtonProps = PropsWithChildren<
    {
        onPress: () => void;
        icon: Icons;
        green?: boolean;
        disabled?: boolean;
        height?: number;
        style?: ViewStyle;
        width?: number
    }
>;




function ParkingTypeButton(props: ButtonProps) {

    const {
        children,
        onPress,
        icon,
        green,
        disabled,
        style,
    } = props || {};

    const cardStyle = cn.styles("base", {
        green,
        disabled
    });

    const textStyle = styles.textBase;

    return (
        <Card elevation={0} style={[cardStyle, { height: props.height }, style]} onPress={onPress}>
            <Icon
                name={icon}
                large
                color={green ? Colours.NEUTRALS_WHITE : Colours.NEUTRALS_BLACK
                    // || disabled ? Colours.GREY_50 : Colours.BLACK
                }
            />

            <Text
                white={!!green}
                bold
                h2={true}
                textBreakStrategy={"highQuality"}
                style={textStyle}>
                {children}
            </Text>
        </Card>
    );
}

export default React.memo(ParkingTypeButton);
