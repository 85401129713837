import { StyleSheet } from "react-native";
import { theme } from "../../../constants/theme";
import { FutureBookingStatus } from "./types";
import { darken } from "polished";

export const spinnerStyles = StyleSheet.create({
    spinnerContainer: {
        width: 45,
        height: 45,
        alignSelf: 'center'
    }
})

export const priorityBadgeStyles = StyleSheet.create({
    container: {
        position: "absolute",
        top: 1,
        right: 1,
        width: 12,
        height: 15,
        zIndex: 11,
    },
});

export const dayTextStyles = StyleSheet.create({
    container: {
        height: 28,
        padding: 0,
        margin: 0,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.GREY_10,
    },
    text: {
        textTransform: "uppercase",
        fontSize: 13,
        color: theme.palette.GREY_20,
    },
});

export const dayNumberStyles = StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    text: {
        fontSize: 20,
        color: theme.palette.NEUTRALS_BLACK,
    },
});


export const getTileColors = (
    status: FutureBookingStatus | undefined,
    isToday: boolean | undefined,
) => {
    const TEXT_DARKEN = 0.18;

    const defaultStyles = StyleSheet.create({
        container: { backgroundColor: theme.palette.NEUTRALS_WHITE },
        dayTextContainer: {
            backgroundColor: theme.palette.GREY_LIGHT,
        },
        dayText: {
            color: darken(TEXT_DARKEN, theme.palette.GREY_20),
        },
        dayNumber: { color: theme.palette.NEUTRALS_BLACK, },
        statusText: {
            color: theme.palette.NEUTRALS_WHITE,
        }
    });

    const confirmedStyles = StyleSheet.create({
        container: {
            borderColor: theme.palette.GREEN_300,
            backgroundColor: theme.palette.GREEN_100
        },
        dayTextContainer: {
            backgroundColor: theme.palette.GREEN_300,
        },
        dayText: { color: theme.palette.NEUTRALS_WHITE },
        dayNumber: { color: theme.palette.GREEN_300 },
        statusText: {
            color: theme.palette.GREEN_300,
        }
    });

    const waitlistStyles = StyleSheet.create({
        container: {
            backgroundColor: theme.palette.YELLOW,
            borderColor: theme.palette.ORANGE
        },
        dayTextContainer: {
            backgroundColor: theme.palette.ORANGE,
        },
        dayText: { color: theme.palette.NEUTRALS_WHITE },
        dayNumber: { color: theme.palette.ORANGE },
        statusText: {
            color: theme.palette.ORANGE,
        }
    });

    const requestedStyles = StyleSheet.create({
        container: {
            backgroundColor: theme.palette.BLUE_100,
            borderColor: theme.palette.BLUE_300,
        },
        dayTextContainer: {
            backgroundColor: theme.palette.BLUE_300,
        },
        dayText: { color: theme.palette.NEUTRALS_WHITE },
        dayNumber: { color: theme.palette.BLUE_300 },
        statusText: {
            color: theme.palette.BLUE_300,
        }
    });

    const isTodayStyles = StyleSheet.create({
        container: {
            backgroundColor: theme.palette.NEUTRALS_WHITE,
            borderColor: theme.palette.BLUE_300
        },
        dayTextContainer: {
            backgroundColor: theme.palette.BLUE_100,
        },
        dayText: { color: theme.palette.BLUE_300 },
        dayNumber: { color: theme.palette.BLUE_300 },
        statusText: {
            color: theme.palette.BLUE_300,
        }
    });


    if (isToday) {
        return isTodayStyles
    }

    switch (status) {
        case FutureBookingStatus.Confirmed: {
            return confirmedStyles
        }
        case FutureBookingStatus.PriorityRequested:
        case FutureBookingStatus.Requested: {
            return requestedStyles
        }
        case FutureBookingStatus.WaitListedNearTop:
        case FutureBookingStatus.WaitListed: {
            return waitlistStyles
        }

        default:
            return defaultStyles
    }
};

export const containerStyles = StyleSheet.create({
    base: {
        flex: 1,
        overflow: "hidden",
        maxHeight: 80,
        minHeight: 75,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: theme.palette.GREY_10,
        marginRight: theme.spacing(0.5),
        backgroundColor: theme.palette.NEUTRALS_WHITE,
    },
    isInPast: {
        opacity: 0.5,
    },
    isLoading: {
        alignItems: 'stretch',
        justifyContent: 'center',
    }
});
