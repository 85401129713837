import React from 'react';
import { View } from 'react-native';
import Strings from "../../constants/localization/localization";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "react/parkable-components/styles/Colours";
import { getTimeElapsed } from "./SessionElapsedFunctions";
import { OcpiSession } from "../../model/ocpi/OcpiSession";
import { ParkSessionDTO } from "../../model/ParkSessionDTO";
import { useCalculateEstimatedAmount } from "react/api/parkingPrice/parkingPrice.api";
import localizeCurrency from "react/constants/localization/localizeCurrency";
import { Routes } from "react/navigation/root/root.paths";
import { useNavigation } from "react/navigation/constants";
import { ParkingPriceBreakdownItemType } from "../../api/parkingPrice/dto/ParkingCost.response";

type Props = {
    session: ParkSessionDTO | null;
    park: { id: number; organisation: number | null; reservationMinutes: number | null } | null;
    isReservation: boolean;
    currencyCode?: string;
    ocpiSessions?: Array<OcpiSession>;
    parkId?: number;
};

export default function SessionElapsed(props: Props) {
    const { session, currencyCode, park, parkId } = props;
    const { cost, breakdown } = useCalculateEstimatedAmount({
        organisationId: park?.organisation,
        parkId,
        sessionId: session?.id,
    });

    const processingFee = breakdown?.find(p => p.type === ParkingPriceBreakdownItemType.ProcessingFee)
        ?.costInCents ?? 0;

    const navigation = useNavigation();

    if (!session || !park || cost === undefined) {
        return null;
    }
    const isFreeSession = cost === 0 && !!session.freeSession;
    const totalCost = (cost ?? 0) + (processingFee ?? 0);

    const elapsedText = getTimeElapsed(session, park);
    const currentCharge = isFreeSession ? Strings.free_session :
        localizeCurrency(totalCost, currencyCode ?? undefined, false, true)

    const onCasualPricePress = () => {
        navigation.push(Routes.PricingView, {
            parkId: park.id,
            parkingPrice: session.parkingPrice!,
            startTime: session.startedAt,
            bayId: session.bay
        });
    }

    return (
        <View style={{ width: '100%', }}>
            <TableRow iconLeft={"stopwatchfilled"} iconLeftProps={{ color: Colours.BLUE_300, }}
                label={Strings.time_elapsed} >{elapsedText}</TableRow>
            <TableRow iconLeft={"dollarfilled"}
                iconLeftProps={{ color: Colours.GREEN_300, }}
                onPress={onCasualPricePress}
                chevron
                chevronText={Strings.pricing}
                label={Strings.running_total} >
                {currentCharge}
            </TableRow>
        </View>
    );
}
