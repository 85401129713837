import Strings from "../../../constants/localization/localization";
import { IconName } from "react/parkable-components/icon/Icons";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Spinner from "react/parkable-components/spinner/Spinner";
import React, { useEffect, useState} from "react";
import moment from "moment/moment";
import {Term} from "../../../model/Term";

type AllProps = {
    endedDate: string | null,
    term: Term | undefined
}

export default function subscriptionEndedTableRow(props: AllProps){

    const [contentLeft, setContentLeft] = useState<JSX.Element | undefined>(<Spinner/>);
    const [label, setLabel] = useState("");
    const [icon, setIcon] = useState<IconName|undefined>(undefined);
    const [dateString, setDateString] = useState(Strings.loading);

    useEffect(() => {
        if(!!props.endedDate){
            setDateString(moment(props.endedDate).format("Do MMMM YYYY"));

            setLabel(Strings.subscription_ended_on);
            setContentLeft(undefined);
            setIcon("calendar");
        }
    }, [props.endedDate]);

    return (<TableRow contentLeft={contentLeft} iconLeft={icon}
                      label={label}>
        {dateString}
    </TableRow>)
}
