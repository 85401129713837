import React from 'react';
import { View } from 'react-native';
import AccountVehiclesCardItem from "./cardItems/AccountVehiclesCardItem";
import AccountCreditCardsCardItem from "./cardItems/AccountCreditCardsCardItem";
import { EmployeeSubscriptionDTO } from "../../dto/EmployeeSubscriptionDTO";

type UserDetailsCardProps = {
    hideVehicle?: boolean;
    hideCreditCard?: boolean;
    parkId?: number;
    subscription?: EmployeeSubscriptionDTO;
    selectSubscriptionVehicles?: boolean;
}

export default function UserDetailsCard(props: UserDetailsCardProps) {

    const {
        hideVehicle, hideCreditCard, parkId, subscription, selectSubscriptionVehicles,
    } = props;

    return (
        <View>
            {!hideVehicle && <AccountVehiclesCardItem
                parkId={parkId}
                subscription={subscription}
                selectSubscriptionVehicles={selectSubscriptionVehicles}
            />}
            {!hideCreditCard && <AccountCreditCardsCardItem />}

        </View>
    )
}
