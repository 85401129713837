import fetch from "../reducers/fetch";
import * as Api from "../../api/subscription";
import {showAlert} from "../../alerts";
import Strings from "../../constants/localization/localization";
import {DispatchFunc, SharingReminderDay, Term} from "../../model/Types";
import moment, {Moment} from "moment";
import {getCurrencyCode, logEvent} from "react/analytics";
import {Territory} from "../../model/Territory";
import {Token} from "../../api/rest";
import { EmployeeSubscriptionDTO } from "../../dto/EmployeeSubscriptionDTO";
import { EmployeeSubscriptionDTOResponse } from "../../dto/EmployeeSubscriptionDTOResponse";

export const SET_EMPLOYEE_SUBSCRIPTION_SESSION = 'SET_SUBSCRIPTION_SESSION';
export const SET_EMPLOYEE_SUBSCRIPTION = 'SET_EMPLOYEE_SUBSCRIPTION';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_EMPLOYEE_SUBSCRIPTIONS = 'SET_EMPLOYEE_SUBSCRIPTIONS';
export const SET_BAY_SHARE_MUTE_TODAY = 'SET_BAY_SHARE_MUTE_TODAY';


export function setEmployeeSubscription(employeeSubscription: EmployeeSubscriptionDTO) {
    return {
        type: SET_EMPLOYEE_SUBSCRIPTION,
        employeeSubscription
    }
}

export function createPublicSubscription (parkId: number, bayId: number, term: Term, pricePerTerm: number,
                                          startDate: Moment | undefined, territory: Pick<Territory, 'currencyCode'> | undefined,
                                          subscriptionVehicleIds: number[]) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.createPublicSubscriptionAPI(api, token, {
            parkId,
            bayId,
            term,
            start: startDate ? startDate.format("YYYY-MM-DD") : undefined,
            vehicleIds: subscriptionVehicleIds,
        }).then(({employeeSubscription}) => {

            sendSubStartedAnalyticsEvent('EmployeeSubscription',
                    employeeSubscription.owner,
                    employeeSubscription.id,
                    parkId,
                    bayId,
                    pricePerTerm,
                    territory,
                    'public');

            dispatch(setEmployeeSubscription(employeeSubscription));
            return Promise.resolve(employeeSubscription);
        }).catch((err) => {
            if (err !== 'network_error') {
                showAlert(Strings.subscription_not_created + " " + err?.message??'');
            } else {
                throw err;
            }
        });
    }, arguments, true);
}

function sendSubStartedAnalyticsEvent(
    type: string, userId: number, subscriptionId: number, parkId: number,
    bayId: number, pricePerTerm: number, territory: Pick<Territory, 'currencyCode'> | undefined,
    visibility: string) {
    try {
        const item = {
            item_id: `${subscriptionId}`,
            item_name: 'long-term',
            item_category: 'long-term',
            item_brand: 'Parkable',
            price: pricePerTerm/100
        };

        const firebaseParams = {
            //currency: territory?.currencyCode?.toUpperCase()??'NZD',
            value: pricePerTerm/100,
            items: [item],
            transaction_id: `${subscriptionId}`
        } as {[key: string]: any};

        const facebookParams = {
            fb_num_items: 1,
            fb_content_type: 'subscription',
            fb_content_id: `${parkId}`,
        } as {[key: string]: string | number}

        (async () => {
            const currencyCode = await getCurrencyCode(territory, parkId);
            if (currencyCode) {
                facebookParams.fb_currency = currencyCode.toUpperCase();
                firebaseParams.currency = currencyCode.toUpperCase();
                logEvent({price: pricePerTerm, currencyCode: currencyCode.toUpperCase()},'fb_mobile_purchase', facebookParams, 'purchase', firebaseParams);
            } else {
                console.log("unable to get currency code")
            }
        })();

    } catch (e) {
        console.log('error on send analytics', e);
    }
}

export function acceptEmployeeSubscription(subscriptionId: number, territory: Pick<Territory, 'currencyCode'> | undefined) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.acceptEmployeeSubscription(api, token, subscriptionId).then(({employeeSubscription}) => {
            sendSubStartedAnalyticsEvent('EmployeeSubscription', employeeSubscription.owner, employeeSubscription.id, employeeSubscription.park, employeeSubscription.bay, employeeSubscription.pricePerTerm,  territory, 'private');
            dispatch(setEmployeeSubscription(employeeSubscription));
            return Promise.resolve(employeeSubscription);
        })
    }, arguments)
}

export function getSessionInSubscriptionBay(employeeSubscriptionId: number) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getSessionInSubscriptionBayAPI(api, token, employeeSubscriptionId).then((r) => {
            dispatch(setSessionForEmployeeSubscription(employeeSubscriptionId, r.parkSession));
        });
    }, arguments);
}

export function setSessionForEmployeeSubscription(employeeSubscriptionId: number, session: any) {
    return {
        type: SET_EMPLOYEE_SUBSCRIPTION_SESSION,
        employeeSubscriptionId,
        session
    }
}

export function getEmployeeSubscription(id: number, onSuccess?: (sub: EmployeeSubscriptionDTO) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getEmployeeSubscriptionAPI(api, token, id).then((r) => {
            dispatch(setEmployeeSubscription(r.employeeSubscription));
            onSuccess?.(r.employeeSubscription);
            return new Promise(resolve => resolve(r.employeeSubscription))
        });
    }, arguments);
}

export function cancelEmployeeSubscription(subscriptionId: number, atTermEnd: boolean, leaveReason?: string) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.cancelEmployeeSubscription(api, token, subscriptionId, atTermEnd, leaveReason).then((result) => {
            dispatch(setEmployeeSubscription(result.employeeSubscription));
            return new Promise(resolve => resolve({...result}))
        })
    }, arguments, true) as Promise<EmployeeSubscriptionDTOResponse>
}

export function setEmployeeSubscriptions(employeeSubscriptions: EmployeeSubscriptionDTO[]) {
    return {
        type: SET_EMPLOYEE_SUBSCRIPTIONS,
        employeeSubscriptions
    }
}

export function getEmployeeSubscriptionsByUser(onSuccess?: any) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getEmployeeSubscriptionsByUserAPI(api, token, false).then((r) => {
            dispatch(setEmployeeSubscriptions(r.subscriptions));
            onSuccess?.(r.subscriptions);
            return r.subscriptions
        });
    }, arguments);
}

export function muteBayShareToday() {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        const today = moment().format('YYYY-MM-DD');
        dispatch(setBayShareMutedToday(today));
        return Promise.resolve();
    }, arguments);
}

function setBayShareMutedToday(today: any) {
    return {
        type: SET_BAY_SHARE_MUTE_TODAY,
        bayShareMutedAt: today
    }
}
