import React from "react";
import { Platform, StyleSheet } from "react-native";
import { useNavigation } from "react/navigation/constants";
import { Routes } from "react/navigation/root/root.paths";
import Colours from "react/parkable-components/styles/Colours";
import { FAB, Icons } from "../../parkable-components";

const styles = StyleSheet.create({
    base: {
        backgroundColor: Colours.NEUTRALS_WHITE,
        position: "absolute",
        borderRadius: 23,
        bottom: 45,
        right: Platform.OS === "web" ? 20 : 82,
        height: 45,
        justifyContent: "center",
        alignItems: "center",
        elevation: 0,
        flexDirection: undefined, //workaround for issue in react-native component library
    },
});

export default function () {
    const navigation = useNavigation();
    const onPress = () => {
        navigation.push(Routes.MapSettingsView, {});
    };

    return (
        <FAB
            style={styles.base}
            icon={Icons.settings}
            onPress={onPress}
            iconProps={{
                color: Colours.BLUE_300,
                iconStyle: {
                    fontSize: 18,
                },
            }}
        />
    );
}
