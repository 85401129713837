import React from "react";
import { StyleSheet, View } from "react-native";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import Strings from "react/constants/localization/localization";
import Radio, { RadioOption } from "react/parkable-components/radio/Radio";
import { spacing } from "react/constants/theme/spacing";

type RadioBlockParams = {
    isEV?: string;
    onEVChange: (value: string) => void;
    isMotorbike?: string;
    onMotorbikeChange: (value: string) => void;
};

export const VehicleRadioBlock = ({ isEV, onEVChange, isMotorbike, onMotorbikeChange }: RadioBlockParams) => {
    const vehicleOptions: RadioOption[] = [
        { label: Strings.this_is_car, value: "false" },
        { label: Strings.this_is_motorbike_or_scooter, value: "true" },
    ];
    const evOptions: RadioOption[] = [
        { label: Strings.yes, value: "true" },
        { label: Strings.no, value: "false" },
    ];

    return (
        <View>
            <View>
                <Text bold style={styles.radioHeader}>
                    {Strings.vehicle_type}
                </Text>
                <Text style={styles.helperText}>{Strings.what_type_of_vehicle}</Text>
                <Radio options={vehicleOptions} onChange={(e) => onMotorbikeChange(e.value)} value={isMotorbike} />
            </View>
            <View style={styles.radioEV}>
                <Text bold style={styles.radioHeader}>
                    {Strings.electric_vehicle}
                </Text>
                <Text style={styles.helperText}>{Strings.is_this_plug_in_electric_vehicle}</Text>
                <Radio options={evOptions} onChange={(e) => onEVChange(e.value)} value={isEV} />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    radioHeader: {
        color: Colours.NEUTRALS_900,
        fontSize: 18,
    },
    helperText: {
        fontSize: 16,
        color: Colours.NEUTRALS_500,
    },
    radioEV: { marginTop: spacing(3) },
});
