import { useMySWR } from "../http/use-swr";
import { generateQueryString, get, put } from "../http/rest";
import { Nully } from "react/constants/nully";
import { EmployeeSubscriptionDTOResponse } from "../../dto/EmployeeSubscriptionDTOResponse";
import { EmployeeSubscriptionDTOsResponse } from "../../dto/EmployeeSubscriptionDTOsResponse";

export const useEmployeeSubscriptions = () => {
    return useMySWR<EmployeeSubscriptionDTOsResponse>(`v3/employeesubscriptions?onlyActive=true`);
};

export const updateSubscriptionVehicles = (subscriptionId: number, vehicleIds: Nully<number[]>) => {
    const queryString = generateQueryString({ vehicleIds });
    return put<EmployeeSubscriptionDTOResponse>(`v3/employeesubscriptions/${subscriptionId}/update-vehicles${queryString}`);
};
