import React from "react";
import { KeyboardAvoidingView, Platform, ScrollView, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import Colours from "react/parkable-components/styles/Colours";
import { IconName } from "react/parkable-components/icon/Icons";
import useSafeArea from "../../hooks/useSafeArea";
import Toolbar from "../widgets/toolbar/Toolbar";
import { PADDING } from "../../root/root.constants";
import LoadingOverlay_new from "./LoadingOverlay_new";

class BaseViewProps {
    children?: any;
    loading?: boolean;
    removeStandardMargins?: boolean;
    style?: StyleProp<ViewStyle>;
    toolbarStyle?: StyleProp<ViewStyle>;
    scrollable?: boolean;
    onScroll?: () => void;
    showToolbar?: boolean;
    showBackButtonOnToolbar?: boolean;
    onBackPress?: () => void;
    backButtonOverride?: () => void;
    toolbarTitleText?: string;
    toolbarIconText?: string;
    toolbarIcon?: IconName;
    onIconClick?: () => void;
}

const ParkableBaseView = (props: BaseViewProps) => {
    const {top, bottom} = useSafeArea();
    const {
        children,
        loading,
        removeStandardMargins,
        style,
        toolbarStyle,
        scrollable,
        onScroll,
        showToolbar,
        showBackButtonOnToolbar,
        onBackPress,
        backButtonOverride,
        toolbarTitleText,
        toolbarIconText,
        toolbarIcon,
        onIconClick,
    } = props;

    const toolbar = (showToolbar ?? true) && <Toolbar
        style={toolbarStyle}
        showBackButton={showBackButtonOnToolbar}
        onBackPress={onBackPress}
        backButtonOverride={backButtonOverride}
        toolbarTitleText={toolbarTitleText}
        toolbarIconText={toolbarIconText}
        toolbarIcon={toolbarIcon}
        onIconClick={onIconClick}
    />;

    const outerViewStyles = [
        {marginTop: top, paddingBottom: Math.max(bottom, PADDING)},
        ((removeStandardMargins ?? false) ? {} : {paddingHorizontal: PADDING}),
        styles.innerView,
    ];

    return <>
        {loading && <LoadingOverlay_new leaveStatusBarAlone={false} loading={loading} />}
        <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : "height"}
            style={[styles.parkableBaseView, style]}>
            {(scrollable ?? true)
                ? <ScrollView
                    alwaysBounceVertical={false}
                    onScroll={onScroll}
                    style={outerViewStyles}
                    keyboardShouldPersistTaps='handled'
                    contentInset={{ top: 0, left: 0, bottom: 60, right: 0 }}>
                    {toolbar}
                    {children}
                </ScrollView>
                : <View style={outerViewStyles}>
                    {toolbar}
                    {children}
                </View>}
        </KeyboardAvoidingView>
    </>
};

export default ParkableBaseView;

const styles = StyleSheet.create({
    parkableBaseView: {
        width: "100%",
        height: "100%",
        backgroundColor: Colours.NEUTRALS_WHITE,
    },
    innerView: {
        flex: 1,
    },
});
