import { StyleProp, StyleSheet, TextStyle, ViewStyle, } from "react-native";
import Colours from "react/parkable-components/styles/Colours";

const iconStyles = StyleSheet.create({
	base: {
		height: 31,
		justifyContent: "center",
		alignItems: "center",
		paddingTop: 4,
		paddingBottom: 4,
	},

	active: {
		backgroundColor: Colours.GREEN_300,
	},
	inactive: {
		backgroundColor: Colours.BUTTON_DISABLED,
	},

	orgMemberBase: {
		height: 42,
	},
	orgMemberActive: {
		backgroundColor: Colours.PINK,
	},

	favoritePark: {
		backgroundColor: Colours.PURPLE,
	},
});

export const generateIconStyle = (args: {
	isOrgMember?: boolean;
	isFavouritePark?: boolean;
	isParkUnavailable?: boolean;
}) => {
	const styles: StyleProp<ViewStyle> = [iconStyles.base];

	if (args.isOrgMember) {
		return args.isParkUnavailable
			? [...styles, iconStyles.orgMemberBase, iconStyles.inactive]
			: [...styles, iconStyles.orgMemberBase, iconStyles.orgMemberActive]

	}

	if (args.isFavouritePark) {
		return args.isParkUnavailable
			? [...styles, iconStyles.favoritePark, iconStyles.inactive]
			: [...styles, iconStyles.favoritePark]
	}

	if (args.isParkUnavailable) {
		return [...styles, iconStyles.inactive]
	}

	return [...styles, iconStyles.active];
};

const arrowStyles = StyleSheet.create({
	container: {
		height: 0,
		alignItems: "center",
	},
	arrowDown: {
		width: 0,
		height: 0,
		top: -1,
		borderStyle: "solid",
		borderLeftWidth: 5,
		borderRightWidth: 5,
		borderBottomWidth: 6,
		borderLeftColor: "transparent",
		borderRightColor: "transparent",
		borderBottomColor: Colours.NEUTRALS_WHITE,
		transform: [{ rotate: "180deg" }],
	},
	inactiveArrowDown: {
		borderBottomColor: Colours.BUTTON_DISABLED,
	},
});

export const generateArrowStyles = (args: {
	isParkUnavailable?: boolean;
}): StyleProp<ViewStyle> => {
	if (args.isParkUnavailable) {
		return [arrowStyles.arrowDown, arrowStyles.inactiveArrowDown];
	}
	return [arrowStyles.arrowDown];
};

const textPanelStyles = StyleSheet.create({
	textPanel: {
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		width: "100%",
		height: 17,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: Colours.NEUTRALS_WHITE,
	},
	inactiveTextPanel: {
		backgroundColor: Colours.BUTTON_DISABLED,
	},
	orgMemberpanel: {
		backgroundColor: Colours.NEUTRALS_WHITE,
		height: 6
	},
});

export const generateTextPanelStyles = (args: {
	isOrgMember?: boolean;
	isParkUnavailable?: boolean;
}): StyleProp<ViewStyle> => {
	const styles: StyleProp<ViewStyle> = [textPanelStyles.textPanel];

	if (args.isOrgMember) {
		styles.push(textPanelStyles.orgMemberpanel);
	}
	if (args.isParkUnavailable) {
		styles.push(textPanelStyles.inactiveTextPanel);
	}
	return styles;
};

const textStyles = StyleSheet.create({
	markerText: {
		color: Colours.NEUTRALS_BLACK,
		fontWeight: "bold",
		textTransform: "uppercase",
		fontSize: 12,
		letterSpacing: 0.5,
	},
	inactiveMarkerText: {
		color: Colours.GREY_50,
	},
});

export const generateTextStyles = (args: {
	isParkUnavailable?: boolean;
}): StyleProp<TextStyle> => {
	const styles: StyleProp<TextStyle> = [textStyles.markerText];

	if (args.isParkUnavailable) {
		return [...styles, textStyles.inactiveMarkerText];
	}

	return styles;
};

export const generateCommonPinStyles = (
	args: Parameters<typeof generateTextStyles>[0] &
		Parameters<typeof generateTextPanelStyles>[0] &
		Parameters<typeof generateArrowStyles>[0] &
		Parameters<typeof generateIconStyle>[0]
) => {
	const { isFavouritePark, isOrgMember, isParkUnavailable } = args;
	const iconStyle = generateIconStyle({
		isFavouritePark,
		isOrgMember,
		isParkUnavailable,
	});
	const textPanelStyle = generateTextPanelStyles({
		isOrgMember,
		isParkUnavailable,
	});
	const textStyle = generateTextStyles({ isParkUnavailable });
	const arrowStyle = generateArrowStyles({ isParkUnavailable });
	const arrowContainerStyle = arrowStyles.container;
	const iconColor = isParkUnavailable ? Colours.GREY_50 : Colours.NEUTRALS_WHITE;

	return {
		iconStyle,
		iconColor,
		textPanelStyle,
		textStyle,
		arrowStyle,
		arrowContainerStyle,
	};
};

export const markerStyles = StyleSheet.create({
	container: {
		// Corrects top-left anchor position
		marginTop: -54,
		marginLeft: -21,
		width: 42,
		borderRadius: 3,
		alignItems: "stretch",

		shadowColor: Colours.NEUTRALS_BLACK,
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.4,
		shadowRadius: 6,
	},
	innerContainer: {
		borderRadius: 3,
		overflow: "hidden",
	},
});
