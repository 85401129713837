import { GroupRole, OrganisationRole } from "../../../model/User";
import {BlockDTO} from "../../vehicle/dto/vehicleDTO";

export interface UserDTOResponse {
    user: UserDTO
}

export interface UserDTO {
    id: number,
    userName: string,
    userNameLowercase: string,
    email: string,
    createdAt: Date,
    roles: Array<string> | null,
    vehicleId: number | null,
    referralCode: string | null,
    hostData: number | null,
    stripeCustomerId: string | null,
    groups: GroupRole[] | null,
    organisations: OrganisationRole[] | null,
    vouchers: Array<number> | null,
    firstName: string | null,
    lastName: string | null,
    phone: string | null,
    dateFormat: string | null,
    localeIdentifier: string | null,
    block: BlockDTO | null,
    active: boolean | null,
    acceptedTsAndCs?: boolean,
    authChangesAllowed?: boolean,//this is only on the DTO, not the User entity
    hasAcceptedTsAndCs?: boolean,
    lastAcceptedTsAndCs: string | null,
    companyDetails?: string,
    pendingEmail?: string,
    firebaseUid?: boolean,
    countryCode?: string,
}

export type RetrieveUserRolesResponse = {
    userRoles: UserRoleDTO[];
}

export interface UserRoleDTO {
    id: number,
    user: number,
    roleId: number,
    organisation: number | null,
    campus: number | null,
    park: number | null,
    userGroup: number | null,
    suspended: boolean,
    role: RoleDTO,
}

export enum AccessLevel {
    Global = "Global",
    Organisation = "Organisation",
    Campus = "Campus",
    Park = "Park",
    UserGroup = "UserGroup"
}

export interface RoleDTO {
    id: number,
    name: string,
    description: string,
    accessLevel: AccessLevel,
    permissions: string[],

}

export interface LastUpdatedResponse {
    lastUpdated: string;
}
