import React, {useEffect, useRef, useState} from 'react';
import {TableRow, Text} from 'react/parkable-components';
import Strings from "../../constants/localization/localization";
import {View} from 'react-native';
import {BayActivityDTO} from "../../model/BayActivityDTO";
import {getDiffMinutesRounded} from "../../constants/Util";
import moment from 'moment-timezone';
import {Moment} from "moment";
import Colours from "react/parkable-components/styles/Colours";

type Props = {
    bayActivity: BayActivityDTO,
    endHold: Moment
}

export default function HoldBayTableRow({ bayActivity, endHold }: Props) {

    const [count, setCount] = useState(1);
    const countRef = useRef(count);
    countRef.current = count;

    useEffect( () => {
            const timer = setInterval(() => {
                setCount((countRef.current+1)%4);
            }, 300);

            return () => clearTimeout(timer);
        }, ["once"]
    );

    let time = "0.00";
    let expired = true;
    if(!!bayActivity.hold && !!bayActivity.hold.startDate) {
        const now = moment() as Moment;

        if(endHold.diff(now, "seconds") > 0){
            time = getDiffMinutesRounded(now, endHold);
            expired = false;
        }
    }

    return (<View style={{paddingLeft:28, marginBottom: 18, backgroundColor: expired ? Colours.ROSE : Colours.GREY_10}}>
        <TableRow textProps={{ small: true, grey:true }}
                  contentLeft={<View style={{flexDirection: "row"}}>
                      <Text small grey style={{paddingRight:5}}>{Strings.holding_bay_for}</Text><Text small>{Strings.format_mins(time)}</Text>
                  </View>} />
    </View>);
}
